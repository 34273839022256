import React, {useState, useEffect, useContext} from 'react';
import chimera from '../../chimera';
import LoadingSpinner from '../LoadingSpinner';
import BannerContext, { BannerLog } from "../BannerLogContext";
import ReportTable from '../ReportTable';

const ReportTableListBody = props => {
    const [tables, setTables] = useState(null);
    const [loading, setLoading] = useState(true);
    const banners = useContext(BannerContext);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        chimera.callAPI(signal, `/api/reporttables/type/${props.type}`)
        .then(reports => {
            setTables(reports);
            setLoading(false);
        })
        .catch(e => {
            if(e.name !== "AbortError") {
                console.error(e);
                banners.addBanner('danger', 'Failed to read reports from server', 'Error');
            }
        })
        return () => {
            controller.abort();
        }
    }, []);

    const dateStr = (str) => {
        const date = new Date(str);
        return date.toLocaleString();
    }

    return (
        <div>
        {loading ? 
            <LoadingSpinner size={75}/>
        :
        <>
            {tables.map((table, i) => <div id={`tableAccordion${i}`}>
                <div className="card mb-2">
                    <a data-bs-toggle="collapse" href={`#table${i}`}>
                        <div className="card-header">
                            <span className="btn">{dateStr(table.createdAt)}</span>
                        </div>
                    </a>
                    <div id={`table${i}`} className="collapse" data-bs-parent={`#tableAccordion${i}`}>
                        <div className="card-body text-start">
                            <ReportTable report={table}/>
                        </div>
                    </div>
                </div>
            </div>)}
        </>
        }
        </div>
    )
}

const ReportsTableList = props => {
    return (
        <BannerLog>
            <ReportTableListBody type={props.type}/>
        </BannerLog>
    )
}

export default ReportsTableList;