import React, {useState, useEffect, useContext, useLayoutEffect} from "react";
import ToolPage from './ToolPage';
import LoadingSpinner from '../LoadingSpinner';
import ObjectTable from '../ObjectTable';
import chimera from "../../chimera";
import BannerContext from "../BannerLogContext";
import ButtonOptionList from "../ButtonOptionList";
import ModalCancelOnly from "../ModalCancelOnly";
import ModalContext from "../ModalContext";
import Autocomplete from "../Autocomplete";
import FormFieldMicro from "../FormFieldMicro";

function invoiceWasProcessed(invoice, agentAssociations) {
    for(const aa of agentAssociations) {
        for(const processedInvoice of aa.processedInvoices) {
            if(processedInvoice.id === invoice.Id) {
                return true;
            }
        }
    }
    return false;
}

const AgentAssociationModal = ({association, onClose}) => {
    const [customerName, setCustomerName] = useState(association ? association.customer.name : '');
    const [agentName, setAgentName] = useState(association ? association.agent.name : '');
    const [customerRef, setCustomerRef] = useState(association ? association.customer.ref : '');
    const [customerQbId, setCustomerQbId] = useState(association ? association.customer.qbId : '');
    const [agentRef, setAgentRef] = useState(association ? association.agent.ref : '');
    const [customers, setCustomers] = useState(null);
    const [vendors, setVendors] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const modaling = useContext(ModalContext);

    useEffect(() => {
        if(customers === null) {
            chimera.callAPI(undefined, '/api/customers')
            .then(newCustomers => setCustomers(newCustomers))
            .catch(err => {
                console.error(err);
                alert("ERROR: An unhandled exception has occurred when trying to read Chimera Customers. Please refresh and try again.");
            })
        }
    }, [customers]);

    useEffect(() => {
        if(vendors === null) {
            chimera.callQuickBooksAPI(undefined, '/api/qb/vendor')
            .then(newVendors => setVendors(newVendors))
            .catch(err => {
                console.error(err);
                alert("ERROR: An unhandled exception has occurred when trying to read QuickBooks Vendors. Please refresh and try again.");
            })
        }
    }, [vendors]);

    const customerSuggestionChosenCallback = (suggestion) => {
        const chosenCustomer = customers.find(customer => customer.displayName === suggestion);
        if(chosenCustomer) {
            setCustomerName(chosenCustomer.displayName);
            setCustomerRef(chosenCustomer.accountNumber);
            setCustomerQbId(chosenCustomer.integrationIds.quickbooks);
        }
    }

    const agentSuggestionChosenCallback = (suggestion) => {
        const chosenAgent = vendors.find(vendor => vendor.DisplayName === suggestion);
        if(chosenAgent) {
            setAgentName(chosenAgent.DisplayName);
            setAgentRef(chosenAgent.Id);
        }
    }

    const handleSave = async(e) => {
        e.preventDefault();
        setIsSaving(true);
        if(association) {
            // PUT
            await chimera.callAPI(undefined, `/api/agentassociations/${association._id}`, 'PUT', {
                customer: {
                    name: customerName,
                    ref: customerRef,
                    qbId: customerQbId
                },
                agent: {
                    name: agentName,
                    ref: agentRef
                }
            })
        }
        else {
            // POST
            await chimera.callAPI(undefined, '/api/agentassociations', 'POST', {
                customer: {
                    name: customerName,
                    ref: customerRef,
                    qbId: customerQbId
                },
                agent: {
                    name: agentName,
                    ref: agentRef
                }
            })
        }
        modaling.backtrack();
        onClose();
    }

    return <ModalCancelOnly context={modaling} onClose={onClose}>
        {customers !== null && vendors !== null ? 
        <>
            <div className="row">
                <div className="col">
                    <Autocomplete
                        label="Customer Name"
                        value={customerName}
                        suggestions={customers.map(customer => customer.displayName)}
                        suggestionChosenCallback={customerSuggestionChosenCallback}
                        strictMode
                        disabled={isSaving}
                        required
                    />
                </div>
                <div className="col text-start my-auto">
                    <b>Customer Account Number:</b>&nbsp;{customerRef ? customerRef : '(None)'}<br/>
                    <b>Customer QB ID:</b>&nbsp;{customerQbId ? customerQbId : '(None)'}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Autocomplete
                        label="Agent Name"
                        value={agentName}
                        suggestions={vendors.map(vendor => vendor.DisplayName)}
                        suggestionChosenCallback={agentSuggestionChosenCallback}
                        strictMode
                        disabled={isSaving}
                        required
                    />
                </div>
                <div className="col text-start my-auto">
                    <b>Agent QB ID:</b>&nbsp;{agentRef ? agentRef : '(None)'}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <p className="text-muted">
                        (NOTE: You must click to select an autocomplete suggestion in order to register changes to the Customer Account Number, Customer QB ID, and Agent ID, which are required fields that cannot be set to '(None)')
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <button className="btn btn-primary" onClick={handleSave} disabled={isSaving || !customerRef || !customerQbId || !agentRef}>
                        <i className={isSaving ? 'fas fa-spinner' : 'fas fa-floppy-disk'}/>&nbsp;{isSaving ? 'Saving...' : 'Save & Close'}
                    </button>
                </div>
            </div>
        </>
        :
            <LoadingSpinner size={75} label="Reading Chimera Customers & QuickBooks Vendors..."/>
        }
    </ModalCancelOnly>
}

const AgentAssociationRow = ({object, clickedObject}) => {
    const modaling = useContext(ModalContext);

    const openBills = (e) => {
        e.preventDefault();
        modaling.setModal(<ModalCancelOnly context={modaling}>
            <div className="row">
                <div className="col text-start">
                    <h4>Info</h4>
                    <p><b>Customer:</b>&nbsp;{object.customer.name}</p>
                    <p><b>Agent:</b>&nbsp;{object.agent.name}</p>
                    <h4>Bills</h4>
                    <ol>
                        {object.createdBills.sort((a,b) => a.createdAt < b.createdAt ? 1 : -1).map((bill, i) => <li key={i}><a href={`https://qbo.intuit.com/app/bill?txnId=${bill.id}`} target="_blank" rel="noopener noreferrer">Bill (ID: {bill.id}) created {bill.txnDate}</a></li>)}
                    </ol>
                </div>
            </div>
        </ModalCancelOnly>)
    }

    const openInvoices = (e) => {
        e.preventDefault();
        modaling.setModal(<ModalCancelOnly context={modaling}>
            <div className="row">
                <div className="col text-start">
                    <h4>Info</h4>
                    <p><b>Customer:</b>&nbsp;{object.customer.name}</p>
                    <p><b>Agent:</b>&nbsp;{object.agent.name}</p>
                    <h4>Invoices</h4>
                    <ol>
                        {object.processedInvoices.sort((a,b) => a.createdAt < b.createdAt ? 1 : -1).map((invoice, i) => <li key={i}><a href={`https://qbo.intuit.com/app/invoice?txnId=${invoice.id}`} target="_blank" rel="noopener noreferrer">Invoice #{invoice.docNumber} (ID: {invoice.id})</a></li>)}
                    </ol>
                </div>
            </div>
        </ModalCancelOnly>)
    }

    return (
        <>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.customer.name}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.agent.name}
            </td>
            <td>
                <button className="btn btn-primary" onClick={openBills}>
                    <i className="fas fa-magnifying-glass"/>&nbsp;View Bills
                </button>
            </td>
            <td>
            <button className="btn btn-primary" onClick={openInvoices}>
                    <i className="fas fa-magnifying-glass"/>&nbsp;View Invoices
                </button>
            </td>
        </>
    )
}

const StartScreen = ({setScreen, agentAssociations, isLoading}) => {
    return(
        <div>
            {isLoading ? 
                <LoadingSpinner size={75} label="Loading..."/>
            :
                <ButtonOptionList options={[
                    {
                        text: agentAssociations.length === 0 ? 'Run the Tool (requires Agent Associations)' : 'Run the Tool',
                        icon: 'fas fa-arrow-right',
                        disabled: agentAssociations.length === 0,
                        func: (e) => {
                            e.preventDefault();
                            setScreen('SELECT');
                        }
                    },
                    {
                        text: 'Manage Agent Associations',
                        icon: 'fas fa-gear',
                        func: (e) => {
                            e.preventDefault();
                            setScreen('AGENTS');
                        }
                    }
                ]}/>
            }
        </div>
    )
}

const InvoiceRow = ({object, clickedObject, agentAssociations}) => {
    return (
        <>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.CustomerRef.name}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.DocNumber}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.Id}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {chimera.dollarStr(object.TotalAmt)}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {invoiceWasProcessed(object, agentAssociations) ? 'Yes' : 'No'}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.TxnDate}
            </td>
        </>
    )
}

const SelectScreen = ({setScreen, agentAssociations, setParentSelectedInvoices}) => {
    let now = new Date();
    now.setMinutes(now.getMinutes()-now.getTimezoneOffset());
    let currentMonth = now.toISOString().split('-')[1];
    let currentYear = now.toISOString().split('-')[0];
    let firstOfTheMonth = `${currentYear}-${currentMonth}-01`;

    const [startDate, setStartDate] = useState(firstOfTheMonth);
    const [endDate, setEndDate] = useState(now.toISOString().substring(0,10));
    const [invoices, setInvoices] = useState(null);
    const [controller] = useState(new AbortController());
    const [signal] = useState(controller.signal);
    const banners = useContext(BannerContext);

    useEffect(() => {
        setInvoices(null);
    }, [startDate, endDate]);

    useEffect(() => {
        if(invoices === null) {
            chimera.callQuickBooksAPI(signal, '/api/qb/invoice/agenttoolquery', 'POST', {
                startDate,
                endDate, 
                customerIds: agentAssociations.map(aa => aa.customer.qbId)
            })
            .then(newInvoices => setInvoices(newInvoices))
            .catch(err => {
                if(err.name !== "AbortError") {
                    console.error(err);
                    banners.addBanner('danger', 'Failed to read invoices; the tool cannot continue.', 'Error');
                }
            })
        }
    }, [invoices]);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    const handleChange = (event) => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;

        if(name === "startDate") {
            setStartDate(value);
        }
        else if(name === "endDate") {
            setEndDate(value);
        }
    }

    const clicked = (clickedInvoice) => {
        window.open(`https://qbo.intuit.com/app/invoice?txnId=${clickedInvoice.Id}`, '_blank');
    }

    const runWithSelected = (selectedInvoices) => {
        // If selectedInvoices is empty, assume we want to run them all and use `invoices` instead.
        if(selectedInvoices.length === 0) {
            setParentSelectedInvoices(invoices);
        }
        else {
            setParentSelectedInvoices(selectedInvoices);
        }
        setScreen('RUNNING');
    }

    return(
        <div>
            <div className="row">
                <div className="col">
                    <button className="btn btn-secondary float-start" onClick={(e) => {e.preventDefault(); setScreen('START')}}>
                        <i className="fas fa-arrow-left"/>
                    </button>
                </div>
                <div className="col">
                    <h1>Select Invoices</h1>
                </div>
                <div className="col"></div>
            </div>
            <div className="row">
                <div className="col">
                    <FormFieldMicro
                        type="date"
                        name="startDate"
                        label="Start Date"
                        value={startDate}
                        handleChange={handleChange}
                    />
                </div>
                <div className="col">
                    <FormFieldMicro
                        type="date"
                        name="endDate"
                        label="End Date"
                        value={endDate}
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {invoices !== null ? 
                        <ObjectTable 
                            id="agentToolInvoicesTable"
                            cols={[
                                {
                                    label: 'Customer Name',
                                    sort: (ascending) => {
                                        return (a, b) => a.CustomerRef.name < b.CustomerRef.name ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                                    }
                                },
                                {
                                    label: 'Doc Number',
                                    sort: (ascending) => {
                                        return (a, b) => a.DocNumber < b.DocNumber ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                                    }
                                },
                                {
                                    label: 'ID',
                                    sort: (ascending) => {
                                        return (a, b) => a.Id < b.Id ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                                    }
                                },
                                {
                                    label: 'Total Amount',
                                    sort: (ascending) => {
                                        return (a, b) => a.TotalAmt < b.TotalAmt ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                                    }
                                },
                                {
                                    label: 'Processed?',
                                    sort: (ascending) => {
                                        return (a, b) => invoiceWasProcessed(a, agentAssociations) < invoiceWasProcessed(b, agentAssociations) ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                                    }
                                },
                                {
                                    label: 'Txn Date',
                                    sort: (ascending) => {
                                        return (a, b) => a.TxnDate < b.TxnDate ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                                    }
                                },
                            ]}
                            objects={invoices}
                            btns={[
                                {label: 'Process Invoices', func: runWithSelected, disabled: invoices.length === 0, icon: 'fas fa-arrow-right', color: 'primary'}
                            ]}
                            rowElement={InvoiceRow}
                            rowElementProps={{agentAssociations}}
                            clickedObject={clicked}
                            search
                            paginated
                            selectable
                            defaultSortByColName="Txn Date"
                            defaultSortAscending={false}
                            idPath="Id"
                        /> 
                    : 
                        <LoadingSpinner size={75} label="Loading Invoices..."/>
                    }
                </div>
            </div>
        </div>
    )
}

const RunningScreen = ({setScreen, agentAssociations, invoices, setParentResults}) => {
    const [label, setLabel] = useState("Running...");
    const [isError, setIsError] = useState(false);
    const banners = useContext(BannerContext);

    const monthToString = (month) => {
        switch(month) {
            case '01':
                return 'January';
            case '02':
                return 'February';
            case '03':
                return 'March';
            case '04':
                return 'April';
            case '05':
                return 'May';
            case '06':
                return 'June';
            case '07':
                return 'July';
            case '08':
                return 'August';
            case '09':
                return 'September';
            case '10':
                return 'October';
            case '11':
                return 'November';
            case '12':
                return 'December';
        }
        return '';
    }

    const composeBillLine = (customerName, nVoip, nFax, amount, months) => {
        let productsSold = '';
        if(nVoip > 0) {
            productsSold += `${nVoip} line${nVoip > 1 ? 's' : ''}`;
        }
        if(nFax > 0) {
            if(nVoip > 0) {
                productsSold += ', ';
            }
            productsSold += `${nFax} fax${nFax > 1 ? 'es' : ''}`;
        }
        let monthsByYear = {};
        for(const month of months) {
            const tokens = month.split('-');
            if(tokens.length === 2) { // expected format: 'mm-yyyy'
                const monthToken = tokens[0];
                const yearToken = tokens[1];
                if(monthsByYear[yearToken] === undefined) {
                    monthsByYear[yearToken] = [monthToken];
                }
                else {
                    monthsByYear[yearToken].push(monthToken);
                }
            }
        }
        let dateStrs = [];
        for(const year in monthsByYear) {
            let dateStr = '';
            const monthStrs = monthsByYear[year].map(month => monthToString(month));
            dateStr = `${monthStrs.join(', ')} ${year}`;
            dateStrs.push(dateStr);
        }
        return {
            Description: `Agent Ref Pro - ${customerName} - ${productsSold} - ${dateStrs.join('; ')}`,
            Amount: amount,
            DetailType: 'AccountBasedExpenseLineDetail',
            AccountBasedExpenseLineDetail: {
                ClassRef: {
                    value: '200000000001713630',
                    name: 'VOIP Expenses'
                },
                AccountRef: {
                    value: '97',
                    name: '6100 Outside Services'
                },
                BillableStatus: 'NotBillable',
                TaxCodeRef: {
                    value: 'NON'
                }
            }
        }
    }

    const createBill = (associationsForThisAgent, tspairs) => {
        // Save changes to processedInvoices and createdBills for all affected AgentAssociations
        // Create QB Bills
        return new Promise((resolve, reject) => {
            let lines = [];
            let processedInvoicesByQbCustomerId = {};
            for(const aa of associationsForThisAgent) {
                let nVoip = 0;
                let nFax = 0;
                let amount = 0;
                let months = [];
                const invoicesForThisCustomer = invoices.filter(invoice => invoice.CustomerRef.value === aa.customer.qbId);
                for(const invoice of invoicesForThisCustomer) {
                    let trackedThisMonth = false;
                    for(const line of invoice.Line) {
                        if(line.DetailType === "SalesItemLineDetail") {
                            for(const tspair of tspairs) {
                                const tokens = line.SalesItemLineDetail.ItemRef.name.split(':');
                                if(tokens[tokens.length-1] === tspair.item) {
                                    if(!trackedThisMonth) {
                                        const month = invoice.TxnDate.substring(5);
                                        if(!months.includes(month)) {
                                            months.push(month);
                                        }
                                        trackedThisMonth = true;
                                    }
                                    if(tspair.item.toLowerCase().startsWith('voip')) {
                                        nVoip++;
                                    }
                                    else if(tspair.item.toLowerCase().startsWith('fax')) {
                                        nFax++;
                                    }
                                    amount += line.SalesItemLineDetail.Qty * tspair.monthlyCommission;
                                }
                            }
                        }
                    }
                }
                if(invoicesForThisCustomer.length > 0 && (nVoip > 0 || nFax > 0)) {
                    lines.push(composeBillLine(aa.customer.name, nVoip, nFax, amount, months));
                    processedInvoicesByQbCustomerId[aa.customer.qbId] = invoicesForThisCustomer.map(invoice => {return {docNumber: invoice.DocNumber, id: invoice.Id}});
                }
            }
            
            if(lines.length > 0) {
                chimera.callQuickBooksAPI(undefined, '/api/qb/bill', 'POST', {
                    Line: lines,
                    VendorRef: {
                        value: associationsForThisAgent[0].agent.ref,
                        name: associationsForThisAgent[0].agent.name
                    }
                })
                .then(bill => {
                    resolve({
                        bill,
                        processedInvoicesByQbCustomerId,
                    })
                })
                .catch(err => {
                    reject(err);
                })
            }
            else {
                resolve({
                    bill: null,
                    processedInvoicesByQbCustomerId: null,
                })
            }
        })
    }

    useEffect(() => {
        // Determine how much to pay each agent according to VoIP line items and T/S pairs
        setLabel("Reading T/S Pairs...");
        chimera.callAPI(undefined, '/api/tspairs')
        .then(async tspairs => {
            // Organize agentAssociations so that one agent has multiple customers. Should have set it up that way before but whatever, it's too late, F it
            let results = [];
            try {
                let agentRefs = [];
                for(const aa of agentAssociations) {
                    if(!agentRefs.includes(aa.agent.ref)) {
                        agentRefs.push(aa.agent.ref);
                    }
                }
                for(const agentRef of agentRefs) {
                    const associationsForThisAgent = agentAssociations.filter(aa => aa.agent.ref === agentRef);
                    if(associationsForThisAgent) {
                        setLabel(`Creating bill for ${associationsForThisAgent[0].agent.name}...`);
                        // First, create the bill
                        let bill = null, processedInvoicesByQbCustomerId;
                        try {
                            ({ bill, processedInvoicesByQbCustomerId } = await createBill(associationsForThisAgent, tspairs));
                        }
                        catch(err) {
                            console.error(err);
                            banners.addBanner('danger', `Failed to create Bill for agent ${associationsForThisAgent[0].agent.name}`, 'Error');
                        }
    
                        if(bill !== null) {
                            // Then, save `processedInvoices` and `createdBills`.
                            // Note that one Bill can have lines for different customers (different associations), so the same `bill` will be in many `createdBills`.
                            for(const aa of associationsForThisAgent) {
                                try {
                                    setLabel(`Saving Created Bills + Processed Invoices to AgentAssociation ID ${aa._id}...`);
                                    await chimera.callAPI(undefined, `/api/agentassociations/${aa._id}`, 'PUT', {
                                        createdBills: [].concat(aa.createdBills, [{txnDate: bill.TxnDate, id: bill.Id}]),
                                        processedInvoices: [].concat(aa.processedInvoices, processedInvoicesByQbCustomerId[aa.customer.qbId])
                                    })
                                }
                                catch(err) {
                                    console.error(err);
                                    banners.addBanner('danger', `Failed to save Created Bills and Processed Invoices to AgentAssociation ID ${aa._id}`);
                                }
                            }
                            results.push(bill);
                        }
                    }
                }
            }
            catch(err) {
                console.error(err);
                banners.addBanner('danger', 'An unhandled error has occurred and the tool cannot continue', 'Error');
                setIsError(true);
                return;
            }
            setParentResults(results);
            setScreen('RESULTS');
        })
        .catch(err => {
            console.error(err);
            banners.addBanner('danger', 'Failed to read T/S Pairs; the tool cannot continue.', 'Error');
            setIsError(true);
        });
    }, []);

    return(
        <div>
            <div className="row">
                <div className="col">
                    {isError ?
                    <button className="btn btn-secondary float-start" onClick={(e) => {e.preventDefault(); setScreen('START')}}>
                        <i className="fas fa-arrow-left"/>
                    </button>
                    :null}
                </div>
                <div className="col">
                    <h1>Processing Invoices</h1>
                </div>
                <div className="col"></div>
            </div>
            {isError ? <h3 className="text-danger">Error</h3> : <LoadingSpinner size={75} label={label}/>}
        </div>
    )
}

const ResultsScreen = ({setScreen, results}) => {
    return(
        <div>
            <div className="row">
                <div className="col-1">
                    <button className="btn btn-secondary float-start" onClick={(e) => {e.preventDefault(); setScreen('START')}}>
                        <i className="fas fa-arrow-left"/>
                    </button>
                </div>
                <div className="col">
                    <h1>Results</h1>
                </div>
                <div className="col-1"></div>
            </div>
            <div className="row">
                <div className="col">
                    <ol className="text-start">
                        {results.map((bill, i) => <li key={i}><a href={`https://qbo.intuit.com/app/bill?txnId=${bill.Id}`} target="_blank" rel="noopener noreferrer">Bill for {bill.VendorRef.name} (ID: {bill.Id})</a></li>)}
                    </ol>
                </div>
            </div>
        </div>
    )
}

const AgentsScreen = ({setScreen, agentAssociations, setAgentAssociations}) => {
    const modaling = useContext(ModalContext);

    const clicked = (agentAssociation) => {
        modaling.setModal(<AgentAssociationModal association={agentAssociation} onClose={() => {setAgentAssociations(null)}}/>);
    }

    const openNewAssociationForm = () => {
        modaling.setModal(<AgentAssociationModal onClose={() => {setAgentAssociations(null)}}/>);
    }

    return(
        <div>
            <div className="row">
                <div className="col-1">
                    <button className="btn btn-secondary float-start" onClick={(e) => {e.preventDefault(); setScreen('START')}}>
                        <i className="fas fa-arrow-left"/>
                    </button>
                </div>
                <div className="col">
                    <h1>Manage Agent Associations</h1>
                </div>
                <div className="col-1"></div>
            </div>
            <div className="row">
                <div className="col">
                    <ObjectTable 
                        id="agentAssociationsTable"
                        cols={[
                            {
                                label: 'Customer Name',
                                sort: (ascending) => {
                                    return (a, b) => a.customer.name < b.customer.name ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                                }
                            },
                            {
                                label: 'Agent Name',
                                sort: (ascending) => {
                                    return (a, b) => a.agent.name < b.agent.name ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                                }
                            },
                            {
                                label: 'Created Bills',
                                sort: (ascending) => {
                                    return (a, b) => a.createdBills.length < b.createdBills.length ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                                }
                            },
                            {
                                label: 'Processed Invoices',
                                sort: (ascending) => {
                                    return (a, b) => a.processedInvoices.length < b.processedInvoices.length ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                                }
                            },
                        ]}
                        objects={agentAssociations}
                        btns={[
                            {label: 'New Association', func: openNewAssociationForm}
                        ]}
                        rowElement={AgentAssociationRow}
                        clickedObject={clicked}
                        search
                        paginated
                        defaultSortByColName="Customer Name"
                        defaultSortAscending={true}
                    />
                </div>
            </div>
        </div>
    )
}

const AgentToolBody = props => {
    const [screen, setScreen] = useState("START") // START, SELECT, RUNNING, RESULTS, AGENTS
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [results, setResults] = useState(null);
    const [agentAssociations, setAgentAssociations] = useState(null);
    const banners = useContext(BannerContext);

    useEffect(() => {
        if(screen === "START") {
            // Reset tool when returning to START screen
            setSelectedInvoices([]);
            setResults(null);
            setAgentAssociations(null);
        }
        else if(screen !== "SELECT" && screen !== "RUNNING" && screen !== "RESULTS" && screen !== "AGENTS") {
            setScreen("START");
        }
    }, [screen]);

    useEffect(() => {
        if(agentAssociations === null) {
            chimera.callAPI(undefined, '/api/agentassociations')
            .then(associations => setAgentAssociations(associations))
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to read Agent Associations; page cannot be loaded.', 'Error');
            })
        }
    }, [agentAssociations]);

    const getScreen = () => {
        switch(screen) {
            case 'START':
                return <StartScreen setScreen={setScreen} agentAssociations={agentAssociations} isLoading={agentAssociations === null}/>
            case 'SELECT':
                return <SelectScreen setScreen={setScreen} agentAssociations={agentAssociations} setParentSelectedInvoices={setSelectedInvoices}/>
            case 'RUNNING':
                return <RunningScreen setScreen={setScreen} agentAssociations={agentAssociations} invoices={selectedInvoices} setParentResults={setResults}/>
            case 'RESULTS':
                return <ResultsScreen setScreen={setScreen} results={results}/>
            case 'AGENTS':
                return <AgentsScreen setScreen={setScreen} agentAssociations={agentAssociations} setAgentAssociations={setAgentAssociations}/>
        }
    }

    return (
        <div>
            {getScreen()}
        </div>
    )
}

const AgentTool = props => {
    const toolName = "Agent Tool";
    const toolId = "agenttool";
    return (
        <ToolPage toolId={toolId} toolName={toolName}>
            <ToolPage.Header image="/images/agent_tool.png" alt="Agent Tool image" toolName={toolName}>
                This tool delivers QuickBooks Bills for VoIP Agents according to the selected Invoices
                and the Agent Associations maintainable through this tool.
            </ToolPage.Header>
            <ToolPage.How>
                <h3>
                    Manage Agent Associations
                </h3>
                <p>
                    The second option is <b>Manage Agent Associations</b>. It is listed second to be out of the way of the main option to run the tool,
                    but it is discussed first here because it gives context for how this tool works.
                </p>
                <p>
                    This option presents a table of objects called Agent Associations. These are stored in Chimera's database and are used in the logic of this tool
                    to produce the output. Choosing this option presents the user with a suite of CRUD operations (Create, Read, Update, and Delete), allowing you to
                    manage the Agent Associations.
                </p>
                <p>
                    Agent Associations pair a Chimera Customer with a VoIP Agent (which is technically a QuickBooks Vendor). Each Agent Association tracks references to
                    the invoices processed and the bills created by this tool.
                </p>
                <h3>
                    Methodology
                </h3>
                <p>
                    The first option is to <b>Run the Tool</b>. This section outlines the steps in the process of this tool's script.
                </p>
                <ol>
                    <li>Provide <b>Start Date</b> and <b>End Date</b> values. This defines the <b>Date Range</b>.</li>
                    <li>The user is presented with a list of Invoices from QuickBooks queried according to the Date Range, Agent Associations, and Status. Only Paid invoices are shown. The list, therefore, includes all Paid invoices created within the Date Range that belong to one of the Chimera Customers included in the Agent Associations.</li>
                    <li>The user selects one or more invoices to process and clicks <b>Process Invoices</b> to run the script.</li>
                    <li>Each selected invoice is scanned for valid VoIP line items, detected according to the T/S Pairs defined in the Admin Panel.</li>
                    <li>For each Agent Association, a Bill is generated according to the tallies between all applicable Invoices. Chimera creates the Bill in QuickBooks and displays a link on the results screen.</li>
                    <li>All processed Invoices and created Bills are tracked by the Agent Associations for later viewing. These can be accessed by the table under the <b>Manage Agent Associations</b> option.</li>
                </ol>
            </ToolPage.How>
            <ToolPage.Body>
                <AgentToolBody/>
            </ToolPage.Body>
        </ToolPage>
    );
}

export default AgentTool;