import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';

import LoadingSpinner from '../../LoadingSpinner';
import BannerLogContext from '../../BannerLogContext';
import chimera from '../../../chimera';

const InvoiceAuditing = props => {
    const [showProgressBar, setShowProgressBar] = useState(true);
    const [progressBarLabel, setProgressBarLabel] = useState("Working...");
    const [invoices, setInvoices] = useState([]);
    const [errorText, setErrorText] = useState("");
    const [controller, setController] = useState(new AbortController());
    const [signal, setSignal] = useState(controller.signal);
    const banners = useContext(BannerLogContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        runAudit();
    }, []);

    const runAudit = async() => {
        try {
            const unsentInvoices = await chimera.callQuickBooksAPI(signal, '/api/qb/invoice/status/0'); // 0 === unsent. They use numbers instead of strings even though they show as strings in response body.
            setInvoices(unsentInvoices);
            setShowProgressBar(false);
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                banners.addBanner('danger', <span><strong>Invoice Auditing:</strong> An error occurred and the job cannot be completed.</span>);
                setErrorText("A problem has occurred. The results cannot be loaded.");
                setShowProgressBar(false);
            }
        }
    }

    return(
        <>
            {showProgressBar ? <LoadingSpinner size={100} label={progressBarLabel}/>
            :
            <>
                {errorText !== "" ? 
                    <p className="text-danger">{errorText}</p>
                :
                    <>
                        {invoices.length > 0 ?
                            <>
                                <h4>
                                    Unsent Invoices
                                </h4>
                                <ol className="text-start">
                                    {invoices.map(invoice => <li>
                                        <span><a href={`https://app.qbo.intuit.com/app/invoices?txnId=${invoice.Id}`} target="_blank" rel="noopener noreferrer">Invoice {invoice.DocNumber} for {invoice.CustomerRef.name}</a>&nbsp;(${parseFloat(invoice.TotalAmt).toFixed(2)})</span>
                                    </li>)}
                                </ol>
                            </>
                        :
                        <p>
                            <span className="text-success">Congrats!</span>&nbsp;All invoices have been sent.
                        </p>
                    }
                    </>
                }
            </>}
        </>
    )
}

export default InvoiceAuditing;