import React, {useState, useEffect, useContext} from 'react';

import LoadingSpinner from '../LoadingSpinner';
import chimera from '../../chimera';
import BannerContext from "../BannerLogContext";

const UnityDIDList = props => {
    const [numbers, setNumbers] = useState([]);
    const [loading, setLoading] = useState(true);
    const banners = useContext(BannerContext);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        chimera.callAPI(signal, '/api/unity/dialplan/DID Table/phonenumbers')
        .then(dids => {
            setNumbers(dids.filter(did => did.domain === props.domain))
            setLoading(false);
        })
        .catch(e => {
            if(e.name !== "AbortError") {
                console.error(e);
                banners.addBanner('danger', 'Failed to get DIDs from Unity', 'Error');
            }
        })

        return () => {
            controller.abort();
        }
    }, []);

    return (
        <>
        {loading ?
            <LoadingSpinner size={50}/>
        :
            <ol style={{paddingLeft: "1rem"}}>
                {numbers.map((did, i) => <li key={i}>{chimera.phoneNumberStr(did.number)} ({did.description}){did.enabled !== "yes" ? ' (DISABLED)' : ''}</li>)}
            </ol>
        }
        </>
    )
}

export default UnityDIDList;