import React, {useState} from "react";
import ToolPage from './ToolPage';

import chimera from '../../chimera';
import LoadingSpinner from "../LoadingSpinner";

const QBCustomerCheckerBody = props => {
    const [isRunning, setIsRunning] = useState(false);
    const [results, setResults] = useState(null);
    const [spinnerLabel, setSpinnerLabel] = useState("Running...");

    const runJob = async(event) => {
        event.preventDefault();
        try {
            setIsRunning(true);
            let qbCustomersNotInChimera = [];
            setSpinnerLabel("Getting Chimera customers...");
            let chimeraCustomers = await chimera.CommercialCustomer.getAll();
            chimeraCustomers = chimeraCustomers.filter((customer) => customer.integrationIds.quickbooks);
            setSpinnerLabel("Getting QuickBooks customers...");
            let qbCustomers = await chimera.callQuickBooksAPI(undefined, '/api/qb/customers');
            setSpinnerLabel("Checking for matches...");
            for(const qbCustomer of qbCustomers) {
                let hasMatch = false;
                for(const chimeraCustomer of chimeraCustomers) {
                    if(chimeraCustomer.integrationIds.quickbooks === qbCustomer.Id) {
                        hasMatch = true;
                        break;
                    }
                }
                if(!hasMatch) {
                    qbCustomersNotInChimera.push(qbCustomer);
                }
            }
            setResults(qbCustomersNotInChimera);
            setIsRunning(false);
        }
        catch(err) {
            if(err.name !== "AbortError") {
                console.error(err);
                alert(`ERROR: An unhandled error occurred. The job cannot continue. Please refresh the page and try again.`);
            }
            return;
        }
    }

    return (
        <div className="col-lg-12">
            {isRunning ? <LoadingSpinner label={spinnerLabel} size={75}/> : 
            <>
            <button className="btn btn-primary" onClick={runJob}>
                <i className="fas fa-arrow-right"/>&nbsp;Run Checker
            </button>
            {results !== null ? 
                <>
                {results.length === 0 ? <p className="text-success">Looks good! All customers in QuickBooks are in Chimera.</p> : 
                    <>
                    <p className="text-start">The following QuickBooks customers are NOT in Chimera:</p>
                    <ul className="text-start">
                        {results.map(customer => <li><a href={`https://app.qbo.intuit.com/app/customerdetail?nameId=${customer.Id}`} target="_blank" rel="noopener noreferrer">{customer.DisplayName}</a></li>)}
                    </ul>
                    </>
                }
                </>
            :null}
            </>
            }
        </div>
    );
}

const QBCustomerChecker = props => {
    const toolName = "QB Customer Checker";
    const toolId = "customerchecker";
    return (
        <ToolPage toolId={toolId} toolName={toolName}>
            <ToolPage.Header image={null} toolName={toolName} alt="QB Customer Checker">
                A small tool for checking for QuickBooks customers missing from Chimera.
            </ToolPage.Header>
            <ToolPage.How>
                <h3>Methodology</h3>
                <p>
                    This tool pulls two lists: a list of all customers from QuickBooks, and a list of all customers in Chimera that have QuickBooks integrations.
                    A Chimera Customer has a QuickBooks integration if the QuickBooks logo is colored in on their page. In terms of data, the Integration ID stored
                    for QuickBooks corresponds with the internal ID used for that customer in Quickbooks. This tool will check each QuickBooks customer to see if any of the
                    Chimera customers uses its ID as their QuickBooks integration ID in Chimera, which is the connection required in order to be able to say "This QB customer is in Chimera."
                </p>
                <h3>What should I do with this information?</h3>
                <p>
                    For each customer shown in the list of results, one of the following may be needed:
                </p>
                <ol>
                    <li>
                        The QuickBooks customer is obsolete and should be made inactive. Inactive customers will not be shown in the results.
                    </li>
                    <li>
                        The QuickBooks customer doesn't exist in Chimera at all, and a new Chimera Customer should be created. It is important to create the Chimera customer without creating a new QuickBooks record, and then manually integrate the QuickBooks later.
                    </li>
                    <li>
                        The QuickBooks customer does exist in Chimera, but the integration is not there or it is integrated with a different QuickBooks profile. Again, you can solve this by manually setting the QuickBooks integration to the correct customer's QuickBooks ID.
                    </li>
                </ol>
            </ToolPage.How>
            <ToolPage.Body>
                <QBCustomerCheckerBody />
            </ToolPage.Body>
        </ToolPage>
    );
}

export default QBCustomerChecker;