import React, { useEffect, useState } from "react";

const userInputMatchesSuggestion = (input, suggestions) => {
    return suggestions.find((suggestion) => suggestion === input);
}

const Autocomplete = ({label, suggestions, suggestionChosenCallback, strictMode, value, disabled, required}) => {
    const [activeSuggestion, setActiveSuggestion] = useState(0); // the index of the active suggestion
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [userInput, setUserInput] = useState(value ? value : "");
    const [inputIsSuggestion, setInputIsSuggestion] = useState(value ? (userInputMatchesSuggestion(value, suggestions)): false);

    useEffect(() => {
        setUserInput(value);
        setInputIsSuggestion(userInputMatchesSuggestion(value, suggestions));
    }, [value]);

    const onChange = e => {
        const userInput = e.currentTarget.value;

        // Filter our suggestions that don't contain the user's input
        const filteredSuggestions = suggestions.filter(
            suggestion =>
            suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        setInputIsSuggestion(false);
        setFilteredSuggestions(filteredSuggestions);
        setShowSuggestions(true);
        setUserInput(userInput);
        setActiveSuggestion(0);
    };

    const onClick = e => {
        setActiveSuggestion(0);
        setFilteredSuggestions([]);
        setShowSuggestions(false);
        setUserInput(e.currentTarget.innerText);
        suggestionChosenCallback(e.currentTarget.innerText);
        setInputIsSuggestion(true);
    };

    const onKeyDown = e => {
        // User pressed the enter key
        if (e.keyCode === 13) {
            setActiveSuggestion(0);
            setShowSuggestions(false);
            setUserInput(filteredSuggestions[activeSuggestion]);
            suggestionChosenCallback(filteredSuggestions[activeSuggestion]);
            setInputIsSuggestion(true);
        }
        // User pressed the up arrow
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) return;
            setActiveSuggestion(activeSuggestion - 1);
        }
        // User pressed the down arrow
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) return;
            setActiveSuggestion(activeSuggestion + 1);
        }
    };

    const suggestionsListComponent = () => {
        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                return (
                    <ul className="suggestions">
                        {filteredSuggestions.map((suggestion, index) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === activeSuggestion) {
                                className = "suggestion-active";
                            }

                            return (
                            <li className={className} key={index} onClick={onClick}>
                                {suggestion}
                            </li>
                            );
                        })}
                    </ul>
                );
            } else {
                return (
                    <span className="text-muted">This value matches no suggestions.</span>
                );
            }
        }
        else {
            return null;
        }
    }

    const validationIndicator = () => {
        if(strictMode) {
            // Indicate whether the input is valid or not.
            const icon = inputIsSuggestion ? "fas fa-check" : "fas fa-times"
            const colorClass = inputIsSuggestion ? "text-success" : "text-danger"
            return (
                <>&nbsp;<span className={colorClass}><i className={icon}/></span></>
            )
        }
        else {
            return null;
        }
    }

    return (
        <>
        <label htmlFor={label} className="form-label text-start w-fit autocomplete">
            {required ? <><span className="red">*</span>&nbsp;</> : null}{label}{validationIndicator()}
            <input
                className="form-control"
                type="text"
                onChange={onChange}
                onKeyDown={onKeyDown}
                value={userInput}
                size={30}
                id={label}
                name={label}
                disabled={disabled}
            />
            {suggestionsListComponent()}
        </label>
        </>
    );
}

export default Autocomplete;