import React from "react";

const ProgressBar = ({bgColor, progress, height, label}) => {

    // Truncate decimal places
    progress = progress.split(".")[0];

    const container = {
        width: '100%',
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 20,
    }
    
    const background = {
        height: height,
        width: '100%',
        backgroundColor: 'whitesmoke',
        borderRadius: 40,
    }

    const filler = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: bgColor,
        borderRadius: 40,
        textAlign: 'right'
    }

    const progressText = {
        padding: 10,
        color: 'black',
        fontWeight: 400
    }

    return (
        <div style={container}>
            <div style={background}>
                <div style={filler}>
                    <span style={progressText}>{`${progress}%`}</span>
                </div>
            </div>
            <div className="form-text">
                {label}
            </div>
        </div>
    );
}

export default ProgressBar;