import React, {useState, useEffect} from 'react';

const TimelineFormContext = React.createContext();

const TimelineFormContextProvider = props => {
    const [currentStep, setCurrentStep] = useState(props.timeline.currentStep);
    const [completedAllSteps, setCompletedAllSteps] = useState(false);

    const next = () => {
        setCurrentStep(currentStep + 1);
        if(props.stepCompletedCallback) {
            props.stepCompletedCallback(currentStep);
        }
    }

    return <>
        <TimelineFormContext.Provider value={{currentStep: currentStep, next: next, completedAllSteps: completedAllSteps, setCompletedAllSteps: setCompletedAllSteps}}>
            {props.children}
        </TimelineFormContext.Provider>
    </>
}

export { TimelineFormContext as default, TimelineFormContextProvider };