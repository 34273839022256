import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';

import ReportTable from './ReportTable';
import LoadingSpinner from './LoadingSpinner';
import BannerContext, { BannerLog } from "./BannerLogContext";
import chimera from '../chimera';

const ReportTablePageBody = props => {
    const [loading, setLoading] = useState(true);
    const [report, setReport] = useState(null);
    const [timestamp, setTimestamp] = useState("");
    const banners = useContext(BannerContext);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        const regex = /\/reporttables\/[a-f0-9]{24}[\/]{0,1}$/ig;
        const matches = window.location.pathname.match(regex);
        if(matches) {
            const match = matches[0];
            const id = match.split('/')[2];
            chimera.callAPI(signal, `/api/reporttables/${id}`)
            .then(table => {
                const date = new Date(table.createdAt);
                setTimestamp(date.toLocaleString());
                setReport(table);
                setLoading(false);
            })
            .catch(err => {
                if(err.name !== "AbortError") {
                    console.error(err);
                    banners.addBanner('danger', 'Could not fetch the Report', 'Error');
                }
            })
        }
        else {
            banners.addBanner('danger', 'Could not fetch the Report', 'Error');
        }
        return () => {
            controller.abort();
        }
    }, []);

    return (
        <>
            {loading ? 
                <LoadingSpinner size={75}/>
            :
                <div className="row">
                    <div className="col">
                        <h1>{report.type} Report</h1>
                        <h2>{timestamp}</h2>
                        <ReportTable report={report} writeCell={chimera.writeReportTableCell(report.type)} etcPos={chimera.reportTableEtcPos(report.type)} writeEtc={chimera.reportTableWriteEtc(report.type)}/>
                    </div>
                </div>
            }
        </>
    )
}

const ReportTablePage = props => {
    return (
        <div className="container pb-5">
            <BannerLog>
                <ReportTablePageBody/>
            </BannerLog>
        </div>
    )
}

export default ReportTablePage;