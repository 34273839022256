import React from 'react';
import Modal from './Modal';

const ModalCancelOnly = props => {
    const choices = [
        {
            btnColor: 'secondary',
            btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Cancel</span>,
            func: (e) => {
                e.preventDefault();
                if(props.backtrack) {
                    props.context.backtrack();
                }
                else {
                    props.context.setModal(null);
                }
                if(props.onClose) props.onClose();
            }
        }
    ]

    return(
        <Modal choices={choices} dismiss={choices[0].func}>
            {props.children}
        </Modal>
    );
}

export default ModalCancelOnly;