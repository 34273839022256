import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';
import chimera from '../../chimera';
import BannerContext, { BannerLog } from '../BannerLogContext';
import LoadingSpinner from '../LoadingSpinner';
import ReportTable from '../ReportTable';

const EndpointsLastSeenBody = props => {
    const [loading, setLoading] = useState(true);
    const [label, setLabel] = useState("Running... this may take several minutes...");
    const [report, setReport] = useState(null);
    const [saveBtnIcon, setSaveBtnIcon] = useState("fas fa-floppy-disk");
    const [saveBtnLabel, setSaveBtnLabel] = useState("Save to Chimera");
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [controller, setController] = useState(new AbortController());
    const [signal, setSignal] = useState(controller.signal);
    const banners = useContext(BannerContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        /** 
        chimera.callAPI(signal, '/api/reports/endpointslastseen/run')
        .then(response => {
            setReport(response);
            setLoading(false);
        })
        .catch(e => {
            if(e.name !== "AbortError") {
                console.error(e);
                setLabel("Error");
                banners.addBanner('danger', 'Failed to generate the report', 'Error');
            }
        })
        */

        const syncroAssets = (syncroCustomerId) => {
            return new Promise(async(resolve, reject) => {
                let page = 1;
                let totalPages = 1;
                let assets = [];
                while(page <= totalPages) {
                    try {
                        const response = await chimera.callSyncroAPI(signal, 'POST', '/assets', {
                            customer_id: syncroCustomerId,
                            page: page
                        })
                        totalPages = response.meta.total_pages;
                        for(const asset of response.assets) {
                            assets.push(asset);
                        }
                    }
                    catch(err) {
                        reject(err);
                    }
                    page++;
                }
                resolve(assets);
            })
        }

        chimera.callAPI(signal, '/api/customers/integration/syncro')
        .then(async customers => {
            let data = [];
            const sortedCustomers = customers.sort((a,b) => a.displayName < b.displayName ? -1 : 1);
            for(const customer of sortedCustomers) {
                const syncroEndpoints = await syncroAssets(customer.integrationIds.syncro);
                for(const syncroEndpoint of syncroEndpoints) {
                    let syncroName;
                    if(syncroEndpoint.properties && syncroEndpoint.properties.computer_name) {
                        syncroName = syncroEndpoint.properties.computer_name;
                    }
                    else if(syncroEndpoint.properties && syncroEndpoint.properties.kabuto_information && syncroEndpoint.properties.kabuto_information.general && syncroEndpoint.properties.kabuto_information.general.name) {
                        syncroName = syncroEndpoint.properties.kabuto_information.general.name;
                    }
                    else {
                        syncroName = syncroEndpoint.name;
                    }

                    data.push({
                        "Computer Name": syncroName,
                        "Customer": customer.displayName,
                        "Last Seen": syncroEndpoint.properties.kabuto_information.last_synced_at,
                        syncroId: syncroEndpoint.id.toString(),
                    })
                }
            }
            const cols = ["Computer Name", "Customer", "Last Seen"];
            data = data.filter(row => {
                const syncroLastSeen = new Date(row["Last Seen"]);
                const today = new Date();
                const threshold = new Date(today.setDate(today.getDate() - 21)); // 21 days is the cutoff
                const syncroLastSeenDate = syncroLastSeen.toISOString().substring(0,10);
                const thresholdDate = threshold.toISOString().substring(0,10);
                return thresholdDate >= syncroLastSeenDate;
            })
            setReport({
                type: 'EndpointsLastSeen',
                cols: cols,
                rows: data
            })
            setLoading(false);
        })

    }, []);

    const saveReport = async(event) => {
        event.preventDefault();
        setSaveBtnIcon("fas fa-spinner");
        setSaveBtnDisabled(true);
        setSaveBtnLabel("Saving...");
        try {
            await chimera.callAPI(signal, '/api/reporttables', 'POST', report);
            setSaveBtnIcon("fas fa-check");
            setSaveBtnLabel("Saved!");
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                banners.addBanner('danger', 'Failed to save report', 'Error');
                setSaveBtnDisabled(false);
                setSaveBtnIcon("fas fa-floppy-disk")
            }
        }
    }

    return (
        <>
            <h1>Endpoints Last Seen</h1>
            {loading ? 
                <LoadingSpinner size={50} label={label}/>
            :
                <>
                    <div className="row mb-2">
                        <div className="col">
                            <button className="btn btn-primary float-start" onClick={saveReport} disabled={saveBtnDisabled}>
                                <i className={saveBtnIcon}/>
                                &nbsp;{saveBtnLabel}
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ReportTable report={report}/>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

const EndpointsLastSeen = props => {
    return(
        <BannerLog>
            <EndpointsLastSeenBody />
        </BannerLog>
    )
}

export default EndpointsLastSeen;