import React, {useState, useEffect, useContext} from 'react';
import BannerContext, { BannerLog } from './BannerLogContext';
import chimera from '../chimera';
import LoadingSpinner from './LoadingSpinner';
import Tooltip from './Tooltip';

const UnityBackupsMonitorBody = props => {
    const [monitors, setMonitors] = useState(null);
    const banners = useContext(BannerContext);

    useEffect(() => {
        if(monitors === null) {
            chimera.callAPI(undefined, '/api/unitybackupsmonitors')
            .then(newMonitors => setMonitors(newMonitors))
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to read monitors; table cannot be displayed', 'Error');
            })
        }
    }, [monitors]);

    return(
    <>
    {monitors ? 
        <>
        <h3>Core 1</h3>
        <table className="table table-hover table-bordered">
            <thead>
                <tr>
                    <th>Folder</th>
                    <th>Size</th>
                    <th># of Objects</th>
                    <th>Most Recent Backup&nbsp;<Tooltip text='Estimated by scanning for the most recent "Last Modified" timestamp'><i className="fas fa-circle-question"/></Tooltip></th>
                    <th>Timestamp</th>
                </tr>
            </thead>
            <tbody>
                {monitors.filter(m => m.folder === 'core1.gocbit.com/').map((m, i) => <tr key={i}>
                    <td>{m.folder}</td>
                    <td>{m.size} ({chimera.formatBytes(m.size)})</td>
                    <td>{m.nObjects}</td>
                    <td>{(new Date(m.mostRecent)).toLocaleString()}</td>
                    <td>{(new Date(m.timestamp)).toLocaleString()}</td>
                </tr>)}
            </tbody>
        </table>
        <h3>Core 2</h3>
        <table className="table table-hover table-bordered">
            <thead>
                <tr>
                    <th>Folder</th>
                    <th>Size</th>
                    <th># of Objects</th>
                    <th>Most Recent Backup&nbsp;<Tooltip text='Estimated by scanning for the most recent "Last Modified" timestamp'><i className="fas fa-circle-question"/></Tooltip></th>
                    <th>Timestamp</th>
                </tr>
            </thead>
            <tbody>
                {monitors.filter(m => m.folder === 'core2.gocbit.com/').map((m, i) => <tr key={i}>
                    <td>{m.folder}</td>
                    <td>{m.size} ({chimera.formatBytes(m.size)})</td>
                    <td>{m.nObjects}</td>
                    <td>{(new Date(m.mostRecent)).toLocaleString()}</td>
                    <td>{(new Date(m.timestamp)).toLocaleString()}</td>
                </tr>)}
            </tbody>
        </table>
        </>
    :
        <LoadingSpinner size={75}/>
    }
    </>
    )
}

const UnityBackupsMonitor = props => {
    return (
        <div className="container pb-5">
            <h1>
                Unity Backups
            </h1>
            <BannerLog>
                <UnityBackupsMonitorBody/>
            </BannerLog>
        </div>
    )
}

export default UnityBackupsMonitor