import React, {useState, useEffect, useContext} from 'react';

import chimera from '../chimera';
import TimelineFormContext, {TimelineFormContextProvider} from './TimelineFormContext';
import FormField from './FormField';

const TimelineForm = props => {
    let subComponents = React.Children.map(props.children, (child) => child.type === Main || Sidebar || Submit ? child : null);
    subComponents = subComponents.filter(sub => sub !== null);
    const main = subComponents.filter(sub => sub.type === Main)[0];
    const sidebars = subComponents.filter(sub => sub.type === Sidebar);
    return <>
        <TimelineFormContextProvider timeline={props.timeline} stepCompletedCallback={props.stepCompletedCallback}>
            {sidebars.length > 0 ? 
                <div className="row">
                    <div className="col-8 overflow-auto max-vh-70">
                        {main}
                    </div>
                    <div className="col-4">
                        {sidebars[0]}
                    </div>
                </div>
            :
                <>{main}</>
            }
        </TimelineFormContextProvider>
    </>
}

const Submit = props => {
    const formContext = useContext(TimelineFormContext);
    const canSubmit = props.canSubmit && formContext.completedAllSteps;
    return <div className="row">
        <div className="col">
            <button className="btn btn-success w-75" onClick={props.handleSubmit} disabled={formContext.currentStep !== props.nSteps || !canSubmit}>
                <i className={props.icon}/>
                &nbsp;{props.label}
            </button>
        </div>
    </div>
}
TimelineForm.Submit = Submit;

const Main = props => {
    const formContext = useContext(TimelineFormContext);
    const completedAllSteps = () => {
        let completed = true;
        let sections = React.Children.map(props.children, (child) => child.type === Section && !child.props.hidden ? child : null);
        sections = sections.filter(sub => sub !== null);
        for(const section of sections) {
            if(!section.props.canContinue) {
                completed = false;
                break;
            }
        }
        return completed;
    }
    formContext.setCompletedAllSteps(completedAllSteps());

    let sections = React.Children.map(props.children, (child) => child.type === Section ? child : null);
    sections = sections.filter(sub => sub !== null);
    sections.forEach((sub, i) => {
        sub.props.index = i;
    })

    let submits = React.Children.map(props.children, (child) => child.type === Submit ? child : null);
    submits = submits.filter(sub => sub !== null);

    if(sections.length > 0) {
        sections[sections.length - 1].props.last = true;
    }

    return <>{sections}{submits}</>
}
TimelineForm.Main = Main;

const Section = props => {
    const formContext = useContext(TimelineFormContext);

    const next = (event) => {
        event.preventDefault();
        formContext.next();
    }

    let children = React.Children.map(props.children, (child) => child);
    children.forEach((child) => {
        child.props.sectionIndex = props.index;
    })

    return (
        <>
        {!props.hidden ? 
        <div className={props.index !== 0 ? "row mt-5" : "row"}>
            <div className="col section-outline p-4">
                {props.label ? 
                    <h4>{props.label}</h4>
                :null}
                {props.children}
                {formContext.currentStep === props.index ?
                    <button className="btn btn-primary mt-3" onClick={next} disabled={!props.canContinue}>
                        Continue
                    </button>
                :null}
            </div>
        </div>
        :null}
        </>
    )
}
TimelineForm.Section = Section;

const Field = props => {
    const formContext = useContext(TimelineFormContext);

    if(!props.disabled) props.disabled = formContext.currentStep < props.sectionIndex

    return (
        <FormField {...props}/>
    )
}
TimelineForm.Field = Field;

const Sidebar = props => {
    return (
        <div>
            {props.children}
        </div>
    )
}
TimelineForm.Sidebar = Sidebar;

export default TimelineForm;