import React, {useState, useEffect, useContext} from "react";
import chimera from "../chimera";
import BannerContext, { BannerLog } from "./BannerLogContext";
import LoadingSpinner from "./LoadingSpinner";

const TaggingInterfaceBody = props => {
    const [tags, setTags] = useState(null);
    const [selectedTagIds, setSelectedTagIds] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const banners = useContext(BannerContext);

    useEffect(() => {
        if(tags === null) {
            chimera.callAPI(undefined, '/api/tags')
            .then(newTags => setTags(newTags))
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to read Tags. Please close this modal and try again.', 'Error');
            })
        }
    }, [tags]);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        if(name.includes('tag-check')) {
            const tagIndex = event.target.name.split('tag-check')[1];
            const tagId = tags[tagIndex]._id;
            if(value && !selectedTagIds.includes(tagId)) {
                setSelectedTagIds([...selectedTagIds, tagId]);
            }
            else if(selectedTagIds.includes(tagId)) {
                setSelectedTagIds(selectedTagIds.filter(id => id !== tagId));
            }
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSaving(true);
        chimera.callAPI(undefined, '/api/customertags', 'PUT', {
            customerIds: props.customers.map(customer => customer._id),
            tagIds: selectedTagIds
        })
        .then(taggedCustomers => {
            if(props.callback) {
                props.callback(taggedCustomers);
            }
        })
        .catch(err => {
            console.error(err);
            banners.addBanner('danger', 'Failed to save the tags to the customers', 'Error');
        })
        .finally(() => {
            setIsSaving(false);
        })
    }

    return(
        <>
        {tags === null ? <LoadingSpinner size={50}/> : 
        <>
        <h3>{selectedTagIds.length === 0 ? "Select Tags To Apply" : `Applying ${selectedTagIds.length} Tag(s)...`}</h3>
        {tags.map((tag, i) => <><input type="checkbox" name={`tag-check${i}`} className="btn-check" autoComplete='off' id={`tag-check${i}`} checked={selectedTagIds.includes(tag._id)} onClick={handleChange} disabled={isSaving}/>
            <label className="btn btn-outline-secondary btn-sm me-1 pt-2" htmlFor={`tag-check${i}`}>{tag.name}</label>
        </>)}
        <p>
            Are you sure you want to apply the above tag configuration to ALL customers exactly as shown?
        </p>
        <p>
            <strong>This will overwrite the entire tags list for all customers shown below. If any of the following customers currently has a tag not selected here, it will be removed by this operation.</strong>
        </p>
        <p>
            Apply the tags to the following customers?
        </p>
        <ul>
            {props.customers.map(customer => <li>{customer.displayName}</li>)}
        </ul>
        <button className="btn btn-primary" onClick={handleSubmit} disabled={isSaving}>
            <i className={isSaving ? 'fas fa-spinner' : 'fas fa-floppy-disk'}/>&nbsp;{isSaving ? "Saving..." : "Save Tags"}
        </button>
        </>
        }
        </>
    )
}

const TaggingInterface = props => {
    return (
        <BannerLog>
            <TaggingInterfaceBody {...props}/>
        </BannerLog>
    )
}

export default TaggingInterface;