import React, {useState, useEffect, useContext, useRef} from 'react';

import chimera from '../chimera';
import LoadingSpinner from './LoadingSpinner';
import Pagination from './Pagination';
import ModalContext from './ModalContext';
import ExportModal from './ExportModal';

const ResidentialLeadsTable = props => {
    const [sortByColIndex, setSortByColIndex] = useState(1); // default to Customer Name column
    const [sortAscending, setSortAscending] = useState(true);
    const [filterSetting, setFilterSetting] = useState('Default');
    const [query, setQuery] = useState("");
    const [page, setPage] = useState(1); // 1-indexed
    const [nPages, setNPages] = useState(1);
    const [filteredLeads, setFilteredLeads] = useState(null);
    const [selectedNums, setSelectedNums] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const selectAll = useRef(null);
    const modaling = useContext(ModalContext);

    const perPage = 10;

    useEffect(() => {
        if(props.leads) {
            setFilteredLeads(sortLeads(props.leads));
        }
        else {
            setFilteredLeads(null);
        }
    }, [props.leads, filterSetting, query, sortAscending, sortByColIndex]);

    useEffect(() => {
        if(filteredLeads !== null) {
            setNPages(Math.ceil(filteredLeads.length / perPage));
        }
        setSelectedNums([]);
    }, [filteredLeads]);

    useEffect(() => {
        setPage(1); // reset to page 1 when changing parameters
    }, [filterSetting, query]);

    useEffect(() => {
        if(page > nPages) {
            setPage(nPages === 0 ? 1 : nPages); // `page` should never be 0
        }
    }, [nPages]);

    useEffect(() => {
        if(selectAll.current && filteredLeads !== null && selectedNums.length === filteredLeads.length) {
            setAllChecked(true);
            selectAll.current.indeterminate = false;
        }
        else if(selectAll.current && selectedNums.length === 0) {
            setAllChecked(false);
            selectAll.current.indeterminate = false;
        }
        else if(selectAll.current) {
            setAllChecked(true);
            selectAll.current.indeterminate = true;
        }
    }, [selectedNums]);

    const handleSortByColIndexChange = (newIndex) => {
        if(newIndex !== sortByColIndex) {
            setSortAscending(true);
        }
        else {
            setSortAscending(!sortAscending);
        }
        setSortByColIndex(newIndex);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if(name === "filter") {
            setFilterSetting(value);
        }
        else if(name === "search") {
            setQuery(value);
        }
        else if(name === "selectAll"){
            if(allChecked) {
                // Uncheck all
                setSelectedNums([]);
            }
            else {
                // Check all
                let newSelectedNums = [];
                for(const lead of filteredLeads) {
                    newSelectedNums.push(lead._id);
                }
                setSelectedNums(newSelectedNums);
            }
            setAllChecked(!allChecked);
            if(selectAll.current) {
                selectAll.current.indeterminate = false;
            }
        }
        else if(event.target.type === "checkbox") {
            if(event.target.checked && !selectedNums.includes(name)) {
                setSelectedNums(nums => [...nums, name]);
            }
            else {
                let newSelectedNums = [];
                for(const num of selectedNums) {
                    if(num !== name) {
                        newSelectedNums.push(num);
                    }
                }
                setSelectedNums(newSelectedNums);
            }
        }
    }

    const customerName = (lead) => {
        return `${lead.attrs.firstName} ${lead.attrs.lastName}`;
    }

    const filterFunc = () => {
        const leadMatchesSearch = (lead) => {
            if(!query || query === "") return true;
            const digest = chimera.digestQuery(query);
            const searchTerm = digest.searchTerm;
            const keywordTerms = digest.keywordTerms;
            let keywordTermMatch = false;
            if(keywordTerms.length > 0) {
                for(const keyword in digest.keywords) {
                    if(keyword === "ip") {
                        keywordTermMatch = chimera.ipInArray(lead.attrs.technical.ips, digest.keywords[keyword]);
                        break;
                    }
                }
            }
            const regex = new RegExp(`([^A-Za-z0-9]|^)${searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')}`, 'ig');
            const searchTermMatch = customerName(lead).match(regex);
            return searchTermMatch && (keywordTermMatch || keywordTerms.length === 0);
        };
        switch(filterSetting) {
            case 'Provisioned':
                return (lead) => lead.status === "Provisioned" && leadMatchesSearch(lead);
            case 'Completed':
                return (lead) => lead.status === "Completed" && leadMatchesSearch(lead);
            case 'Cancelled':
                return (lead) => lead.status === "Cancelled" && leadMatchesSearch(lead);
            case 'Attention':
                return (lead) => {
                    const lastUpdatedDate = new Date(lead.updatedAt);
                    const threeDaysAgo = new Date();
                    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
                    const needsAttention = lastUpdatedDate.getTime() <= threeDaysAgo.getTime();
                    return lead.status !== "Completed" && lead.status !== "Cancelled" && needsAttention && leadMatchesSearch(lead);
                }
            case 'ConduitNotInstalled':
                return (lead) => !lead.attrs.conduitInstalled && lead.status !== "Cancelled";
            case 'ConduitInstalled':
                return (lead) => lead.attrs.conduitInstalled && lead.status !== "Cancelled";
            default:
                return (lead) => lead.status !== "Completed" && lead.status !== "Cancelled" && leadMatchesSearch(lead);
        }
    }

    const sortLeads = (leads) => {
        let arr = [];
        for(const lead of leads) {
            arr.push(lead);
        }
        switch(sortByColIndex) {
            case 0:
                return arr.sort((a, b) => {
                    const values = {
                        'New': 0,
                        'In-Progress': 1,
                        'Provisioned': 2,
                        'Completed': 3,
                        'Cancelled': 4
                    }
                    return values[`${a.status}`] < values[`${b.status}`] ? (sortAscending ? -1 : 1) : (sortAscending ? 1 : -1);
                }).filter(filterFunc());
            case 1:
                return arr.sort((a, b) => customerName(a) < customerName(b) ? (sortAscending ? -1 : 1) : (sortAscending ? 1 : -1)).filter(filterFunc());
            case 2:
                return arr.sort((a, b) => a.currentStep < b.currentStep ? (sortAscending ? -1 : 1) : (sortAscending ? 1 : -1)).filter(filterFunc());
            case 3:
                return arr.sort((a, b) => a.updatedAt < b.updatedAt ? (sortAscending ? -1 : 1) : (sortAscending ? 1 : -1)).filter(filterFunc());
            default:
                return arr.filter(filterFunc());
        }
    }

    const statusColor = (status) => {
        switch(status) {
            case "New":
                return "info";
            case "In-Progress":
                return "warning";
            case "Provisioned":
                return "primary";
            case "Completed":
                return "success";
            case "Cancelled":
                return "danger";
        }
    }

    const openExportModal = (event) => {
        event.preventDefault();
        const objects = filteredLeads.filter(lead => selectedNums.includes(lead._id));
        modaling.setModal(<ExportModal objects={objects} model="residentialLead"/>);
    }

    return (
        <>
        <div className="row mb-2">
            <div className="col d-flex justify-content-start">
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle h-fit" type="button" id="actionsBtn" data-bs-toggle="dropdown" aria-expanded="false" disabled={selectedNums.length === 0}>
                        Actions
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="actionsBtn">
                        <li>
                            <button className="btn dropdown-item" onClick={openExportModal}>
                                Export
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col d-flex flex-column justify-content-center align-items-center">
                <span><u>Filter:</u></span>
                <select className="form-select centered w-fit" name="filter" onChange={handleChange}>
                    <option value="Default" selected={filterSetting === "Default"}>Default</option>
                    <option value="Attention" selected={filterSetting === "Attention"}>Attention Needed</option>
                    <option value="ConduitInstalled" selected={filterSetting === "ConduitInstalled"}>Conduit Installed</option>
                    <option value="ConduitNotInstalled" selected={filterSetting === "ConduitNotInstalled"}>Conduit Not Installed</option>
                    <option value="Provisioned" selected={filterSetting === "Provisioned"}>Show Provisioned</option>
                    <option value="Completed" selected={filterSetting === "Completed"}>Show Completed</option>
                    <option value="Cancelled" selected={filterSetting === "Cancelled"}>Show Cancelled</option>
                </select>
            </div>
            <div className="col d-flex justify-content-end">
                <button className="btn btn-success h-fit" onClick={(event) => {event.preventDefault(); props.openLeadInModal(null)}}>
                    <i className="fas fa-plus"/>
                    &nbsp;New Service Order
                </button>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <div className="d-flex flex-column mb-3">
                    <input className="form-control" type="text" name="search" placeholder="Search service orders..." value={query} onChange={handleChange}/>
                    {query !== "" ? <span className="text-muted text-start">Results: {filteredLeads.length}</span> : null}
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                {filteredLeads === null ?
                    <LoadingSpinner size={75}/>
                :
                    <>
                    {filteredLeads.length > 0 ? 
                    <>
                    <Pagination page={page} setPage={setPage} nPages={nPages}/>
                    <table className="table table-hover table-bordered">
                        <thead>
                            <tr>
                                <th><input className="form-check-input" name="selectAll" type="checkbox" onChange={handleChange} ref={selectAll} checked={allChecked}/></th>
                                <th><button className="btn" onClick={(event) => {event.preventDefault(); handleSortByColIndexChange(0)}}>Status&nbsp;<i className={sortByColIndex === 0 ? (sortAscending ? "fas fa-caret-down" : "fas fa-caret-up") : "fas fa-minus"}/></button></th>
                                <th><button className="btn" onClick={(event) => {event.preventDefault(); handleSortByColIndexChange(1)}}>Customer Name&nbsp;<i className={sortByColIndex === 1 ? (sortAscending ? "fas fa-caret-down" : "fas fa-caret-up") : "fas fa-minus"}/></button></th>
                                <th><button className="btn" onClick={(event) => {event.preventDefault(); handleSortByColIndexChange(2)}}>Current Step&nbsp;<i className={sortByColIndex === 2 ? (sortAscending ? "fas fa-caret-down" : "fas fa-caret-up") : "fas fa-minus"}/></button></th>
                                <th><button className="btn" onClick={(event) => {event.preventDefault(); handleSortByColIndexChange(3)}}>Last Updated&nbsp;<i className={sortByColIndex === 3 ? (sortAscending ? "fas fa-caret-down" : "fas fa-caret-up") : "fas fa-minus"}/></button></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredLeads.slice((page * perPage) - perPage, (page * perPage)).map(lead => <tr>
                                <td>
                                    <input className="form-check-input" type="checkbox" checked={selectedNums.includes(lead._id)} name={lead._id} onChange={handleChange}/>
                                </td>
                                <td className={`table-${statusColor(lead.status)} cursor-pointer`} onClick={(event) => {event.preventDefault(); props.clickedLead(lead)}}>
                                    {lead.status}
                                </td>
                                <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); props.clickedLead(lead)}}>
                                    {lead.attrs.firstName}&nbsp;{lead.attrs.lastName}
                                </td>
                                <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); props.clickedLead(lead)}}>
                                    {lead.currentStep + 1}
                                </td>
                                <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); props.clickedLead(lead)}}>
                                    {new Date(lead.updatedAt).toLocaleString()}
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                    </>
                    :
                    <p><i>No leads exist that match the given parameters.</i></p>
                    }
                    </>
                }
            </div>
        </div>
        </>
    )
}

export default ResidentialLeadsTable