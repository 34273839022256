import React, {useState, useEffect, useLayoutEffect, useContext} from "react";
import qbreportsimg from "../../images/qbreportsimg.png";
import ToolPage from './ToolPage';
import FormField from '../FormField';
import * as XLSX from 'xlsx';

import chimera from '../../chimera';
import BannerLogContext from "../BannerLogContext";

const QBReportsBody = props => {
    const [checkedMap, setCheckedMap] = useState(false);
    const [showChoice, setShowChoice] = useState(true);
    const [showHMIS, setShowHMIS] = useState(false);
    const [showProfitAndLoss, setShowProfitAndLoss] = useState(false);
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [submitBtnIcon, setSubmitBtnIcon] = useState("fas fa-arrow-right");
    const [submitBtnLabel, setSubmitBtnLabel] = useState("Submit");
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
    const [downloadBlobs, setDownloadBlobs] = useState([]);
    const [map, setMap] = useState({});
    const [mapStrs, setMapStrs] = useState({});
    const [saveBtnIcon, setSaveBtnIcon] = useState("fas fa-bookmark");
    const [saveBtnLabel, setSaveBtnLabel] = useState("Saved");
    const [saveBtnEnabled, setSaveBtnEnabled] = useState(false);
    const [excelFile, setExcelFile] = useState(null);
    const [controller, setController] = useState(new AbortController());
    const [signal, setSignal] = useState(controller.signal);
    const banners = useContext(BannerLogContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        if(!checkedMap) {
            const getMap = async() => {
                try {
                    const map = await chimera.callAPI(signal, '/api/profitandloss/map');
                    let mapStrs = {};
                    for(const key in map) {
                        if(!key.toString().startsWith('_') && key.toString() !== "NAME")
                            mapStrs[key] = map[key].join(', ');
                    }
                    setMap(map);
                    setMapStrs(mapStrs);
                    setCheckedMap(true);
                }
                catch(e) {
                    if(e.name !== "AbortError") {
                        console.error(e);
                        alert("ERROR: Could not retrieve the map.");
                        setCheckedMap(true);
                    }
                }
            }
            getMap();
        }
    });

    const getInvoiceAccountNumber = (invoice) => {
        if(!invoice.CustomField) {
            return null;
        }
        else {
            for(const field of invoice.CustomField) {
                if(field.Name === "Account #") {
                    return field.StringValue;
                }
            }
            return null;
        }
    }

    const sortInvoice = (invoice) => {
        const chromeSort = (a,b) => {
            const dateA = new Date(`${a.SalesItemLineDetail.ServiceDate}T13:00:00`);
            const dateB = new Date(`${b.SalesItemLineDetail.ServiceDate}T13:00:00`);
            return dateA.getTime() < dateB.getTime() ? -1 : 1
        }

        if(navigator.userAgent.includes("Chrome"))
            return invoice.Line.filter(line => line.DetailType === "SalesItemLineDetail").sort(chromeSort);
        else
            return invoice.Line.filter(line => line.DetailType === "SalesItemLineDetail").sort((a, b) => a.SalesItemLineDetail.ServiceDate > b.SalesItemLineDetail.ServiceDate ? 1 : -1);
    }

    const invoiceToLaborAmounts = (invoice) => {
        // Return a dataSet suitable for /xlsx from the invoice, representing the Amounts report
        let data = {};
        const chronologicalLineItems = sortInvoice(invoice);
        for(let i = 0; i < chronologicalLineItems.length; i++) {
            const line = chronologicalLineItems[i];
            const date = line.SalesItemLineDetail.ServiceDate;
            const cacn = line.Description.length >= 6 && line.Description.substring(0,6).match(/\d\d\d\d\d\d/g) ? line.Description.substring(0,6).match(/\d\d\d\d\d\d/g)[0] : null;
            const amount = line.Amount;
            if(!date) {
                banners.addBanner('danger', `Could not create Amounts report: Required field "Service Date" missing from line ${i}`);
                return null;
            }
            if(cacn) {
                if(!data[cacn]) {
                    data[cacn] = {[date]: amount};
                }
                else {
                    data[cacn][date] = data[cacn][date] ? amount + data[cacn][date] : amount;
                }
            }
            else {
                banners.addBanner('warning', `(Amounts) No CACN on line ${i}, report will be incomplete`);
            }
        }
        let excelHeaders = [{string: "CACN", key: "cacn"}];
        let dateHeaders = [];
        for(const cacn in data) {
            for(const date in data[cacn]) {
                let countedAlready = false;
                for(const header of dateHeaders) {
                    if(header.key.toString() === date.toString()) {
                        countedAlready = true;
                        break;
                    }
                }
                if(!countedAlready) {
                    dateHeaders.push({
                        date: dateFromString(date),
                        key: date
                    });
                }
            }
        }
        dateHeaders.sort((a, b) => a.date - b.date);
        for(const dateHeader of dateHeaders) {
            excelHeaders.push(dateHeader);
        }
        excelHeaders.push({string: "Total", key: "total"});
        let excelData = [];
        for(const cacn in data) {
            let obj = {};
            obj.cacn = {string: cacn.toString()};
            let total = 0;
            for(const header of excelHeaders) {
                if(header.key !== "cacn" && header.key !== "total") {
                    obj[header.key] = {number: data[cacn][header.key] ? data[cacn][header.key] : 0, style: {numberFormat: "$#,##0.00; ($#,##0.00); -"}};
                    total += obj[header.key].number;
                }
                else if(header.key === "total") {
                    obj[header.key] = {number: total, style: {numberFormat: "$#,##0.00; ($#,##0.00); -"}};
                }
            }
            excelData.push(obj);
        }
        let totalRow = {cacn: {string: "Total"}};
        for(const header of excelHeaders) {
            if(header.key !== "cacn") {
                let total = 0;
                for(const row of excelData) {
                    total += row[header.key].number;
                }
                totalRow[header.key] = {number: total, style: {numberFormat: "$#,##0.00; ($#,##0.00); -"}};
            }
        }
        excelData.push(totalRow);
        return {
            headers: excelHeaders,
            data: excelData
        };
    }

    const dateFromString = (str) => {
        const parts = str.split('-');
        const year = parseInt(parts[0]);
        const month = parseInt(parts[1])-1;
        const day = parseInt(parts[2]);
        return new Date(year, month, day);
    }
    
    const invoiceToLaborHours = (invoice) => {
        // Return a dataSet suitable for /xlsx from the invoice, representing the Hours report
        let data = {};
        const chronologicalLineItems = sortInvoice(invoice);
        for(let i = 0; i < chronologicalLineItems.length; i++) {
            const line = chronologicalLineItems[i];
            const date = line.SalesItemLineDetail.ServiceDate;
            const cacn = line.Description.length >= 6 && line.Description.substring(0,6).match(/\d\d\d\d\d\d/g) ? line.Description.substring(0,6).match(/\d\d\d\d\d\d/g)[0] : null;
            const qty = line.SalesItemLineDetail.Qty;
            if(!date) {
                banners.addBanner('danger', `Could not create Hours report: Required field "Service Date" missing from line ${i}`);
                return null;
            }
            if(cacn) {
                if(!data[cacn]) {
                    data[cacn] = {[date]: qty};
                }
                else {
                    data[cacn][date] = data[cacn][date] ? qty + data[cacn][date] : qty;
                }
            }
            else {
                banners.addBanner('warning', `(Hours) No CACN on line ${i}, report will be incomplete`);
            }
        }
        let excelHeaders = [{string: "CACN", key: "cacn"}];
        let dateHeaders = [];
        for(const cacn in data) {
            for(const date in data[cacn]) {
                let countedAlready = false;
                for(const header of dateHeaders) {
                    if(header.key.toString() === date.toString()) {
                        countedAlready = true;
                        break;
                    }
                }
                if(!countedAlready) {
                    dateHeaders.push({
                        date: dateFromString(date),
                        key: date
                    });
                }
            }
        }
        dateHeaders.sort((a, b) => a.date - b.date);
        for(const dateHeader of dateHeaders) {
            excelHeaders.push(dateHeader);
        }
        excelHeaders.push({string: "Total", key: "total"});
        let excelData = [];
        for(const cacn in data) {
            let obj = {};
            obj.cacn = {string: cacn.toString()};
            let total = 0;
            for(const header of excelHeaders) {
                if(header.key !== "cacn" && header.key !== "total") {
                    obj[header.key] = {number: data[cacn][header.key] ? data[cacn][header.key] : 0};
                    total += obj[header.key].number;
                }
                else if(header.key === "total") {
                    obj[header.key] = {number: total};
                }
            }
            excelData.push(obj);
        }
        let totalRow = {cacn: {string: "Total"}};
        for(const header of excelHeaders) {
            if(header.key !== "cacn") {
                let total = 0;
                for(const row of excelData) {
                    total += row[header.key].number;
                }
                totalRow[header.key] = {number: total};
            }
        }
        excelData.push(totalRow);
        return {
            headers: excelHeaders,
            data: excelData
        };
    }

    const frontPaddedZeroes = (number) => {
        const nZeroes = (3 - (number.toString().length)) >= 0 ? 3 - (number.toString().length) : 0;
        let str = "";
        for(let i = 0; i < nZeroes; i++) {
            str += "0";
        }
        str += number.toString();
        return str;
    }

    const invoiceToCostCollections = (invoice) => {
        // Returns a dataSet representing the Cost Collections of an invoice
        const excelHeaders = [
            {
                string: "Invoice No",
                key: "invoice_number"
            },
            {
                string: "Invoice Line",
                key: "line"
            },
            {
                string: "Invoice Date",
                key: "date"
            },
            {
                string: "Contractor Invoice ID",
                key: "contractor_invoice_id"
            },
            {
                string: "Contract Number",
                key: "contract_number"
            },
            {
                string: "Contract Release",
                key: "contract_release"
            },
            {
                string: "Charge Code (CACN)",
                key: "cacn"
            },
            {
                string: "Cost Invoiced",
                key: "cost"
            }
        ];
        const accountNumber = getInvoiceAccountNumber(invoice);
        if(accountNumber) {
            const contractNumber = accountNumber.split(' ')[0];
            const rel = accountNumber.split(' ')[accountNumber.split(' ').length - 1];
            const date = new Date(invoice.TxnDate);
            let data = [];
            const chronologicalLineItems = sortInvoice(invoice);
            for(let i = 0; i < chronologicalLineItems.length; i++) {
                const line = chronologicalLineItems[i];
                const cacn = line.Description.length >= 6 && line.Description.substring(0,6).match(/\d\d\d\d\d\d/g) ? line.Description.substring(0,6).match(/\d\d\d\d\d\d/g)[0] : null;
                const amount = line.Amount;
                if(cacn) {
                    data.push({
                        invoice_number: {string: invoice.DocNumber},
                        line: {string: frontPaddedZeroes(i+1)},
                        date: {date: date},
                        contractor_invoice_id: {string: "208352"}, // CBIT's unique ID
                        contract_number: {string: "000" + contractNumber},
                        contract_release: {string: frontPaddedZeroes(parseInt(rel))},
                        cacn: {string: cacn},
                        cost: {
                            number: amount,
                            style: {
                                numberFormat: "0.00"
                            }
                        }
                    });
                }
                else {
                    banners.addBanner('warning', `(Cost Collections) No CACN on line ${i}, report will be incomplete`);
                }
            }
            return {
                headers: excelHeaders,
                data: data
            };
        }
        else {
            banners.addBanner('danger', `Could not create Cost Collections report: Required custom field "Account #" missing from invoice`);
            return null;
        }
    }

    const handleHMISSubmit = async(event) => {
        event.preventDefault();
        setSubmitBtnIcon("fas fa-spinner");
        setSubmitBtnLabel("Loading...");
        setSubmitBtnDisabled(true);
        try {
            const invoice = await chimera.callQuickBooksAPI(signal, `/api/qb/invoice/${invoiceNumber}`);

            if(!invoice.CustomerRef.name.includes("HMIS")) {
                //banners.addBanner('danger', `"HMIS" not found in customer's name. Are you sure this is an HMIS invoice?`);
            }

            const amountsDataSet = invoiceToLaborAmounts(invoice);
            const hoursDataSet = invoiceToLaborHours(invoice);
            const costCollectionsDataSet = invoiceToCostCollections(invoice);
            const accountNumber = getInvoiceAccountNumber(invoice);

            let downloadBlobs = [];
            
            if(amountsDataSet) {
                try {
                    const blob = await chimera.callAPI(signal, '/api/xlsx', 'POST', {config: {dateFormat: 'm/d/yyyy'}, dataSets: [amountsDataSet]}, 'blob');
                    downloadBlobs.push({blob: blob, label: `Labor Summary Report ${accountNumber} (Amounts).xlsx`});
                }
                catch(e) {
                    if(e.name !== "AbortError") {
                        console.error(e);
                        setInvoiceNumber("");
                        setSubmitBtnIcon("fas fa-arrow-right");
                        setSubmitBtnLabel("Submit");
                        setSubmitBtnDisabled(false);
                        alert(`ERROR: POST /api/xlsx failed`);
                    }
                }
            }

            if(hoursDataSet) {
                try {
                    const blob = await chimera.callAPI(signal, '/api/xlsx', 'POST', {config: {dateFormat: 'm/d/yyyy'}, dataSets: [hoursDataSet]}, 'blob');
                    downloadBlobs.push({blob: blob, label: `Labor Summary Report ${accountNumber} (Hours).xlsx`});
                }
                catch(e) {
                    if(e.name !== "AbortError") {
                        console.error(e);
                        setInvoiceNumber("");
                        setSubmitBtnIcon("fas fa-arrow-right");
                        setSubmitBtnLabel("Submit");
                        setSubmitBtnDisabled(false);
                        alert(`ERROR: POST /api/xlsx failed`);
                    }
                }
            }
            
            if(costCollectionsDataSet) {
                try{
                    const blob = await chimera.callAPI(signal, '/api/xlsx', 'POST', {config: {dateFormat: 'yyyymmdd'}, dataSets: [costCollectionsDataSet]}, 'blob');
                    downloadBlobs.push({blob: blob, label: `Cost Collections ${accountNumber}.xlsx`});
                }
                catch(e) {
                    if(e.name !== "AbortError") {
                        console.error(e);
                        setInvoiceNumber("");
                        setSubmitBtnIcon("fas fa-arrow-right");
                        setSubmitBtnLabel("Submit");
                        setSubmitBtnDisabled(false);
                        alert(`ERROR: POST /api/xlsx failed`);
                    }
                }
            }
            
            if(!amountsDataSet && !hoursDataSet && !costCollectionsDataSet) {
                setInvoiceNumber("");
                setSubmitBtnIcon("fas fa-arrow-right");
                setSubmitBtnLabel("Submit");
                setSubmitBtnDisabled(false);
            }
            else {
                setDownloadBlobs(downloadBlobs);
            }
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                const invNum = invoiceNumber;
                setInvoiceNumber("");
                setSubmitBtnIcon("fas fa-arrow-right");
                setSubmitBtnLabel("Submit");
                setSubmitBtnDisabled(false);
                alert(`ERROR: Could not get invoice number ${invNum} from QuickBooks`);
            }
        }
    }

    const sheetToJson = (ws) => {
        let data = {};
        for(const key in ws) {
            if(!key.toString().startsWith('!')) {
                // The object at this key represents a cell.
                // The key is the cell name.
                const col = key.toString()[0];
                if(col === "A") {
                    const row = key.toString().split(col)[1];
                    if(parseInt(row) >= 6) {
                        const valueCell = `B${row}`;
                        if(typeof(ws[valueCell]) !== "undefined") {
                            const value = parseFloat(ws[valueCell].f);
                            if(value.toString() !== "NaN") {
                                const dataKey = ws[`A${row}`].v.trim();
                                // TODO: Check for duplicates, warn if one occurs.
                                data[dataKey] = value;
                            }
                        }
                    }
                }
            }
        }
        return data;
    }

    const handleProfitAndLossSubmit = async(event) => {
        event.preventDefault();
        setSubmitBtnIcon("fas fa-spinner");
        setSubmitBtnLabel("Loading...");
        setSubmitBtnDisabled(true);
        if(saveBtnEnabled) saveMap();
        const reader = new FileReader();
        reader.onload = async(evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {type: 'binary'});

            const wsName = wb.SheetNames[0];
            const ws = wb.Sheets[wsName];
            const data = sheetToJson(ws);
            const dateStr = ws['A3'].v;
            // Pass to a route that expects data in this format
            // // and produces the Profit and Loss report.

            try {
                // Ignore 4 digit numbers prepended to keywords
                const dataIgnoringNums = {};
                for(const key in data) {
                    if(key.length >= 4 && key.substring(0,4).match(/^[0-9]{4}$/g)) {
                        dataIgnoringNums[key.substring(4).trim()] = data[key];
                    }
                    else {
                        dataIgnoringNums[key] = data[key];
                    }
                }
                const blob = await chimera.callAPI(signal, '/api/profitandloss', 'POST', {items: dataIgnoringNums, dateStr: dateStr}, 'blob');
                let downloadBlobs = [];
                downloadBlobs.push({blob: blob, label: `Profit And Loss (${dateStr}).xlsx`});
                setDownloadBlobs(downloadBlobs);
            }
            catch(e) {
                if(e.name !== "AbortError") {
                    console.error(e);
                    alert("POST /api/profitandloss failed");
                    setSubmitBtnIcon("fas fa-arrow-right");
                    setSubmitBtnLabel("Submit");
                    setSubmitBtnDisabled(false);
                }
            }
        }
        reader.readAsBinaryString(excelFile);
    }

    const downloadBlob = (obj) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(obj.blob);
        link.download = obj.label;
        link.click();
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if(name === "excelFile") {
            setExcelFile(event.target.files[0]);
        }
        else if(name === "invoiceNumber"){
            setInvoiceNumber(value);
        }
    }

    const camelCaseToSentenceCase = (camelCase) => {
        return camelCase.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + camelCase.replace(/([A-Z])/g, " $1").slice(1);
    }

    const handleMapChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const newMapStrs = JSON.parse(JSON.stringify(mapStrs));
        for(const key in newMapStrs) {
            if(key.toString().toLowerCase().replaceAll(' ', '') === name) {
                newMapStrs[key] = value;
            }
        }
        setMapStrs(newMapStrs);
        setSaveBtnEnabled(true);
        setSaveBtnLabel("Save");
    }

    const saveMap = async(newMap) => {
        try {
            await chimera.callAPI(signal, '/api/profitandloss/map', 'PUT', {map: newMap !== undefined ? newMap : map}, 'text');
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                alert(`WARNING: Could not save map`);
            }
        }
    }

    const handleMapSubmit = async(event) => {
        event.preventDefault();
        let newMap = map;
        for(const key in mapStrs) {
            const terms = mapStrs[key].split(',');
            let values = [];
            for(const term of terms) {
                values.push(term.trim());
            }
            newMap[key] = values;
        }
        setMap(newMap);
        setSaveBtnEnabled(false);
        setSaveBtnLabel("Saved");
        saveMap(newMap);
    }

    const handleSelectHMIS = (event) => {
        event.preventDefault();
        setShowChoice(false);
        setShowHMIS(true);
    }

    const handleSelectProfitAndLoss = (event) => {
        event.preventDefault();
        setShowChoice(false);
        setShowProfitAndLoss(true);
    }

    const resetTool = (event) => {
        event.preventDefault();
        setShowChoice(true);
        setShowHMIS(false);
        setShowProfitAndLoss(false);
        setInvoiceNumber("");
        setSubmitBtnIcon("fas fa-arrow-right");
        setSubmitBtnLabel("Submit");
        setSubmitBtnDisabled(false);
        setDownloadBlobs([]);
        banners.clearBanners();
    }

    const mapFormElement = (fullName) => {
        const elementName = fullName.toLowerCase().replaceAll(' ', '');
        return(
            <div className="row mt-1 mb-1">
                <div className="col-lg-2-5">
                    <div style={{display: "inline", verticalAlign: "middle", paddingTop: 7}}>{fullName}:</div>
                </div>
                <div className="col">
                    <input
                        id={elementName}
                        name={elementName}
                        className="form-control w-100"
                        type="text"
                        onChange={handleMapChange}
                        value={mapStrs[fullName]}/>
                </div>
            </div>
        );
    }

    let renderedDownloadLinks = [];
    for(const obj of downloadBlobs) {
        renderedDownloadLinks.push(
            <div className="row mb-3 mt-3">
                <div className="col-4"></div>
                <div className="col-4">
                    <button className="btn btn-success" onClick={(event) => {event.preventDefault(); downloadBlob(obj)}} style={{width: "100%"}}>
                        {obj.label}
                    </button>
                </div>
                <div className="col-4"></div>
            </div>
        );
    }
    return (
        <div className="col-lg-12">
            <>
            {showChoice ?
                <div className="mb-3 centered">
                    <div className="row mb-3 mt-3">
                        <h4>
                            Choose a Report:
                        </h4>
                    </div>
                    <div className="row mb-3 mt-3">
                        <div className="col-4"></div>
                        <div className="col-4">
                            <button className="btn btn-primary" onClick={handleSelectHMIS} style={{width: "100%"}}>
                                HMIS Labor &amp; Cost Collections
                            </button>
                        </div>
                        <div className="col-4"></div>
                    </div>
                    <div className="row mb-3 mt-3">
                        <div className="col-4"></div>
                        <div className="col-4">
                            <button className="btn btn-primary" onClick={handleSelectProfitAndLoss} style={{width: "100%"}}>
                                Profit and Loss
                            </button>
                        </div>
                        <div className="col-4"></div>
                    </div>
                </div>
            :null}
            {showHMIS ?
                <div className="mb-3 centered">
                    {downloadBlobs.length > 0 ?
                        <div>
                            <h4>Downloads</h4>
                            {renderedDownloadLinks}
                            <div className="row">
                                <div className="col-lg-2"/>
                                <div className="col-lg-8">
                                    <div style={{
                                        marginTop: 5,
                                        marginBottom: 15,
                                        height: 1,
                                        width: "100%",
                                        borderTop: "1px solid rgba(0,0,0,0.2)"
                                    }}/>
                                </div>
                                <div className="col-lg-2"/>
                            </div>
                            <button className="btn btn-primary" onClick={resetTool}>
                                Run Another Report
                            </button>
                        </div>
                    :
                        <form id="HMISForm" onSubmit={handleHMISSubmit} noValidate>
                            <div className="mb-3 centered">
                                <label htmlFor="invoiceNumber" className="form-label">Invoice Number:
                                    <input id="invoiceNumber" name="invoiceNumber"  
                                    className="form-control centered" type="text" 
                                    aria-describedby="invoiceNumberDescr" value={invoiceNumber} 
                                    onChange={handleChange} required disabled={submitBtnDisabled}/>
                                </label>
                                <div id="invoiceNumberDescr" className="form-text">
                                    The number of the HMIS invoice to be used to generate the reports.
                                </div>
                                <button type="submit" className="btn btn-primary" disabled={submitBtnDisabled}>
                                    <i className={submitBtnIcon}></i>
                                    <span>&nbsp;{submitBtnLabel}</span>
                                </button>
                            </div>
                        </form>
                    }
                </div>
            :null}
            {showProfitAndLoss ?
                <>
                {downloadBlobs.length > 0 ?
                    <div>
                        <h4>Downloads</h4>
                        {renderedDownloadLinks}
                        <div className="row">
                            <div className="col-lg-2"/>
                            <div className="col-lg-8">
                                <div style={{
                                    marginTop: 5,
                                    marginBottom: 15,
                                    height: 1,
                                    width: "100%",
                                    borderTop: "1px solid rgba(0,0,0,0.2)"
                                }}/>
                            </div>
                            <div className="col-lg-2"/>
                        </div>
                        <button className="btn btn-primary" onClick={resetTool}>
                            Run Another Report
                        </button>
                    </div>
                :
                    <>
                    <div className="mb-3 centered">
                        <div className="col">
                            <div id="accordion">
                                <div className="card">
                                    <a data-bs-toggle="collapse" href="#profitAndLossMap">
                                        <div className="card-header map-header">
                                            <span className="btn">Map</span>
                                        </div>
                                    </a>
                                    <div id="profitAndLossMap" className="collapse" data-bs-parent="#accordion">
                                        <div className="card-body text-start">
                                            <form id="ProfitAndLossMapForm" onSubmit={handleMapSubmit} noValidate>
                                                {mapFormElement("Services Res Only")}
                                                {mapFormElement("Services Minus Res")}
                                                {mapFormElement("Unapplied Cash Income Res")}
                                                {mapFormElement("Unapplied Cash Income Minus Res")}
                                                {mapFormElement("Convenience Fee Res Only")}
                                                {mapFormElement("Convenience Fee Minus Res")}
                                                {mapFormElement("MSP Services Res Only")}
                                                {mapFormElement("MSP Services Minus Res")}
                                                {mapFormElement("Telephone Res Only")}
                                                {mapFormElement("Telephone Minus Res")}
                                                {mapFormElement("Internet Res Only")}
                                                {mapFormElement("Internet Minus Res")}
                                                {mapFormElement("Labor Res Only")}
                                                {mapFormElement("Labor Minus Res")}
                                                {mapFormElement("MSP Retail Res Only")}
                                                {mapFormElement("MSP Retail Minus Res")}
                                                {mapFormElement("Sales of Product Income Res Only")}
                                                {mapFormElement("Sales of Product Income Minus Res")}
                                                {mapFormElement("Sales Tax Liability Taxable Amount")}
                                                <button type="submit" className="btn btn-primary" disabled={!saveBtnEnabled}>
                                                    <i className={saveBtnIcon}></i>
                                                    <span>&nbsp;{saveBtnLabel}</span>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form id="ProfitAndLossForm" onSubmit={handleProfitAndLossSubmit} noValidate>
                        <div className="mb-3 centered">
                            <FormField name="excelFile"
                                    handleChange={handleChange}
                                    label="Report Document (.xlsx)"
                                    type="file"
                                    required
                                    description="The Profit and Loss report from QuickBooks, exported in Excel (.xlsx) format.">
                            </FormField>
                            <button type="submit" className="btn btn-primary" disabled={submitBtnDisabled}>
                                <i className={submitBtnIcon}></i>
                                <span>&nbsp;{submitBtnLabel}</span>
                            </button>
                        </div>
                    </form>
                    </>
                }
                </>
            :null}
            </>
        </div>
    );
}

const QBReports = props => {
    const toolName = "QB Reports";
    const toolId = "qbreports";
    return (
        <ToolPage toolId={toolId} toolName={toolName}>
            <ToolPage.Header image={qbreportsimg} toolName={toolName} alt="A spreadsheet report flowing from QuickBooks">
                Generate custom reports from QuickBooks data.
            </ToolPage.Header>
            <ToolPage.How>
                <p>
                    This tool offers you a choice between different kinds of reports.
                    Here is a breakdown of the options:
                </p>
                <h3>
                    HMIS Labor &amp; Cost Collections
                </h3>
                <h4>
                    Preparation
                </h4>
                <p>
                    This report requires a pre-existing invoice in QuickBooks to pull the
                    data from. That invoice has a number of special requirements:
                </p>
                <ul>
                    <li>
                        All line item descriptions must begin with a 6 digit charge code (CACN).
                        The tool will show a warning if no charge code can be detected. The charge code
                        will only be detected if it is the very first thing in the line item's description.
                    </li>
                    <li>
                        The tool will warn if you run an invoice for a customer that does not have
                        "HMIS" in their name. This is a non-critical warning meant to ensure you
                        run the correct invoice. Please verify that any relevant customers in QuickBooks
                        contain "HMIS" in their name to prevent this warning.
                    </li>
                </ul>
                <h4>
                    Usage
                </h4>
                <p>
                    <ol>
                        <li>
                            <strong>Click <i>HMIS Labor &amp; Cost Collections</i></strong> to begin.
                        </li>
                        <li>
                            <strong>Enter the Invoice Number</strong> of the invoice you wish to generate
                            the reports from. For HMIS invoices, this is generally a four digit number.
                        </li>
                        <li>
                            <strong>Click <i>Submit</i></strong> to generate the reports.
                        </li>
                        <li>
                            <strong>Click the green buttons</strong> to start the downloads.
                        </li>
                    </ol>
                </p>
                <h3>
                    Profit and Loss
                </h3>
                <h4>
                    Preparation
                </h4>
                <p>
                    This report generates a profit and loss report directly from the QuickBooks API,
                    which means you shouldn't have to do anything special before running this tool.
                </p>
                <h4>
                    Usage
                </h4>
                <p>
                    <ol>
                        <li>
                            <strong>Click <i>Profit and Loss</i></strong> to begin.
                        </li>
                        <li>
                            <strong>Enter the Start Date and End Date</strong> to define the bounds on the date range of the report.
                            Typically, these are the first and last dates of a given month, but they can be anything.
                        </li>
                        <li>
                            <strong>(Optional) Select the Accounting Method.</strong> By default, this is set to
                            "Cash", which is generally CBIT's custom, but you could change it to "Accrual" if you
                            wanted to for some reason.
                        </li>
                        <li>
                            <strong>Click <i>Submit</i></strong> to generate the report.
                        </li>
                        <li>
                            <strong>Click the green button</strong> to start the download.
                        </li>
                    </ol>
                </p>
                <h4>
                    Methodology
                </h4>
                <p>
                    This report is highly customized and currently follows the mapping provided
                    to the developer corresponding to an example report generated for September 2021.
                </p>
                <p>
                    As QuickBooks items are added/changed over time, <b>this report will not adapt automatically.</b> It
                    will be necessary to update the developer on the current mapping to ensure that reports are accurate.
                </p>
            </ToolPage.How>
            <ToolPage.Body>
                <QBReportsBody />
            </ToolPage.Body>
        </ToolPage>
    );
}

export default QBReports;