import React, {useState, useLayoutEffect, useContext} from 'react';

import chimera from '../../chimera';
import FormField from '../FormField';
import PatchNotesDisplay from '../PatchNotesDisplay';
import Modal from '../Modal';
import ModalContext from '../ModalContext';

const PatchNotesUpload = props => {
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [submitBtnIcon, setSubmitBtnIcon] = useState("fas fa-plus");
    const [submitBtnLabel, setSubmitBtnLabel] = useState("Submit");
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
    const [controller, setController] = useState(new AbortController());
    const [signal, setSignal] = useState(controller.signal);
    const modaling = useContext(ModalContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    const handleChange = (event) => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        if(name === "title") {
            setTitle(value);
        }
        else if(name === "text") {
            setText(value);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitBtnIcon("fas fa-spinner");
        setSubmitBtnLabel("Submitting...");
        setSubmitBtnDisabled(true);
        chimera.callAPI(signal, '/api/patchnotes', 'POST', {
            title: title,
            text: text
        })
        .then(_ => {
            setSubmitBtnIcon("fas fa-check");
            setSubmitBtnLabel("Submitted!");
            window.open('/patchnotes', '_self');
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                console.error(err);
                alert("Error");
                setSubmitBtnDisabled(false);
                setSubmitBtnIcon("fas fa-plus");
                setSubmitBtnLabel("Submit");
            }
        })
    }

    const composePatchNotes = () => {
        const date = new Date();
        return {
            title: title,
            text: text,
            createdAt: date
        }
    }

    const openPreview = (event) => {
        event.preventDefault();
        const patchNotes = composePatchNotes();
        const modal = <Modal choices={[]} dismiss={(event) => {event.preventDefault(); modaling.setModal(null)}}>
            <PatchNotesDisplay notes={patchNotes}/>
        </Modal>
        modaling.setModal(modal);
    }

    return (
        <div className="container pb-5">
            <h1>Submit Patch Notes</h1>
            <form id="PatchNotesForm" onSubmit={handleSubmit} noValidate>
                <FormField
                    name="title"
                    handleChange={handleChange}
                    label="Title"
                    type="text"
                    description="The Patch Notes title"
                />
                <FormField
                    name="text"
                    handleChange={handleChange}
                    label="Text"
                    type="textarea"
                    description="The Patch Notes text"
                />
                <button className="btn btn-secondary" onClick={openPreview}>
                    <i className="fas fa-magnifying-glass"/>
                    &nbsp;Preview
                </button>
                <br/>
                <button className="btn btn-primary mt-2" type="submit" disabled={submitBtnDisabled}>
                    <i className={submitBtnIcon}/>
                    &nbsp;{submitBtnLabel}
                </button>
            </form>
        </div>
    )
}

export default PatchNotesUpload;