import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';

import chimera from '../../chimera';
import LoadingSpinner from '../LoadingSpinner';
import BannerContext, {BannerLog} from '../BannerLogContext';
import ReportTable from '../ReportTable';

const UnityE911Body = props => {
    const [report, setReport] = useState(null);
    const [label, setLabel] = useState("");
    const [loading, setLoading] = useState(true);
    const [btnLabel, setBtnLabel] = useState("Save to Chimera & Email to Support (Open Ticket)");
    const [btnIcon, setBtnIcon] = useState("fas fa-arrow-right");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [controller, setController] = useState(new AbortController());
    const [signal, setSignal] = useState(controller.signal);
    const banners = useContext(BannerContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        setLabel("Getting Unity domains...");
        chimera.callAPI(signal, '/api/unity/domains')
        .then(async domains => {
            try {
                const cols = ["Domain", "User", "Domain 911 Caller ID", "User 911 Caller ID"];
                let rows = [];
                for(const domainObj of domains) {
                    setLabel(`Reading subscribers for ${domainObj.domain}...`);
                    const detailedDomain = await chimera.callAPI(signal, `/api/unity/domains/${domainObj.domain}`);
                    const subscribers = await chimera.callAPI(signal, `/api/unity/domains/${domainObj.domain}/subscribers`);
                    for(const sub of subscribers) {
                        if(sub.callid_emgr.toString() !== detailedDomain.callid_emgr.toString()) {
                            rows.push({
                                "Domain": detailedDomain.domain,
                                "User": `${sub.user} (${sub.first_name} ${sub.last_name})`,
                                "Domain 911 Caller ID": detailedDomain.callid_emgr.toString(),
                                "User 911 Caller ID": sub.callid_emgr.toString()
                            });
                        }
                    }
                }
                setReport({type: "UnityE911", cols: cols, rows: rows})
                setLoading(false);
            }
            catch(e) {
                if(e.name !== "AbortError") {
                    console.error(e);
                    banners.addBanner('danger', 'Something went wrong and the report could not be generated', 'Error');
                }
            }
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                console.error(err);
                banners.addBanner('danger', 'Failed to read domains from Unity API', 'Error');
            }
        })
    }, []);

    const saveAndSend = (event) => {
        event.preventDefault();
        setBtnDisabled(true);
        setBtnIcon("fas fa-spinner");
        setBtnLabel("Working...");
        chimera.callAPI(signal, '/api/reporttables', 'POST', report)
        .then(savedReport => {
            chimera.callAPI(signal, '/api/sendmail', 'POST', {
                subject: 'Unity E911 Report',
                email: 'support@gocbit.com',
                text: `The report can be viewed in Chimera here: https://bms.gocbit.com/reporttables/${savedReport._id}\n\nThis is an automated message sent from Chimera BMS.`
            })
            .then(_ => {
                setBtnLabel("Done!");
                setBtnIcon("fas fa-check");
            })
            .catch(err => {
                if(err.name !== "AbortError") {
                    console.error(err);
                    banners.addBanner('danger', 'The report was saved to Chimera, but the email failed to send.', 'Error');
                    setBtnLabel("Error");
                    setBtnIcon("fas fa-times");
                }
            })
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                console.error(err);
                banners.addBanner('danger', 'Failed to save to Chimera. Report will not be emailed to Support.', 'Error');
                setBtnLabel("Save to Chimera & Email to Support (Open Ticket)");
                setBtnIcon("fas fa-arrow-right");
                setBtnDisabled(false);
            }
        })
    }

    return (
        <>
        {loading ? 
            <LoadingSpinner size={75} label={label}/>
        :
            <>
                <button className="btn btn-primary" onClick={saveAndSend} disabled={btnDisabled}>
                    <i className={btnIcon}/>
                    &nbsp;{btnLabel}
                </button>
                <ReportTable report={report}/>
            </>
        }
        </>
    )
}

const UnityE911 = props => {
    return (
        <BannerLog>
            <UnityE911Body/>
        </BannerLog>
    )
}

export default UnityE911;