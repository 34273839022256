import React, {useState, useEffect, useContext} from 'react';

import LoadingSpinner from '../LoadingSpinner';
import chimera from '../../chimera';
import BannerContext from "../BannerLogContext";
import Modal from '../Modal';
import ModalContext from '../ModalContext';
import NotesInterface from '../NotesInterface';
import NoteDisplay from '../NoteDisplay';

const NotesSection = props => {
    const [categories, setCategories] = useState(null);
    const modalContext = useContext(ModalContext);
    const banners = useContext(BannerContext);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        chimera.callAPI(signal, '/api/notecategories')
        .then(cats => setCategories(cats))
        .catch(err => {
            if(err.name !== "AbortError") {
                console.error(err);
                banners.addBanner('danger', 'Could not read Note Categories (cannot display notes)', 'Error');
            }
        })

        return () => {
            controller.abort();
        }
    }, []);
    
    const openNotesInterface = event => {
        event.preventDefault();
        const choices = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                btnColor: "secondary",
                func: (event) => {
                    event.preventDefault();
                    props.setNotes(null);
                    modalContext.backtrack();
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <NotesInterface refId={props.refId} technical={props.technical} noFilter={props.noFilter} subId={props.subId}/>
        </Modal>
        modalContext.setModal(modal);
    }

    const noteMatchesTechnical = (note) => {
        if(props.noFilter) return true;
        let noteIsTechnical = false;
        for(const catId of note.categories) {
            for(const cat of categories) {
                if(cat._id === catId && cat.technical) {
                    noteIsTechnical = true;
                    break;
                }
            }
        }
        return (noteIsTechnical && props.technical) || (!noteIsTechnical && !props.technical);
    }

    const noteMatchesSubID = (note) => {
        if(props.subId === undefined || note.subId === undefined || note.subId === "") return true;
        return note.subId === props.subId;
    }
    return (
        <>
        <div className="row">
            <div className="col-3"/>
            <div className="col-6">
                {props.smallHeader ? <b>{props.header}</b> : <h5><b>{props.header}</b></h5>}
            </div>
            <div className="col-3">
                <button className="btn btn-sm btn-secondary float-end" onClick={openNotesInterface}>
                    <i className="fas fa-note-sticky"/>&nbsp;
                    {props.notes === null || categories === null ? <i className="fas fa-spinner"/> : props.notes.filter(note => noteMatchesTechnical(note) && noteMatchesSubID(note)).length}
                </button>
            </div>
        </div>
        {props.notes === null || categories === null ?
            <LoadingSpinner size={50}/>
        :
            <>
            {props.notes.filter(note => note.pinned && noteMatchesTechnical(note) && noteMatchesSubID(note)).length > 0 ?
                <>
                {props.notes.filter(note => note.pinned && noteMatchesTechnical(note) && noteMatchesSubID(note)).map((note, i) => <div key={i} className="row mt-1"><div className="col"><NoteDisplay note={note} readonly/></div></div>)}
                </>
            :
                <p className="text-muted"><i>There are no pinned notes for this {props.objectName ? props.objectName : 'customer'}.</i></p>
            }
            </>
        }
        </>
    )
}

export default NotesSection;