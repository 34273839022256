import React from 'react';

import chimera from '../../chimera';

const PocDisplay = props => {
    const path = partial => {
        return props.basePath + '.' + partial;
    }

    return (
        <>
        {!props.isEditing ? 
        <div className="text-start">
            <div className="row">
                <b>{props.poc.firstName} {props.poc.lastName}</b>
            </div>
            <div className="row">
                <span><i className="fas fa-envelope"/>&nbsp;{props.poc.email}</span>
                {props.poc.phone ? 
                    <><br/><span><i className="fas fa-phone"/>&nbsp;{chimera.phoneNumberStr(props.poc.phone)}</span></>
                : null}
            </div>
        </div>
        :
        <div className="position-relative">
            {props.series ? 
                <button className="btn btn-danger position-absolute top-0 start-100 translate-close" onClick={props.handleDelete} disabled={props.disabled}>
                    <i className="fas fa-minus"/>
                </button>
            : null}
            <div className="row mb-1">
                <div className="col">
                    <input className="form-control"
                        type="text"
                        name={path('firstName')}
                        value={chimera.getAttr(props.baseValue, path('firstName'))}
                        onChange={props.onChange}
                        placeholder="First Name"
                        disabled={props.disabled}
                        onBlur={props.onBlur}/>
                </div>
                <div className="col">
                    <input className="form-control"
                        type="text"
                        name={path('lastName')}
                        value={chimera.getAttr(props.baseValue, path('lastName'))}
                        onChange={props.onChange}
                        placeholder="Last Name"
                        disabled={props.disabled}
                        onBlur={props.onBlur}/>
                </div>
            </div>
            <div className="row mb-1">
                <div className="col">
                    <input className="form-control"
                        type="text"
                        name={path('email')}
                        value={chimera.getAttr(props.baseValue, path('email'))}
                        onChange={props.onChange}
                        placeholder="Email"
                        disabled={props.disabled}
                        onBlur={props.onBlur}/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <input className="form-control"
                        type="text"
                        name={path('phone')}
                        value={chimera.getAttr(props.baseValue, path('phone'))}
                        onChange={props.onChange}
                        placeholder="Phone"
                        maxLength={11}
                        disabled={props.disabled}
                        onBlur={props.onBlur}/>
                </div>
            </div>
        </div>
        }
        </>
    )
}

export default PocDisplay;