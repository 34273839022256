import React from 'react';

const LoadingSpinner = ({size, label}) => {
    return (
        <center>
            <div style={{height: size, width: size}}>
                <div className="spinner" style={{height: size, width: size, marginTop: size / 2, marginLeft: size / 2}}/>
            </div>
            {label ? 
            <div className="form-text">
                {label}
            </div>
            :null}
        </center>
    )
}

export default LoadingSpinner;