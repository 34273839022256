import React, {useState} from 'react';
import chimera from '../chimera';

const LoginPage = props => {
    const [btnIcon, setBtnIcon] = useState("fab fa-microsoft");
    const [btnLabel, setBtnLabel] = useState("Sign in with Azure AD");
    const [btnDisabled, setBtnDisabled] = useState(false);
    
    const handleOAuthLoginClick = async() => {
        setBtnIcon("fas fa-spinner");
        setBtnLabel("Connecting...");
        setBtnDisabled(true);
        const target = window.location.pathname + window.location.search;
        if(target !== "/") {
            await fetch('/api/session/target', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    target: target
                })
            });
        }
        window.open(`${chimera.isDeveloperMode() ? "http://localhost:3001" : ""}/auth/azuread`, "_self");
    }

    return(
        <div className="container" style={{paddingTop: 100}}>
            <h2>Please sign in to Chimera BMS</h2>
            <button type="button" className="btn btn-primary btn-lg" disabled={btnDisabled}
                onClick={() => handleOAuthLoginClick()}>
                <i className={btnIcon}></i>
                &nbsp;{btnLabel}
            </button>
        </div>
    )
}

export default LoginPage;