import React, {useState, useLayoutEffect, useEffect} from 'react';
import ModalCancelOnly from './ModalCancelOnly';

import chimera from '../chimera';
import LoadingSpinner from './LoadingSpinner';

const ServiceTypesModal = ({customerIds, modalContext, onClose}) => {
    const [alerts, setAlerts] = useState(null);
    const [changes, setChanges] = useState(null);
    const [emailBtnIcon, setEmailBtnIcon] = useState("fas fa-envelope");
    const [emailBtnLabel, setEmailBtnLabel] = useState("Email Me");
    const [emailBtnDisabled, setEmailBtnDisabled] = useState(false);
    const [controller] = useState(new AbortController());
    const [signal] = useState(controller.signal);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        chimera.callAPI(signal, '/api/updateservicetypes', 'POST', {ids: customerIds})
        .then(results => {
            setAlerts(results.alerts);
            setChanges(results.changes);
        })
        .catch(err => {
            console.error(err);
            alert('An unhandled error has occurred. Please see the console output for more (Ctrl+Shift+J)');
        })
    }, []);

    const capitalizedServiceType = (serviceType) => {
        switch(serviceType) {
            case 'internet':
                return 'Internet';
            case 'msp':
                return 'MSP';
            case 'voip':
                return 'VoIP';
            case 'video':
                return 'Video';
            default:
                return '';
        }
    }

    const renderAlert = (alert) => {
        return (
            <span>
                <strong>{alert.customerName}:</strong>&nbsp;
                {alert.nTxns === 0 ? 
                    'There were no recurring transactions detected for this customer in QuickBooks.' 
                :
                    `This ${capitalizedServiceType(alert.serviceType)} customer in Chimera was not detected as billed for it in QuickBooks.`
                }
            </span>
        )
    }

    const renderChange = (change) => {
        return (
            <span>
                <strong>{change.customerName}:</strong>&nbsp;
                {`Set as ${capitalizedServiceType(change.serviceType)} customer`}
            </span>
        )
    }

    const emailResults = (event) => {
        event.preventDefault();
        setEmailBtnIcon("fas fa-spinner");
        setEmailBtnLabel("Sending...");
        setEmailBtnDisabled(true);
        const html = document.getElementById('serviceTypesResults').innerHTML;
        chimera.callAPI(signal, '/api/sendmail', 'POST', {
            subject: "Service Types Auto-Sync Results",
            html: html
        })
        .then(_ => {
            alert('Email sent successfully');
        })
        .catch(err => {
            console.error(err);
            alert('ERROR: Failed to send email');
        })
        .finally(() => {
            setEmailBtnIcon("fas fa-envelope");
            setEmailBtnLabel("Email Me");
            setEmailBtnDisabled(false);
        })
    }

    return(
        <ModalCancelOnly backtrack context={modalContext} onClose={onClose}>
            <h2>Service Types Auto-Sync</h2>
            {alerts !== null && changes !== null ?
            <>
            {alerts.length === 0 ?
            <>
            <h3 className="text-success">Success</h3>
            <div id="serviceTypesResults">
                <p>{customerIds.length} Customer(s) successfully synced with no issues.</p>
                {changes.length === 0 ? <p>No action needed, indicating all Service Types were already correctly set.</p> : 
                <>
                <p>The process identified the need for the following changes to be made:</p>
                <ol className="text-start">
                    {changes.sort((a,b) => a.customerName < b.customerName ? -1 : 1).map((change, i) => <li key={i}>{renderChange(change)}</li>)}
                </ol>
                </>
                }
            </div>
            <button className="btn btn-primary" onClick={emailResults} disabled={emailBtnDisabled}>
                <i className={emailBtnIcon}/>&nbsp;{emailBtnLabel}
            </button>
            </>
            :
            <>
            <h2 className="text-danger">Attention</h2>
            <div id="serviceTypesResults">
                <p>The process detected the following issues:</p>
                <ol className="text-start">
                    {alerts.sort((a,b) => a.customerName < b.customerName ? -1 : 1).map((alert, i) => <li key={i}>{renderAlert(alert)}</li>)}
                </ol>
                {changes.length === 0 ? <p>No changes were made, indicating all Service Types were already correctly set.</p> : 
                <>
                <p>The process identified the need for the following changes to be made:</p>
                <ol className="text-start">
                    {changes.sort((a,b) => a.customerName < b.customerName ? -1 : 1).map((change, i) => <li key={i}>{renderChange(change)}</li>)}
                </ol>
                </>
                }
            </div>
            <button className="btn btn-primary" onClick={emailResults} disabled={emailBtnDisabled}>
                <i className={emailBtnIcon}/>&nbsp;{emailBtnLabel}
            </button>
            </>
            }
            </>
            :
            <LoadingSpinner size={75} label={`Updating Service Types for ${customerIds.length} Customer(s)...`}/>
            }
        </ModalCancelOnly>
    )
}

export default ServiceTypesModal;