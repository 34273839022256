import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';
import chimera from '../chimera';
import LoadingSpinner from './LoadingSpinner';
import BannerContext, { BannerLog } from "./BannerLogContext";
import FormField from './FormField';
import UserContext from '../UserContext';
import NoteDisplay from './NoteDisplay';

const NotesInterfaceBody = props => {

    const getDefaultGroupsCanRead = () => {
        let defaultGroupsCanRead = [];
        for(const group of context.groups) {
            defaultGroupsCanRead.push(group);
        }
        if(!defaultGroupsCanRead.includes("Executive")) defaultGroupsCanRead.push("Executive");
        return defaultGroupsCanRead;
    }

    const banners = useContext(BannerContext);
    const context = useContext(UserContext);
    const [notes, setNotes] = useState(null);
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [groupsCanRead, setGroupsCanRead] = useState(getDefaultGroupsCanRead());
    const [categories, setCategories] = useState(null);
    const [categoriesEnabled, setCategoriesEnabled] = useState([]);
    const [groups, setGroups] = useState(null);
    const [loading, setLoading] = useState(true);
    const [working, setWorking] = useState(false);
    const [saveBtnIcon, setSaveBtnIcon] = useState("fas fa-plus");
    const [saveBtnLabel, setSaveBtnLabel] = useState("Save Note");
    const [controller, setController] = useState(new AbortController());
    const [signal, setSignal] = useState(controller.signal);

    useEffect(() => {
        if(groups === null) {
            chimera.callAPI(signal, '/api/groups/')
            .then(newGroups => setGroups(newGroups.map(group => group.name)))
            .catch(e => {
                if(e.name !== "AbortError") {
                    banners.addBanner('danger', 'Could not read groups', 'Error');
                    console.error(e);
                }
            })
        }
    }, [groups]);

    useEffect(() => {
        if(categories === null) {
            chimera.callAPI(signal, '/api/notecategories')
            .then(newCategories => setCategories(newCategories))
            .catch(e => {
                if(e.name !== "AbortError") {
                    banners.addBanner('danger', 'Could not read categories', 'Error');
                    console.error(e);
                }
            })
        }
        if(notes === null && categories !== null) {
            chimera.callAPI(signal, `/api/notes/refId/${props.refId}`)
            .then(newNotes => setNotes(sortByStarred(newNotes.filter(note => noteMatchesTechnical(note) && noteMatchesSubID(note)))))
            .catch(e => {
                if(e.name !== "AbortError") {
                    banners.addBanner('danger', 'Could not read notes', 'Error');
                    console.error(e);
                }
            });
        }
    }, [categories, notes]);

    useEffect(() => {
        if(notes !== null && groups !== null && categories !== null) setLoading(false);
    }, [notes, groups, categories]);

    const noteMatchesTechnical = (note) => {
        if(props.noFilter) return true;
        let noteIsTechnical = false;
        for(const catId of note.categories) {
            for(const cat of categories) {
                if(cat._id === catId && cat.technical) {
                    noteIsTechnical = true;
                    break;
                }
            }
        }
        return (noteIsTechnical && props.technical) || (!noteIsTechnical && !props.technical);
    }

    const noteMatchesSubID = (note) => {
        if(props.subId === undefined || note.subId === undefined || note.subId === "") return true;
        return note.subId === props.subId;
    }

    const sortByStarred = (arr) => {
        let starredNotes = [];
        let unstarredNotes = [];
        for(const note of arr) {
            if(note.starred) starredNotes.push(note);
            else unstarredNotes.push(note);
        }
        return [].concat(starredNotes, unstarredNotes);
    }

    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        if(name === "title") {
            setTitle(value);
        }
        else if(name === "text") {
            setText(value);
        }
        else if(name.includes("group")) {
            const index = parseInt(name.substring(5));
            if(groupsCanRead.includes(groups[index])) {
                // Group was unchecked, remove it.
                let newCanRead = [];
                for(const group of groupsCanRead) {
                    if(group !== groups[index]) {
                        newCanRead.push(group);
                    }
                }
                setGroupsCanRead(newCanRead);
            }
            else {
                // Group was checked, add it.
                setGroupsCanRead(canRead => [...canRead, groups[index]]);
            }
        }
        else if(name.includes("category")) {
            const index = parseInt(name.substring(8));
            if(categoriesEnabled.find(id => id === categories[index]._id)) {
                // Category was unchecked, remove it.
                let newCategories = [];
                for(const id of categoriesEnabled) {
                    if(id !== categories[index]._id) {
                        newCategories.push(id);
                    }
                }
                setCategoriesEnabled(newCategories);
            }
            else {
                // Category was checked, add it.
                setCategoriesEnabled(ids => [...ids, categories[index]._id]);
            }
        }
    }

    const updateIndex = async(index, value, skipUpdate) => {
        let reqBody = {};
        let newNotes = [];
        if(!skipUpdate) {
            for(const key in value) {
                if(key !== '_id' && key !== 'createdAt' && key !== 'updatedAt' && key !== '__v') {
                    reqBody[key] = value[key];
                }
            }
            try {
                const updatedNote = await chimera.callAPI(signal, `/api/notes/${notes[index]._id}`, 'PUT', reqBody);
                for(let i = 0; i < notes.length; i++) {
                    if(i === index) {
                        newNotes.push(updatedNote);
                    }
                    else {
                        newNotes.push(notes[i]);
                    }
                }
            }
            catch(e) {
                if(e.name !== "AbortError") {
                    console.error(e);
                    banners.addBanner('danger', "Failed to save changes for Note", "Error");
                }
            }
        }
        else {
            newNotes = notes.map((note, i) => i === index ? value : note);
        }
        setNotes(sortByStarred(newNotes));
    }

    const saveNewNote = (event) => {
        event.preventDefault();
        setSaveBtnIcon("fas fa-spinner");
        setSaveBtnLabel("Saving...");

        chimera.callAPI(signal, '/api/notes', 'POST', {
            refId: props.refId,
            subId: props.subId !== undefined ? props.subId : '',
            title: title,
            text: text,
            groupsCanRead: groupsCanRead,
            categories: categoriesEnabled,
            author: {
                first: context.user.first,
                last: context.user.last,
                email: context.user.email
            }
        })
        .then(createdNote => addNote(createdNote))
        .catch(e => {
            if(e.name !== "AbortError"){
                console.error(e);
                banners.addBanner('danger', 'Could not create the Note', 'Error');
            }
        })
        .finally(() => {
            setWorking(false);
            setTitle("");
            setText("");
            setGroupsCanRead(context.groups);
            setSaveBtnIcon("fas fa-plus");
            setSaveBtnLabel("Save Note");
        })
    }

    const addNote = (note) => {
        setNotes(notes => sortByStarred([note, ...notes]));
    }

    return (
        <>
        {loading ?
            <LoadingSpinner size={100}/>
        :
            <>
            {notes.length === 0 ?
                <>
                {!working ? 
                <p>There are no notes here yet.</p>
                :null}
                </>
            :null}
            {!working ? 
                <button className="btn btn-primary mb-1" onClick={(event) => {event.preventDefault(); setWorking(true)}}>
                    <i className="fas fa-plus"/>
                    &nbsp;Add Note
                </button>
            :
                <div className="row d-flex justify-content-center mb-1">
                    <div className="col-12 col-sm-10 col-lg-8">
                        <div className="section-outline">
                            <FormField
                                type="text"
                                name="title"
                                label="Subject"
                                value={title}
                                description="The subject of the Note."
                                handleChange={handleChange}
                                placeholder="Subject"
                            />
                            <FormField
                                type="textarea"
                                name="text"
                                label="Note Text"
                                value={text}
                                description="The content of the Note."
                                handleChange={handleChange}
                            />
                            <div className="form-field">
                                <div className="form-label">Categories:</div>
                                {categories.map((cat, i) => <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" name={`category${i}`} id={`category${i}Check`} checked={categoriesEnabled.find(id => id === cat._id)} onChange={handleChange}/>
                                    <label className="form-check-label" htmlFor={`category${i}Check`}>
                                        {cat.name}
                                    </label>
                                </div>)}
                            </div>
                            <div className="form-field">
                                <div className="form-label">Share With Groups:</div>
                                {groups.map((group, i) => <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" name={`group${i}`} id={`group${i}Check`} checked={group !== "Executive" ? groupsCanRead.includes(group) : true} onChange={handleChange} disabled={group === "Executive"}/>
                                    <label className="form-check-label" htmlFor={`group${i}Check`}>
                                        {group}
                                    </label>
                                </div>)}
                            </div>
                            <div className="row">
                                <div className="col">
                                    <button className="btn btn-secondary" onClick={(event) => {event.preventDefault(); setWorking(false)}}>
                                        <i className="fas fa-arrow-left"/>
                                        &nbsp;Cancel
                                    </button>
                                </div>
                                <div className="col">
                                    <button className="btn btn-success" onClick={saveNewNote}>
                                        <i className={saveBtnIcon}/>
                                        &nbsp;{saveBtnLabel}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {notes.length > 0 ?
                <>
                {notes.map((note, i) => <div className="row mb-1">
                    <div className="col">
                        <NoteDisplay note={note} index={i} updateIndex={updateIndex} groups={groups} categories={categories}/>
                    </div>
                </div>)}
                </>
            :null}
            </>
        }
        </>
    )
}

const NotesInterface = props => {
    return (
        <BannerLog>
            <NotesInterfaceBody {...props}/>
        </BannerLog>
    )
}

export default NotesInterface;