import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

const PatchNotesDisplay = props => {

    const timestamp = (new Date(props.notes.createdAt)).toLocaleString();

    return (
        <div className="text-start section-outline">
            {props.notes.title ? <h2>{props.notes.title}</h2> : null}
            <p className="text-muted"><i>{timestamp}</i></p>
            <ReactMarkdown remarkPlugins={[gfm]}>
                {props.notes.text}
            </ReactMarkdown>
        </div>
    )
}

export default PatchNotesDisplay;