import React, {useState, useEffect} from 'react';

import LoadingSpinner from '../LoadingSpinner';
import chimera from '../../chimera';

const OpenBalancePastDue = props => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openBalance, setOpenBalance] = useState(0.0);
    const [pastDue, setPastDue] = useState(0.0);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        const now = new Date();
        const today = now.toISOString().substring(0,10);
        chimera.callQuickBooksAPI(signal, `/api/qb/customer/${props.id}`)
        .then(qbCustomer => {
            setOpenBalance(qbCustomer.Balance);
            chimera.callQuickBooksAPI(signal, `/api/qb/customer/${props.id}/invoices`)
            .then(invoices => {
                let newPastDue = 0.0;
                for(const invoice of invoices) {
                    if(invoice.DueDate > today) {
                        newPastDue += invoice.TotalAmt;
                    }
                }
                setPastDue(newPastDue);
                setLoading(false);
            })
            .catch(e => {
                if(e.name !== "AbortError") {
                    console.error(e);
                    setError(e);
                    setLoading(false);
                }
            })
        })
        .catch(e => {
            if(e.name !== "AbortError") {
                console.error(e);
                setError(e);
                setLoading(false);
            }
        })
        return () => {
            controller.abort();
        }
    }, []);

    return (
        <div className="d-flex flex-column text-start">
        {loading ?
            <>
            <b>Open Balance / Past Due:</b>
            <LoadingSpinner size={50}/>
            </>
        :
            <>
            {error ? 
                <p className="text-danger text-start">
                    An error occurred and the amounts could not be calculated.
                </p>
            :
                <>
                <div className="row text-start">
                    <div className="col-3">
                        <b>Open Balance:</b>
                    </div>
                    <div className="col-9">
                        <span className={openBalance > 0 ? "text-danger" : "text-body"}>{chimera.dollarStr(openBalance)}</span>
                    </div>
                </div>
                <div className="row text-start">
                    <div className="col-3">
                        <b>Past Due:</b>
                    </div>
                    <div className="col-9">
                        <span className={pastDue > 0 ? "text-danger" : "text-body"}>{chimera.dollarStr(pastDue)}</span>
                    </div>
                </div>
                </>
            }
            </>
        }
        </div>
    )
}

export default OpenBalancePastDue;