import React, {useState, useEffect, useContext} from 'react';

import BannerContext from '../BannerLogContext';
import chimera from '../../chimera';
import LoadingSpinner from '../LoadingSpinner';
import Tooltip from '../Tooltip';

const IPListTable = ({claims, setClaims, claimantObjectType, claimantId, isEditing, disabled, modifyCallback}) => {
    const [ipLists, setIPLists] = useState(null);
    const [claimableIPList, setClaimableIPList] = useState(null); // IPs either unused or claimed by the current claimant
    const [activeListId, setActiveListId] = useState('');
    const [claimedIds, setClaimedIds] = useState(null);
    const banners = useContext(BannerContext);

    useEffect(() => {
        if(ipLists === null) {
            chimera.callAPI(undefined, '/api/iplists')
            .then(lists => setIPLists(lists))
            .catch(err => {
                console.error(err);
                if(banners) {
                    banners.addBanner('danger', 'Failed to read IP Lists, IP selection will be unavailable.', 'Error');
                }
            })
        }
        else {
            let masterList = [];
            for(const list of ipLists) {
                for(const entry of list.list) {
                    masterList.push({
                        listId: list._id,
                        entryId: entry._id,
                        pop: list.pop,
                        ip: entry.ip,
                        gateway: entry.gateway,
                        subnetMask: entry.subnetMask,
                        claims: entry.claims,
                        notes: entry.notes
                    })
                }
            }
            setClaimableIPList(masterList.filter(entry => entry.claims.length === 0 || (claimantId && claimantObjectType ? entry.claims.find(claim => claim.objectId === claimantId && claim.objectType === claimantObjectType) : false)))
        }
    }, [ipLists]);

    useEffect(() => {
        setIPLists(null);
    }, [claimantId]);

    useEffect(() => {
        setIPLists(null);
    }, [isEditing]);

    useEffect(() => {
        if(claimableIPList !== null) {
            let claimedEntries = claimableIPList.filter(entry => entry.claims.find(claim => claim.objectType === claimantObjectType && claim.objectId === claimantId));
            if(claimedEntries.length > 0) {
                // Use the first claimed entry's corresponding listId unless the current listId is valid
                if(!claimedEntries.find(entry => entry.listId === activeListId)) {
                    setActiveListId(claimedEntries[0].listId);
                }
            }
            else if(claimableIPList.length > 0){
                setActiveListId(claimableIPList[0].listId);
            }
            setClaimedIds(claimedEntries.map(entry => entry.entryId));
        }
    }, [claimableIPList]);

    useEffect(() => {
        if(claimedIds !== null) {
            let newClaims = JSON.parse(JSON.stringify(claims));
            newClaims[claimantId] = claimedIds;
            console.log(newClaims);
            setClaims(newClaims);
        }
    }, [claimedIds]);

    const handleChange = (event) => {
        event.preventDefault();
        const oldId = event.target.name;
        const newId = event.target.value;
        
        let index = -1;
        for(let i = 0; i < claimedIds.length; i++) {
            if(claimedIds[i] === oldId) {
                index = i;
                break;
            } 
        }
        if(index >= 0) {
            let newClaimedIds = JSON.parse(JSON.stringify(claimedIds));
            newClaimedIds[index] = newId;
            setClaimedIds(newClaimedIds);
        }
    }

    const removeById = (id) => {
        setClaimedIds(claimedIds.filter(claimedId => claimedId !== id));
        if(modifyCallback) {
            modifyCallback();
        }
    }

    const addRow = (event) => {
        event.preventDefault();
        // find first IP not already used
        let foundIp = claimableIPList.find(entry => entry.listId === activeListId && !claimedIds.includes(entry.entryId));
        if(foundIp) {
            setClaimedIds([...claimedIds, foundIp.entryId]);
        }
        if(modifyCallback) {
            modifyCallback();
        }
    }

    const claimedEntries = () => {
        return claimableIPList.filter(entry => entry.claims.find(claim => claim.objectType === claimantObjectType && claim.objectId === claimantId));
    }

    const isEntryInList = (entryId, listId) => {
        for(const ipList of ipLists) {
            if(ipList._id === listId) {
                for(const entry of ipList.list) {
                    if(entry._id === entryId) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    const entryByIds = (entryId, listId) => {
        for(const ipList of ipLists) {
            if(ipList._id === listId) {
                for(const entry of ipList.list) {
                    if(entry._id === entryId) {
                        return entry;
                    }
                }
            }
        }
        return undefined;
    }

    return (
        <>
        {ipLists && claimableIPList ?
        <>
        {!isEditing ? 
            <>
            {claimedEntries().length > 0 ? 
                <>
                <ul className="nav nav-tabs mb-3">
                    {ipLists.filter(ipList => ipList.list.find(entry => claimedEntries().find(claimedEntry => claimedEntry.entryId === entry._id))).map((ipList, i) => <li key={i} className="nav-item">
                        <a href="#" className={activeListId === ipList._id ? 'btn nav-link active' : 'btn nav-link'} onClick={(event) => {event.preventDefault(); setActiveListId(ipList._id)}}>
                            {ipList.pop}
                        </a>
                    </li>)}
                </ul>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>IP</th>
                            <th>Gateway</th>
                            <th>Subnet Mask</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {claimedEntries().filter(entry => entry.listId === activeListId).map((row, i) => <tr key={i}>
                            <td>{row.ip}</td>
                            <td>{row.gateway}</td>
                            <td>{row.subnetMask}</td>
                            <td>{row.notes}</td>
                        </tr>)}
                    </tbody>
                </table>
                </>
            :
                <p>(None)</p>
            }
            </>
        :
            <>
            {claimedIds !== null ?
            <>
            <ul className="nav nav-tabs mb-3">
                {ipLists.map((ipList, i) => <li key={i} className="nav-item">
                    <a href="#" className={activeListId === ipList._id ? 'btn nav-link active' : 'btn nav-link'} onClick={(event) => {event.preventDefault(); setActiveListId(ipList._id)}}>
                        {ipList.pop}
                    </a>
                </li>)}
            </ul>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>IP</th>
                        <th>Gateway</th>
                        <th>Subnet Mask</th>
                        <th>Notes</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {claimedIds.filter(id => isEntryInList(id, activeListId)).map(id => entryByIds(id, activeListId)).map((row, i) => <tr key={i}>
                        <td>
                            <select className="form-select" value={row._id} name={row._id} onChange={handleChange} disabled={disabled}>
                                {claimableIPList.filter(entry => entry.listId === activeListId).filter(entryOption => {
                                    // All claimable options are shown.
                                    // This filter subtracts options that are used by other rows.
                                    // If the claimedIds contains the option, it cannot be shown unless it belongs to this row.
                                    if(claimedIds.includes(entryOption.entryId)) {
                                        return entryOption.entryId === row._id;
                                    }
                                    else {
                                        return true;
                                    }
                                }).map((el, i) => <option key={i} value={el.entryId}>{el.ip}</option>)}
                            </select>
                        </td>
                        <td>{row.gateway}</td>
                        <td>{row.subnetMask}</td>
                        <td>{row.notes}</td>
                        <td>
                            <button className="btn btn-danger btn-sm" onClick={(e) => {e.preventDefault(); removeById(row._id)}} disabled={disabled}>
                                <i className="fas fa-minus"/>
                            </button>
                        </td>
                    </tr>)}
                    <tr>
                        <td colSpan={5}>
                            {claimableIPList.find(entry => entry.listId === activeListId && !claimedIds.includes(entry.entryId)) ? 
                            <button className="btn btn-success" onClick={addRow} disabled={disabled}>
                                <i className="fas fa-plus"/>&nbsp;Add IP
                            </button>
                            :
                            <Tooltip text="Cannot add new IP because there are no remaining IPs available for this POP.">
                                <button className="btn btn-success" onClick={addRow} disabled={true}>
                                    <i className="fas fa-plus"/>&nbsp;Add IP
                                </button>
                            </Tooltip>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
            </>
            :<LoadingSpinner size={25}/>}
            </>
        }
        </>
        :
        <LoadingSpinner size={25} label="Loading POP/IP selections..."/>
        }
        </>
    )
}

export default IPListTable;