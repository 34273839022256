import React, {useState, useEffect, useContext} from 'react';
import BannerContext, { BannerLog } from '../BannerLogContext';
import ModalContext from '../ModalContext';
import Modal from '../Modal';
import UserContext from '../../UserContext';
import ReportsTableList from './ReportTableList';

import FCC477 from './FCC477';
import EndpointsLastSeen from './EndpointsLastSeen';
import UnityE911 from './UnityE911';
import CustomersAdded from './CustomersAdded';
import UnityOldDevices from './UnityOldDevices';
import VoIPDiscrepancy from './VoIPDiscrepancy';
import MicrosoftProductAudit from './MicrosoftProductAudit';
import WorkstationEndpointAudit from './WorkstationEndpointAudit';
import ServerEndpointAudit from './ServerEndpointAudit';

const ReportCard = props => {
    return (
        <div className="card h-100">
            <img src={props.imgsrc} className="card-img-top" alt={props.imgalt}/>
            <div className="card-body">
                <h5 className="card-title">{props.title}</h5>
                <p className="card-text">
                    {props.children}
                </p>
            </div>
            <div className="card-footer">
                <button className="btn btn-primary" onClick={(event) => {event.preventDefault(); props.onClick()}}>
                    {props.run ?
                        <>
                        <i className="fas fa-play"/>
                        &nbsp;Run Report
                        </>
                    :
                        <>
                        <i className="fas fa-magnifying-glass"/>
                        &nbsp;View Reports
                        </>
                    }
                </button>
            </div>
        </div>
    )
}

const MainScene = props => {
    const loadManualReportScene = (event) => {
        event.preventDefault();
        props.setScene("manual");
    }

    const loadViewScene = (event) => {
        event.preventDefault();
        props.setScene("view");
    }

    return (
        <>
        <div className="row">
            <div className="col">
                <button className="btn btn-primary" onClick={loadManualReportScene}>
                    <i className="fas fa-play"/>
                    &nbsp;Run a Manual Report
                </button>
            </div>
        </div>
        <div className="row mt-1">
            <div className="col">
                <button className="btn btn-primary" onClick={loadViewScene}>
                    <i className="fas fa-magnifying-glass"/>
                    &nbsp;View Reports
                </button>
            </div>
        </div>
        </>
    )
}

const ManualReportScene = props => {
    const modaling = useContext(ModalContext);
    const context = useContext(UserContext);

    const openReportInModal = (report) => {
        let reportComponent;
        switch(report) {
            case "FCC477":
                reportComponent = <FCC477/>;
                break;
            case "EndpointsLastSeen":
                reportComponent = <EndpointsLastSeen/>;
                break;
            case "UnityE911":
                reportComponent = <UnityE911/>;
                break;
            case "CustomersAdded":
                reportComponent = <CustomersAdded/>;
                break;
            case "UnityOldDevices":
                reportComponent = <UnityOldDevices/>;
                break;
            case "VoIPDiscrepancy":
                reportComponent = <VoIPDiscrepancy/>;
                break;
            case "MicrosoftProductAudit":
                reportComponent = <MicrosoftProductAudit/>;
                break;
            case "WorkstationEndpointAudit":
                reportComponent = <WorkstationEndpointAudit/>;
                break;
            case "ServerEndpointAudit":
                reportComponent = <ServerEndpointAudit/>;
                break;
        }
        if(reportComponent) {
            const choices = [
                {
                    btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                    btnColor: 'secondary',
                    func: (event) => {
                        event.preventDefault();
                        modaling.setModal(null);
                    }
                }
            ]
            const modal = <Modal choices={choices} dismiss={choices[0].func}>
                {reportComponent}
            </Modal>
            modaling.setModal(modal);
        }
    }

    return (
        <>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-4">
            <div className="col">
                <ReportCard imgsrc="images/fcc_logo.png" imgalt="FCC Logo" title="FCC 477 Data" onClick={() => {openReportInModal("FCC477")}} run>
                    Generates an Excel file (.xlsx) with information on CBIT's VoIP customers as catalogued by Chimera.
                </ReportCard>
            </div>
            <div className="col">
                <ReportCard imgsrc="images/endpoints_last_seen.png" imgalt="Endpoints Last Seen" title="Endpoints Last Seen" onClick={() => {openReportInModal("EndpointsLastSeen")}} run>
                    Syncro and Bitdefender endpoints not seen within the last 21 days.
                </ReportCard>
            </div>
            <div className="col">
                <ReportCard imgsrc="images/unity_911.png" imgalt="Unity E911" title="Unity E911" onClick={() => {openReportInModal("UnityE911")}} run>
                    Make sure that Subscribers in Unity have a 911 caller ID that matches the one set for their respective Domains.
                </ReportCard>
            </div>
            {context.permissions.financial.read ?
            <div className="col">
                <ReportCard imgsrc="images/customers_added.png" imgalt="Unity E911" title="Customers Added" onClick={() => {openReportInModal("CustomersAdded")}} run>
                    Retrieve the list of customers created in Chimera within the specified timeframe, along with their estimated generated revenues.
                </ReportCard>
            </div>
            :null}
            <div className="col">
                <ReportCard imgsrc="images/unity_old_devices.png" imgalt="Unity Old Devices" title="Unity Old Devices" onClick={() => {openReportInModal("UnityOldDevices")}} run>
                    Retrieve the list of devices in Unity that haven't phoned home in a sigificant amount of time.
                </ReportCard>
            </div>
            <div className="col">
                <ReportCard imgsrc="images/voip_discrepancy.png" imgalt="VoIP Discrepancy" title="VoIP Discrepancy" onClick={() => {openReportInModal("VoIPDiscrepancy")}} run>
                    Compare VoIP customers in Chimera, Unity, and QuickBooks to analyze for discrepancies between systems.
                </ReportCard>
            </div>
            <div className="col">
                <ReportCard imgsrc="images/microsoft_product_audit.png" imgalt="Microsoft Product Audit" title="Microsoft Product Audit" onClick={() => {openReportInModal("MicrosoftProductAudit")}} run>
                    Compare Pax8 subscriptions to QB Recurring Transactions to analyze for discrepancies between systems.
                </ReportCard>
            </div>
            <div className="col">
                <ReportCard imgsrc="images/workstation_endpoint_audit.png" imgalt="Workstation Endpoint Audit" title="Workstation Endpoint Audit" onClick={() => {openReportInModal("WorkstationEndpointAudit")}} run>
                    Review workstation endpoint counts per package (and more) by customer
                </ReportCard>
            </div>
            <div className="col">
                <ReportCard imgsrc="images/server_endpoint_audit.png" imgalt="Server Endpoint Audit" title="Server Endpoint Audit" onClick={() => {openReportInModal("ServerEndpointAudit")}} run>
                    Review server endpoint counts per package (and more) by customer
                </ReportCard>
            </div>
        </div>
        </>
    )
}

const ViewScene = props => {
    const modaling = useContext(ModalContext);
    const context = useContext(UserContext);

    const openListInModal = (type) => {
        const choices = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                btnColor: 'secondary',
                func: (event) => {
                    event.preventDefault();
                    modaling.setModal(null);
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <ReportsTableList type={type}/>
        </Modal>
        modaling.setModal(modal);
    }

    return (
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-4">
            <div className="col">
                <ReportCard imgsrc="images/endpoints_last_seen.png" imgalt="Endpoints Last Seen" title="Endpoints Last Seen" onClick={() => {openListInModal("EndpointsLastSeen")}}>
                    Syncro and Bitdefender endpoints not seen within the last 21 days.
                </ReportCard>
            </div>
            <div className="col">
                <ReportCard imgsrc="images/unity_911.png" imgalt="Unity E911" title="Unity E911" onClick={() => {openListInModal("UnityE911")}}>
                    Make sure that Subscribers in Unity have a 911 caller ID that matches the one set for their respective Domains.
                </ReportCard>
            </div>
            {context.permissions.financial.read ?
            <div className="col">
                <ReportCard imgsrc="images/customers_added.png" imgalt="Customers Added" title="Customers Added" onClick={() => {openListInModal("CustomersAdded")}}>
                    Retrieve the list of customers created in Chimera within the specified timeframe, along with their estimated generated revenues.
                </ReportCard>
            </div>
            :null}
            <div className="col">
                <ReportCard imgsrc="images/unity_old_devices.png" imgalt="Unity Old Devices" title="Unity Old Devices" onClick={() => {openListInModal("UnityOldDevices")}}>
                    Retrieve the list of devices in Unity that haven't phoned home in a sigificant amount of time.
                </ReportCard>
            </div>
            <div className="col">
                <ReportCard imgsrc="images/voip_discrepancy.png" imgalt="VoIP Discrepancy" title="VoIP Discrepancy" onClick={() => {openListInModal("VoIPDiscrepancy")}}>
                    Compare VoIP customers in Chimera, Unity, and QuickBooks to analyze for discrepancies between systems.
                </ReportCard>
            </div>
            <div className="col">
                <ReportCard imgsrc="images/microsoft_product_audit.png" imgalt="Microsoft Product Audit" title="Microsoft Product Audit" onClick={() => {openListInModal("MicrosoftProductAudit")}}>
                    Compare Pax8 subscriptions to QB Recurring Transactions to analyze for discrepancies between systems.
                </ReportCard>
            </div>
            <div className="col">
                <ReportCard imgsrc="images/workstation_endpoint_audit.png" imgalt="Workstation Endpoint Audit" title="Workstation Endpoint Audit" onClick={() => {openListInModal("WorkstationEndpointAudit")}}>
                    Review workstation endpoint counts per package (and more) by customer
                </ReportCard>
            </div>
            <div className="col">
                <ReportCard imgsrc="images/server_endpoint_audit.png" imgalt="Server Endpoint Audit" title="Server Endpoint Audit" onClick={() => {openListInModal("ServerEndpointAudit")}}>
                    Review server endpoint counts per package (and more) by customer
                </ReportCard>
            </div>
        </div>
    )
}

const ReportsBody = props => {
    const [scene, setScene] = useState("start");

    const loadScene = () => {
        switch(scene) {
            case "manual":
                return <ManualReportScene setScene={setScene}/>;
            case "view":
                return <ViewScene setScene={setScene}/>;
            default:
                return <MainScene setScene={setScene}/>;
        }
    }

    const backToStart = (event) => {
        event.preventDefault();
        setScene("start");
    }

    return (
        <>
        {scene !== "start" ? 
            <div className="row mb-3">
                <div className="col">
                    <button className="btn btn-secondary float-start" onClick={backToStart}>
                        <i className="fas fa-arrow-left"/>
                        &nbsp;Back to Start
                    </button>
                </div>
            </div>
        :null}
        {loadScene()}
        </>
    )
}

const Reports = props => {
    return (
        <div className="container pb-5">
            <h1>Reports</h1>
            <BannerLog>
                <ReportsBody/>
            </BannerLog>
        </div>
    )
}

export default Reports;