import React, {useContext} from 'react';

import ModalContext from '../ModalContext';
import ExportModal from '../ExportModal';
import VendorFormModal from './VendorFormModal';
import AlertsContext from '../AlertsContext';
import ObjectTable from '../ObjectTable';

const VendorRow = ({object, clickedObject}) => {
    return (
        <>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.name}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.website}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.accountNumber}
            </td>
        </>
    )
}

/**
 * 
 * @param {Array} props.vendors The list of orders to display
 * @param {Function} props.clickedVendor A function that takes the clicked order as an argument
 */
const VendorsTable = props => {
    const modaling = useContext(ModalContext);
    const alerts = useContext(AlertsContext);

    const openNewVendor = (_) => {
        modaling.setModal(<VendorFormModal modalContext={modaling} onClose={() => {props.setVendors(null); alerts.reload();}}/>)
    }

    /** TODO: Implement exporting for Vendors */
    const openExportModal = (selectedObjects) => {
        modaling.setModal(<ExportModal objects={selectedObjects} model="vendor"/>);
    }

    return (
        <ObjectTable 
            id="vendorsTable"
            cols={[
                {
                    label: 'Name', 
                    sort: (ascending) => {
                        return (a, b) => a.name < b.name ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                },
                {
                    label: 'Website',
                    sort: (ascending) => {
                        return (a, b) => a.website < b.website ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                },
                {
                    label: 'Account #',
                    sort: (ascending) => {
                        return (a, b) => a.accountNumber < b.accountNumber ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                },
            ]}
            objects={props.vendors}
            actions={[
                {label: 'Export', func: openExportModal}
            ]}
            btns={[
                {label: 'Add Vendor', func: openNewVendor}
            ]}
            rowElement={VendorRow}
            clickedObject={props.clickedVendor}
            search
            paginated
            defaultSortByColName="Name"
            defaultSortAscending={true}
        />
    )
}

export default VendorsTable