import React, {useState, useEffect, useRef} from 'react';

const BannerContext = React.createContext();

const RenderAlert = ({type, message, header, index, _ref, removeMessage}) => {
    return <div key={index} className={`alert alert-${type} alert-dismissible text-start`} role="alert" ref={_ref}>
        {header ? <span><strong>{header}:</strong>&nbsp;{message}</span> : message}
        {index !== undefined && removeMessage ? <button type="button" className="btn-close" aria-label="Close" onClick={(e) => {e.preventDefault(); removeMessage(index)}}/> : null}
    </div>
}

const BannerLog = props => {
    const [messages, setMessages] = useState([]);
    const [scrolledAlready, setScrolledAlready] = useState(false);
    const [additiveMode, setAdditiveMode] = useState(false);
    const firstErrorRef = useRef(null);
    const lastErrorRef = useRef(null);

    useEffect(() => {
        if(messages.length > 0) {
            if(firstErrorRef.current && !scrolledAlready && !additiveMode) {
                firstErrorRef.current.scrollIntoView({behavior: "smooth"});
                setAdditiveMode(true);
                setScrolledAlready(true);
            }
            else if(lastErrorRef.current && !scrolledAlready && additiveMode) {
                lastErrorRef.current.scrollIntoView({behavior: "smooth"});
                setScrolledAlready(true);
            }
        }
        else {
            setAdditiveMode(false);
        }
    }, [messages]);

    const addBanner = (type, message, header) => {
        setScrolledAlready(false);
        setMessages(messages => [...messages, {type: type, message: message, header: header ? header : null}]);
    }

    const removeMessage = (delIndex) => {
        let newMessages = [];
        for(let i = 0; i < messages.length; i++) {
            if(i !== delIndex) {
                newMessages.push(messages[i]);
            }
        }
        setMessages(newMessages);
    }

    const addBanners = (type, msgs) => {
        setScrolledAlready(false);
        let newMessages = [];
        for(const msg of messages) {
            newMessages.push(msg);
        }
        for(const msg of msgs) {
            newMessages.push({type: type, message: msg});
        }
        setMessages(newMessages);
    }

    const clearBanners = () => {
        setMessages([]);
        setAdditiveMode(false);
    }

    const setBanners = (banners) => {
        for(const banner of banners) {
            addBanner(banner.type, banner.message, banner.header);
        }
    }

    return <>
        {messages.map((msg, i) =>
            <RenderAlert key={i} type={msg.type} message={msg.message} header={msg.header} index={i} _ref={!additiveMode ? (i === 0 ? firstErrorRef : null) : (i === messages.length-1 ? lastErrorRef : null)} removeMessage={removeMessage}/>
        )}
        <BannerContext.Provider value={{addBanner: addBanner, addBanners: addBanners, clearBanners: clearBanners, setBanners: setBanners, messages: messages}}>
            {props.children}
        </BannerContext.Provider>
    </>
}

export { BannerContext as default, BannerLog, RenderAlert as RenderAlert};