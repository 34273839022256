import React, {useState, useEffect, useMemo, useContext} from "react";
import ToolPage from './ToolPage';

import chimera from '../../chimera';
import BannerLogContext from "../BannerLogContext";
import LoadingSpinner from "../LoadingSpinner";
import Modal from '../Modal';
import ModalContext from '../ModalContext';

const TagForm = props => {
    const [tagName, setTagName] = useState(props.tag ? props.tag.name : "");

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if(name === "tagName") {
            setTagName(value);
        }
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if(props.handleSubmit) props.handleSubmit(tagName);
    }

    return(
    <>
        <h3>{props.tag ? "Edit Tag" : "Create Tag"}</h3>
        <input className="form-control" type="text" name="tagName" value={tagName} onChange={handleChange} onBlur={trimOnBlur}/>
        <button className="btn btn-primary mb-1" onClick={handleSubmit}>
            <i className="fas fa-floppy-disk"/>&nbsp;Save
        </button>
    </>
    )
}

const TagsEditorBody = props => {
    const [tags, setTags] = useState(null);
    const banners = useContext(BannerLogContext);
    const modaling = useContext(ModalContext);

    useEffect(() => {
        if(tags === null) {
            chimera.callAPI(undefined, '/api/tags')
            .then(newTags => setTags(newTags))
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to read Tags', 'Error');
            });
        }
    }, [tags]);

    const editTagInModal = (tag) => {
        const choices = [
            {
                btnColor: 'secondary',
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Cancel</span>,
                func: (e) => {
                    e.preventDefault();
                    modaling.backtrack();
                }
            }
        ]
        const handleSubmit = (submittedTagName) => {
            chimera.callAPI(undefined, `/api/tags/${tag._id}`, 'PUT', {name: submittedTagName})
            .then(_ => {
                modaling.backtrack();
                banners.addBanner('info', 'Saved changes successfully.', 'Success');
            })
            .catch(err => {
                console.error(err);
                modaling.backtrack();
                banners.addBanner('danger', `Failed to save changes to Tag: ${tag.name}`, 'Error');
            })
            .finally(() => {
                setTags(null);
            })
        }
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <TagForm tag={tag} handleSubmit={handleSubmit}/>
        </Modal>
        modaling.setModal(modal);
    }

    const createTagInModal = (event) => {
        event.preventDefault();
        const choices = [
            {
                btnColor: 'secondary',
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Cancel</span>,
                func: (e) => {
                    e.preventDefault();
                    modaling.backtrack();
                }
            }
        ]
        const handleSubmit = (submittedTagName) => {
            chimera.callAPI(undefined, `/api/tags`, 'POST', {name: submittedTagName})
            .then(_ => {
                modaling.backtrack();
                banners.addBanner('info', `Created new Tag: ${submittedTagName}`, 'Success');
            })
            .catch(err => {
                console.error(err);
                modaling.backtrack();
                banners.addBanner('danger', 'Failed to create new Tag', 'Error');
            })
            .finally(() => {
                setTags(null);
            })
        }
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <TagForm handleSubmit={handleSubmit}/>
        </Modal>
        modaling.setModal(modal);
    }

    const confirmDelete = (tag) => {
        const choices = [
            {
                btnColor: 'secondary',
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Cancel</span>,
                func: (e) => {
                    e.preventDefault();
                    modaling.backtrack();
                }
            },
            {
                btnColor: 'danger',
                btnInner: <span><i className="fas fa-times"/>&nbsp;Yes, Delete</span>,
                func: (e) => {
                    e.preventDefault();
                    chimera.callAPI(undefined, `/api/tags/${tag._id}`, 'DELETE')
                    .then(_ => {
                        modaling.backtrack();
                        banners.addBanner('info', `Deleted Tag: ${tag.name}`, 'Success');
                    })
                    .catch(err => {
                        console.error(err);
                        modaling.backtrack();
                        banners.addBanner('danger', `Failed to delete Tag: ${tag.name}`, 'Error');
                    })
                    .finally(() => {
                        setTags(null);
                    })
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Are you sure?</h3>
            <p>Do you want to permanently delete this tag?: <b>{tag.name}</b></p>
            <p className="text-muted"><i>Deleting this tag will remove it from all Customers.</i></p>
        </Modal>
        modaling.setModal(modal);
    }

    return (
        <div className="col-lg-12">
            {tags !== null ? 
            <>
                <button className="btn btn-primary" onClick={createTagInModal}>
                    <i className="fas fa-plus"/>&nbsp;Create New Tag
                </button>
                <ul className="text-start">
                    {tags.map(tag => <li>
                        {tag.name}&nbsp;
                        <button className="btn btn-sm btn-secondary me-1" onClick={(e) => {e.preventDefault(); editTagInModal(tag)}}>
                            <i className="fas fa-pencil"/>
                        </button>
                        <button className="btn btn-sm btn-danger" onClick={(e) => {e.preventDefault(); confirmDelete(tag)}}>
                            <i className="fas fa-times"/>
                        </button>
                    </li>)}
                </ul>
            </>
            :<LoadingSpinner size={50}/>}
        </div>
    );
}

const TagsEditor = props => {
    const toolName = "Tags Editor";
    const toolId = "tags";
    return (
        <ToolPage toolId={toolId} toolName={toolName}>
            <ToolPage.Header image={null} toolName={toolName} alt="Tags Editor">
                An interface for managing Customer Tags
            </ToolPage.Header>
            <ToolPage.How>
                <h3>What are Customer Tags?</h3>
                <p>
                    Customer Tags are labels that can be applied to commercial Customers for improved organization and querying. For example, we may wish to label our most valuable customers as "VIP",
                    and using a "VIP" tag will enable us to run queries to grab all "VIP" customers, which may be a first step to a number of processes that we could imagine, such as reports or scripted actions.
                </p>
                <h3>How do I apply Tags to Customers?</h3>
                <p>
                    This interface is only for managing the creation, deletion, or editing of Tags and has no function for actually assigning them to Customers.
                </p>
                <p>
                    It's possible to assign Tags to Customers in two different ways, one from the Customer list page and one from the Customer details page.
                </p>
                <ol>
                    <li>
                        <b>From the Customer list page,</b> select the customers that you want to tag and use the Action button to select <b>Set Tags</b> and follow the interface that appears.
                    </li>
                    <li>
                        <b>From the Customer details page,</b> use the Action button to select <b>Edit</b> to enter Editing Mode and scroll all the way down to the bottom right of the page.
                        This will show all available tags, and you can click on them to toggle them on or off. Then simply scroll back up and click Save to apply your changes.
                    </li>
                </ol>
                <h3>How do I manage Customer Tags?</h3>
                <p>
                    You can use this interface to create, delete, or edit tags.
                </p>
                <ul>
                    <li>
                        <b>To Create a new Tag,</b> click the blue button below that says "Create New Tag", type in the tag you want to create in the form that pops up, then click "Save".
                    </li>
                    <li>
                        <b>To Delete a Tag,</b> click the red "X" button next to it in the list below. There will be a pop-up confirmation.
                    </li>
                    <li>
                        <b>To Edit an existing Tag,</b> click the pencil button next to it in the list below, make your changes in the form that pops up, then click "Save".
                    </li>
                </ul>
            </ToolPage.How>
            <ToolPage.Body>
                <TagsEditorBody />
            </ToolPage.Body>
        </ToolPage>
    );
}

export default TagsEditor;