import React, {useState, useEffect, useContext} from "react";
import ToolPage from './ToolPage';

import chimera from '../../chimera';
import BannerLogContext from "../BannerLogContext";
import LoadingSpinner from "../LoadingSpinner";

const CommitTogglerBody = props => {
    const [committing, setCommitting] = useState(false);
    const [docNumbers, setDocNumbers] = useState([""]);
    const [sizeBefore, setSizeBefore] = useState(1);
    const [disableInputs, setDisableInputs] = useState(false);
    const banners = useContext(BannerLogContext);

    useEffect(() => {
        if(docNumbers.length > sizeBefore) {
            document.getElementById(`docNumber${docNumbers.length-1}`).focus();
        }
        setSizeBefore(docNumbers.length);
    }, [docNumbers]);

    const addDocNumber = (event) => {
        if(event) event.preventDefault();
        setDocNumbers(numbers => [...numbers, ""]);
    }

    const setDocNumberAtIndex = (index, value) => {
        setDocNumbers(docNumbers.map((docNum, i) => i === index ? value : docNum));
    }

    const deleteDocNumberAtIndex = (index) => {
        let newDocNumbers = [];
        for(let i = 0; i < docNumbers.length; i++) {
            if(i !== index) newDocNumbers.push(docNumbers[i]);
        }
        setDocNumbers(newDocNumbers);
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const isDocNumber = name.includes("docNumber");
        const index = isDocNumber ? parseInt(name.split("docNumber")[1]) : 0;
        if(isDocNumber) {
            setDocNumberAtIndex(index, value);
        }
        else if(name === "committingToggle") {
            setCommitting(event.target.checked);
        }
    }

    const handleKeyDown = (event) => {
        if(event.key === "Enter") {
            // Create a new input field and redirect focus to it
            addDocNumber();
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        banners.clearBanners();
        setDisableInputs(true);
        chimera.callAPI(undefined, '/api/avalara/commit', 'POST', {
            docs: docNumbers,
            commit: committing
        })
        .then(res => {
            console.log(res);
            setDisableInputs(false);
            let allGood = true;
            for(let i = 0; i < res.length; i++) {
                if(!res[i].ok) {
                    allGood = false;
                    banners.addBanner('danger', res[i].err[0].msg, `Failed to ${committing ? 'commit' : 'uncommit'} transaction ${docNumbers[i]}`);
                }
            }
            if(allGood) {
                banners.addBanner('info', `Successfully ${committing ? 'committed' : 'uncommitted'} all transactions.`, 'Success!');
            }
        })
        .catch(err => {
            console.error(err);
            banners.addBanner('danger', 'The request to Avalara was unsuccessful', 'Error');
            setDisableInputs(false);
        })
    }

    return (
        <div className="col-lg-12">
            <div className="form-check mb-2">
                <input id="committingToggle" name="committingToggle"
                    className="form-check-input float-none" type="checkbox"
                    checked={committing}
                    onChange={handleChange}
                />&nbsp;
                <label className="form-check-label" htmlFor="committingToggle">
                    Commit?
                </label>
            </div>
            {docNumbers.map((docNumber, i) => <div className="d-flex justify-content-center mb-2">
                <input id={`docNumber${i}`} type="text" className="form-control w-25" name={`docNumber${i}`} value={docNumber} onChange={handleChange} maxLength={10} onBlur={trimOnBlur} onKeyDown={handleKeyDown} disabled={disableInputs}/>
                <button className="btn btn-danger btn-sm ms-2" onClick={(event) => {event.preventDefault(); deleteDocNumberAtIndex(i)}} disabled={disableInputs}>
                    <i className="fas fa-minus"/>
                </button>
            </div>)}
            <div className="row mb-2">
                <div className="col">
                    <button className="btn btn-success" onClick={addDocNumber} disabled={disableInputs}>
                        <i className="fas fa-plus"/>
                    </button>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    <button className="btn btn-primary" onClick={handleSubmit} disabled={disableInputs}>
                        <i className="fas fa-arrow-right"/>&nbsp;{committing ? "Commit All" : "Uncommit All"}
                    </button>
                </div>
            </div>
            {disableInputs ? <LoadingSpinner size={50}/> : null}
        </div>
    );
}

const CommitToggler = props => {
    const toolName = "Commit Toggler";
    const toolId = "commit";
    return (
        <ToolPage toolId={toolId} toolName={toolName}>
            <ToolPage.Header image="/images/commit_toggler.png" toolName={toolName} alt="Arrows between documents in committed or uncommitted states">
                Commit or Uncommit transactions by Doc Number in Avalara.
            </ToolPage.Header>
            <ToolPage.How>
                <h3>Proccess</h3>
                <p>
                    Avalara associates a Document Code with each transaction.
                    When Chimera processes invoices and sales receipts using Avalara's API, it uses the Document Number of that transaction
                    from QuickBooks for easy referencing.
                </p>
                <p>
                    Avalara's API accepts a batch of Document Codes to commit/uncommit all at once. This tool provides a front-end for supplying those Document Codes, which can be viewed in Avalara and are
                    associated with the Document Numbers in QuickBooks.
                </p>
                <p>
                    Avalara's API will respond with a list of results, one for each Document Code, showing which were successful and which were not. This tool will alert you if any have failed.
                    It is safe to commit/uncommit the same transaction more than once, so don't fear duplicate actions.
                </p>
                <h3>Tips</h3>
                <ul>
                    <li>The first text box is provided by default. Once you are done entering the first Document Code, you can press Enter to immediately create another field that grabs your keyboard's focus.
                    This way, you can easily type number after number.</li>
                </ul>
            </ToolPage.How>
            <ToolPage.Body>
                <CommitTogglerBody />
            </ToolPage.Body>
        </ToolPage>
    );
}

export default CommitToggler;