import React, {useState, useEffect, useContext} from 'react';
import ModalContext from '../ModalContext';
import chimera from '../../chimera';
import BannerContext from '../BannerLogContext';
import { useParams, useNavigate } from 'react-router-dom';
import AlertsContext from '../AlertsContext';
import SubcontractorOrdersTable from './SubcontractorOrdersTable';
import SubcontractorOrderFormModal from './SubcontractorOrderFormModal';

const SubcontractorTab = props => {
    const [orders, setOrders] = useState(null);
    const [openOrderId, setOpenOrderId] = useState(null);
    const modaling = useContext(ModalContext);
    const banners = useContext(BannerContext);
    const alerts = useContext(AlertsContext);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if(id) {
            setOpenOrderId(id);
        }
    }, [id]);

    useEffect(() => {
        if(openOrderId) {
            modaling.setModal(<SubcontractorOrderFormModal orderId={openOrderId} modalContext={modaling} banners={banners} onClose={onModalClose}/>);
        }
    }, [openOrderId]);

    useEffect(() => {
        if(orders === null) {
            chimera.callAPI(undefined, '/api/subcontractororders')
            .then(newOrders => setOrders(newOrders))
            .catch(err => {
                console.error(err);
                if(props.bannerContext) {
                    props.bannerContext.addBanner('danger', 'Failed to read Subcontractor Orders; they cannot be displayed.', 'Error');
                }
            })
        }
    }, [orders, props.bannerContext]);

    const onModalClose = () => {
        setOpenOrderId(null);
        setOrders(null);
        modaling.setModal(null);
        alerts.reload();
        navigate('/orders/subcontractors');
    }

    return (<div>
        <div className="row">
            <div className="col">
                <SubcontractorOrdersTable orders={orders} setOrders={setOrders} clickedOrder={(order) => navigate(`/orders/subcontractors/${order._id}`)}/>
            </div>
        </div>
    </div>)
}

export default SubcontractorTab;