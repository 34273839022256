import React, {useState, useEffect, useLayoutEffect, useContext} from "react";

import LoadingSpinner from "../../LoadingSpinner";
import BannerLogContext from "../../BannerLogContext";
import chimera from "../../../chimera";
import ReportTable from "../../ReportTable";

const EndpointAuditing = props => {
    const [showResults, setShowResults] = useState(false);
    const [showAllClears, setShowAllClears] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [progressBarLabel, setProgressBarLabel] = useState("Working...");
    const [endpointsTableData, setEndpointsTableData] = useState([]);
    const [inactiveEndpointsByCustomer, setInactiveEndpointsByCustomer] = useState([]);
    const [controller, setController] = useState(new AbortController());
    const [signal, setSignal] = useState(controller.signal);
    const banners = useContext(BannerLogContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        runAudit();
    }, [])

    const runAudit = async() => {
        try {
        setProgressBarLabel("Reading all MSP customers...");
        setShowProgressBar(true);

        //
        // Get Bitdefender customers from Chimera
        //
        // TODO: Add a warning for MSP customers without BD integration?
        const allCustomers = await chimera.CommercialCustomer.getAll(signal);
        const bdCustomers = allCustomers.filter(customer => customer.integrationIds.bitdefender !== "");

        //
        // Get item IDs from QuickBooks
        //
        let platinumItemIds = [];
        let goldItemIds = [];
        let silverItemIds = [];
        const qbPackageItemNames = [
            'Managed IT Services - Platinum Package',
            'Managed Server - Platinum Package',
            'Managed IT Services - Gold Package',
            'Managed Server - Gold Package',
            'Managed IT Services - Silver Package'
        ]
        setProgressBarLabel("Reading Endpoint Package items from QuickBooks...");
        const items = await chimera.callQuickBooksAPI(signal, '/api/qb/items', 'POST', {whitelist: qbPackageItemNames});
        for(const item of items) {
            if(item.item.includes('Platinum')) {
                platinumItemIds.push(item.id);
            }
            else if(item.item.includes('Gold')) {
                goldItemIds.push(item.id);
            }
            else if(item.item.includes('Silver')) {
                silverItemIds.push(item.id);
            }
        }

        //
        // Compose Table Data
        //
        let endpointsTableData = [];
        let inactiveEndpoints = [];
        for(const customer of bdCustomers.sort((a, b) => a.displayName < b.displayName ? -1 : 1)) {
            let managedEndpointsTotal = 0;
            let activeEndpointsTotal = 0;
            let nPlat = 0;
            let nGold = 0;
            let nSilv = 0;
            let nServ = 0;
            let qbPlat = 0;
            let qbGold = 0;
            let qbSilv = 0;
            let qbServ = 0;
            setProgressBarLabel(`Reading endpoints for ${customer.displayName} from GravityZone...`);
            //const endpoints = await chimera.callAPI(`/bd/companies/${customer.integrationIds.bitdefender}/endpoints`);
            let endpoints;
            try {
                endpoints = await chimera.callBitdefenderAPI(
                    signal,
                    'GET', 
                    `/companies/${customer.integrationIds.bitdefender}/endpoints`,
                    null,
                    () => {setProgressBarLabel("Waiting out GravityZone API limitations...")},
                    () => {setProgressBarLabel(`Reading endpoints for ${customer.displayName} from GravityZone...`)});
            }
            catch(err) {
                console.error(err);
                if(err.status && err.status === 400 && err.details && err.details.data && err.details.code === -32602 && err.details.data.details.includes('parentId')) {
                    banners.addBanner('danger', `${customer.displayName} has an invalid Bitdefender integration ID. Was their Bitdefender profile recently deleted?`, 'Endpoint Auditing')
                }
                console.log(`skipping ${customer.displayName}`);
                continue;
            }
            managedEndpointsTotal = endpoints.length;
            const today = new Date();
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(today.getDate() - 7);
            for(const endpoint of endpoints) {
                setProgressBarLabel(`Fetching details for ${endpoint.name} at ${customer.displayName} from GravityZone...`);
                //const details = await chimera.callAPI(`/bd/endpoints/${endpoint.id}`);
                const details = await chimera.callBitdefenderAPI(
                    signal,
                    'GET',
                    `/endpoints/${endpoint.id}`,
                    null,
                    () => {setProgressBarLabel("Waiting out GravityZone API limitations...")},
                    () => {setProgressBarLabel(`Fetching details for ${endpoint.name} at ${customer.displayName} from GravityZone...`)});
                const lastSeen = new Date(details.lastSeen);
                if(lastSeen > sevenDaysAgo) {
                    // Endpoint is active
                    activeEndpointsTotal++;
                }
                else {
                    inactiveEndpoints.push({
                        customer: customer,
                        details: details
                    })
                }

                if(details.operatingSystem.toLowerCase().includes("server")) {
                    nServ++;
                }

                if(details.modules && details.modules.antimalware && details.modules.advancedThreatControl && details.modules.edrSensor) {
                    nPlat++;
                }
                else if(details.modules && details.modules.antimalware && details.modules.advancedThreatControl) {
                    nGold++;
                }
                else if(details.modules && details.modules.antimalware) {
                    nSilv++;
                }
            }

            setProgressBarLabel(`Getting asset totals for ${customer.displayName} from recurring transactions in QuickBooks...`);
            const recurringTransactions = await chimera.callQuickBooksAPI(signal, `/api/qb/recurringtransaction/${customer.integrationIds.quickbooks}/type/all/tag/all`);
            const types = ['Invoice', 'SalesReceipt'];
            for(const transaction of recurringTransactions) {
                for(const type of types) {
                    if(transaction[type] && (transaction[type].RecurringInfo.Active || transaction[type].RecurringInfo.Name.includes("BMS"))) { // BMS tag treated as Active for this use case
                        for(const line of transaction[type].Line) {
                            if(line.DetailType === "SalesItemLineDetail") {
                                if(line.SalesItemLineDetail.ItemRef.name.toLowerCase().includes("server")) {
                                    qbServ += line.SalesItemLineDetail.Qty;
                                }
                                if(platinumItemIds.includes(line.SalesItemLineDetail.ItemRef.value)) {
                                    qbPlat += line.SalesItemLineDetail.Qty;
                                }
                                else if(goldItemIds.includes(line.SalesItemLineDetail.ItemRef.value)) {
                                    qbGold += line.SalesItemLineDetail.Qty;
                                }
                                else if(silverItemIds.includes(line.SalesItemLineDetail.ItemRef.value)) {
                                    qbSilv += line.SalesItemLineDetail.Qty;
                                }
                            }
                        }
                        break;
                    }
                }
            }

            setProgressBarLabel(`Getting asset total for ${customer.displayName} from Syncro...`);
            const totalSyncroAssets = (await chimera.callSyncroAPI(
                signal,
                'GET',
                `/customers/${customer.integrationIds.syncro}/assets/total`,
                null,
                () => {setProgressBarLabel("Waiting out Syncro API limits for 1 minute...")},
                () => {setProgressBarLabel(`Getting asset total for ${customer.displayName} from Syncro...`)})).total;

            endpointsTableData.push({
                name: customer.displayName,
                bitdefenderPackages: {
                    plat: nPlat,
                    gold: nGold,
                    silv: nSilv,
                    servers: nServ
                },
                managedEndpoints: {
                    total: managedEndpointsTotal,
                    active: activeEndpointsTotal
                },
                quickbooksPackages: {
                    plat: qbPlat,
                    gold: qbGold,
                    silv: qbSilv,
                    servers: qbServ
                },
                syncroEndpoints: {
                    totalSyncroAssets: totalSyncroAssets
                }
            });

            if(managedEndpointsTotal !== nPlat + nGold + nSilv) {
                const difference = managedEndpointsTotal - nPlat - nGold - nSilv;
                banners.addBanner('warning', `${customer.displayName} has ${difference} misconfigured endpoint(s). They weren't counted as Platinum, Gold, or Silver.`, 'Endpoint Auditing');
            }
        }

        setInactiveEndpointsByCustomer(inactiveEndpoints);
        setShowResults(true);
        setEndpointsTableData(endpointsTableData);
        setShowProgressBar(false);
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                banners.addBanner('danger', <span><strong>Endpoint Auditing:</strong> An unhandled exception has occurred. The job cannot continue.</span>);
            }
        }
    }

    const renderEndpointsTable = () => {
        let rows = [];
        for(const entry of endpointsTableData) {
            const endpointCountMismatch = entry.managedEndpoints.total !== entry.managedEndpoints.active;
            const platCountMismatch = entry.bitdefenderPackages.plat !== entry.quickbooksPackages.plat;
            const goldCountMismatch = entry.bitdefenderPackages.gold !== entry.quickbooksPackages.gold;
            const silverCountMismatch = entry.bitdefenderPackages.silv !== entry.quickbooksPackages.silv;
            const bitdefenderTotalsMismatch = entry.managedEndpoints.total !== (entry.bitdefenderPackages.plat + entry.bitdefenderPackages.gold + entry.bitdefenderPackages.silv);
            const qbLessThanBdPlat = entry.bitdefenderPackages.plat > entry.quickbooksPackages.plat;
            const qbLessThanBdGold = entry.bitdefenderPackages.gold > entry.quickbooksPackages.gold;
            const qbLessThanBdSilv = entry.bitdefenderPackages.silv > entry.quickbooksPackages.silv;
            const problem = endpointCountMismatch ||
                            bitdefenderTotalsMismatch ||
                            platCountMismatch ||
                            goldCountMismatch ||
                            silverCountMismatch ||
                            qbLessThanBdPlat ||
                            qbLessThanBdGold ||
                            qbLessThanBdSilv;
            if(showAllClears || problem) {
                rows.push(
                    <tr className={problem ? 'highlight-problem-light' : 'highlight-ok'}>
                        <td>
                            {entry.name}
                        </td>
                        <td>
                            {entry.syncroEndpoints.totalSyncroAssets}
                        </td>
                        <td className='text-end'>
                            {entry.managedEndpoints.total}
                        </td>
                        <td className='text-end'>
                            {entry.bitdefenderPackages.plat + entry.bitdefenderPackages.gold + entry.bitdefenderPackages.silv}
                        </td>
                        <td className='text-end'>
                            {entry.managedEndpoints.active}
                        </td>
                        <td className='text-end'>
                            {entry.bitdefenderPackages.servers}
                        </td>
                        <td className='text-end'>
                            {entry.quickbooksPackages.servers}
                        </td>
                        <td className={qbLessThanBdPlat ? 'text-end highlight-problem-dark' : 'text-end'}>
                            {entry.bitdefenderPackages.plat}
                        </td>
                        <td className={qbLessThanBdPlat ? 'text-end highlight-problem-dark' : 'text-end'}>
                            {entry.quickbooksPackages.plat}
                        </td>
                        <td className={qbLessThanBdGold ? 'text-end highlight-problem-dark' : 'text-end'}>
                            {entry.bitdefenderPackages.gold}
                        </td>
                        <td className={qbLessThanBdGold ? 'text-end highlight-problem-dark' : 'text-end'}>
                            {entry.quickbooksPackages.gold}
                        </td>
                        <td className={qbLessThanBdSilv ? 'text-end highlight-problem-dark' : 'text-end'}>
                            {entry.bitdefenderPackages.silv}
                        </td>
                        <td className={qbLessThanBdSilv ? 'text-end highlight-problem-dark' : 'text-end'}>
                            {entry.quickbooksPackages.silv}
                        </td>
                    </tr>
                );
            }
        }
        let inactiveEndpointsReport = {
            cols: [
                'Customer',
                'Endpoint Name',
                'Endpoint Last Seen',
            ],
            rows: [],
            etc: null,
            type: 'Inactive Endpoints'
        }
        for(const endpoint of inactiveEndpointsByCustomer) {
            const lastSeenDate = new Date(endpoint.details.lastSeen);
            inactiveEndpointsReport.rows.push({
                'Customer': endpoint.customer.displayName,
                'Endpoint Name': endpoint.details.name,
                'Endpoint Last Seen': lastSeenDate.toLocaleString()
            });
        }
        return(
            <>
            <div className="row">
                {rows.length > 0 ?
                <div className="col">
                    <h4>Endpoints (GravityZone/Pax8)</h4>
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-success w-fit mb-2 float-start" onClick={download}>
                                <i className="fas fa-download"/>&nbsp;Download as .xlsx
                            </button>
                        </div>
                    </div>
                    <table id="endpointsTable" className="w-100 black-border text-start">
                        <tr>
                            <th rowSpan={2} className="text-center">
                                Customer
                            </th>
                            <th colSpan={4} className="text-center">
                                Totals
                            </th>
                            <th colSpan={2} className="text-center">
                                Servers
                            </th>
                            <th colSpan={2} className="text-center">
                                Plat
                            </th>
                            <th colSpan={2} className="text-center">
                                Gold
                            </th>
                            <th colSpan={2} className="text-center">
                                Silv
                            </th>
                        </tr>
                        <tr className="text-center">
                            <th> {/** Under "Totals" */}
                                Syncro
                            </th>
                            <th> {/** Under "Totals" */}
                                Bitdefender
                            </th>
                            <th> {/** Under "Totals" */}
                                Sum Counted
                            </th>
                            <th> {/** Under "Totals" */}
                                Active
                            </th>
                            <th> {/** Under "Servers" */}
                                BD
                            </th>
                            <th> {/** Under "Servers" */}
                                QB
                            </th>
                            <th> {/** Under "Plat" */}
                                BD
                            </th>
                            <th> {/** Under "Plat" */}
                                QB
                            </th>
                            <th> {/** Under "Gold" */}
                                BD
                            </th>
                            <th> {/** Under "Gold" */}
                                QB
                            </th>
                            <th> {/** Under "Silv" */}
                                BD
                            </th>
                            <th> {/** Under "Silv" */}
                                QB
                            </th>
                        </tr>
                        {rows}
                    </table>
                </div>
                :
                <div className="col">
                    <strong>Looks good!</strong> No issues here.
                </div>
                }
            </div>
            {inactiveEndpointsReport.rows.length > 0 ?
            <div className="row mt-4">
                <div className="col">
                    <h4>Inactive Endpoints</h4>
                    <ReportTable report={inactiveEndpointsReport}/>
                </div>
            </div>
            :null}
            </>
        )
    }
    
    const handleChange = (event) => {
        const name = event.target.name;
        if(event.target.attributes.type && event.target.attributes.type.nodeValue === "checkbox") {
            if(name === "showAllClears") {
                setShowAllClears(event.target.checked);
            }
        }
    }

    const download = async(event) => {
        event.preventDefault();
        const headerStyle = {
            alignment: {
                horizontal: 'center',
                vertical: 'center',
                wrapText: true
            },
            font: {
                name: "Calibri",
                size: 10,
                bold: true
            },
            border: {
                right: {
                    style: "medium",
                    color: "black"
                },
                left: {
                    style: "medium",
                    color: "black"
                },
                top: {
                    style: "medium",
                    color: "black"
                },
                bottom: {
                    style: "medium",
                    color: "black"
                }
            },
            fill: {
                type: "pattern",
                patternType: "solid",
                fgColor: "#d3d3d3"
            }
        }
        const headers = [
            {
                string: "Customer",
                key: "customer",
                rowSpan: 1,
                style: headerStyle,
            },
            {
                section: {
                    title: {string: "Totals", style: headerStyle},
                    cols: [
                        {
                            string: "Syncro",
                            key: "totalSyncroAssets",
                            style: headerStyle
                        },
                        {
                            string: "Bitdefender",
                            key: "managedEndpointsTotal",
                            style: headerStyle
                        },
                        {
                            string: "Sum Counted",
                            key: "endpointPackagesTotal",
                            style: headerStyle
                        },
                        {
                            string: "Active",
                            key: "managedEndpointsActive",
                            style: headerStyle
                        }
                    ]
                }
            },
            {
                section: {
                    title: {string: "Servers", style: headerStyle},
                    cols: [
                        {
                            string: "BD",
                            key: "endpointPackagesServers",
                            style: headerStyle
                        },
                        {
                            string: "QB",
                            key: "qbPackagesServers",
                            style: headerStyle
                        }
                    ]
                }
            },
            {
                section: {
                    title: {string: "Plat", style: headerStyle},
                    cols: [
                        {
                            string: "BD",
                            key: "endpointPackagesPlat",
                            style: headerStyle
                        },
                        {
                            string: "QB",
                            key: "qbPackagesPlat",
                            style: headerStyle
                        }
                    ]
                }
            },
            {
                section: {
                    title: {string: "Gold", style: headerStyle},
                    cols: [
                        {
                            string: "BD",
                            key: "endpointPackagesGold",
                            style: headerStyle
                        },
                        {
                            string: "QB",
                            key: "qbPackagesGold",
                            style: headerStyle
                        }
                    ]
                }
            },
            {
                section: {
                    title: {string: "Silv", style: headerStyle},
                    cols: [
                        {
                            string: "BD",
                            key: "endpointPackagesSilv",
                            style: headerStyle
                        },
                        {
                            string: "QB",
                            key: "qbPackagesSilv",
                            style: headerStyle
                        }
                    ]
                }
            }
        ]

        const fillGood = {
            type: "pattern",
            patternType: "solid",
            fgColor: "#add8e6"
        }

        const fillBad = {
            type: "pattern",
            patternType: "solid",
            fgColor: "#cc8e84"
        }

        const fillHighlight = {
            type: "pattern",
            patternType: "solid",
            fgColor: "#e55039"
        }

        const dataStyleBase = {
            font: {
                name: "Calibri",
                size: 10,
            },
            border: {
                right: {
                    style: "medium",
                    color: "black"
                },
                left: {
                    style: "medium",
                    color: "black"
                },
                top: {
                    style: "medium",
                    color: "black"
                },
                bottom: {
                    style: "medium",
                    color: "black"
                }
            }
        }

        const dataStyleGood = JSON.parse(JSON.stringify(dataStyleBase));
        const dataStyleBad = JSON.parse(JSON.stringify(dataStyleBase));
        const dataStyleHighlight = JSON.parse(JSON.stringify(dataStyleBase));
        dataStyleGood.fill = fillGood;
        dataStyleBad.fill = fillBad;
        dataStyleHighlight.fill = fillHighlight;

        let data = []

        for(const entry of endpointsTableData) {
            const endpointCountMismatch = entry.managedEndpoints.total !== entry.managedEndpoints.active;
            const platCountMismatch = entry.bitdefenderPackages.plat !== entry.quickbooksPackages.plat;
            const goldCountMismatch = entry.bitdefenderPackages.gold !== entry.quickbooksPackages.gold;
            const silverCountMismatch = entry.bitdefenderPackages.silv !== entry.quickbooksPackages.silv;
            const bitdefenderTotalsMismatch = entry.managedEndpoints.total !== (entry.bitdefenderPackages.plat + entry.bitdefenderPackages.gold + entry.bitdefenderPackages.silv);
            const qbLessThanBdPlat = entry.bitdefenderPackages.plat > entry.quickbooksPackages.plat;
            const qbLessThanBdGold = entry.bitdefenderPackages.gold > entry.quickbooksPackages.gold;
            const qbLessThanBdSilv = entry.bitdefenderPackages.silv > entry.quickbooksPackages.silv;
            const problem = endpointCountMismatch ||
                            bitdefenderTotalsMismatch ||
                            platCountMismatch ||
                            goldCountMismatch ||
                            silverCountMismatch ||
                            qbLessThanBdPlat ||
                            qbLessThanBdGold ||
                            qbLessThanBdSilv;
            if(showAllClears || problem) {
                let style = problem ? dataStyleBad : dataStyleGood;
                data.push({
                    customer: {string: entry.name, style: style},
                    totalSyncroAssets: {number: entry.syncroEndpoints.totalSyncroAssets, style: style},
                    managedEndpointsTotal: {number: entry.managedEndpoints.total, style: style},
                    managedEndpointsActive: {number: entry.managedEndpoints.active, style: style},
                    endpointPackagesTotal: {number: entry.bitdefenderPackages.plat + entry.bitdefenderPackages.gold + entry.bitdefenderPackages.silv, style: style},
                    endpointPackagesPlat: {number: entry.bitdefenderPackages.plat, style: qbLessThanBdPlat ? dataStyleHighlight : style},
                    endpointPackagesGold: {number: entry.bitdefenderPackages.gold, style: qbLessThanBdGold ? dataStyleHighlight : style},
                    endpointPackagesSilv: {number: entry.bitdefenderPackages.silv, style: qbLessThanBdSilv ? dataStyleHighlight : style},
                    qbPackagesPlat: {number: entry.quickbooksPackages.plat, style: qbLessThanBdPlat ? dataStyleHighlight : style},
                    qbPackagesGold: {number: entry.quickbooksPackages.gold, style: qbLessThanBdGold ? dataStyleHighlight : style},
                    qbPackagesSilv: {number: entry.quickbooksPackages.silv, style: qbLessThanBdSilv ? dataStyleHighlight : style},
                    endpointPackagesServers: {number: entry.bitdefenderPackages.servers, style: style},
                    qbPackagesServers: {number: entry.quickbooksPackages.servers, style: style}
                })
            }
        }

        const blob = await chimera.callAPI(signal, '/api/xlsx', 'POST', {
            dataSets: [
                {
                    worksheet: "Endpoints Audit",
                    headers: headers,
                    data: data
                }
            ]
        }, 'blob');

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = new Date();
        link.download = `Endpoints Audit ${today.toISOString().substring(0,10)}.xlsx`;
        link.click();
    }

    return(
        <>
        {showResults ? 
            <>
                <div>
                    <input type="checkbox" name="showAllClears" value={showAllClears} onChange={handleChange}/>
                    &nbsp;Show All-Clears
                </div>
                {renderEndpointsTable()}
            </>
            :
            <>
            {showProgressBar ?
                <LoadingSpinner size={100} label={progressBarLabel}/>
            : null}
            </>
        }
        </>
    )
}

export default EndpointAuditing;