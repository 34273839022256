import React, {useState} from 'react';

import chimera from '../../chimera';
import PocDisplay from './PocDisplay';
import AddressDisplay from './AddressDisplay';
import FormField from '../FormField';

/** TODO: Include "same as other location" option? */
const NewLocationForm = props => {
    const [location, setLocation] = useState({
        nickname: '',
        poc: {
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
        },
        notificationEmails: [],
        businessPhone: '',
        shippingSameAs: true,
        serviceAddress: {
            street1: '',
            street2: '',
            city: '',
            county: '',
            state: 'WA',
            zip: ''
        },
        shippingAddress: {
            street1: '',
            street2: '',
            city: '',
            county: '',
            state: 'WA',
            zip: ''
        },
        serviceTypes: {
            msp: false,
            internet: false,
            voip: false,
            video: false
        },
        technical: {
            networkSummary: '',
            managementIp: '',
            cids: [],
            ips: [],
            gateways: [],
            subnetMasks: [],
            pocs: [],
            pops: [],
            idrac: false,
            voipLines: 0,
            equipmentLocation: '',
            servicePort: ''
        }
    })
    const handleSubmit = (event) => {
        event.preventDefault();
        if(props.handleSubmit) {
            // submit the location object
            props.handleSubmit({
                nickname: location.nickname,
                poc: location.poc,
                notificationEmails: location.notificationEmails,
                businessPhone: location.businessPhone,
                serviceAddress: location.serviceAddress,
                shippingAddress: location.shippingAddress,
                serviceTypes: location.serviceTypes,
                technical: location.technical
            })
        }
    }
    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        const name = event.target.name;
        const value = event.target.type !== "checkbox" ? event.target.value : event.target.checked;
        const newLocation = JSON.parse(JSON.stringify(location));
        if(name === "shippingSameAs" && value) {
            newLocation.shippingAddress = newLocation.serviceAddress;
        }
        if(event.target.type === "number") {
            // the only numeric field is `voipLines`, which expects an integer.
            chimera.setAttr(newLocation, name, parseInt(value));
        }
        else {
            if(name.toLowerCase().includes("phone")) {
                chimera.setAttr(newLocation, name, value.replace(/\D/g, ''));
            }
            else {
                chimera.setAttr(newLocation, name, value);
            }
        }
        if(name.includes("serviceAddress") && newLocation.shippingSameAs) {
            newLocation.shippingAddress = newLocation.serviceAddress;
        }
        setLocation(newLocation);
    }
    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }
    const deleteString = (basePath, index) => {
        let newStrings = [];
        const strings = chimera.getAttr(location, basePath);
        for(let i = 0; i < strings.length; i++) {
            if(i !== index)
                newStrings.push(strings[i]);
        }

        handleChange({
            target: {
                type: "Array",
                name: basePath,
                value: newStrings
            },
            preventDefault: () => {}
        });
    }
    const addString = (basePath) => {
        let newStrings = [];
        const strings = chimera.getAttr(location, basePath);
        for(const str of strings) {
            newStrings.push(str);
        }
        newStrings.push("");

        handleChange({
            target: {
                type: "Array",
                name: basePath,
                value: newStrings
            },
            preventDefault: () => {}
        });
    }
    const strInterface = (basePath, label, placeholder, description) => {
        const strings = chimera.getAttr(location, basePath);
        return(
            <div className="row justify-content-center">
                <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                    <div className="form-field">
                        <div className="form-label">{label}:</div>
                        {strings.map((str, i) => 
                            <div key={i} className="mb-1 d-flex flex-row">
                                <input className="form-control" type="text" name={`${basePath}[${i}]`} value={str} placeholder={placeholder} onChange={handleChange} onBlur={trimOnBlur}/>
                                <button className="btn btn-danger ms-2" onClick={(event) => {event.preventDefault(); deleteString(basePath, i)}}><i className="fas fa-minus"/></button>
                            </div>
                        )}
                        <button className="btn btn-success" onClick={(event) => {event.preventDefault(); addString(basePath)}}>
                            <i className="fas fa-plus"/>
                        </button>
                        <div className="form-text">
                            {description}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const addPoc = (basePath) => {
        let newPocs = [];
        const pocs = chimera.getAttr(location, basePath);
        for(const poc of pocs) {
            newPocs.push(poc);
        }
        newPocs.push({
            firstName: "",
            lastName: "",
            email: "",
            phone: ""
        });

        handleChange({
            target: {
                type: "Array",
                name: basePath,
                value: newPocs
            },
            preventDefault: () => {}
        })
    }
    const deletePoc = (basePath, index) => {
        let newPocs = [];
        const pocs = chimera.getAttr(location, basePath);
        for(let i = 0; i < pocs.length; i++) {
            if(i !== index) {
                newPocs.push(pocs[i]);
            }
        }

        handleChange({
            target: {
                type: "Array",
                name: basePath,
                value: newPocs
            },
            preventDefault: () => {}
        })
    }
    return (
        <>
        <h3>Add New Location</h3>
        <form id="newLocationForm" onSubmit={handleSubmit} noValidate>
            <div className="row">
                <div className="col">
                    <FormField
                        type="text"
                        name="nickname"
                        label="Location Nickname"
                        value={location.nickname}
                        description="A term to refer to this location; displayed in the tab interface."
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        required
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <FormField
                        type="component"
                        name="poc"
                        label="Point of Contact"
                        description="Point of Contact for this location"
                        required>
                            <PocDisplay poc={location.poc} basePath="poc" baseValue={location} onChange={handleChange} onBlur={trimOnBlur} isEditing/>
                    </FormField>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                    <div className="form-field">
                        <div className="form-label"><span className="red">*</span>&nbsp;Notification Emails:</div>
                        {location.notificationEmails.map((email, i) => 
                            <div key={i} className="mb-1 d-flex flex-row">
                                <input className="form-control" type="text" name={`notificationEmails[${i}]`} value={email} placeholder="Email" onChange={handleChange} onBlur={trimOnBlur}/>
                                {i !== 0 ? <button className="btn btn-danger ms-2" onClick={(event) => {event.preventDefault(); deleteString("notificationEmails", i)}}><i className="fas fa-minus"/></button> : null}
                            </div>
                        )}
                        <button className="btn btn-success" onClick={(event) => {event.preventDefault(); addString("notificationEmails")}}>
                            <i className="fas fa-plus"/>
                        </button>
                        <div className="form-text">The emails to be contacted for any automated notifications or alerts. At least 1 is required.</div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <FormField
                        type="text"
                        name="businessPhone"
                        label="Business Phone"
                        value={location.businessPhone}
                        description="The main phone number for contacting the business at this location."
                        handleChange={handleChange}
                        maxLength={11}
                        placeholder="Phone"
                        onBlur={trimOnBlur}
                        required
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <FormField
                        type="component"
                        name="serviceAddress"
                        label="Service Address"
                        description="The physical location of the customer site."
                        required>
                            <AddressDisplay addr={location.serviceAddress} basePath="serviceAddress" baseValue={location} onChange={handleChange} isEditing onBlur={trimOnBlur}/>
                    </FormField>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <FormField
                        type="component"
                        name="shippingAddress"
                        label="Shipping Address"
                        description="The mailing address for this customer location."
                        required>
                            <AddressDisplay addr={location.shippingAddress} basePath="shippingAddress" baseValue={location} onChange={handleChange} isEditing onBlur={trimOnBlur} sameAsLabel="Same As Service Address" sameAsPath="shippingSameAs" sameAs={location.shippingSameAs}/>
                    </FormField>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-field">
                        <span className="form-label"><span className="red">*</span>&nbsp;Service Types:</span>
                        <div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="serviceTypes.internet" id="internetService" checked={location.serviceTypes.internet} onChange={handleChange}/>
                                <label className="form-check-label" htmlFor="internetService">
                                    Internet
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="serviceTypes.voip" id="voipService" checked={location.serviceTypes.voip} onChange={handleChange}/>
                                <label className="form-check-label" htmlFor="voipService">
                                    VoIP
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="serviceTypes.msp" id="mspService" checked={location.serviceTypes.msp} onChange={handleChange}/>
                                <label className="form-check-label" htmlFor="mspService">
                                    MSP
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="serviceTypes.video" id="videoService" checked={location.serviceTypes.video} onChange={handleChange}/>
                                <label className="form-check-label" htmlFor="videoService">
                                    Video
                                </label>
                            </div>
                        </div>
                        <span className="form-text">The services to be provided to the customer at this location.</span>
                    </div>
                </div>
            </div>
            {location.serviceTypes.msp ? 
                <>
                    <div className="row">
                        <div className="col">
                            <FormField
                                type="textarea"
                                name="technical.networkSummary"
                                label="Network Summary"
                                value={chimera.getAttr(location, 'technical.networkSummary')}
                                description="A summary of the customer's network at this location."
                                handleChange={handleChange}
                                onBlur={trimOnBlur}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <FormField
                                type="checkbox"
                                name="technical.idrac"
                                label="iDRAC Enabled"
                                value={location.technical.idrac}
                                description="Whether or not the customer location has iDRAC support enabled."
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                </>
            :null}
            {location.serviceTypes.voip ? 
                <div className="row">
                    <div className="col">
                        <FormField
                            type="number"
                            name="technical.voipLines"
                            label="# of Lines"
                            value={location.technical.voipLines}
                            description="The maximum number of simultaneous calls the customer can have at this location."
                            handleChange={handleChange}
                            min={0}
                            step={1}
                        />
                    </div>
                </div>
            :null}
            {location.serviceTypes.internet ? 
                <>
                <div className="row">
                    <div className="col">
                        <FormField
                            type="text"
                            name="technical.managementIp"
                            label="Management IP"
                            value={chimera.getAttr(location, 'technical.managementIp')}
                            description="The management IP."
                            handleChange={handleChange}
                            onBlur={trimOnBlur}
                        />
                    </div>
                </div>
                {strInterface("technical.ips", "IPs", "IP Address", "(Optional) A list of associated IP addresses.")}
                {strInterface("technical.cids", "CIDs", "CID", "(Optional) A list of associated CIDs.")}
                {strInterface("technical.gateways", "Gateways", "Gateway", "(Optional) A list of associated gateways.")}
                {strInterface("technical.subnetMasks", "Subnet Masks", "Subnet Mask", "(Optional) A list of associated Subnet Masks.")}
                {strInterface("technical.pops", "POPs", "POP", "(Optional) A list of associated Points of Presence (POPs).")}
                <div className="row">
                    <div className="col">
                        <FormField
                            type="textarea"
                            name="technical.equipmentLocation"
                            label="Equipment Location"
                            value={chimera.getAttr(location, 'technical.equipmentLocation')}
                            description="A description of the equipment location. Be as descriptive as necessary."
                            handleChange={handleChange}
                            onBlur={trimOnBlur}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <FormField
                            type="textarea"
                            name="technical.servicePort"
                            label="Service Port"
                            value={chimera.getAttr(location, 'technical.servicePort')}
                            description="The service port."
                            handleChange={handleChange}
                            onBlur={trimOnBlur}
                        />
                    </div>
                </div>
                </>
            :null}
            <div className="row justify-content-center">
                <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                    <div className="form-field">
                        <div className="form-label">Technical POCs:</div>
                        {location.technical.pocs.map((poc, i) => 
                            <div key={i} className={i !== location.technical.pocs.length - 1 ? "section-outline mb-4" : "section-outline"}>
                                <PocDisplay poc={poc} basePath={`technical.pocs[${i}]`} baseValue={location} onChange={handleChange} isEditing handleDelete={(event) => {event.preventDefault(); deletePoc("technical.pocs", i)}} series/>
                            </div>
                        )}
                        <button className="btn btn-success mt-2 mb-2" onClick={(event) => {event.preventDefault(); addPoc("technical.pocs")}}>
                            <i className="fas fa-plus"/>
                        </button>
                        <div className="form-text">
                            (Optional) Points of contact for technical purposes.
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    <button className="btn btn-primary" type="submit" disabled={!location.serviceTypes.msp && !location.serviceTypes.internet && !location.serviceTypes.voip && !location.serviceTypes.video}>
                        <i className="fas fa-plus"/>
                        &nbsp;Add Location
                    </button>
                </div>
            </div>
        </form>
        </>
    )
}

export default NewLocationForm;