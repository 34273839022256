import React, {useState} from 'react';

import qblogo from "../../images/qblogo.png";
import syncrologo from "../../images/syncrologo.png";
import bdlogo from "../../images/bdlogo.png";
import unitylogo from"../../images/unitylogo.png";
import pax8logo from "../../images/pax8logo.png";
import duologo from "../../images/duologo.png";

import chimera from '../../chimera';

const IntegrationsDisplay = props => {
    const [checked, setChecked] = useState(false);

    const handleChange = event => {
        if(event.target.type === "checkbox") {
            setChecked(event.target.checked);
        }
        else {
            props.onChange({
                target: {
                    type: "object",
                    name: event.target.name,
                    value: event.target.value
                },
                preventDefault: () => {}
            })
        }
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const path = partial => {
        return props.basePath + '.' + partial;
    }

    return (
        <>
            {!props.isEditing ? 
                <>
                <div className="row">
                    <div className="col">
                        <img className={`w-${props.residential ? '50' : '25'}${props.customer.integrationIds.quickbooks === "" ? ' logo-dull' : ''}`} src={qblogo} alt="QB Logo"/>
                    </div>
                    <div className="col">
                        <img className={`w-${props.residential ? '50' : '25'}${props.customer.integrationIds.syncro === "" ? ' logo-dull' : ''}`} src={syncrologo} alt="Syncro Logo"/>
                    </div>
                    <div className="col">
                        <img className={`w-${props.residential ? '50' : '25'}${props.customer.integrationIds.unity === "" ? ' logo-dull' : ''}`} src={unitylogo} alt="Unity Logo"/>
                    </div>
                    {!props.residential ? 
                    <div className="col">
                        <img className={`w-${props.residential ? '50' : '25'}${props.customer.integrationIds.pax8 === "" ? ' logo-dull' : ''}`} src={pax8logo} alt="Pax8 Logo"/>
                    </div>
                    :null}
                    {!props.residential ? 
                    <div className="col">
                        <img className={`w-${props.residential ? '50' : '25'}${props.customer.integrationIds.duo === "" ? ' logo-dull' : ''}`} src={duologo} alt="Duo Logo"/>
                    </div>
                    :null}
                </div>
                <div className="row">
                    <div className="col">
                        {props.customer.integrationIds.quickbooks !== "" ? 
                        <a href={`https://app.qbo.intuit.com/app/customerdetail?nameId=${props.customer.integrationIds.quickbooks}`} target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-up-right-from-square"/>
                        </a>
                        :null}
                    </div>
                    <div className="col">
                        {props.customer.integrationIds.syncro !== "" ?
                        <a href={`https://gocbit.syncromsp.com/customers/${props.customer.integrationIds.syncro}`} target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-up-right-from-square"/>
                        </a>
                        :null}
                    </div>
                    <div className="col">
                        <span>{props.customer.integrationIds.unity}</span>
                    </div>
                    {!props.residential ?
                    <>
                    <div className="col">
                        {props.customer.integrationIds.pax8 !== "" ?
                        <a href={`https://app.pax8.com/companies/${props.customer.integrationIds.pax8}?activeTab=Details`} target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-up-right-from-square"/>
                        </a>
                        :null}
                    </div>
                    <div className="col">
                        
                    </div>
                    </>
                    :null}
                </div>
                </>
            :
                <>
                <div className="row mb-1">
                    <div className="col">
                        <strong>
                            <p>
                                <span className="text-danger">Beware!</span>&nbsp;
                                Chimera's ability to properly integrate with the platforms below hinges on these values.
                            </p>
                            <p>
                                Please only change them if you absolutely know what you are doing.
                            </p>
                        </strong>
                        <div className="form-check d-flex justify-content-center">
                            <input className="form-check-input" type="checkbox" id="checker" checked={checked} onChange={handleChange} disabled={props.disabled}/>
                            &nbsp;
                            <label className="form-check-label" htmlFor="checker">
                                I know what I am doing
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-2 text-end">
                        <strong>QuickBooks</strong>
                    </div>
                    <div className="col-10">
                        <input className="form-control" type="text" name={path('quickbooks')} value={chimera.getAttr(props.baseValue, path('quickbooks'))} onChange={props.onChange} disabled={!checked || props.disabled} onBlur={trimOnBlur}/>
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-2 text-end">
                        <strong>Syncro</strong>
                    </div>
                    <div className="col-10">
                        <input className="form-control" type="text" name={path('syncro')} value={chimera.getAttr(props.baseValue, path('syncro'))} onChange={props.onChange} disabled={!checked || props.disabled} onBlur={trimOnBlur}/>
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-2 text-end">
                        <strong>Unity</strong>
                    </div>
                    <div className="col-10">
                        <input className="form-control" type="text" name={path('unity')} value={chimera.getAttr(props.baseValue, path('unity'))} onChange={props.onChange} disabled={!checked || props.disabled} onBlur={trimOnBlur}/>
                    </div>
                </div>
                {!props.residential ? 
                <div className="row mb-1">
                    <div className="col-2 text-end">
                        <strong>Pax8</strong>
                    </div>
                    <div className="col-10">
                        <input className="form-control" type="text" name={path('pax8')} value={chimera.getAttr(props.baseValue, path('pax8'))} onChange={props.onChange} disabled={!checked || props.disabled} onBlur={trimOnBlur}/>
                    </div>
                </div>
                :null}
                {!props.residential ? 
                <div className="row">
                    <div className="col-2 text-end">
                        <strong>Duo</strong>
                    </div>
                    <div className="col-10">
                        <input className="form-control" type="text" name={path('duo')} value={chimera.getAttr(props.baseValue, path('duo'))} onChange={props.onChange} disabled={!checked || props.disabled} onBlur={trimOnBlur}/>
                    </div>
                </div>
                :null}
                </>
            }
        </>
    )
}

export default IntegrationsDisplay;