import React, {useState, useEffect, useContext} from 'react';
import {useLocation} from 'react-router-dom';

function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SearchPage = props => {
    const query = useQuery();

    return (
        <div className="container">
            <h1 className="chomsky">
                Search
            </h1>
            <p>
                Your quest for "{query.get('q')}" ends here as the Search
                functionality has yet to be implemented. Sorry!
            </p>
        </div>
    )
}

export default SearchPage;