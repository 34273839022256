import React, {useState, useEffect, useContext} from 'react';

import LoadingSpinner from '../LoadingSpinner';
import chimera from '../../chimera';
import BannerContext from "../BannerLogContext";
import FormField from '../FormField';

const IntegrationForm = props => {
    const [loading, setLoading] = useState(false);
    const [willQb, setWillQb] = useState(props.customer.integrationIds.quickbooks ? true : false);
    const [willSyncro, setWillSyncro] = useState(props.customer.integrationIds.syncro ? true : false);
    const [willUnity, setWillUnity] = useState(props.customer.integrationIds.unity ? true : false);
    const [willPax8, setWillPax8] = useState(props.customer.integrationIds.pax8 ? true : false);
    const [willDuo, setWillDuo] = useState(props.customer.integrationIds.duo ? true : false);
    const [createQb, setCreateQb] = useState(false);
    const [createSyncro, setCreateSyncro] = useState(false);
    const [createUnity, setCreateUnity] = useState(false);
    const [createPax8, setCreatePax8] = useState(false);
    const [createDuo, setCreateDuo] = useState(false);
    const [warnMsp, setWarnMsp] = useState(false);
    const [warnVoip, setWarnVoip] = useState(false);
    const [unityDomain, setUnityDomain] = useState("");
    const [pax8Domain, setPax8Domain] = useState("");
    const banners = useContext(BannerContext);

    useEffect(() => {
        setWarnMsp((willPax8 || willDuo) && !props.customer.serviceTypes.msp);
        setWarnVoip(willUnity && !props.customer.serviceTypes.voip);
    }, [willUnity, willPax8, willDuo]);

    useEffect(() => {
        setCreateQb(willQb && !props.customer.integrationIds.quickbooks);
        setCreateSyncro(willSyncro && !props.customer.integrationIds.syncro);
        setCreateUnity(willUnity && !props.customer.integrationIds.createUnity);
        setCreatePax8(willPax8 && !props.customer.integrationIds.pax8);
        setCreateDuo(willDuo && !props.customer.integrationIds.duo);
    }, [willQb, willSyncro, willUnity, willPax8, willDuo]);

    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        const name = event.target.name;
        const checked = event.target.checked;
        const value = event.target.value;
        if(name === "platformCheckQb") {
            setWillQb(checked);
        }
        else if(name === "platformCheckSyncro") {
            setWillSyncro(checked);
        }
        else if(name === "platformCheckUnity") {
            setWillUnity(checked);
        }
        else if(name === "platformCheckPax8") {
            setWillPax8(checked);
        }
        else if(name === "platformCheckDuo") {
            setWillDuo(checked);
        }
        else if(name === "unityDomain") {
            setUnityDomain(value);
        }
        else if(name === "pax8Domain") {
            setPax8Domain(value);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        chimera.createCustomer(undefined, props.customer, banners, {
            willSyncro: createSyncro,
            willQb: createQb,
            willUnity: createUnity,
            willPax8: createPax8,
            willDuo: createDuo,
            unityDomain: unityDomain,
            pax8Domain: pax8Domain,
            startSleepingCallback: () => {},
            stopSleepingCallback: () => {},
            cleanupCallback: () => {},
            integrationsOnly: true
        })
        .then(integrationIds => {
            if(props.callback) {
                props.callback(integrationIds);
            }
        })
        .catch(err => {
            console.error(err);
            banners.addBanner('danger', 'Failed to create Integrations', 'Error');
        })
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    return(
        <>
        {loading ? <LoadingSpinner size={50}/> : 
        <>
            <div className="row">
                <div className="col">
                    <div className="form-label">Create Records:</div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" name="platformCheckQb" id="qbCheck" checked={willQb} onChange={handleChange} disabled={props.customer.integrationIds.quickbooks}/>
                        <label className="form-check-label" htmlFor="qbCheck">
                            QuickBooks
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" name="platformCheckSyncro" id="syncroCheck" checked={willSyncro} onChange={handleChange} disabled={props.customer.integrationIds.syncro}/>
                        <label className="form-check-label" htmlFor="syncroCheck">
                            Syncro
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" name="platformCheckUnity" id="unityCheck" checked={willUnity} onChange={handleChange} disabled={props.customer.integrationIds.unity}/>
                        <label className="form-check-label" htmlFor="unityCheck">
                            Unity
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" name="platformCheckPax8" id="pax8Check" checked={willPax8} onChange={handleChange} disabled={props.customer.integrationIds.pax8}/>
                        <label className="form-check-label" htmlFor="pax8Check">
                            Pax8
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" name="platformCheckDuo" id="duoCheck" checked={willDuo} onChange={handleChange} disabled={props.customer.integrationIds.duo}/>
                        <label className="form-check-label" htmlFor="duoCheck">
                            Duo
                        </label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {createUnity && !props.customer.integrationIds.unity ? 
                        <FormField
                            type="text"
                            name="unityDomain"
                            label="Unity Domain"
                            value={unityDomain}
                            description="The Domain Name to be used in Unity."
                            handleChange={handleChange}
                            placeholder="Domain"
                            onBlur={trimOnBlur}
                            required
                        />
                    :null}
                    {createPax8 ? 
                        <FormField
                            type="text"
                            name="pax8Domain"
                            label="Pax8 Web Domain"
                            value={pax8Domain}
                            description="The Customer's website domain to be stored in Pax8."
                            handleChange={handleChange}
                            placeholder="Website"
                            onBlur={trimOnBlur}
                            required
                        />
                    :null}
                </div>
            </div>
            <div className="row">
                <div className="col text-start">
                    {warnMsp ? <p><strong>Warning:</strong>&nbsp;This customer is not an MSP customer, but you have selected one or more platforms that are usually reserved for MSP customers only. You may proceed, but Chimera recommends that you set this customer as an MSP customer afterwards.</p> : null}
                    {warnVoip ? <p><strong>Warning:</strong>&nbsp;This customer is not a VoIP customer, but you have selected one or more platforms that are usually reserved for VoIP customers only. You may proceed, but Chimera recommends that you set this customer as a VoIP customer afterwards.</p> : null}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <button className="btn btn-primary mb-1" onClick={handleSubmit} disabled={!createQb && !createSyncro && !createUnity && !createPax8 && !createDuo}>
                        <i className="fas fa-plus"/>&nbsp;Create Integrations
                    </button>
                </div>
            </div>
        </>
        }
        </>
    )
}

export default IntegrationForm;