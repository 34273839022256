import React, {useContext, useState} from "react";

import FormField from "../FormField";
import Modal, {choiceCancel} from '../Modal';
import AlertsContext from "../AlertsContext";
import chimera from "../../chimera";

function defaultCheckInDate() {
    let sevenDaysLater = new Date();
    sevenDaysLater.setDate(sevenDaysLater.getDate() + 7);
    return sevenDaysLater.toISOString().substring(0,10);
}

const SnoozeModal = props => {
    const [checkInDate, setCheckInDate] = useState(defaultCheckInDate());
    const [isSaving, setIsSaving] = useState(false);
    const alertsContext = useContext(AlertsContext);

    const handleChange = (event) => {
        if(event.target.name === "checkInDate") {
            setCheckInDate(event.target.value);
        }
    }

    const choices = [
        choiceCancel(props),
        {
            btnColor: 'primary',
            btnInner: <span><i className={isSaving ? "fas fa-spinner" : "fas fa-floppy-disk"}/>&nbsp;{isSaving ? "Saving..." : "Save"}</span>,
            func: async (e) => {
                e.preventDefault();
                setIsSaving(true);
                chimera.callAPI(undefined, '/api/internetOrders/checkInDates', 'POST', {checkInDate: checkInDate, orderIds: props.orders.map((order) => order._id)})
                .then(savedOrders => {
                    for(const order of savedOrders) {
                        if(alertsContext.internetOrderIsAfterCheckInDate(order._id)) {
                            alertsContext.removeInternetOrder(order._id);
                        }
                    }
                    props.bannerContext.addBanner('info', 'The selected orders were successfully snoozed.');
                    props.modalContext.setModal(null);
                })
                .catch(err => {
                    console.error(err);
                    props.bannerContext.addBanner('danger', 'Something went wrong and the Orders could not be snoozed.', 'Error');
                    props.modalContext.setModal(null);
                })
            }
        }
    ]

    return (
        <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Snooze</h3>
            <p>Set check-in date for the ({props.orders.length}) selected orders:</p>
            <FormField
                type="date"
                name="checkInDate"
                label="Check-In Date"
                value={checkInDate}
                handleChange={handleChange}
                description="If no changes are made to the selected Orders by this date, they will enter the Attention Needed queue."
                disabled={isSaving}
            />
        </Modal>
    );
}

export default SnoozeModal;