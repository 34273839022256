import React, { useState, useEffect, useContext, useRef } from 'react';

import chimera from '../chimera';
import LoadingSpinner from './LoadingSpinner';
import Pagination from './Pagination';
import AlertsContext from './AlertsContext';

/**
 * 
 * @param cols Array of {label, sort(ascending)}
 * @param filters Array of {label, value, func}
 * @param actions Array of {label, func}
 * @param btns Array of {label, func, color?, icon?}
 */
const ObjectTable = ({cols, objects, actions, filters, btns, search, paginated, id, defaultSortByColName, defaultSortAscending, defaultFilterSetting, rowElement: RowElement, clickedObject, selectable, idPath = '_id', rowElementProps}) => {
    const [sortByColName, setSortByColName] = useState(defaultSortByColName ? defaultSortByColName : cols[0].label);
    const [sortAscending, setSortAscending] = useState(defaultSortAscending !== undefined ? defaultSortAscending : true);
    const [filterSetting, setFilterSetting] = useState(defaultFilterSetting ? defaultFilterSetting : 'Default');
    const [query, setQuery] = useState("");
    const [page, setPage] = useState(1); // 1-indexed
    const [nPages, setNPages] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [filteredObjects, setFilteredObjects] = useState(null);
    const [selectedNums, setSelectedNums] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const selectAll = useRef(null);
    const alertsContext = useContext(AlertsContext);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if(name === "filter") {
            setFilterSetting(value);
        }
        else if(name === "search") {
            setQuery(value);
        }
        else if(name === "selectAll"){
            if(allChecked) {
                // Uncheck all
                setSelectedNums([]);
            }
            else {
                // Check all
                let newSelectedNums = [];
                for(const order of filteredObjects) {
                    newSelectedNums.push(chimera.getAttr(order, idPath));
                }
                setSelectedNums(newSelectedNums);
            }
            setAllChecked(!allChecked);
            if(selectAll.current) {
                selectAll.current.indeterminate = false;
            }
        }
        else if(event.target.type === "checkbox") {
            if(event.target.checked && !selectedNums.includes(name)) {
                setSelectedNums(nums => [...nums, name]);
            }
            else {
                let newSelectedNums = [];
                for(const num of selectedNums) {
                    if(num !== name) {
                        newSelectedNums.push(num);
                    }
                }
                setSelectedNums(newSelectedNums);
            }
        }
    }

    useEffect(() => {
        if(objects) {
            setFilteredObjects(sortObjects(objects));
        }
        else {
            setFilteredObjects(null);
        }
    }, [objects, filterSetting, query, sortAscending, sortByColName, alertsContext.alerts]);

    useEffect(() => {
        if(filteredObjects !== null) {
            setNPages(perPage == -1 ? 1 : Math.ceil(filteredObjects.length / perPage)); // Note: === instead of == when comparing `perPage` to -1 is false. JS is fun
        }
        setSelectedNums([]);
    }, [filteredObjects, perPage]);

    useEffect(() => {
        setPage(1); // reset to page 1 when changing parameters
    }, [filterSetting, query, perPage]);

    useEffect(() => {
        if(page > nPages) {
            setPage(nPages === 0 ? 1 : nPages); // `page` should never be 0
        }
    }, [nPages]);

    useEffect(() => {
        if(selectAll.current && filteredObjects !== null && selectedNums.length === filteredObjects.length) {
            setAllChecked(true);
            selectAll.current.indeterminate = false;
        }
        else if(selectAll.current && selectedNums.length === 0) {
            setAllChecked(false);
            selectAll.current.indeterminate = false;
        }
        else if(selectAll.current) {
            setAllChecked(true);
            selectAll.current.indeterminate = true;
        }
    }, [selectedNums]);

    const filterFunc = () => {
        // Find the matching function for the current filterSetting
        if(!filters) return () => true;
        for(const filter of filters) {
            if(filter.value === filterSetting) {
                return filter.func;
            }
        }
        return () => true;
    }

    const searchFilter = () => {
        return (object) => {
            if(!search || !query || query === "") return true;
            return chimera.searchObjectForString(object, query);
        };
    }

    const sortFunc = () => {
        // Find the matching function for the column name
        for(const col of cols) {
            if(col.label === sortByColName) {
                return col.sort(sortAscending);
            }
        }
        return () => 1;
    }

    const sortObjects = (objects) => {
        // Group flagged/alerted objects into their own subgroup that goes first and has the sorting/filtering applied
        let flaggedArr = [];
        let unflaggedArr = [];
        for(const object of objects) {
            if(alertsContext.isFlagged(object)) {
                flaggedArr.push(object);
            }
            else {
                unflaggedArr.push(object);
            }
        }

        flaggedArr = flaggedArr.sort(sortFunc());
        unflaggedArr = unflaggedArr.sort(sortFunc());

        return flaggedArr.concat(unflaggedArr).filter(filterFunc()).filter(searchFilter());
    }

    const handleSortByColNameChange = (newName) => {
        if(newName !== sortByColName) {
            setSortAscending(true);
        }
        else {
            setSortAscending(!sortAscending);
        }
        setSortByColName(newName);
    }

    const ColHeader = ({label}) => {
        return (
            <th>
                <button className="btn" onClick={(event) => {event.preventDefault(); handleSortByColNameChange(label)}}>
                    {label}&nbsp;
                    <i className={sortByColName === label ? (sortAscending ? "fas fa-caret-up" : "fas fa-caret-down") : "fas fa-minus"}/>
                </button>
            </th>
        )
    }

    const finalObjects = () => {
        if(!paginated || perPage === -1) {
            return filteredObjects;
        }
        else {
            return filteredObjects.slice((page * perPage) - perPage, (page * perPage));
        }
    }

    return(
        <>
        {actions || filters || btns ? 
        <div className="row mb-2">
            <div className="col d-flex justify-content-start">
                {actions ? 
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle h-fit" type="button" id={`${id}ActionsBtn`} data-bs-toggle="dropdown" aria-expanded="false" disabled={selectedNums.length === 0}>
                        Actions
                    </button>
                    <ul className="dropdown-menu" aria-labelledby={`${id}ActionsBtn`}>
                        {actions.map((action, i) => <li key={i}><button className={`btn dropdown-item${action.classes ? ` ${action.classes}` : ''}`} onClick={(e) => {e.preventDefault(); action.func(filteredObjects.filter(object => selectedNums.includes(chimera.getAttr(object, idPath))))}}>{action.label}</button></li>)}
                    </ul>
                </div>
                :null}
            </div>
            <div className="col d-flex flex-column justify-content-center align-items-center">
                {filters ? 
                <>
                <span><u>Filter:</u></span>
                <select className="form-select centered w-fit" name="filter" onChange={handleChange} value={filterSetting}>
                    {filters.map((filter, i) => <option key={i} value={filter.value}>{filter.label}</option>)}
                </select>
                </>
                :null}
            </div>
            <div className="col d-flex justify-content-end">
                {btns ?  
                <>
                {btns.map((btn, i) => <button key={i} className={`btn btn-${btn.color ? btn.color : 'success'} h-fit${i !== btns.length-1 ? ' me-1' : ''}`} onClick={(e) => {e.preventDefault(); btn.func(filteredObjects.filter(object => selectedNums.includes(chimera.getAttr(object, idPath))))}} disabled={btn.disabled}>
                    <i className={btn.icon ? btn.icon : 'fas fa-plus'}/>&nbsp;{btn.label}
                </button>)}
                </>
                :null}
            </div>
        </div>
        :null}
        {search ? 
        <div className="row">
            <div className="col">
                <div className="d-flex flex-column mb-3">
                    <input className="form-control" type="text" name="search" placeholder="Search by any field..." value={query} onChange={handleChange}/>
                    {query !== "" ? <span className="text-muted text-start">Results: {filteredObjects !== null ? filteredObjects.length : <i className="fas fa-spinner"/>}</span> : null}
                </div>
            </div>
        </div>
        :null}
        <div className="row">
            <div className="col">
                {filteredObjects === null ?
                    <LoadingSpinner size={75}/>
                :
                    <>
                    {filteredObjects.length > 0 ? 
                    <>
                    {paginated ? <Pagination page={page} setPage={setPage} nPages={nPages} perPage={perPage} setPerPage={setPerPage}/> : null}
                    <table className="table table-hover table-bordered">
                        <thead>
                            <tr>
                                {actions || selectable ? <th><input className="form-check-input" name="selectAll" type="checkbox" onChange={handleChange} ref={selectAll} checked={allChecked}/></th> : null}
                                {cols.map((col, i) => <ColHeader key={i} label={col.label}/>)}
                            </tr>
                        </thead>
                        <tbody>
                            {finalObjects().map((object, i) => <tr key={i}>
                                {actions || selectable?
                                <td>
                                    <input className="form-check-input" type="checkbox" checked={selectedNums.includes(chimera.getAttr(object, idPath))} name={chimera.getAttr(object, idPath)} onChange={handleChange}/>
                                </td>
                                :null}
                                {rowElementProps ? <RowElement object={object} clickedObject={clickedObject} {...rowElementProps}/> :
                                    <RowElement object={object} clickedObject={clickedObject}/>
                                }
                            </tr>)}
                        </tbody>
                    </table>
                    </>
                    :
                    <p><i>No matches for the given parameters.</i></p>
                    }
                    </>
                }
            </div>
        </div>
        </>
    )
}

export default ObjectTable;