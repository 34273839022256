import React, {useState, useEffect, useContext, useRef} from 'react';

import chimera from '../chimera';
import BannerContext, { BannerLog } from './BannerLogContext';
import LoadingSpinner from './LoadingSpinner';
import { NavLink } from "react-router-dom";
import Pagination from './Pagination';
import Modal from "./Modal";
import ModalContext from "./ModalContext";
import NotesInterface from './NotesInterface';
import PushSync from './PushSync';
import ExportModal from './ExportModal';

const CustomerRow = props => {
    return (
        <div className="row border-top">
            <div className="col-1 d-flex align-items-center justify-content-center">
                <input className="form-check-input" type="checkbox" checked={props.isChecked} name={props.customer.accountNumber} onChange={props.handleChange}/>
            </div>
            <div className="col-3 col-sm-2 text-start d-flex align-items-center border-start">
                <span style={{paddingBottom: "0.2em"}}>{props.customer.accountNumber}</span>
            </div>
            <div className="col-4 col-sm-3 text-start border-start">
                <NavLink className="text-body text-decoration-none w-100 d-inline-block hover-blue" to={`/residential/${props.customer.accountNumber}`}>
                    <b>{props.customer.firstName} {props.customer.lastName}</b><br/>
                    <span className="text-muted">{props.customer.homeAddress.street1}{props.customer.homeAddress.street2 ? <>, {props.customer.homeAddress.street2}</> : null}</span>
                </NavLink>
            </div>
            <div className="col-4 col-sm-3 text-start border-start d-flex align-items-center">
                <span style={{paddingBottom: "0.2em"}}>{props.customer.type}</span>
            </div>
            <div className="d-none d-sm-block col-sm-3 border-start">
                <div className="row h-100">
                    <div className="col d-flex align-items-center justify-content-center">
                        <i className={props.customer.serviceTypes.internet ? "fas fa-check" : "fas fa-ban"}/>
                    </div>
                    <div className="col d-flex align-items-center justify-content-center">
                        <i className={props.customer.serviceTypes.voip ? "fas fa-check" : "fas fa-ban"}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ResidentialCustomersBody = props => {
    const [customers, setCustomers] = useState(null);
    const [allChecked, setAllChecked] = useState(false);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState("");
    const [selectedNums, setSelectedNums] = useState([]);
    const [filteredCustomers, setFilteredCustomers] = useState(null);
    const selectAll = useRef(null);
    const banners = useContext(BannerContext);
    const modalContext = useContext(ModalContext);

    const perPage = 20;

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if(customers !== null) {
            // Isolate keyword terms from normal search terms so they aren't treated as part of a search for a customer name
            const digest = chimera.digestQuery(query);
            const searchTerm = digest.searchTerm;
            const keywordTerms = digest.keywordTerms;
            const regex = new RegExp(`([^A-Za-z0-9]|^)${searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')}`, 'ig');
            const fullName = (customer) => `${customer.firstName} ${customer.lastName}`
            let newFilteredCustomers = searchTerm === "" ? customers : customers.filter((customer) => fullName(customer).match(regex) || customer.homeAddress.street1.match(regex));
            if(keywordTerms.length > 0) {
                if(digest.keywords.ip !== "") {
                    newFilteredCustomers = newFilteredCustomers.filter(customer => chimera.ipInArray(customer.technical.ips, digest.keywords.ip));
                }
            }
            setFilteredCustomers(newFilteredCustomers);
            setSelectedNums([]);
        }
        else {
            setFilteredCustomers(null);
            chimera.callAPI(signal, '/api/residentialcustomers')
            .then(customers => {
                setCustomers(customers);
            })
            .catch(e => {
                if(e.name !== "AbortError") {
                    console.error(e);
                    banners.addBanner('danger', <span><b>Error:</b> Error loading customers.</span>);
                }
            })
        }
        return () => {
            controller.abort();
        }
    }, [customers, query]);

    const handleChange = event => {
        if(event.target.name === "search") {
            setQuery(event.target.value);
            setPage(1);
        }
        else if(event.target.name === "selectAll"){
            if(allChecked) {
                // Uncheck all
                setSelectedNums([]);
            }
            else {
                // Check all
                let newSelectedNums = [];
                for(const customer of filteredCustomers) {
                    newSelectedNums.push(customer.accountNumber);
                }
                setSelectedNums(newSelectedNums);
            }
            setAllChecked(!allChecked);
            if(selectAll.current) {
                selectAll.current.indeterminate = false;
            }
        }
        else if(event.target.type === "checkbox") {
            if(event.target.checked && !selectedNums.includes(event.target.name)) {
                setSelectedNums(nums => [...nums, event.target.name]);
            }
            else {
                let newSelectedNums = [];
                for(const num of selectedNums) {
                    if(num !== event.target.name) {
                        newSelectedNums.push(num);
                    }
                }
                setSelectedNums(newSelectedNums);
            }
        }
    }

    const customersOnPage = (pageNum) => {
        return filteredCustomers.sort((a, b) => `${a.firstName} ${a.lastName}` < `${b.firstName} ${b.lastName}` ? -1 : 1).slice((pageNum * perPage) - perPage, (pageNum * perPage));
    }

    const isChecked = customer => {
        return selectedNums.includes(customer.accountNumber);
    }

    const openPushModal = event => {
        event.preventDefault();
        let selectedCustomers = [];
        for(const acctNum of selectedNums) {
            for(const customer of filteredCustomers) {
                if(customer.accountNumber === acctNum) {
                    selectedCustomers.push(customer);
                    break;
                }
            }
        }
        const choices = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                btnColor: "secondary",
                func: (event) => {
                    event.preventDefault();
                    modalContext.setModal(null);
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <PushSync customers={selectedCustomers} residential/>
        </Modal>
        modalContext.setModal(modal);
    }

    const openNotesInterface = event => {
        event.preventDefault();
        if(selectedNums.length === 1) {
            let customer = null;
            for(const cust of filteredCustomers) {
                if(cust.accountNumber === selectedNums[0]) customer = cust;
            }
            const choices = [
                {
                    btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                    btnColor: "secondary",
                    func: (event) => {
                        event.preventDefault();
                        modalContext.setModal(null);
                    }
                }
            ]
            const modal = <Modal choices={choices} dismiss={choices[0].func}>
                <NotesInterface refId={customer._id}/>
            </Modal>
            modalContext.setModal(modal);
        }
    }

    const deleteSelected = event => {
        event.preventDefault();
        const loadingModal = 
            <Modal choices={[]} dismiss={(event) => {event.preventDefault(); modalContext.setModal(null)}}>
                <LoadingSpinner size={100}/>
            </Modal>;
        const choices = [
            {
                btnColor: 'danger',
                btnInner: <span><i className="fas fa-times"/>&nbsp;Delete From Chimera Only</span>,
                func: async(e) => {
                    e.preventDefault();
                    modalContext.setModal(loadingModal);
                    let nDeletions = 0;
                    for(const customer of filteredCustomers) {
                        if(selectedNums.includes(customer.accountNumber)) {
                            if(await chimera.deleteCustomer(customer, {chimera: true, residential: true}, banners)) {
                                nDeletions++;
                                if(selectedNums.length <= 10) {
                                    if (banners) banners.addBanner('info', `Successfully deleted customer # ${customer.accountNumber} (${customer.displayName})`);
                                }
                            }
                        }
                    }
                    if(selectedNums.length > 10) {
                        banners.addBanner('info', 'Successfully deleted customers');
                    }
                    
                    const nPagesBefore = Math.ceil(filteredCustomers.length / 20);
                    const nPagesAfter = Math.ceil((filteredCustomers.length - nDeletions) / 20);
                    if(nPagesAfter < nPagesBefore) {
                        setPage(page - (nPagesBefore - nPagesAfter));
                    }
                    setCustomers(null);
                    modalContext.setModal(null);
                }
            },
            {
                btnColor: 'danger',
                btnInner: <span><i className="fas fa-times"/>&nbsp;Delete Everywhere</span>,
                func: async(e) => {
                    e.preventDefault();
                    modalContext.setModal(loadingModal);
                    let nDeletions = 0;
                    for(const customer of filteredCustomers) {
                        if(selectedNums.includes(customer.accountNumber)) {
                            if(await chimera.deleteCustomer(customer, {everywhere: true, residential: true}, banners)) {
                                nDeletions++;
                                if(selectedNums.length <= 10) {
                                    if (banners) banners.addBanner('info', `Successfully deleted customer # ${customer.accountNumber} (${customer.firstName} ${customer.lastName})`);
                                }
                            }
                        }
                    }
                    if(selectedNums.length > 10) {
                        banners.addBanner('info', 'Successfully deleted customers');
                    }
                    
                    const nPagesBefore = Math.ceil(filteredCustomers.length / 20);
                    const nPagesAfter = Math.ceil((filteredCustomers.length - nDeletions) / 20);
                    if(nPagesAfter < nPagesBefore) {
                        setPage(page - (nPagesBefore - nPagesAfter));
                    }
                    setCustomers(null);
                    modalContext.setModal(null);
                }
            },
            {
                btnColor: 'secondary',
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;No, Cancel</span>,
                func: (e) => {
                    e.preventDefault();
                    modalContext.setModal(null);
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[1].func}>
            <h3>Delete Customer{selectedNums.length > 1 ? "s" : ""}?</h3>
            <p><strong>
                Are you sure you want to delete {selectedNums.length} selected customer{selectedNums.length > 1 ? "s" : ""}? <span className="text-danger">This operation cannot be undone.</span>
            </strong></p>
        </Modal>
        modalContext.setModal(modal);
    }

    const openExportModal = (event) => {
        event.preventDefault();
        const objects = filteredCustomers.filter(customer => isChecked(customer));
        modalContext.setModal(<ExportModal objects={objects} model="residentialCustomer"/>);
    }

    return (
        <>
        {filteredCustomers === null ?
            <LoadingSpinner size={100}/>
        :
        <>
            <div className="d-flex flex-column mb-3">
                <input className="form-control" type="text" name="search" placeholder="Search customers..." value={query} onChange={handleChange}/>
                {query !== "" ? <span className="text-muted text-start">Results: {filteredCustomers.length}</span> : null}
            </div>
            <div className="row mb-3">
                <div className="col d-flex justify-content-start">
                    <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="actionsBtn" data-bs-toggle="dropdown" aria-expanded="false" disabled={selectedNums.length === 0}>
                            Actions
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="actionsBtn">
                            <li>
                                <button className="btn dropdown-item" onClick={openPushModal}>
                                    Push
                                </button>
                            </li>
                            <li>
                                <button className="btn dropdown-item" onClick={openNotesInterface} disabled={selectedNums.length !== 1}>
                                    Open Notes
                                </button>
                            </li>
                            <li>
                                <button className="btn dropdown-item" onClick={openExportModal}>
                                    Export
                                </button>
                            </li>
                            <li>
                                <button className="btn text-danger dropdown-item" onClick={deleteSelected}>
                                    Delete
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col d-flex justify-content-end">
                    <NavLink className="btn btn-primary" to="/residential/new">
                        <i className="fas fa-plus"/>
                        &nbsp;Add Customer
                    </NavLink>
                </div>
            </div>
            {filteredCustomers.length > 0 ?
            <>
            <Pagination page={page} nPages={Math.ceil(filteredCustomers.length / perPage)} setPage={setPage}/>
            <div className="section-outline pb-0 mb-3">
                <div className="row pb-2">
                    <div className="col-1 d-flex align-items-center justify-content-center">
                        <input className="form-check-input" name="selectAll" type="checkbox" onChange={handleChange} ref={selectAll} checked={allChecked}/>
                    </div>
                    <div className="col-3 col-sm-2">
                        <div className="row text-start">
                            <b>Account Number</b>
                        </div>
                    </div>
                    <div className="col-4 col-sm-3">
                        <div className="row text-start">
                            <b>Name</b>
                        </div>
                    </div>
                    <div className="col-4 col-sm-3">
                        <div className="row text-start">
                            <b>Zone</b>
                        </div>
                    </div>
                    <div className="d-none d-sm-block col-sm-3">
                        <div className="row">
                            <div className="col">
                                <b>ISP</b>
                            </div>
                            <div className="col">
                                <b>VoIP</b>
                            </div>
                        </div>
                    </div>
                </div>
                {customersOnPage(page).map((customer, i) => <CustomerRow key={i} customer={customer} isChecked={isChecked(customer)} handleChange={handleChange}/>)}
            </div>
            </>
            :
                <>
                <h3>No Results</h3>
                <p>
                    0 customers match your query.
                </p>
                </>
            }
        </>
        }
        </>
    )
}

const ResidentialCustomers = props => {
    return (
        <div className="container pb-5">
            <h1>Residential Customers</h1>
            <BannerLog>
                <ResidentialCustomersBody />
            </BannerLog>
        </div>
    )
}

export default ResidentialCustomers;