import React, {useState, useLayoutEffect, useContext} from "react";
import chimera from "../chimera";
import BannerContext, { BannerLog } from "./BannerLogContext";
import LoadingSpinner from "./LoadingSpinner";

const PushSyncBody = props => {
    const [loading, setLoading] = useState(null);
    const [label, setLabel] = useState("");
    const [platforms, setPlatforms] = useState([]);
    const banners = useContext(BannerContext);

    const controller = new AbortController();
    const signal = controller.signal;

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    const handleChange = (event) => {
        let platform = event.target.name.substring(6) // 6 is the length of prefix `toggle`
        if(event.target.checked) {
            if(!platforms.includes(platform)) {
                setPlatforms([...platforms, platform]);
            }
        }
        else {
            if(platforms.includes(platform)) {
                setPlatforms(platforms.filter(p => p !== platform));
            }
        }
    }

    const run = async(e) => {
        e.preventDefault();
        setLoading(true);
        for(const customer of props.customers) {
            const customerName = props.residential ? `${customer.firstName} ${customer.lastName}` : customer.displayName;
            for(const platform of platforms) {
                try {
                    if(customer.integrationIds[platform.toLowerCase()]) {
                        setLabel(`Updating ${customerName} in ${platform}...`)
                        const startSleepingCallback = () => {setLabel(`Waiting out ${platform} API limitations...`)}
                        const stopSleepingCallback = () => {setLabel(`Updating ${customerName} in ${platform}...`)}
                        await chimera[`pushTo${platform}`](customer, startSleepingCallback, stopSleepingCallback, signal, props.residential);
                    }
                }
                catch(e) {
                    console.error(e);
                    banners.addBanner('danger', `Failed to push ${customerName} to ${platform}`, `${platform} Error`);
                }
            }
        }
        setLoading(false);
    }
    

    return(
        <div>
            <h3>Push Tool</h3>
            {loading === null 
            ? 
            <>
                <div className="row">
                    <div className="col">
                        <div className="form-label">Push To:</div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="toggleQuickBooks" id="qbCheck" checked={platforms.includes('QuickBooks')} onChange={handleChange}/>
                            <label className="form-check-label" htmlFor="qbCheck">
                                QuickBooks
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="toggleSyncro" id="syncroCheck" checked={platforms.includes('Syncro')} onChange={handleChange}/>
                            <label className="form-check-label" htmlFor="syncroCheck">
                                Syncro
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col">
                        <button className="btn btn-primary" onClick={run}>
                            <i className="fas fa-arrow-right"/>&nbsp;Push
                        </button>
                    </div>
                </div>
            </>
            :
            <>
                {loading ? <LoadingSpinner size={75} label={label}/> :
                <p>
                    Done pushing the {props.customers.length > 1 ? 'customers' : 'customer'} to their integrated platforms.
                </p>
                }
            </>
            }
        </div>
    )
}

const PushSync = props => {
    return (
        <BannerLog>
            <PushSyncBody customers={props.customers}/>
        </BannerLog>
    )
}

export default PushSync;