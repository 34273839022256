import React, {useState, useContext, useEffect} from 'react';
import chimera from '../../chimera';
import FormFieldMicro from '../FormFieldMicro';
import BannerContext, {BannerLog} from '../BannerLogContext';
import Modal, {choiceCancel, choiceDelete} from '../Modal';
import LoadingSpinner from '../LoadingSpinner';
import ModalContext from '../ModalContext';
import MiniNotes from '../MiniNotes';

const DEFAULT_CONTACT = {
    first: '',
    last: '',
    title: '',
    phone: '',
    ext: '',
    email: '',
    notes: ''
}

const ContactArea = (props) => {
    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        props.setModified(true);
        const name = event.target.name;
        const value = event.target.type !== "checkbox" ? event.target.value : event.target.checked;

        let newVendor = JSON.parse(JSON.stringify(props.workingVendor));

        chimera.setAttr(newVendor, name, value);

        props.setWorkingVendor(newVendor);
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    return(
        <div className={`section-outline${props.index > 0 ? ' mt-2' : ''}`}>
            <div className="row row-cols-1 row-cols-md-4 g-4">
                <FormFieldMicro
                    type="text"
                    name={`contacts[${props.index}].first`}
                    label="First Name"
                    value={props.workingVendor.contacts[props.index].first}
                    handleChange={handleChange}
                    onBlur={trimOnBlur}
                    disabled={props.isSaving}
                />
                <FormFieldMicro
                    type="text"
                    name={`contacts[${props.index}].last`}
                    label="Last Name"
                    value={props.workingVendor.contacts[props.index].last}
                    handleChange={handleChange}
                    onBlur={trimOnBlur}
                    disabled={props.isSaving}
                />
                <FormFieldMicro
                    type="text"
                    name={`contacts[${props.index}].title`}
                    label="Title"
                    value={props.workingVendor.contacts[props.index].title}
                    handleChange={handleChange}
                    onBlur={trimOnBlur}
                    disabled={props.isSaving}
                />
                <FormFieldMicro
                    type="text"
                    name={`contacts[${props.index}].email`}
                    label="Email"
                    value={props.workingVendor.contacts[props.index].email}
                    handleChange={handleChange}
                    onBlur={trimOnBlur}
                    disabled={props.isSaving}
                />
            </div>
            <div className="row row-cols-1 row-cols-md-2 g-2">
                <FormFieldMicro
                    type="text"
                    name={`contacts[${props.index}].phone`}
                    label="Phone"
                    value={props.workingVendor.contacts[props.index].phone}
                    handleChange={handleChange}
                    onBlur={trimOnBlur}
                    disabled={props.isSaving}
                />
                <FormFieldMicro
                    type="text"
                    name={`contacts[${props.index}].ext`}
                    label="Ext."
                    value={props.workingVendor.contacts[props.index].ext}
                    handleChange={handleChange}
                    onBlur={trimOnBlur}
                    disabled={props.isSaving}
                />
            </div>
            <div>
                <FormFieldMicro
                    type="textarea"
                    name={`contacts[${props.index}].notes`}
                    label="Notes"
                    value={props.workingVendor.contacts[props.index].notes}
                    handleChange={handleChange}
                    onBlur={trimOnBlur}
                    disabled={props.isSaving}
                    fullWidth
                    resize='vertical'
                />
                {props.showDelete ? <>&nbsp;<button className="btn btn-sm btn-danger w-fit h-fit my-auto" onClick={(e) => {e.preventDefault(); props.removeContactAtIndex(props.index)}} disabled={props.isSaving}><i className="fas fa-times"/></button></>:null}
            </div>
        </div>
    )
}

const VendorFormBody = props => {
    const [isEditing, setIsEditing] = useState(props.isEditing);
    const banners = useContext(BannerContext);
    const modaling = useContext(ModalContext);

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }
    
    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        props.setModified(true);
        const name = event.target.name;
        const value = event.target.type !== "checkbox" ? event.target.value : event.target.checked;
        let newWorkingVendor = JSON.parse(JSON.stringify(props.workingVendor));
        
        if(event.target.type === "number") {
            chimera.setAttr(newWorkingVendor, name, parseFloat(value));
        }
        else if(name.split('.')[name.split('.').length-1].toLowerCase().includes("phone")) {
            chimera.setAttr(newWorkingVendor, name, value.replace(/\D/g, ''));
        }
        else {
            chimera.setAttr(newWorkingVendor, name, value);
        }

        props.setWorkingVendor(newWorkingVendor);
    }

    const handleSaveError = (err) => {
        console.error(err);
        if(err.details && err.details.name === "ValidationError") {
            for(const key in err.details.errors) {
                banners.addBanner('danger', err.details.errors[key].message, 'Validation Error');
            }
        }
        else if(err.name !== 'AbortError') {
            banners.addBanner('danger', 'Failed to save vendor', 'Error');
        }
    }

    const handleSave = (event, vendor) => {
        if(event) event.preventDefault();
        const resolve = (savedVendor) => {
            if(props.saveMode === 'PUT') {
                banners.addBanner('info', `Changes saved successfully.`, 'Saved');
            }
            else { // saveMode === 'POST'
                banners.addBanner('info', `The Vendor${savedVendor.name ? ` "${savedVendor.name}"` : ""} was created successfully.`, 'Success');
                props.setSaveMode('PUT');
            }
            props.setWorkingVendor(savedVendor);
            props.setSavedVendor(savedVendor);
        }
        props.handleSave(resolve, handleSaveError, vendor);
    }

    const handleCancel = (event) => {
        event.preventDefault();
        props.setWorkingVendor(props.savedVendor);
        setIsEditing(false);
    }

    const addContact = (e) => {
        e.preventDefault();
        const newVendor = JSON.parse(JSON.stringify(props.workingVendor));
        newVendor.contacts = [...props.workingVendor.contacts, DEFAULT_CONTACT];
        props.setWorkingVendor(newVendor);
    }

    const removeContactAtIndex = (index) => {
        const newVendor = JSON.parse(JSON.stringify(props.workingVendor));
        newVendor.contacts = props.workingVendor.contacts.filter((_, i) => i !== index);
        props.setWorkingVendor(newVendor);
    }

    const websiteIsValid = (website) => {
        // lazy solution
        if(website.startsWith('http') || website.startsWith('www.')) {
            return true;
        }
        else {
            return false;
        }
    }

    return(
        <div className="row">
            <div className="col-9 border-end">
                {isEditing ? 
                    <>
                    <h3>Edit Vendor:</h3>
                    <div className="row row-cols-1 row-cols-md-4 g-4">
                    <FormFieldMicro
                        type="text"
                        name="name"
                        label="Name"
                        value={props.workingVendor.name}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={props.isSaving}
                    />
                    <FormFieldMicro
                        type="text"
                        name="website"
                        label="Website"
                        value={props.workingVendor.website}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={props.isSaving}
                    />
                    <FormFieldMicro
                        type="text"
                        name="accountNumber"
                        label="Account #"
                        value={props.workingVendor.accountNumber}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={props.isSaving}
                    />
                    </div>
                    <hr/>
                    <div className="row section-outline mx-1">
                        <div className="col">
                            <h4 className="text-start">Contacts</h4>
                            {props.workingVendor.contacts.map((_, i) => <div key={i} className="row">
                                <div className="col">
                                    <ContactArea {...props} index={i} removeContactAtIndex={removeContactAtIndex} showDelete/>
                                </div>
                            </div>)}
                            <div className="row mt-2">
                                <div className="col d-flex flex-column">
                                    <button className="btn btn-success w-fit" onClick={addContact} disabled={props.isSaving}>
                                        <i className="fas fa-plus"/>&nbsp;Add Contact
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <h4 className="text-start">Products/Services</h4>
                    <FormFieldMicro
                        type="textarea"
                        name="productsServices"
                        label="Products/Services"
                        value={props.workingVendor.productsServices}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={props.isSaving}
                        fullWidth
                        resize='vertical'
                    />
                    </>
                :
                    <div className="text-start">
                    <h3>{props.savedVendor.name}</h3>
                    <p><strong>Account #:</strong>&nbsp;{props.savedVendor.accountNumber}</p>
                    <p><strong>Website:</strong>&nbsp;{websiteIsValid(props.savedVendor.website) ? <a href={props.savedVendor.website} target="_blank" rel="noopener noreferrer">{props.savedVendor.website}</a> : props.savedVendor.website}</p>
                    <h4>Contacts</h4>
                    <div className="row row-cols-1 row-cols-md-3 g-3">
                        {props.savedVendor.contacts.length > 0 ? 
                        <>
                        {props.savedVendor.contacts.map((contact, i) => 
                            <div className="section-outline" key={i}>
                                <i className="fas fa-address-book"/>&nbsp;<strong>{contact.first} {contact.last}</strong><br/>
                                <span className='text-muted'>{contact.title}</span><br/>
                                <i className="fas fa-phone"/>&nbsp;{chimera.phoneNumberStr(contact.phone)}{contact.ext ? ` (Ext. ${contact.ext})` : null}<br/>
                                <i className="fas fa-envelope"/>&nbsp;<a href={`mailto:${contact.email}`}>{contact.email}</a>
                                {contact.notes ? <><br/><br/><span>{contact.notes.replace(/\n/g, ' ').length > 20 ? contact.notes.replace(/\n/g, ' ').substring(0,20) + '...' : contact.notes.replace(/\n/g, ' ')}</span></> : null}
                            </div>
                        )}
                        </>
                        :
                        <p className="text-muted">No contacts for this vendor.</p>
                        }
                    </div>
                    <h4 className="mt-2">Products/Services</h4>
                    {props.savedVendor.productsServices.split('\n').map((p, i) => <p key={i}>{p}</p>)}
                    </div>
                }
            </div>
            <div className="col-3">
                {/** Sidebar */}
                {isEditing ? 
                    <div className="row">
                        {props.vendorId ? 
                        <div className="col">
                            <button className="btn btn-secondary w-100 mb-1" onClick={handleCancel} disabled={props.isSaving}>
                                <i className="fas fa-arrow-left"/>&nbsp;Cancel
                            </button>
                        </div>
                        :null}
                        <div className="col">
                            <button className="btn btn-primary w-100 mb-1" onClick={handleSave} disabled={props.saveDisabled}>
                                <i className={props.isSaving ? "fas fa-spinner" : "fas fa-floppy-disk"}/>&nbsp;{props.isSaving ? "Saving..." : "Save"}
                            </button>
                        </div>
                    </div>
                :
                    <button className="btn btn-warning w-100 mb-1" onClick={(e) => {e.preventDefault(); setIsEditing(true)}}>
                        <i className="fas fa-pencil"/>&nbsp;Edit
                    </button>
                }
            </div>
        </div>
    )
}

const VendorFormModal = props => {
    const DEFAULT_VENDOR = {
        name: '',
        accountNumber: '',
        website: '',
        contacts: [],
        productsServices: ''
    }
    const [workingVendor, setWorkingVendor] = useState(props.vendorId ? null : DEFAULT_VENDOR);
    const [savedVendor, setSavedVendor] = useState(null);
    const [modified, setModified] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [saveMode, setSaveMode] = useState(props.vendorId ? 'PUT' : 'POST');
    const modaling = useContext(ModalContext);

    useEffect(() => {
        if(props.vendorId) {
            chimera.callAPI(undefined, `/api/vendors/${props.vendorId}`)
            .then(vendor => {
                setWorkingVendor(vendor);
                setSavedVendor(vendor);
            })
        }
    }, [props.vendorId]);

    const saveDisabled = () => {
        if(isSaving) return true;
        if(saveMode === 'PUT') {
            return chimera.deepEqual(workingVendor, savedVendor);
        }
        else {
            return !modified;
        }
    }

    const handleSave = (resolve, reject, vendor) => {
        //event.preventDefault();
        setIsSaving(true);
        // On any save, the Check-In Date is pushed out 7 days.
        let body = JSON.parse(JSON.stringify(vendor ? vendor : workingVendor));
        let checkInDate = new Date();
        checkInDate.setDate(checkInDate.getDate() + 7);
        body.checkInDate = checkInDate.toISOString().substring(0,10);
        chimera.callAPI(undefined, `/api/vendors${saveMode === "PUT" ? `/${vendor ? vendor._id : workingVendor._id}` : ''}`, saveMode, body)
        .then(savedVendor => {
            setIsSaving(false); // this line present in both clauses BEFORE the resolve/reject is called prevents memory leaks if resolve/reject unmounts the component
            if(resolve) resolve(savedVendor);
        })
        .catch(err => {
            setIsSaving(false);
            if(reject) reject(err);
        });
    }

    const saveAndCloseFunc = (resolve, reject) => {
        const dismiss = (event) => {
            event.preventDefault();
            modaling.backtrack();
        }
        modaling.setModal(<Modal choices={[]} dismiss={dismiss}>
            <LoadingSpinner size={75}/>
        </Modal>)
        handleSave(resolve, reject);
    }

    const saveAndCloseResolve = (savedVendor) => {
        setWorkingVendor(savedVendor);
        setSavedVendor(savedVendor);
        setSaveMode('PUT');
        modaling.setModal(null);
    }

    const saveAndCloseReject = (err) => {
        console.error(err);
        alert("ERROR: Failed to save changes");
        modaling.backtrack();
    }

    const choices = [
        choiceCancel(props, !saveDisabled(), <>
            <h3>You have unsaved changes</h3>
            <p>Are you sure you want to close this form? Your changes will not be saved.</p>
        </>, "Close", {func: saveAndCloseFunc, resolve: saveAndCloseResolve, reject: saveAndCloseReject})
    ]

    if(saveMode === 'PUT') {
        choices.push(choiceDelete(props, () => {
            modaling.setModal(<Modal choices={[]} dismiss={(e) => {e.preventDefault(); modaling.setModal(null)}}>
                <LoadingSpinner size={75}/>
            </Modal>);
            chimera.callAPI(undefined, `/api/vendors/${savedVendor._id}`, 'DELETE')
            .then(_ => {
                props.banners.addBanner('info', 'The vendor has been deleted.', 'Success');
            })
            .catch(err => {
                console.error(err);
                //alert("ERROR: Failed to delete the vendor");
                props.banners.addBanner('danger', 'Failed to delete the vendor.', 'Error');
            })
            .finally(() => {
                props.onClose();
                modaling.setModal(null);
            })
        }, {label: "Delete Permanently"}))
    }

    return (
        <Modal choices={choices} dismiss={choices[0].func}>
            <BannerLog>
                {workingVendor && (props.vendorId ? savedVendor : true) ? 
                <VendorFormBody {...props} 
                    workingVendor={workingVendor} setWorkingVendor={setWorkingVendor} 
                    savedVendor={savedVendor} setSavedVendor={setSavedVendor}
                    modifed={modified} setModified={setModified}
                    isSaving={isSaving} setIsSaving={setIsSaving}
                    saveMode={saveMode} setSaveMode={setSaveMode}
                    saveDisabled={saveDisabled()}
                    handleSave={handleSave}
                    isEditing={props.vendorId ? false : true}
                />
                : <LoadingSpinner size={50}/>}
            </BannerLog>
        </Modal>
    )
}

export default VendorFormModal;