import React from 'react';

import chimera from '../../chimera';

const AddressDisplay = props => {
    const path = partial => {
        return props.basePath + '.' + partial;
    }

    const disabled = props.disabled || props.sameAs;

    return (
        <>
        {!props.isEditing ? 
            <>
            <div>
                {props.addr.street1.trim()}
                {props.addr.street2 ? 
                    <>, {props.addr.street2.trim()}</>
                :null}
                <br/>
                {props.addr.city.trim()}<br/>{props.addr.county.trim()} County<br/>{props.addr.state.trim()}, {props.addr.zip.trim()}
            </div>
            </>
        :
        <>
            {props.sameAsLabel ? 
            <div className="form-check d-flex justify-content-center">
                <input className="form-check-input" type="checkbox" name={props.sameAsPath} id={props.sameAsPath} checked={props.sameAs} onChange={props.onChange} disabled={props.disabled}/>
                &nbsp;
                <label className="form-check-label" htmlFor={props.sameAsPath}>
                    {props.sameAsLabel}
                </label>
            </div>
            :null}
            <div className="row mb-1">
                <div className="col">
                    <input type="text" className="form-control" placeholder="Street" name={path('street1')} value={chimera.getAttr(props.baseValue, path('street1'))} onChange={props.onChange} disabled={disabled} onBlur={props.onBlur}/>
                </div>
            </div>
            <div className="row mb-1">
                <div className="col">
                    <input type="text" className="form-control" placeholder="Street 2 (optional)" name={path('street2')} value={chimera.getAttr(props.baseValue, path('street2'))} onChange={props.onChange} disabled={disabled} onBlur={props.onBlur}/>
                </div>
            </div>
            <div className="row mb-1">
                <div className="col">
                    <input type="text" className="form-control" placeholder="City" name={path('city')} value={chimera.getAttr(props.baseValue, path('city'))} onChange={props.onChange} disabled={disabled} onBlur={props.onBlur}/>
                </div>
                <div className="col">
                    <input type="text" className="form-control" placeholder="County" name={path('county')} value={chimera.getAttr(props.baseValue, path('county'))} onChange={props.onChange} disabled={disabled} onBlur={props.onBlur}/>
                </div>
            </div>
            <div className="row mb-1">
                <div className="col-8">
                    <select className="w-100 form-select" name={path('state')} onChange={props.onChange} disabled={disabled} value={chimera.getAttr(props.baseValue, path('state')) ? chimera.getAttr(props.baseValue, path('state')) : "WA"}>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                    </select>
                </div>
                <div className="col-4">
                    <input type="text" className="form-control" placeholder="ZIP" name={path('zip')} value={chimera.getAttr(props.baseValue, path('zip'))} onChange={props.onChange} maxLength={5} disabled={disabled} onBlur={props.onBlur}/>
                </div>
            </div>
        </>
        }
        </>
    )
}

export default AddressDisplay;