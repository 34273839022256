import React, {useState} from "react";
import auditingimg from "../../images/auditingimg.png";
import ToolPage from './ToolPage';
import EndpointAuditing from "./Auditing/EndpointAuditing";
import PhoneNumberAuditing from "./Auditing/PhoneNumberAuditing";
import DuoAuditing from "./Auditing/DuoAuditing";
import InvoiceAuditing from "./Auditing/InvoiceAuditing";

const Auditing = props => {
    let now = new Date();
    now.setMinutes(now.getMinutes()-now.getTimezoneOffset());
    let currentMonth = now.toISOString().split('-')[1];
    let currentYear = now.toISOString().split('-')[0];
    let firstOfTheMonth = `${currentYear}-${currentMonth}-01`;

    const [startDate, setStartDate] = useState(firstOfTheMonth);
    const [endDate, setEndDate] = useState(now.toISOString().substring(0,10));
    const [accountingMethod, setAccountingMethod] = useState("Accrual");
    const [showEndpointAuditing, setShowEndpointAuditing] = useState(false);
    const [showPhoneNumberAuditing, setShowPhoneNumberAuditing] = useState(false);
    const [showDuoAuditing, setShowDuoAuditing] = useState(false);
    const [showInvoiceAuditing, setShowInvoiceAuditing] = useState(false);

    const toolName = "Auditing";
    const toolId = "audit";
    return (
        <ToolPage toolId={toolId} toolName={toolName}>
            <ToolPage.Header image={auditingimg} alt="placeholder image" toolName={toolName}>
                This page contains a selection of auditing tools each dedicated to a specific product or service
                to ensure that CBIT is operating optimally.
            </ToolPage.Header>
            <ToolPage.How>
                <h3>
                    Endpoints
                </h3>
                <p>
                    This section refers to endpoint management currently fulfilled through Bitdefender as managed through GravityZone.
                </p>
                <h4>
                    What It Does
                </h4>
                <p>
                    Below is a breakdown of the steps the job will take as it works to produce the final report:
                </p>
                <ol>
                    <li>
                        All MSP customers are pulled from Chimera's database.
                    </li>
                    <li>
                        A query is made to QuickBooks to pull information about the Endpoint Packages (Silver, Gold, and Platinum), which is
                        used for future queries to make comparisons.
                    </li>
                    <li>
                        For each MSP customer, detailed information about all of their endpoints in GravityZone is pulled.
                    </li>
                    <li>
                        For each endpoint, its details can be analyzed to determine whether it is configured as a Silver, Gold, or Platinum endpoint.
                    </li>
                    <li>
                        Then, the Chimera customer's QuickBooks ID and the information about the QuickBooks products pulled in step 2 are used to
                        pull the recurring transactions for that customer. The recurring transactions are read and any line items charging for endpoint protection
                        are counted up.
                    </li>
                    <li>
                        All of this information is used to generate a report essentially comparing the charges in QuickBooks to the reality in
                        GravityZone to help ensure that customers are being charged for what they are getting and getting what they are being charged for.
                    </li>
                </ol>
                <h4>
                    What It Means
                </h4>
                <p>
                    More than any other tool included in this suite, the Endpoint Auditing utility generates a report containing a lot of information.
                    What everything means may not be obvious at first glance, so here is a comprehensive breakdown:
                </p>
                <ol>
                    <li>
                        <strong>Customer</strong> column: This simply shows the Chimera customer's Display Name.
                    </li>
                    <li>
                        <strong>Totals <i className="fas fa-arrow-right"/> Syncro</strong> column: This is a count indicating the number of assets this customer has in Syncro.
                        It can be useful as a means of ensuring that all Syncro assets are protected by Bitdefender as a part of this audit.
                    </li>
                    <li>
                        <strong>Totals <i className="fas fa-arrow-right"/> Bitdefender</strong> column: This is a count indicating the number of assets this customer has in Bitdefender.
                        This should always match the total number of managed endpoints shown in the GravityZone portal when viewing the Executive Summary for a customer.
                    </li>
                    <li>
                        <strong>Totals <i className="fas fa-arrow-right"/> Sum Counted</strong> column: This is the sum of detected Platinum, Gold, and Silver devices in Bitdefender.
                        Which devices are Platinum, Gold, or Silver is determined algorithmically depending on each device's configuration, so if an endpoint is misconfigured then it may not
                        be counted as either one, and this total will be less than the <strong>Totals <i className="fas fa-arrow-right"/> Bitdefender</strong> column.
                    </li>
                    <li>
                        <strong>Totals <i className="fas fa-arrow-right"/> Active</strong> column: This is a count indicating the number of endpoints in Bitdefender that have phoned
                        home within the last 7 days as of running the report. This should match the number of Active endpoints shown in the Exeutive Summary for a customer in GravityZone.
                    </li>
                    <li>
                        <strong>Servers</strong> column: The <strong>BD</strong> column shows the number of detected servers in Bitdefender. The <strong>QB</strong> column shows
                        the number of servers detected in recurring transactions from QuickBooks.
                    </li>
                    <li>
                        <strong>Plat/Gold/Silv</strong> columns: The <strong>BD</strong> column shows the number of detected Plat/Gold/Silv packages in Bitdefender.
                        The <strong>QB</strong> column shows the number of Plat/Gold/Silv packages detected in recurring transactions from QuickBooks.
                    </li>
                </ol>
                <h4>
                    Why It Takes So Long
                </h4>
                <p>
                    Unfortunately, due to the way that Bitdefender has designed their API, the necessary information on an endpoint must be retrieved by using
                    a HTTP request specific to that endpoint. That means that for every endpoint in Bitdefender that factors in to this report, a separate API call
                    is made. Computers are very fast, but the Internet is comparitively very slow. That many requests going across the Internet just takes time.
                </p>
                <p>
                    This problem could potentially be addressed in the future by using multi-threading, where multiple API requests can be made in parallel.
                    That would be a careful effort, though, in order to comply with Bitdefender API rate limits, especially if more than 1 user were to run the
                    tool at a time. However, the sequential approach currently utilized, while slower, also reduces room for technical error and ensures data integrity.
                    So... sorry about the wait. Thank you for your patience.
                </p>
                <h3>
                    Phone Numbers
                </h3>
                <p>
                    This section refers to the phone numbers in Unity and in each of CBIT's providers (Inteliquent, Bandwidth, and Telnyx).
                </p>
                <h4>
                    What It Does
                </h4>
                <p>
                    Below is a breakdown of the steps the job will take as it works to produce the final report:
                </p>
                <ol>
                    <li>
                        Pull all phone numbers from Unity. This only considers those stored under the "DID Table" Dial Plan.
                    </li>
                    <li>
                        Pull all phone numbers from Inteliquent.
                    </li>
                    <li>
                        Pull all phone numbers from Bandwidth.
                    </li>
                    <li>
                        Pull all phone numbers from Telnyx.
                    </li>
                    <li>
                        For each number in Unity, find how many "homes" it has, where a "home" is the provider its registered under. Every number should have exactly
                        1 home.
                    </li>
                    <li>
                        For each number in Inteliquent, see if it is also in Unity.
                    </li>
                    <li>
                        For each number in Bandwidth, see if it is also in Unity.
                    </li>
                    <li>
                        For each number in Telnyx, see if it is also in Unity.
                    </li>
                </ol>
                <h4>
                    What It Means
                </h4>
                <p>
                    If a problem is detected, the number is added to one of 5 potential lists. If a list has no entries, then the list is omitted entirely. Here are the possible lists:
                </p>
                <ol>
                    <li>
                        <strong>Homeless Unity Numbers</strong> are numbers in Unity but not in any of CBIT's providers.
                    </li>
                    <li>
                        <strong>Multi-Home Unity Numbers</strong> are numbers in Unity that exist in multiple providers at once. This anomaly should never occur but,
                        in case it ever does, this list will catch it.
                    </li>
                    <li>
                        <strong>Inteliquent Numbers Not In Unity</strong> are numbers in Inteliquent that are not in Unity under the DID Table Dial Plan.
                    </li>
                    <li>
                        <strong>Bandwidth Numbers Not In Unity</strong> are numbers in Bandwidth that are not in Unity under the DID Table Dial Plan.
                    </li>
                    <li>
                        <strong>Telnyx Numbers Not In Unity</strong> are numbers in Telnyx that are not in Unity under the DID Table Dial Plan.
                    </li>
                </ol>
                <h4>
                    Why It Takes No Time At All
                </h4>
                <p>
                    Contrasted to the Endpoint Auditing's many API requests (especially including 1 per endpoint), this job requires just 4. As more numbers
                    are added over time, it's possible that additional requests will need to be made, since APIs only return so much info at once, but not many more.
                    Then, all the algorithmic work is done on the front-end and, thankfully, computers are very, very fast.
                </p>
                <h3>
                    Duo
                </h3>
                <p>
                    This section refers to the Duo MFA utility.
                </p>
                <h4>
                    What It Does
                </h4>
                <p>
                    Below is a breakdown of the steps the job will take as it works to produce the final report:
                </p>
                <ol>
                    <li>
                        Pull the Summary and Edition for the main account. This has to be done separately, as the main account is not included
                        in the list of accounts returned from the Duo API (it only returns subaccounts). The Summary includes how many users are under the account,
                        which Duo's price model is based on. The Edition determines the per-user charge.
                    </li>
                    <li>
                        Pull the list of subaccounts from Duo.
                    </li>
                    <li>
                        Pull the list of Chimera customers.
                    </li>
                    <li>
                        Pull the list of QuickBooks items named "Duo MFA", "Duo Access", and "Duo Beyond".
                    </li>
                    <li>
                        For every subaccount, its Summary and Edition are pulled from Duo.
                    </li>
                    <li>
                        Then the subaccount is matched with a Chimera customer. The Account ID in Duo is used as the Integration ID included
                        in the Chimera customer's object schema.
                    </li>
                    <li>
                        With the Chimera customer identified, their QuickBooks Integration ID is used to pull all of their recurring invoices from
                        QuickBooks.
                    </li>
                    <li>
                        All recurring invoices are combed and the totals of Duo products are calculated. If there are any Duo products sold that are the
                        wrong edition, an error is produced.
                    </li>
                    <li>
                        All of this information is presented in the report table that is generated.
                    </li>
                </ol>
                <h4>
                    What It Means
                </h4>
                <p>
                    The table speaks for itself. It shows the number of units purchased and their cost for each customer, as well as the
                    number of units sold and their price. Then the <strong>Difference</strong> column is used to show if CBIT is making money or losing money
                    with regards to that customer. The grand total is displayed in the final row.
                </p>
                <h3>
                    Invoices
                </h3>
                <p>
                    This is used for quickly listing invoices that have not yet been sent.
                </p>
                <h4>
                    Criteria
                </h4>
                <p>
                    Querying QuickBooks for "unsent" invoices is trickier than it might sound. What it really amounts to is asking QuickBooks for all Invoices that satisfy all of the following criteria:
                </p>
                <ol>
                    <li>
                        The invoice lacks a status that indicates it has been sent. There is no "unsent" status, it would just lack a status altogether.
                    </li>
                    <li>
                        The invoice has not been paid. Even status-less invoices can still be set as paid.
                    </li>
                    <li>
                        The invoice has not been voided. Even status-less invoices can still be voided.
                    </li>
                    <li>
                        The total amount due for the invoice is greater than $0. Even a non-paid, non-void, status-less invoice can still be worth ignoring
                        if there is no charge to collect.
                    </li>
                </ol>
            </ToolPage.How>
            <ToolPage.Body>
                <div className="col-lg-12">
                    <h2>
                        Endpoints
                    </h2>
                    {showEndpointAuditing ? 
                        <EndpointAuditing startDate={startDate} endDate={startDate} accountingMethod={accountingMethod}/>
                    :
                        <button className="btn btn-primary" onClick={(event) => {event.preventDefault(); setShowEndpointAuditing(true)}}>
                            <i className="fas fa-arrow-right"/>
                            &nbsp; Start Audit
                        </button>
                    }
                    <h2 className="mt-5 mb-2">
                        Phone Numbers
                    </h2>
                    {showPhoneNumberAuditing ? 
                        <PhoneNumberAuditing/>
                    :
                        <button className="btn btn-primary" onClick={(event) => {event.preventDefault(); setShowPhoneNumberAuditing(true)}}>
                            <i className="fas fa-arrow-right"/>
                            &nbsp; Start Audit
                        </button>
                    }
                    <h2 className="mt-5 mb-2">
                        Duo
                    </h2>
                    {showDuoAuditing ?
                        <DuoAuditing/>
                    : 
                        <button className="btn btn-primary" onClick={(event) => {event.preventDefault(); setShowDuoAuditing(true)}}>
                            <i className="fas fa-arrow-right"/>
                            &nbsp; Start Audit
                        </button>
                    }
                    <h2 className="mt-5 mb-2">
                        Invoices
                    </h2>
                    {showInvoiceAuditing ?
                        <InvoiceAuditing/>
                    : 
                        <button className="btn btn-primary" onClick={(event) => {event.preventDefault(); setShowInvoiceAuditing(true)}}>
                            <i className="fas fa-arrow-right"/>
                            &nbsp; Start Audit
                        </button>
                    }
                </div>
            </ToolPage.Body>
        </ToolPage>
    );
}

export default Auditing;