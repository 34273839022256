import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';

import LoadingSpinner from '../../LoadingSpinner';
import BannerLogContext from '../../BannerLogContext';
import chimera from '../../../chimera';

const PhoneNumberAuditing = props => {

    const [showProgressBar, setShowProgressBar] = useState(true);
    const [progressBarLabel, setProgressBarLabel] = useState("Working...");
    const [homelessUnityNumbers, setHomelessUnityNumbers] = useState([]);
    const [multiHomeUnityNumbers, setMultiHomeUnityNumbers] = useState([]);
    const [inteliquentNumbersNotInUnity, setInteliquentNumbersNotInUnity] = useState([]);
    const [bandwidthNumbersNotInUnity, setBandwidthNumbersNotInUnity] = useState([]);
    const [telnyxNumbersNotInUnity, setTelnyxNumbersNotInUnity] = useState([]);
    const [errorText, setErrorText] = useState("");
    const [controller, setController] = useState(new AbortController());
    const [signal, setSignal] = useState(controller.signal);
    const banners = useContext(BannerLogContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        runAudit();
    }, [])

    const runAudit = async() => {
        // 'DID Table' seems to hold all the relevant numbers.
        try {
            const unityNumbers = (await chimera.callAPI(signal, '/api/unity/dialplan/DID Table/phonenumbers')).map(n => {return {number: n.number.substring(1), description: n.description, enabled: n.enabled}});
            const inteliquentNumbers = (await chimera.callAPI(signal, '/api/inteliquent/phonenumbers')).map(n => {return {number: n.tn, status: n.tnStatus}});
            const bandwidthNumbers = await chimera.callAPI(signal, '/api/bandwidth/tns');
            const telnyxNumbers = (await chimera.callAPI(signal, '/api/telnyx/tns')).map(n => {return {number: n.number.substring(2), status: n.status}});
    
            let unityNumberHomeCounts = [];
            for(const number of unityNumbers) {
                let homes = [];
                for(const n of inteliquentNumbers) {
                    if(number.number === n.number) {
                        homes.push('Inteliquent');
                        break;
                    }
                }
                for(const n of bandwidthNumbers) {
                    if(number.number === n.number) {
                        homes.push('Bandwidth');
                        break;
                    }
                }
                for(const n of telnyxNumbers) {
                    if(number.number === n.number) {
                        homes.push('Telnyx');
                        break;
                    }
                }
                unityNumberHomeCounts.push({
                    number: number,
                    homes: homes
                });
            }
            let homelessUnityNumbers = [];
            let multiHomeUnityNumbers = [];
            for(const n of unityNumberHomeCounts) {
                if(n.homes.length === 0)
                    homelessUnityNumbers.push(n.number);
                else if(n.homes.length > 1) 
                    multiHomeUnityNumbers.push(n);
            }
    
            let inteliquentNumbersNotInUnity = [];
            let bandwidthNumbersNotInUnity = [];
            let telnyxNumbersNotInUnity = [];
    
            for(const number of inteliquentNumbers) {
                let found = false;
                for(const n of unityNumbers) {
                    if(number.number === n.number) {
                        found = true;
                        break;
                    }
                }
                if(!found) {
                    inteliquentNumbersNotInUnity.push(number);
                }
            }
    
            for(const number of bandwidthNumbers) {
                let found = false;
                for(const n of unityNumbers) {
                    if(number.number === n.number) {
                        found = true;
                        break;
                    }
                }
                if(!found) {
                    bandwidthNumbersNotInUnity.push(number);
                }
            }
    
            for(const number of telnyxNumbers) {
                let found = false;
                for(const n of unityNumbers) {
                    if(number.number === n.number) {
                        found = true;
                        break;
                    }
                }
                if(!found) {
                    telnyxNumbersNotInUnity.push(number);
                }
            }

            setHomelessUnityNumbers(homelessUnityNumbers);
            setMultiHomeUnityNumbers(multiHomeUnityNumbers);
            setInteliquentNumbersNotInUnity(inteliquentNumbersNotInUnity);
            setBandwidthNumbersNotInUnity(bandwidthNumbersNotInUnity);
            setTelnyxNumbersNotInUnity(telnyxNumbersNotInUnity);
            setShowProgressBar(false);
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                let message;
                if(e.status === 500) {
                    const response = e.details;
                    message = `${response} (status code ${e.status})`;
                }
                else {
                    message = "An unhandled exception has occurred. The job cannot continue.";
                }
                banners.addBanner('danger', <span><strong>Phone Number Auditing:</strong> {message}</span>);
                setErrorText("A problem has occurred. The results cannot be loaded.");
                setShowProgressBar(false);
            }
        }
    }

    const phoneNumberStr = (n) => {
        const first3 = n.substring(0,3);
        const second3 = n.substring(3,6);
        const last4 = n.substring(6);
        return `(${first3}) ${second3}-${last4}`
    }

    const renderResults = () => {
        if(errorText) {
            return <div>
                <p className="text-danger">{errorText}</p>
            </div>
        }
        else {
            return <div>
                {homelessUnityNumbers.length > 0 ?
                    <>
                        <h4>
                            "Homeless" Unity Numbers
                        </h4>
                        <ol className="text-start">
                            {homelessUnityNumbers.map(n => <li>{phoneNumberStr(n.number)} <i className="text-muted">({n.description ? n.description : "no description"})</i></li>)}
                        </ol>
                    </>
                :null}
                {multiHomeUnityNumbers.length > 0 ? 
                    <>
                        <h4>
                            "Multi-Home" Unity Numbers
                        </h4>
                        <ol className="text-start">
                            {multiHomeUnityNumbers.map(n => <li>{phoneNumberStr(n.number.number)} <i className="text-muted">({n.number.description ? n.number.description : "no description"}) ({n.homes.join(', ')}</i>)</li>)}
                        </ol>
                    </>
                :null}
                {inteliquentNumbersNotInUnity.length > 0 ?
                    <>
                        <h4>
                            Inteliquent Numbers not in Unity
                        </h4>
                        <ol className="text-start">
                            {inteliquentNumbersNotInUnity.map(n => <li>{phoneNumberStr(n.number)}</li>)}
                        </ol>
                    </>
                :null}
                {bandwidthNumbersNotInUnity.length > 0 ?
                    <>
                        <h4>
                            Bandwidth Numbers not in Unity
                        </h4>
                        <ol className="text-start">
                            {bandwidthNumbersNotInUnity.map(n => <li>{phoneNumberStr(n.number)}</li>)}
                        </ol>
                    </>
                :null}
                {telnyxNumbersNotInUnity.length > 0 ?
                    <>
                        <h4>
                            Telnyx Numbers not in Unity
                        </h4>
                        <ol className="text-start">
                            {telnyxNumbersNotInUnity.map(n => <li>{phoneNumberStr(n.number)}</li>)}
                        </ol>
                    </>
                :null}
                {homelessUnityNumbers.length === 0 &&
                multiHomeUnityNumbers.length === 0 && 
                inteliquentNumbersNotInUnity.length === 0 &&
                bandwidthNumbersNotInUnity.length === 0 &&
                telnyxNumbersNotInUnity.length === 0 ? 
                    <i className="text-start text-muted">No problems detected. Everything seems to be in proper order.</i>
                :null}
            </div>;
        }
    }

    return(
        <>
            {showProgressBar ? <LoadingSpinner size={100} label={progressBarLabel}/> : <>{renderResults()}</>}
        </>
    )
}

export default PhoneNumberAuditing;