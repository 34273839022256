import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';

import LoadingSpinner from '../../LoadingSpinner';
import BannerLogContext from '../../BannerLogContext';
import chimera from '../../../chimera';

const DuoAuditing = props => {
    const [showProgressBar, setShowProgressBar] = useState(true);
    const [progressBarLabel, setProgressBarLabel] = useState("Working...");
    const [tableData, setTableData] = useState([]);
    const [errorText, setErrorText] = useState("");
    const [controller, setController] = useState(new AbortController());
    const [signal, setSignal] = useState(controller.signal);
    const banners = useContext(BannerLogContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        runAudit();
    }, [])

    const runAudit = async() => {
        try {
            const accounts = await chimera.callAPI(signal, '/api/duo/accounts');
            const mainSummary = await chimera.callAPI(signal, '/api/duo/summary');
            const mainEdition = await chimera.callAPI(signal, '/api/duo/edition', 'GET', null, 'text');
            const customers = await chimera.CommercialCustomer.getAll(signal);
            let messages = [];
            let pairs = [];
            const items = await chimera.callQuickBooksAPI(signal, '/api/qb/items', 'POST', {
                whitelist: [
                    'Duo MFA',
                    'Duo Access',
                    'Duo Beyond'
                ]
            });
            for(const item of items) {
                if(item.item === editionToProduct(mainEdition)) {
                    pairs.push({
                        account: null,
                        customer: null,
                        summary: mainSummary,
                        edition: mainEdition,
                        item: item
                    });
                    break;
                }
            }
            for(const account of accounts) {
                const summary = await chimera.callAPI(signal, `/api/duo/accounts/${account.account_id}/summary`);
                const edition = (await chimera.callAPI(signal, `/api/duo/accounts/${account.account_id}/edition`)).edition;
                const customer = await customers.find(customer => customer.integrationIds.duo === account.account_id);
                if(!customer) {
                    messages.push(<span><strong>Duo Auditing:</strong> No Chimera customer linked with Duo Account ID {account.account_id} (Account Name: {account.name})</span>)
                }
                else {
                    let item = {};
                    for(const i of items) {
                        if(i.item === "Duo MFA" && edition === "ENTERPRISE") {
                            item = i;
                            break;
                        }
                        else if(i.item === "Duo Access" && edition === "PLATFORM") { // TODO: Verify these are linked
                            item = i;
                            break;
                        }
                        else if(i.item === "Duo Beyond" && edition === "BEYOND") {
                            item = i;
                            break;
                        }
                    }
                    const recurringTransactions = await chimera.getRecurringTransactions(signal, customer.integrationIds.quickbooks, 'Invoice', 'all', false);
                    console.log(recurringTransactions);
                    let quantity = 0;
                    for(const transaction of recurringTransactions) {
                        if(transaction.Invoice && transaction.Invoice.RecurringInfo.Active) {
                            for(const line of transaction.Invoice.Line) {
                                if(line.SalesItemLineDetail && line.SalesItemLineDetail.ItemRef.value === item.id) {
                                    quantity += line.SalesItemLineDetail.Qty;
                                }
                                else if(line.SalesItemLineDetail && items.find(i => i.id === line.SalesItemLineDetail.ItemRef.value)) {
                                    // Detected a Duo product of a different edition
                                    banners.addBanner('danger', <span><strong>Duo Auditing:</strong> {customer.displayName}'s recurring invoice (template name: {transaction.Invoice.RecurringInfo.Name}) has a line item for a mismatched Duo edition (should always be {editionToProduct(edition)})</span>)
                                }
                            }
                        }
                    }
                    item.quantity = quantity;
                    pairs.push({
                        account: account,
                        customer: customer,
                        summary: summary,
                        edition: edition,
                        item: item
                    })
                }
            }
            console.log(pairs);
            banners.addBanners('warning', messages);
            setTableData(pairs);
        }
        catch(e) {
            if(e.name !== "AbortError") {
                banners.addBanner('danger', <span><strong>Duo Auditing:</strong> An error occurred and the job cannot be completed.</span>);
                console.error(e);
                setErrorText("A problem has occurred. The results cannot be loaded.")
            }
        }
        setShowProgressBar(false);
    }

    const editionToProduct = (edition) => {
        if(edition === 'ENTERPRISE')
            return "Duo MFA";
        else if(edition === 'PLATFORM')
            return "Duo Access";
        else if(edition === 'BEYOND')
            return "Duo Beyond";
        else
            return "";
    }

    const difference = (collection) => {
        const duoTotal = collection.summary.user_count * collection.item.cost;
        if(collection.customer) {
            const qbTotal = collection.item.quantity * collection.item.price;
            return qbTotal - duoTotal;
        }
        return -duoTotal;
    }

    const differenceTotal = () => {
        let net = 0;
        for(const collection of tableData) {
            net += difference(collection);
        }
        return net;
    }

    return(
        <>
            {showProgressBar ? <LoadingSpinner size={100} label={progressBarLabel}/>
            :
            <>
                {errorText !== "" ? 
                    <p className="text-danger">{errorText}</p>
                :
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Customer</th>
                                <th>Edition</th>
                                <th>Duo (quantity @ price)</th>
                                <th>QB (quantity @ price)</th>
                                <th>Difference</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((collection) => <tr>
                                <td>{collection.customer ? collection.customer.displayName : "(Main CBIT Account)"}</td>
                                <td>{collection.customer ? collection.item.item : editionToProduct(collection.edition)}</td>
                                <td>{collection.summary.user_count} @ {chimera.dollarStr(collection.item.cost)}</td>
                                <td>{collection.customer ? `${collection.item.quantity} @ ${chimera.dollarStr(collection.item.price)}` : "(N/A)"}</td>
                                <td className={difference(collection) > 0 ? "text-success" : "text-danger"}>{chimera.dollarStr(difference(collection))}</td>
                            </tr>)}
                            <tr>
                                <td colSpan={4} className="text-end">
                                    <strong>Total Difference:</strong>
                                </td>
                                <td className={differenceTotal() > 0 ? "text-success" : "text-danger"}>
                                    {chimera.dollarStr(differenceTotal())}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
            </>}
        </>
    )
}

export default DuoAuditing;