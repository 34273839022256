import React from 'react';

const FormField = props => {
    const descr = `${props.name}Descr`;
    let options = [];
    const disabled = props.disabled || props.sameAs;
    if(props.type === "select") {
        if(!props.excludeNoneSelected)
            options.push(<option key={-1} value="NOT SET">-- None Selected --</option>);
        for(const opt of props.options) {
            options.push(<option key={opt.id} value={opt.id}>{opt.value.trim()}</option>);
        }
    }
    let input;
    if(props.type === "select") {
        input = 
            <>
            <br/>
            <select className="form-select centered" value={props.value} name={props.name} onChange={props.handleChange} required={props.required} disabled={disabled}>
                {options}
            </select>
            </>;
    }
    else if(props.type === "textarea") {
        input = 
            <>
            <br/>
            <textarea className="form-control centered" value={props.value} name={props.name} onChange={props.handleChange} disabled={disabled} required={props.required} style={{resize: "both"}} placeholder={props.placeholder ? props.placeholder : ""} maxLength={props.maxLength ? props.maxLength : 524288}/>
            </>;
    }
    else if(props.type === "file") {
        input =
        <input id={props.name} name={props.name}  
            className="form-control centered" type={props.type} 
            aria-describedby={descr} 
            onChange={props.handleChange}
            onBlur={typeof(props.onBlur) === "function" ? props.onBlur : () => {}}
            required={props.required}
            disabled={disabled}/>;
    }
    else if(props.type === "number") {
        input =
        <input id={props.name} name={props.name}
            className="form-control centered" type={props.type}
            aria-describedby={descr}
            value={props.value} 
            onChange={props.handleChange}
            onBlur={typeof(props.onBlur) === "function" ? props.onBlur : () => {}}
            required={props.required}
            min={props.min !== undefined ? props.min : null}
            max={props.max !== undefined ? props.max : null}
            step={props.step !== undefined ? props.step : null}
            disabled={disabled}
            />;
    }
    else if(props.type === "checkbox") {
        input = 
        <div className="form-check">
            <input id={props.name} name={props.name}
                className="form-check-input float-none" type={props.type}
                aria-describedby={descr}
                checked={props.value} 
                onChange={props.handleChange}
                onBlur={typeof(props.onBlur) === "function" ? props.onBlur : () => {}}
                required={props.required}
                disabled={disabled}/>&nbsp;
            <label className="form-check-label" htmlFor={props.name}>
                {props.checkLabel ? props.checkLabel : `${props.value}`.charAt(0).toUpperCase() + `${props.value}`.slice(1)}
            </label>
        </div>
    }
    else if(props.type === "range") {
        input = <>
            <input type="range" class="form-range" value={props.value} min={props.min} max={props.max} step={props.step} id={props.name} name={props.name} onChange={props.handleChange}/><br/>
            <span>{props.value}</span>
        </>
    }
    else if(props.type === "component") {
        input = <>{props.children}</>;
    }
    else {
        input =
            <input id={props.name} name={props.name}  
                className="form-control centered" type={props.type} 
                aria-describedby={descr}
                value={props.value} 
                onChange={props.handleChange}
                onBlur={typeof(props.onBlur) === "function" ? props.onBlur : () => {}}
                required={props.required}
                maxLength={props.maxLength ? props.maxLength : 524288}
                placeholder={props.placeholder ? props.placeholder : ""}
                disabled={disabled}/>;
    }
    return(
        <div className={props.classes ? props.classes : "form-field"}>
            <label htmlFor={props.name} className={props.fullWidth ? "form-label w-100" : "form-label"} style={props.type === "select" ? {width: "25%"} : {}}>
                {props.required ? <span className="red">*</span> : null} {props.label.trim()}:
                {props.sameAsLabel ? 
                <div className="form-check d-flex justify-content-center">
                    <input className="form-check-input" type="checkbox" name={props.sameAsPath} id={props.sameAsPath} checked={props.sameAs} onChange={props.handleChange} disabled={props.disabled}/>
                    &nbsp;
                    <label className="form-check-label" htmlFor={props.sameAsPath}>
                        {props.sameAsLabel}
                    </label>
                </div>
                :null}
                {input}
            </label>
            <div id={descr} className="form-text">
            {props.description}
            </div>
        </div>
    );
}

export default FormField;