import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';

import chimera from '../../chimera';
import LoadingSpinner from '../LoadingSpinner';
import BannerContext, {BannerLog} from '../BannerLogContext';
import ReportTable from '../ReportTable';

const ServerEndpointAuditBody = props => {
    const [phase, setPhase] = useState('start');
    const [report, setReport] = useState(null);
    const [spinnerLabel, setSpinnerLabel] = useState("");
    const [saveBtnLabel, setSaveBtnLabel] = useState("Save to Chimera");
    const [saveBtnIcon, setSaveBtnIcon] = useState("fas fa-floppy-disk");
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [controller] = useState(new AbortController());
    const [signal] = useState(controller.signal);
    const banners = useContext(BannerContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        if(phase.toLowerCase() === 'start') {
            setReport(null);
            setSaveBtnDisabled(false);
            setSaveBtnLabel('Save to Chimera');
            setSaveBtnIcon('fas fa-floppy-disk');
        }
    }, [phase]);

    const getSyncroAssets = async(customer, prevLabel) => {
        let totalAssets = [];
        let page = 1;
        let done = false;
        while(!done) {
            const response = await chimera.callSyncroAPI(signal, 'POST', '/assets', {customer_id: customer.integrationIds.syncro, page: page}, () => {setSpinnerLabel("Waiting out Syncro API limitations for 1 minute...")}, () => {setSpinnerLabel(prevLabel)})
            totalAssets = totalAssets.concat(response.assets);
            if(response.meta.total_pages <= page) {
                done = true;
            }
            page++;
        }
        return totalAssets.filter(asset => {
            if(!asset.properties.os) {
                console.log(asset);
                return false;
            }
            return asset.properties.os.toLowerCase().includes('server')
        }); // filter to servers only
    }

    const itemCount = (itemIds, recurringTransactions) => {
        // catch case where the same item sells for 2 different prices
        let qtyByPrice = {};
        for(const txn of recurringTransactions) {
            // only count invoices & sales receipts
            if(txn.type === "Invoice" || txn.type === "SalesReceipt") {
                for(const line of txn.doc.Line) {
                    if(line.DetailType === "SalesItemLineDetail") {
                        if(itemIds.includes(line.SalesItemLineDetail.ItemRef.value)) {
                            if(qtyByPrice[line.SalesItemLineDetail.UnitPrice]) {
                                qtyByPrice[line.SalesItemLineDetail.UnitPrice] += line.SalesItemLineDetail.Qty;
                            }
                            else {
                                qtyByPrice[line.SalesItemLineDetail.UnitPrice] = line.SalesItemLineDetail.Qty;
                            }
                        }
                    }
                }
            }
        }
        let strs = [];
        for(const price in qtyByPrice) {
            strs.push(`${qtyByPrice[price]}@$${parseFloat(price).toFixed(2)}`);
        }
        return strs.join(', ');
    }

    const totalItems = (itemIds, recurringTransactions) => {
        let total = 0;
        for(const txn of recurringTransactions) {
            // only count invoices & sales receipts
            if(txn.type === "Invoice" || txn.type === "SalesReceipt") {
                for(const line of txn.doc.Line) {
                    if(line.DetailType === "SalesItemLineDetail") {
                        if(itemIds.includes(line.SalesItemLineDetail.ItemRef.value)) {
                            total += line.SalesItemLineDetail.Qty;
                        }
                    }
                }
            }
        }
        return total;
    }

    const composeRow = (customer, recurringTransactions, assets) => {
        let ninetyDaysPrior = new Date();
        ninetyDaysPrior.setDate(ninetyDaysPrior.getDate() - 90);
        let row = {
            "Customer": customer.displayName,
            "Managed Server": itemCount(['851', '854'], recurringTransactions), // includes regular and YR forms
            "Syncro Assets": assets.length,
            "Syncro Sync <90 days": assets.filter(asset => ninetyDaysPrior.toISOString().substring(0,10) <  asset.rmm_store.general.info.last_synced_at.substring(0,10)).length,
            "Syncro Sync >90 days": assets.filter(asset => ninetyDaysPrior.toISOString().substring(0,10) >= asset.rmm_store.general.info.last_synced_at.substring(0,10)).length,
            "SentinelOne": assets.filter(asset => asset.properties.av.includes("Sentinel Agent")).length,
            "Total QB": totalItems(['851', '854'], recurringTransactions)
        }
        return row;
    }

    const run = async(event) => {
        event.preventDefault();
        const cols = [ // Currently hardcoding these items
            "Customer",
            "Managed Server",
            "Syncro Assets",
            "Syncro Sync <90 days",
            "Syncro Sync >90 days",
            "SentinelOne",
            "Total QB"
        ]
        let rows = [];
        let mspCustomers;
        let recurringTransactionsBatch;
        let etc = [];
        try {
            setPhase('loading');
    
            try {
                setSpinnerLabel("Reading MSP Customers from Chimera...");
                mspCustomers = (await chimera.callAPI(signal, '/api/customers')).map(customer => new chimera.CommercialCustomer(customer)).filter(customer => customer.serviceTypes.msp).sort((a, b) => a.displayName < b.displayName ? -1 : 1);
            }
            catch(err) {
                if(err.name !== "AbortError") {
                    console.error(err);
                    banners.addBanner('danger', `An unhandled exception occurred while reading Chimera Customers. The report cannot continue.`, 'Error');
                }
            }

            try {
                setSpinnerLabel("Reading Recurring Transactions from QuickBooks...");
                const customersNotIntegrated = mspCustomers.filter(customer => !customer.integrationIds.quickbooks);
                for(const customer of customersNotIntegrated) {
                    etc.push(`${customer.displayName} has no QuickBooks integration and cannot be included in this report`);
                }
                mspCustomers = mspCustomers.filter(customer => customer.integrationIds.quickbooks);
                recurringTransactionsBatch = await chimera.getRecurringTransactionsBatch(signal, mspCustomers.map(customer => customer.integrationIds.quickbooks), 'all');
                console.log(recurringTransactionsBatch);
            }
            catch(err) {
                if(err.name !== "AbortError") {
                    console.error(err);
                    banners.addBanner('danger', `An unhandled exception occurred while reading Recurring Transactions. The report cannot continue.`, 'Error');
                }
            }

            for(const customer of mspCustomers) {
                setSpinnerLabel(`Reading Syncro Assets for ${customer.displayName}`);
                if(!customer.integrationIds.syncro) {
                    etc.push(`${customer.displayName} has no Syncro integration and cannot be included in this report`);
                }
                else {
                    // Get all assets for one customer and complete their row in the report
                    // This way, we don't need to store all assets or all customers in memory at once
                    let assets;
                    try {
                        assets = await getSyncroAssets(customer, `Reading Syncro Assets for ${customer.displayName}`);
                    }
                    catch(err) {
                        console.error(err);
                        banners.addBanner('danger', 'An error occurred while reading Syncro Assets and the report cannot continue.', 'Error');
                        return;
                    }

                    try {
                        if(recurringTransactionsBatch[customer.integrationIds.quickbooks].length === 0) {
                            etc.push(`${customer.displayName} has no detected recurring transactions in QuickBooks`);
                        }
                        rows.push(composeRow(customer, recurringTransactionsBatch[customer.integrationIds.quickbooks], assets));
                    }
                    catch(err) {
                        console.error(err);
                        banners.addBanner('danger', 'An error occurred while composing the report; it cannot be created.', 'Error');
                        return;
                    }
                }
            }
    
            setSpinnerLabel("Compiling report...");
            setReport({type: "ServerEndpointAudit", cols: cols, rows: rows, etc: etc.length > 0 ? etc : undefined});
            setPhase('results');
        }
        catch(err) {
            if(err.name !== "AbortError") {
                console.error(err);
                banners.addBanner('danger', `An unhandled exception occurred. The report cannot continue.`, 'Error');
            }
        }
    }

    const saveReport = (event) => {
        event.preventDefault();
        setSaveBtnDisabled(true);
        setSaveBtnIcon("fas fa-spinner");
        setSaveBtnLabel("Saving...");
        chimera.callAPI(signal, '/api/reporttables', 'POST', report)
        .then(_ => {
            setSaveBtnIcon("fas fa-check");
            setSaveBtnLabel("Saved!");
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                console.error(err);
                banners.addBanner('danger', 'An error occurred and the report could not be saved', 'Error');
            }
            setSaveBtnDisabled(false);
            setSaveBtnIcon("fas fa-floppy-disk");
            setSaveBtnLabel("Save to Chimera");
        })
    }

    const phaseStart = () => {
        return <button className="btn btn-primary" onClick={run}>
            <i className="fas fa-play"/>&nbsp;Run Report
        </button>;
    }

    const phaseLoading = () => {
        return <LoadingSpinner size={75} label={spinnerLabel}/>;
    }

    const phaseResults = () => {
        return <>
            <button className="btn btn-primary mb-2" onClick={saveReport} disabled={saveBtnDisabled}>
                <i className={saveBtnIcon}/>
                &nbsp;{saveBtnLabel}
            </button>
            <ReportTable report={report}/>
        </>;
    }

    const presentPhase = (phase) => {
        switch(phase.toLowerCase()) {
            case 'start':
                return phaseStart();
            case 'loading':
                return phaseLoading();
            case 'results':
                return phaseResults();
            default:
                return null;
        }
    }

    return (
        <div>
            {presentPhase(phase)}
        </div>
    )
}

const ServerEndpointAudit = props => {
    return (
        <BannerLog>
            <ServerEndpointAuditBody/>
        </BannerLog>
    )
}

export default ServerEndpointAudit;