import React, {useState, useLayoutEffect, useContext} from "react";
import chimera from "../../chimera";
import cacnsimg from "../../images/cacnsimg.png";
import BannerLogContext from "../BannerLogContext";
import FormField from "../FormField";
import ToolPage from './ToolPage';

const RetrieveCACNsBody = props => {
    const now = new Date();
    const firstOfTheMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const [startDate, setStartDate] = useState(firstOfTheMonth.toISOString().substring(0,10));
    const [endDate, setEndDate] = useState(now.toISOString().substring(0,10));
    const [btnLabel, setBtnLabel] = useState("Submit");
    const [btnIcon, setBtnIcon] = useState("fas fa-arrow-right");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [cacns, setCacns] = useState([]);
    const [showForm, setShowForm] = useState(true);
    const [showResult, setShowResult] = useState(false);
    const [clipboardIcon, setClipboardIcon] = useState('fas fa-clipboard');
    const [clipboardText, setClipboardText] = useState("Copy to Clipboard");
    const [controller, setController] = useState(new AbortController());
    const [signal, setSignal] = useState(controller.signal);
    const banners = useContext(BannerLogContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    const handleSubmit = async(event) => {
        event.preventDefault();
        setBtnIcon("fas fa-spinner");
        setBtnLabel("Loading...");
        setBtnDisabled(true);
        try {
            const body = await chimera.callQuickBooksAPI(signal, `/api/qb/cacn/${startDate}/${endDate}`);
            const timeActivities = body.timeActivities;
            let cacns = [];
            for(const timeActivity of timeActivities) {
                if(timeActivity.cacn && !cacns.includes(timeActivity.cacn)) {
                    cacns.push(timeActivity.cacn);
                }
                else if(!timeActivity.cacn){
                    banners.addBanner('warning', `No CACN found for an entry completed on ${timeActivity.TxnDate} by ${timeActivity.EmployeeRef.name} for ${timeActivity.CustomerRef.name}. Please ensure the CACN is present and located at the very beginning of the description.`);
                }
            }
            setCacns(cacns);
            setShowForm(false);
            setShowResult(true);
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                alert("A problem occured and the CACNs could not be retrieved. Please try again later, or contact the site administrator if the problem persists.");
                setBtnIcon("fas fa-arrow-right");
                setBtnLabel("Submit");
                setBtnDisabled(false);
            }
        }
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if(name === "startDate")
            setStartDate(value);
        else if(name === "endDate")
            setEndDate(value);
    }

    const copyToClipboard = async(event) => {
        const text = cacns.join('\n');
        await navigator.clipboard.writeText(text);
        setClipboardIcon("fas fa-clipboard-check");
        setClipboardText("Copied!");
    }

    const renderCACNs = () => {
        let listItems = [];
        for(const cacn of cacns) {
            listItems.push(
                <li>
                    {cacn}
                </li>
            );
        }
        return (
            <ol>
                {listItems}
            </ol>
        )
    }

    return (
        <div className="col-lg-12">
            {showForm ?
            <form id="formCACN" onSubmit={handleSubmit} noValidate>
                <FormField
                    type="date"
                    name="startDate"
                    label="Start Date"
                    value={startDate}
                    description="All CACNs retrieved will be those that have been used on or after this date."
                    handleChange={handleChange}
                    required
                />
                <FormField
                    type="date"
                    name="endDate"
                    label="End Date"
                    value={endDate}
                    description="All CACNs retrieved will be those that have been used on or before this date."
                    handleChange={handleChange}
                    required
                />
                <button type="submit" className="btn btn-primary" disabled={btnDisabled}>
                    <i className={btnIcon}></i>
                    &nbsp;{btnLabel}
                </button>
            </form>
            : null}
            {showResult ? 
            <div>
                <h2>CACNs</h2>
                <p>
                    These are the CACNs used between {startDate} and {endDate}:
                </p>
                <button className="btn btn-primary" onClick={copyToClipboard}>
                    <i className={clipboardIcon}></i>
                    &nbsp;{clipboardText}
                </button>
                <div className="text-start">
                    {renderCACNs()}
                </div>
            </div>
            :null}
        </div>
    );
}

const RetrieveCACNs = props => {
    const toolName = "CACN Retrieval";
    const toolId = "cacns";
    return(
        <ToolPage toolId={toolId} toolName={toolName}>
            <ToolPage.Header image={cacnsimg} alt="placeholder image" toolName={toolName}>
                Get a list of all the CACNs used within a given time period.
            </ToolPage.Header>
            <ToolPage.How>
                <h3>Preparation</h3>
                <p>
                    This tool queries QuickBooks for customers whose names contain both "HMIS" and "Contract" (case-sensitive).
                    Please note that CACNs are pulled only from customers that meet this criteria.
                </p>
                <h3>Usage</h3>
                <p>
                    <ol>
                        <li>
                            <strong>Enter the Start Date.</strong> CACNs retrieved by this tool will include those used on or after this date.
                        </li>
                        <li>
                            <strong>Enter the End Date.</strong> CACNs retrieved by this tool will include those used on or before this date.
                        </li>
                        <li>
                            <strong>Click <i>Submit</i>.</strong> After a brief moment, the tool will display the CACNs used between the dates you provided.
                        </li>
                    </ol>
                </p>
            </ToolPage.How>
            <ToolPage.Body>
                <RetrieveCACNsBody authenticated={props.authenticated}/>
            </ToolPage.Body>
        </ToolPage>
    );
}

export default RetrieveCACNs;