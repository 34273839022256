import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import chimera from '../chimera';

const TaskTray = () => {
    const [response, setResponse] = useState('');

    useEffect(() => {
        const socket = io({
            auth: {
                token: 'abcd'
            }
        })

        socket.on('FromAPI', (data) => {
            setResponse(data);
        });
    }, []);

    return <p>{response}</p>
}

export default TaskTray;