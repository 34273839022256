import React, {useEffect, useState, useContext} from 'react';

import chimera from '../chimera.js';
import BannerContext, {BannerLog} from './BannerLogContext.js';
import LoadingSpinner from './LoadingSpinner.js';
import PatchNotesDisplay from './PatchNotesDisplay.js';

/**
 * A pagination interface
 * @param {number} props.page the selected page (1-indexed)
 * @param {function} props.setPage a function to set `props.page`
 * @param {number} props.nPages the number of pages
 */
 const Pagination = props => {
    let btns = [];
    for(let i = 1; i <= props.nPages; i++) {
        btns.push(
            <li className={i === props.page ? "page-item active" : "page-item"}>
                <a className="page-link" href="#" onClick={(event) => {event.preventDefault(); props.setPage(i)}}>
                    {i}
                </a>
            </li>
        )
    }
    return (
        <nav aria-label="Patch Notes page navigation">
            <ul className="pagination">
                <li className={props.page !== 1 ? "page-item" : "page-item disabled"}>
                    {props.page !== 1 ?
                        <a className="page-link" href="#" aria-label="Previous" onClick={(event) => {event.preventDefault(); props.setPage(props.page-1)}}>
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    :
                        <span className="page-link" aria-hidden="true">&laquo;</span>
                    }
                </li>
                {btns}
                <li className={props.page !== props.nPages ? "page-item" : "page-item disabled"}>
                    {props.page !== props.nPages ? 
                        <a className="page-link" href="#" aria-label="Next" onClick={(event) => {event.preventDefault(); props.setPage(props.page+1)}}>
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    :
                        <span className="page-link" aria-hidden="true">&raquo;</span>
                    }
                </li>
            </ul>
        </nav>
    )
}

const PatchNotesPageBody = props => {
    const [page, setPage] = useState(1);
    const [patchNotes, setPatchNotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const banners = useContext(BannerContext);
    const perPage = 5;

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        chimera.callAPI(signal, '/api/patchnotes')
        .then(notes => {
            setPatchNotes(notes);
            setLoading(false);
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                console.error(err);
                banners.addBanner('danger', 'Failed to retrieve patch notes', 'Error');
            }
        })
        return () => {
            controller.abort();
        }
    }, []);

    return (
        <>
            <h1>Patch Notes</h1>
            {loading ? 
                <LoadingSpinner size={75}/>
            :
                <>
                <Pagination page={page} nPages={Math.ceil(patchNotes.length / perPage)} setPage={setPage}/>
                {patchNotes.slice((page * perPage) - perPage, (page * perPage)).map(patchNote => <div className="row mb-1">
                    <div className="col">
                        <PatchNotesDisplay notes={patchNote}/>
                    </div>
                </div>)}
                </>
            }
        </>
    )
}

const PatchNotesPage = props => {
    return (
        <div className="container pb-5">
            <BannerLog>
                <PatchNotesPageBody/>
            </BannerLog>
        </div>
    )
}

export default PatchNotesPage;