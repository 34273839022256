import React, { useState, useEffect, useContext } from 'react';

import chimera from '../../chimera';
import BannerContext from '../BannerLogContext';
import LoadingSpinner from '../LoadingSpinner';
import TSPairInterface from '../TSPairInterface';

const TSPairsPanel = (props) => {
    const [tsPairs, setTSPairs] = useState(null);
    const banners = useContext(BannerContext);

    useEffect(() => {
        if(tsPairs === null) {
            chimera.callAPI(undefined, '/api/tspairs')
            .then(newPairs => setTSPairs(newPairs))
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to load T/S Pairs', 'Error');
            })
        }
    }, [tsPairs]);

    return(
        <>
        {tsPairs ? 
        <TSPairInterface tspairs={tsPairs}/>
        :
        <LoadingSpinner size={75} label="Loading T/S Pairs..."/>
        }
        </>
    )
}

export default TSPairsPanel;