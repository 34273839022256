import React, {useState, useEffect, useContext} from 'react';

import chimera from '../chimera';
import FiberLeadForm from './FiberLeadForm';
import ResidentialLeadForm from './ResidentialLeadForm';
import Modal from './Modal';
import ModalContext from './ModalContext';
import BannerContext, { BannerLog } from './BannerLogContext';
import LoadingSpinner from './LoadingSpinner';
import FiberLeadsTable from './FiberLeadsTable';
import ResidentialLeadsTable from './ResidentialLeadsTable';
import GenericLeadsTable from './GenericLeadsTable';
import GenericLeadForm from './GenericLeadForm';

const LeadsBody = props => {
    const modaling = useContext(ModalContext);
    const banners = useContext(BannerContext);
    const [leads, setLeads] = useState(null);
    const [type, setType] = useState("fiber");

    useEffect(() => {
        if(leads === null) {
            const controller = new AbortController();
            const signal = controller.signal;
            chimera.callAPI(signal, `/api/leads/type/${type}`)
            .then(leads => setLeads(leads))
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to get Leads', 'Error');
            })
        }
    }, [leads]);

    useEffect(() => {
        setLeads(null);
    }, [type]);

    const openLeadInModal = (lead) => {
        const choices = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                btnColor: 'secondary',
                func: (event) => {
                    event.preventDefault();
                    modaling.setModal(
                        <Modal choices={[]} dismiss={(event) => {event.preventDefault()}}>
                            <LoadingSpinner size={75}/>
                        </Modal>
                    );
                    // After closing in either case, refetch the list of leads.
                    if(type !== "generic") {
                        chimera.callAPI(undefined, `/api/leads/cleanup/type/${type}`)
                        .then(_ => {
                            modaling.setModal(null);
                            setLeads(null);
                        })
                        .catch(err => {
                            console.error(err);
                            modaling.setModal(null);
                            setLeads(null);
                        })
                    }
                    else {
                        modaling.setModal(null);
                        setLeads(null);
                    }
                }
            }
        ]
        const modal = 
        <Modal choices={choices} dismiss={choices[0].func}>
            <BannerLog>
                {type === "fiber" ? <FiberLeadForm lead={lead} setLeads={setLeads}/> : null}
                {type === "residential" ? <ResidentialLeadForm lead={lead} setLeads={setLeads}/> : null}
                {type === "generic" ? <GenericLeadForm lead={lead} setLeads={setLeads}/> : null}
            </BannerLog>
        </Modal>
        modaling.setModal(modal);
    }

    return (
        <>
            <h1>
                Service Orders
            </h1>
            <ul className="nav nav-tabs mb-3">
                <li className="nav-item">
                    <button className={type === "fiber" ? "btn nav-link active" : "btn nav-link"} onClick={(event) => {event.preventDefault(); setLeads(null); setType("fiber")}}>
                        Fiber
                    </button>
                </li>
                <li className="nav-item">
                    <button className={type === "residential" ? "btn nav-link active" : "btn nav-link"} onClick={(event) => {event.preventDefault(); setLeads(null); setType("residential")}}>
                        Residential
                    </button>
                </li>
                <li className="nav-item">
                    <button className={type === "generic" ? "btn nav-link active" : "btn nav-link"} onClick={(event) => {event.preventDefault(); setLeads(null); setType("generic")}}>
                        Generic
                    </button>
                </li>
            </ul>
            {type === "fiber" ? <FiberLeadsTable leads={leads} clickedLead={openLeadInModal} openLeadInModal={openLeadInModal}/> : null}
            {type === "residential" ? <ResidentialLeadsTable leads={leads} clickedLead={openLeadInModal} openLeadInModal={openLeadInModal}/> : null}
            {type === "generic" ? <GenericLeadsTable leads={leads} clickedLead={openLeadInModal} openLeadInModal={openLeadInModal}/> : null}
        </>
    )
}

const Leads = props => {
    return (
        <div className="container pb-5">
            <BannerLog>
                <LeadsBody/>
            </BannerLog>
        </div>
    )
}

export default Leads;