import React from 'react';
import chimera from "../chimera";

/**
 * A React component for editing a list of strings.
 * @param {Object} obj The object containing the list of strings to be edited
 * @param {String} basePath The path, starting from the root of the obj, that leads to the list of strings to be edited
 * @param {Function} handleChange A function responsible for processing the changes to the list of strings
 * @param {String} placeholder A string to display as a placeholder in the <input> tags.
 * @returns An interactive component for editing a list of strings.
 */
const StringList = props => {
    let obj = props.obj;
    let basePath = props.basePath;
    let placeholder = props.placeholder;
    const strings = chimera.getAttr(obj, basePath);

    const addString = (basePath) => {
        let newStrings = [];
        const strings = chimera.getAttr(obj, basePath);
        for(const str of strings) {
            newStrings.push(str);
        }
        newStrings.push("");

        props.handleChange({
            target: {
                type: "Array",
                name: basePath,
                value: newStrings
            },
            preventDefault: () => {}
        });
    }

    const deleteString = (basePath, index) => {
        let newStrings = [];
        const strings = chimera.getAttr(obj, basePath);
        for(let i = 0; i < strings.length; i++) {
            if(i !== index)
                newStrings.push(strings[i]);
        }

        props.handleChange({
            target: {
                type: "Array",
                name: basePath,
                value: newStrings
            },
            preventDefault: () => {}
        });
    }

    const trimOnBlur = (event) => {
        props.handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    return(
        <>
        {strings.map((str, i) => 
            <div className="mb-1 d-flex flex-row" key={i}>
                <input className="form-control" type="text" name={`${basePath}[${i}]`} value={str} placeholder={placeholder} onChange={props.handleChange} onBlur={trimOnBlur} disabled={props.disabled}/>
                {props.disabled ? null : <button className="btn btn-danger ms-2" onClick={(event) => {event.preventDefault(); deleteString(basePath, i)}}><i className="fas fa-minus"/></button>}
            </div>
        )}
        {props.disabled ? null : 
        <button className="btn btn-success" onClick={(event) => {event.preventDefault(); addString(basePath)}}>
            <i className="fas fa-plus"/>
        </button>
        }
        </>
    );
}

export default StringList;