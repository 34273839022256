import React, {useState, useEffect, useContext} from 'react';

import chimera from '../../chimera';
import BannerContext from "../BannerLogContext";

const UnityDevicesCounter = props => {
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const banners = useContext(BannerContext);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        chimera.callAPI(signal, `/api/unity/domains/${props.domain}/devices/total`)
        .then(total => {
            setCount(total);
            setLoading(false);
        })
        .catch(e => {
            if(e.name !== "AbortError") {
                console.error(e);
                banners.addBanner('danger', 'Failed to get Unity device count', 'Error');
            }
        })

        return () => {
            controller.abort();
        }
    }, []);

    return(
        <>
        {loading ?
            <i className="fas fa-spinner"/>
        :
            <>{count}</>
        }
        </>
    )
}

export default UnityDevicesCounter;