import React, {useState, useLayoutEffect, useContext} from "react";
import syncrocustomfieldsimg from "../../images/syncrocustomfieldsimg.png";
import ProgressBar from "../ProgressBar";
import ToolPage from './ToolPage';
import Modal from '../Modal';
import ModalContext from "../ModalContext";
import * as XLSX from 'xlsx';
import chimera from "../../chimera";

const CustomFields = props => {
    const [btnIcon, setBtnIcon] = useState('fas fa-arrow-right');
    const [btnLabel, setBtnLabel] = useState('Submit');
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [excelFile, setExcelFile] = useState(null);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [progress, setProgress] = useState(0);
    const [progressBarLabel, setProgressBarLabel] = useState("");
    const [showChoice, setShowChoice] = useState(true);
    const [showUploadPage, setShowUploadPage] = useState(false);
    const [showUploadForm, setShowUploadForm] = useState(true);
    const [uploadStatus, setUploadStatus] = useState("Uploading...");
    const [showDownloadPage, setShowDownloadPage] = useState(false);
    const [downloadStatus, setDownloadStatus] = useState("Preparing Download...");
    const [downloadBlob, setDownloadBlob] = useState(null);
    const [controller, setController] = useState(new AbortController());
    const [signal, setSignal] = useState(controller.signal);
    const modaling = useContext(ModalContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    const handleSubmit = async(event) => {
        event.preventDefault();
        const fileName = excelFile.name;
        if(fileName.endsWith(".xlsx")) {
            const reader = new FileReader();
            setShowUploadForm(false);
            setUploadStatus("Uploading...");
            reader.onload = async(evt) => {
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, {type: 'binary'});
    
                const wsName = wb.SheetNames[0];
                const ws = wb.Sheets[wsName];
    
                const data = XLSX.utils.sheet_to_json(ws);
                let entries = [];
                let ids = [];
                for(const obj of data) {
                    if(!ids.includes(obj.ID)) {
                        ids.push(obj.ID);
                        const entry = {ID: obj.ID.toString(), Customer: obj.Customer, Fields: []};
                        for(const key in obj) {
                            if(key.toString() !== "ID" && key.toString() !== "Customer") {
                                entry.Fields.push({
                                    key: key,
                                    value: obj[key].toString()
                                })
                            }
                        }
                        entries.push(entry);
                    }
                    else {
                        const choices = [
                            {
                                btnColor: 'primary',
                                btnInner: "Okay",
                                func: (event) => {
                                    event.preventDefault();
                                    modaling.setModal(null);
                                    window.location.reload();
                                }
                            }
                        ]
                        const modal = <Modal choices={choices} dismiss={choices[0].func}>
                            <h3>
                                Wait a minute!
                            </h3>
                            <p>
                                Error: The ID {obj.ID} is used more than once. All IDs must be unique.
                            </p>
                            <p>
                                Please edit your input file and try again. The page will refresh when this modal closes.
                            </p>
                            <p>
                                <b><u>NOTE:</u></b> If you'd like to assign multiple values to a single column for the same customer,
                                you can use a semicolon (';') as a divider. This will turn into a newline break in Syncro. Read the
                                "How does this work?" section for more information.
                            </p>
                        </Modal>
                        modaling.setModal(modal);
                        return;
                    }
                }
                try {
                    await chimera.callAPI(signal, '/api/customfields', 'PUT', {entries: entries});
                    setUploadStatus("Upload Complete!");
                    setBtnIcon("fas fa-arrow-right");
                    setBtnLabel("Update Syncro");
                }
                catch(e) {
                    if(e.name !== "AbortError") {
                        console.error(e);
                        alert(`ERROR: Failed to update entries`);
                    }
                    return;
                }
            };
            reader.readAsBinaryString(excelFile);
        }
        else {
            alert("File must be an Excel document (.xlsx)");
        }
    }

    const handleChange = (event) => {
        setExcelFile(event.target.files[0]);
    }

    const updateSyncro = async(event) => {
        // Get CustomFieldEntry objects and push them to Syncro one by one, updating a progress bar.
        event.preventDefault();
        setUploadStatus("Updating Syncro...");
        setShowProgressBar(true);
        setProgressBarLabel("Fetching entries from database...");
        try {
            const entries = await chimera.callAPI(signal, '/api/customfields/entries');
            for(let i = 0; i < entries.length; i++) {
                setProgressBarLabel(`Updating ${entries[i].Customer}...`);
                await chimera.callSyncroAPI(signal, 'PUT', `/customers/${entries[i].ID}/custom_fields`, {entry: entries[i]},
                    () => setProgressBarLabel("Waiting out Syncro API limits for 1 minute..."),
                    () => setProgressBarLabel(`Updating ${entries[i].Customer}`))
                .catch(err => {
                    if(err.name !== "AbortError") {
                        console.error(err);
                        alert(`PUT /api/syncro/customers/${entries[i].ID}/custom_fields failed (status: ${err.status}). See console output for more details (CTRL+Shift+J)`);
                    }
                });
                setProgress((i / entries.length) * 100)
            }
            setUploadStatus("Syncro is Updated!");
            setProgress(100);
            setProgressBarLabel("Done!");
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                alert('ERROR: GET /api/customfields/entries failed');
            }
        }
    }

    const prepareDownload = async(event) => {
        event.preventDefault();
        setShowChoice(false);
        setShowDownloadPage(true);
        setDownloadStatus("Preparing Download...");
        try {
            const blob = await chimera.callAPI(signal, '/api/customfields/xlsx', 'GET', null, 'blob');
            setDownloadStatus("Download Ready!");
            setDownloadBlob(blob);
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                alert(`ERROR: Download could not be prepared`);
                setDownloadStatus("Download failed :(");
            }
        }
    }

    const downloadList = (event) => {
        event.preventDefault();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(downloadBlob);
        link.download = "CustomFieldsMasterList.xlsx";
        link.click();
    }

    const resetTool = (event) => {
        event.preventDefault();
        setBtnIcon("fas fa-arrow-right");
        setBtnLabel("Submit");
        setBtnDisabled(false);
        setExcelFile(null);
        setShowProgressBar(false);
        setProgress(0);
        setProgressBarLabel("");
        setShowChoice(true);
        setShowUploadPage(false);
        setShowUploadForm(true);
        setUploadStatus("Uploading...");
        setShowDownloadPage(false);
        setDownloadStatus("Preparing Download...");
        setDownloadBlob(null);
    }

    const toolName = "Syncro Custom Fields";
    const toolId = "customfields";
    return (
        <ToolPage toolId={toolId} toolName={toolName}>
            <ToolPage.Header image={syncrocustomfieldsimg} alt="Syncro Custom Fields" toolName={toolName}>
                Import a Excel (.xlsx) file where the columns represent
                values for custom fields in Syncro to update them
                all at once.
            </ToolPage.Header>
            <ToolPage.How>
                <h3>
                    Background
                </h3>
                <p>
                    The Toolbox maintains a database consisting of the Custom Fields for each customer as specified by this tool.
                </p>
                <p>
                    This tool was designed with bulk changes in mind. You can change the whole database at once by downloading it as an
                    Excel spreadsheet, making edits, and then uploading the new spreadsheet, which will completely overwrite the database.
                </p>
                <p>
                    <b><u>NOTE:</u></b> Deleting a row from the spreadsheet will not wipe the values in Syncro.
                    The tool will only touch the customers explicitly included in its database.
                </p>
                <h3 id="usage">
                    Usage
                </h3>
                <h4>Making Edits</h4>
                <p>
                    It is recommended that you download the Master List and make edits to that file before reuploading it.
                </p>
                <p>
                    <strong>This tool uses a special technique to handle newlines.</strong> Since Excel documents cannot have newlines in-place,
                    this tool has been designed to interpret semicolons (';') as newline indicators. In Syncro, these will be translated directly into
                    line breaks.
                </p>
                <p>
                    <b><u>NOTE:</u></b> It may be prettier in Excel to follow a semicolon with a space, but that will cause the new line in Syncro
                    to begin with a small indent. For cleanliness, it is recommended that you separate values by the semicolon only, with no white space.
                    For example, here is how you might specify two IP addresses: <i>127.0.0.1;192.168.0.1</i>
                </p>
                <h4>
                    Download the Master List
                </h4>
                <ol>
                    <li>
                        <strong>Click <i>Download Master List</i>.</strong> This will prepare the file for download.
                    </li>
                    <li>
                        <strong>Wait patiently.</strong> It can take a second or two to prepare the file. Right before it is generated,
                        it performs a number of Syncro API calls to ensure that the database is up to date, reflecting any individual changes
                        that may have been made in Syncro itself since the last time this tool was used.
                    </li>
                    <li>
                        <strong>Click the green <i>CustomFieldsMasterList.xlsx</i> download button.</strong> This will start the download.
                    </li>
                    <li>
                        <strong>(Optional) After making your edits, click <i>Back to Start</i>.</strong> This
                        will return you to where you can select to upload the master list you just got done editing (see below).
                    </li>
                </ol>
                <h4>
                    Upload the Master List
                </h4>
                <ol>
                    <li>
                        <strong>Click <i>Upload Master List</i>.</strong> This will present the dialog for selecting the input file.
                    </li>
                    <li>
                        <strong>Click <i>Browse...</i> to select the input file.</strong> This
                        should be an Excel spreadsheet in the form provided from the "Download Master List" option,
                        where the ID and Customer columns are present and all other columns exactly match Custom Fields in Syncro.
                        This can include as many or as few Custom Fields as desired.
                    </li>
                    <li>
                        <strong>Click <i>Submit</i>.</strong> This will update the database in the Toolbox, overwriting it with the contents of the input file.
                    </li>
                    <li>
                        <strong>(Optional) Click <i>Update Syncro</i>.</strong> This will actually push your changes to Syncro.
                        This can take a long time if there are a lot of entries in the database. You can watch as a progress
                        bar fills incrementally for each customer that is updated. If any problems occur, warnings will be shown.
                    </li>
                </ol>
                <p>
                    <b><u>NOTE:</u></b> An empty value for a given column will not clear the value in Syncro. It is recommended that you use a value such as
                    "-" or "N/A" to indicate a field whose values should be "wiped" within Syncro.
                </p>
            </ToolPage.How>
            <ToolPage.Body>
                <div className="col-lg-12">
                    <div>
                        {showChoice ? 
                            <div className="mb-3 centered">
                                <div className="row mb-3 mt-3">
                                    <h4>
                                        Select an Option:
                                    </h4>
                                </div>
                                <div className="row mb-3 mt-3">
                                    <div className="col-4"></div>
                                    <div className="col-4">
                                        <button className="btn btn-primary" onClick={(event) => {event.preventDefault(); setShowChoice(false); setShowUploadPage(true)}} style={{width: "100%"}}>
                                            Upload Master List
                                        </button>
                                    </div>
                                    <div className="col-4"></div>
                                </div>
                                <div className="row mb-3 mt-3">
                                    <div className="col-4"></div>
                                    <div className="col-4">
                                        <button className="btn btn-primary" onClick={prepareDownload} style={{width: "100%"}}>
                                            Download Master List
                                        </button>
                                    </div>
                                    <div className="col-4"></div>
                                </div>
                            </div>
                        :
                        <>
                        {showUploadPage ? 
                            <>
                            {showUploadForm ? 
                            <form id="customFieldsUploadForm" onSubmit={handleSubmit} noValidate>
                                <div className="mb-3 centered">
                                    <label htmlFor="excelFile" className="form-label">Excel Document:
                                        <input id="excelFile" name="excelFile"  
                                        className="form-control centered" type="file" 
                                        aria-describedby="excelFileDescr"
                                        onChange={handleChange} required/>
                                    </label>
                                    <div id="excelFileDescr" className="form-text">
                                        The Excel document containing the Custom Fields information you want to send to Syncro.
                                    </div>
                                    <button type="submit" className="btn btn-primary">
                                        <i className={btnIcon}></i>
                                        <span>&nbsp;{btnLabel}</span>
                                    </button>
                                </div>
                            </form>
                            :
                            <div>
                                <h3>{uploadStatus}</h3>
                                {uploadStatus === "Upload Complete!" ?
                                    <button className="btn btn-primary" onClick={updateSyncro} disabled={btnDisabled}>
                                        <i className={btnIcon}></i>
                                        <span>&nbsp;{btnLabel}</span>
                                    </button>
                                :null}
                                {showProgressBar ?
                                    <ProgressBar bgColor="#99ccff" progress={progress.toString()} height={30} label={progressBarLabel}/>
                                :null}
                            </div>
                            }
                            </>
                        :null}
                        {showDownloadPage ?
                            <>
                                <div className="mb-3 centered">
                                    <div className="row mb-3 mt-3">
                                        <h3>
                                            {downloadStatus}
                                        </h3>
                                    </div>
                                    {downloadStatus === "Download Ready!" ?
                                        <>
                                        <div className="row mb-3 mt-3">
                                            <div className="col-4"></div>
                                            <div className="col-4">
                                                <button className="btn btn-success" onClick={downloadList}>
                                                    CustomFieldsMasterList.xlsx
                                                </button>
                                            </div>
                                            <div className="col-4"></div>
                                        </div>
                                        <div className="row mb-3 mt-3">
                                            <div className="col-4"></div>
                                            <div className="col-4">
                                                <button className="btn btn-primary" onClick={resetTool}>
                                                    Back to Start
                                                </button>
                                            </div>
                                            <div className="col-4"></div>
                                        </div>
                                        </>
                                    :null}
                                </div>
                            </>
                        :null}
                        </>
                        }
                    </div>
                </div>
            </ToolPage.Body>
        </ToolPage>
    );
}

export default CustomFields;