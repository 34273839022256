import React, {useState, useLayoutEffect, useContext} from 'react';
import chimera from '../chimera';
import BannerContext, { BannerLog } from "./BannerLogContext";
import Modal from './Modal';
import ModalContext from './ModalContext';
import LoadingSpinner from './LoadingSpinner';
import UserContext from '../UserContext';

const NoteDisplay = props => {
    const [isEditing, setIsEditing] = useState(false);
    const [workingNote, setWorkingNote] = useState(props.note);
    const [isSaving, setIsSaving] = useState(false);
    const [controller, setController] = useState(new AbortController());
    const [signal, setSignal] = useState(controller.signal);
    const banners = useContext(BannerContext);
    const modaling = useContext(ModalContext);
    const context = useContext(UserContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        const path = event.target.name;
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        let newNote = JSON.parse(JSON.stringify(workingNote));
        if(path.includes("group")) {
            const index = parseInt(path.substring(5));
            if(workingNote.groupsCanRead.includes(props.groups[index])) {
                // Group was unchecked, remove it.
                let newCanRead = [];
                for(const group of workingNote.groupsCanRead) {
                    if(group !== props.groups[index]) {
                        newCanRead.push(group);
                    }
                }
                newNote.groupsCanRead = newCanRead;
            }
            else {
                // Group was checked, add it.
                newNote.groupsCanRead.push(props.groups[index])
            }
        }
        else if(path.includes("category")) {
            const index = parseInt(path.substring(8));
            if(workingNote.categories.find(id => id === props.categories[index]._id)) {
                // Category was unchecked, remove it.
                let newCategories = [];
                for(const id of workingNote.categories) {
                    if(id !== props.categories[index]._id) {
                        newCategories.push(id);
                    }
                }
                newNote.categories = newCategories;
            }
            else {
                // Category was checked, add it.
                newNote.categories.push(props.categories[index]._id);
            }
        }
        else {
            chimera.setAttr(newNote, path, value);
        }
        setWorkingNote(newNote);
    }

    const authorDisplay = (author) => {
        let s = "";
        if(author.first) {
            s += `${author.first}`;
        }
        if(author.last) {
            s += `${author.first ? ' ' : ''}${author.last}`;
        }
        if(author.email) {
            if(author.first || author.last) {
                s += ` (${author.email})`;
            }
            else {
                s += `${author.email}`;
            }
        }
        return s;
    }

    const dateStr = (timestamp) => {
        const d = new Date(timestamp);
        return d.toLocaleString();
    }

    const metaStamp = () => {
        return (
            <>
            <i className="overflow-hidden">{authorDisplay(props.note.author)}</i>
            <i className="overflow-hidden">{dateStr(props.note.updatedAt)}</i>
            </>
        )
    }

    const togglePin = (e) => {
        if(e) e.preventDefault();
        const newPinned = !props.note.pinned;
        chimera.callAPI(signal, `/api/notes/${props.note._id}`, 'PUT', {pinned: newPinned})
        .then(updatedNote => {
            props.updateIndex(props.index, updatedNote, true)
        })
        .catch(e => {
            if(e.name !== "AbortError") {
                console.error(e);
                banners.addBanner('danger', `Failed to ${newPinned ? 'pin' : 'unpin'} to note; update request failed.`, 'Error');
            }
        })
    }

    const toggleStar = (e) => {
        if(e) e.preventDefault();
        const newStarred = !props.note.starred;
        chimera.callAPI(signal, `/api/notes/${props.note._id}`, 'PUT', {starred: newStarred})
        .then(updatedNote => {
            props.updateIndex(props.index, updatedNote, true)
        })
        .catch(e => {
            if(e.name !== "AbortError") {
                console.error(e);
                banners.addBanner('danger', `Failed to ${newStarred ? 'star' : 'unstar'} to note; update request failed.`, 'Error');
            }
        })
    }

    const startEditing = (event) => {
        event.preventDefault();
        setIsEditing(true);
    }

    const cancelEditing = (event) => {
        event.preventDefault();
        setWorkingNote(props.note);
        setIsEditing(false);
    }

    const confirmDelete = (event) => {
        event.preventDefault();
        const choices = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Cancel</span>,
                btnColor: 'secondary',
                func: (event) => {
                    event.preventDefault();
                    modaling.backtrack();
                }
            },
            {
                btnInner: <span><i className="fas fa-times"/>&nbsp;Yes, Delete</span>,
                btnColor: 'danger',
                func: (event) => {
                    event.preventDefault();
                    modaling.setModal(<Modal choices={[]} dismiss={(event) => {event.preventDefault(); modaling.backtrack()}}>
                        <LoadingSpinner size={75}/>
                    </Modal>);
                    chimera.callAPI(signal, `/api/notes/${props.note._id}`, 'DELETE')
                    .then(_ => {
                        modaling.backtrack();
                        modaling.backtrack();
                    })
                    .catch(e => {
                        if(e.name !== "AbortError") {
                            console.error(e);
                            alert("Error: Could not delete the note. Sorry!");
                            modaling.backtrack();
                            modaling.backtrack();
                        }
                    })
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Are you sure?</h3>
            <p>
                Are you sure you want to delete this note? <strong>This action cannot be undone.</strong>
            </p>
            <p>
                <strong>Subject: {props.note.title}</strong>
            </p>
        </Modal>
        modaling.setModal(modal);
    }

    const saveChanges = async(event) => {
        event.preventDefault();
        setIsSaving(true);
        await props.updateIndex(props.index, workingNote);
        setIsSaving(false);
        setIsEditing(false);
    }

    return (
        <div className="section-outline">
            <div className="text-start">
                <div className="row">
                    <div className={!isEditing ? "col-12 col-lg-8" : "col-12"}>
                        {!props.readonly && !isEditing ? 
                            <div className="d-flex justify-content-start align-items-center">
                                <a href="#" className="text-decoration-none me-3" onClick={togglePin}>
                                    <i className={props.note.pinned ? "fas fa-thumbtack text-body" : "fas fa-thumbtack text-muted"}/>
                                </a>
                                <a href="#" className="text-decoration-none" style={{marginBottom: "2px"}} onClick={toggleStar}>
                                    <i className={props.note.starred ? "fas fa-star text-body" : "fas fa-star text-muted"}/>
                                </a>
                            </div>
                        :null}
                        {!isEditing ? 
                            <span>{props.readonly && props.note.starred ? <><i className="fas fa-star"/>&nbsp;</> : null}<strong>{props.note.title}</strong></span>
                        :
                            <input className="form-control mb-1" name="title" value={workingNote.title} placeholder="Subject" onChange={handleChange} disabled={isSaving}/>
                        }
                        {!isEditing ? 
                            <>
                            {props.note.text.split('\n').map((p, i) => 
                                <p key={i}>{p}</p>
                            )}
                            </>
                        :
                            <textarea className="form-control mb-1" name="text" value={workingNote.text} placeholder="Note Text" onChange={handleChange} disabled={isSaving}/>
                        }
                        {isEditing ? 
                            <>
                            <div className="row mb-1">
                                <div className="col">
                                    <div>
                                        <span className="me-3">Categories:</span>
                                        {props.categories.map((cat, i) => <div key={i} className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" name={`category${i}`} id={`category${i}Check`} checked={workingNote.categories.find(id => id === cat._id)} onChange={handleChange} disabled={isSaving}/>
                                            <label className="form-check-label" htmlFor={`category${i}Check`}>
                                                {cat.name}
                                            </label>
                                        </div>)}
                                    </div>
                                    <div>
                                        <span className="me-3">Share With Groups:</span>
                                        {props.groups.map((group, i) => <div key={i} className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" name={`group${i}`} id={`group${i}Check`} checked={group !== "Executive" ? workingNote.groupsCanRead.includes(group) : true} onChange={handleChange} disabled={isSaving || (group === "Executive")}/>
                                            <label className="form-check-label" htmlFor={`group${i}Check`}>
                                                {group}
                                            </label>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="float-start">
                                        <button className="btn btn-sm btn-secondary me-1" onClick={cancelEditing} disabled={isSaving}>
                                            <i className="fas fa-arrow-left"/>
                                            &nbsp;Cancel
                                        </button>
                                        <button className="btn btn-sm btn-warning" onClick={saveChanges} disabled={isSaving}>
                                            <i className={isSaving ? "fas fa-spinner" : "fas fa-bookmark"}/>
                                            &nbsp;{isSaving ? "Saving..." : "Save"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            </>
                        :
                            null
                        }
                    </div>
                    {!isEditing ? 
                    <div className="d-none d-lg-block col-lg-4 text-end">
                        <div className="d-flex flex-column">
                            {!props.readonly ? 
                            <div className="col">
                                <div className="float-end">
                                    <button className="btn btn-sm btn-secondary me-1" onClick={startEditing}>
                                        <i className="fas fa-pencil"/>
                                    </button>
                                    <button className="btn btn-sm btn-danger" onClick={confirmDelete}>
                                        <i className="fas fa-times"/>
                                    </button>
                                </div>
                            </div>
                            :null}
                            {!props.readonly ? metaStamp() : null}
                        </div>
                    </div>
                    :null}
                </div>
            </div>
        </div>
    )
}

export default NoteDisplay;