import React, {useState, useEffect, useContext, useLayoutEffect} from 'react';

import TimelineForm from './TimelineForm';
import AddressDisplay from './CustomerPage/AddressDisplay';
import PocDisplay from './CustomerPage/PocDisplay';
import chimera from '../chimera';
import BannerContext, {BannerLog} from './BannerLogContext';
import LoadingSpinner from './LoadingSpinner';
import NotesSection from './CustomerPage/NotesSection';
import ModalContext from './ModalContext';
import Modal from './Modal';
import { NewCustomerBody } from './NewCustomer';

const PocList = props => {
    return (
        <div>
            {props.pocs.map((poc, i) => 
                <div className={i !== props.pocs.length - 1 ? "section-outline mb-4" : "section-outline"}>
                    <PocDisplay poc={poc} basePath={`${props.basePath}[${i}]`} baseValue={props.baseValue} onChange={props.handleChange} disabled={props.disabled} isEditing handleDelete={(event) => {event.preventDefault(); props.handleDelete(i)}} onBlur={props.onBlur} series/>
                </div>
            )}
            <button className="btn btn-success mt-2 mb-2" onClick={props.addPoc} disabled={props.disabled}>
                <i className="fas fa-plus"/>
            </button>
        </div>
    )
}

const StrInterface = props => {
    return(
        <div className="row justify-content-center">
            <div className="col">
                {props.strings.map((str, i) => 
                    <div className="mb-1 d-flex flex-row">
                        <input className="form-control" type="text" name={`${props.basePath}[${i}]`} value={str} placeholder={props.placeholder} onChange={props.handleChange} disabled={props.disabled} onBlur={props.onBlur}/>
                        <button className="btn btn-danger ms-2" onClick={(event) => {event.preventDefault(); props.deleteString(props.basePath, i)}} disabled={props.disabled}><i className="fas fa-minus"/></button>
                    </div>
                )}
                <button className="btn btn-success" onClick={(event) => {event.preventDefault(); props.addString(props.basePath)}} disabled={props.disabled}>
                    <i className="fas fa-plus"/>
                </button>
            </div>
        </div>
    );
}

const FiberLeadForm = props => {
    const [lead, setLead] = useState(props.lead);
    const [saveBtnLabel, setSaveBtnLabel] = useState("Save SO");
    const [saveBtnIcon, setSaveBtnIcon] = useState("fas fa-floppy-disk");
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
    const [notes, setNotes] = useState(null);
    const [wasAlreadyCompleted, setWasAlreadyCompleted] = useState(props.lead ? props.lead.status === "Completed" : false);
    const banners = useContext(BannerContext);
    const modaling = useContext(ModalContext);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if(lead === null) {
            chimera.callAPI(signal, '/api/leads/type/fiber', 'POST', {})
            .then(newLead => {
                setLead(newLead);
            })
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Could not open new Lead', 'Error');
            })
        }
        return () => {
            controller.abort();
        }
    }, [lead]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if(lead && notes === null) {
            const sortByStarred = (arr) => {
                let starredNotes = [];
                let unstarredNotes = [];
                for(const note of arr) {
                    if(note.starred) starredNotes.push(note);
                    else unstarredNotes.push(note);
                }
                return [].concat(starredNotes, unstarredNotes);
            }
            chimera.callAPI(signal, `/api/notes/refId/${lead._id}`)
            .then(newNotes => {setNotes(sortByStarred(newNotes))})
            .catch(e => {
                if(e.name !== "AbortError") {
                    banners.addBanner('danger', 'Could not read notes', 'Error');
                    console.error(e);
                }
            });
        }
        return () => {
            controller.abort();
        }
    }, [lead, notes]);

    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        const name = event.target.name;
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;

        let newFormFields = JSON.parse(JSON.stringify(lead));

        if(name === "attrs.billingSameAs" && value) {
            newFormFields.attrs.billingAddress = newFormFields.attrs.customerAddress;
        }
        else if(name === "attrs.emailSameAs" && value) {
            newFormFields.attrs.billingEmail = newFormFields.attrs.poc.email;
        }

        if(name === "currentStep" && value === 5) {
            newFormFields.status = "In-Progress";
        }
        else if(name === "currentStep" && value === 9) {
            newFormFields.status = "Completed";
        }

        chimera.setAttr(newFormFields, name, value);

        // Complex step completion checking
        const collectedCustomerInfo = 
            newFormFields.attrs.customerName !== "" &&
            newFormFields.attrs.poc.firstName !== ""    &&
            newFormFields.attrs.poc.lastName !== ""     &&
            newFormFields.attrs.poc.phone !== ""    &&
            emailIsValid(newFormFields.attrs.poc.email)        &&
            newFormFields.attrs.customerAddress.street1 !== "" &&
            newFormFields.attrs.customerAddress.city !== ""    &&
            newFormFields.attrs.customerAddress.state !== ""   &&
            newFormFields.attrs.customerAddress.county !== ""  &&
            newFormFields.attrs.customerAddress.zip !== "";

        const validBillingAddress = 
            newFormFields.attrs.billingAddress.street1 !== "" &&
            newFormFields.attrs.billingAddress.city !== ""    &&
            newFormFields.attrs.billingAddress.state !== ""   &&
            newFormFields.attrs.billingAddress.county !== ""  &&
            newFormFields.attrs.billingAddress.zip !== "";
        
        newFormFields.steps.collectedCustomerInfo = collectedCustomerInfo;
        newFormFields.steps.validBillingAddress = validBillingAddress;
        newFormFields.steps.validBillStartDate = newFormFields.attrs.billStartDate !== "";
        
        newFormFields.steps.hasValidTechInfo = 
            newFormFields.attrs.cids.length > 0 && newFormFields.attrs.cids[0] !== "" &&
            newFormFields.attrs.ips.length > 0 && newFormFields.attrs.ips[0] !== "" &&
            newFormFields.attrs.subnets.length > 0 && newFormFields.attrs.subnets[0] !== "" &&
            newFormFields.attrs.gateways.length > 0 && newFormFields.attrs.gateways[0] !== "" &&
            newFormFields.attrs.cpes.length > 0 && newFormFields.attrs.cpes[0] !== ""
        
        setLead(newFormFields);
        setSaveBtnDisabled(false);
        setSaveBtnIcon("fas fa-floppy-disk");
        setSaveBtnLabel("Save SO");
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const handleSubmit = async(event) => {
        event.preventDefault();

        const customer = new chimera.CommercialCustomer();
        customer.displayName = lead.attrs.customerName;
        customer.businessName = lead.attrs.businessName;
        customer.poc = lead.attrs.poc;
        customer.notificationEmails = chimera.uniqueArray([lead.attrs.poc.email].concat(lead.attrs.techPocs.map(poc => poc.email)));
        customer.billingAddress = lead.attrs.billingAddress;
        customer.serviceAddress = lead.attrs.customerAddress;
        customer.serviceTypes.internet = true;
        customer.billingPocs = [{firstName: "", lastName: "", phone: "", email: lead.attrs.billingEmail}];
        customer.technical.cids = lead.attrs.cids;
        customer.technical.ips = lead.attrs.ips;
        customer.technical.gateways = lead.attrs.gateways;
        customer.technical.subnetMasks = lead.attrs.subnets;
        customer.technical.pocs = lead.attrs.techPocs;
        customer.technical.cpes = lead.attrs.cpes;

        const createdCustomerCallback = async(createdCustomer) => {
            modaling.setModal(null);
            // Show loading modal
            const controller = new AbortController();
            const signal = controller.signal;
            const dismiss = (event) => {
                event.preventDefault();
                controller.abort();
                modaling.setModal(null);
            }
            const loadingModal = <Modal choices={[]} dismiss={dismiss}>
                <LoadingSpinner size={75}/>
            </Modal>
            modaling.setModal(loadingModal);

            try {
                await chimera.callAPI(signal, '/api/notes/copy', 'POST', {
                    from: lead._id,
                    to: createdCustomer._id
                });
            }
            catch(e) {
                console.error(e);
                alert('Failed to copy notes over... sorry!');
            }
            
            // Save the lead
            try {
                const body = JSON.parse(JSON.stringify(lead));
                body.status = "Provisioned";
                await chimera.callAPI(signal, `/api/leads/type/fiber/${lead._id}`, 'PUT', body);
            }
            catch(e) {
                console.error(e);
                alert('Failed to save service order. Customer has still been successfully created.');
            }

            // Send the email
            try {
                await chimera.callAPI(signal, '/api/sendmail', 'POST', {
                    email: ["accounting@gocbit.com"],
                    subject: `Lead set to Provisioned: ${lead.attrs.customerName}`,
                    text: `The Lead for ${lead.attrs.customerName} has been set to Provisioned. Please find it at https://bms.gocbit.com/leads to set up billing.`
                });
            }
            catch(e) {
                console.error(e);
                alert('Failed to email Accounting');
            }

            modaling.setModal(null);
            const choices = [
                {
                    btnInner: <span><i className="fas fa-check"/>&nbsp;Close</span>,
                    btnColor: "secondary",
                    func: (event) => {
                        event.preventDefault();
                        modaling.setModal(null);
                        if(props.setLeads) props.setLeads(null);
                    }
                },
                {
                    btnInner: <span><i className="fas fa-arrow-right"/>&nbsp;Go to Customer</span>,
                    btnColor: "success",
                    func: (event) => {
                        event.preventDefault();
                        window.open(`/customers/${createdCustomer.accountNumber}`, '_self');
                    }
                }
            ]
            const modal = <Modal choices={choices} dismiss={choices[0].func}>
                <h3>Success!</h3>
                <p>The new customer <strong>{createdCustomer.displayName}</strong> has been created.</p>
            </Modal>
            modaling.setModal(modal);
        }

        // Load New Customer form with Lead info pre-loaded
        // TODO: Highlighted fields are hard-coded. That will probably need to change for future leads.
        const controller = new AbortController();
        const signal = controller.signal;
        const dismiss = (event) => {
            event.preventDefault();
            controller.abort();
            modaling.setModal(null);
        }
        const modal = <Modal choices={[]} dismiss={dismiss}>
            <BannerLog>
                <NewCustomerBody customer={customer} controller={controller} signal={signal} createdCustomerCallback={createdCustomerCallback}/>
            </BannerLog>
        </Modal>
        modaling.setModal(modal);
    }
    
    const handleSave = (event) => {
        event.preventDefault();
        setSaveBtnDisabled(true);
        setSaveBtnIcon("fas fa-spinner");
        setSaveBtnLabel("Saving...");
        chimera.callAPI(undefined, `/api/leads/type/fiber/${lead._id}`, 'PUT', lead)
        .then(newLead => {
            if(newLead.status === "Completed" && !wasAlreadyCompleted) {
                // Send email from fiber@gocbit.com to POC and additional tech POCs,
                // using unique email addresses.
                /** 
                const toAddresses = chimera.uniqueArray([newLead.attrs.poc.email].concat(newLead.attrs.techPocs.map(poc => poc.email)).concat(["support@gocbit.com"]));
                chimera.callAPI(undefined, '/api/sendmail', 'POST', {
                    from: 'FIBER',
                    email: toAddresses,
                    subject: "Your CBIT Fiber Internet Service is Available!",
                    text: `${newLead.attrs.customerName},\n\nThank you for choosing CBIT! Your fiber internet service is now available.\n\nPlease see your network information below: \n\nCID(s):\n${newLead.attrs.cids.join('\n')}\n\nCPE(s):\n${newLead.attrs.cpes.join('\n')}\n\nIP(s):\n${newLead.attrs.ips.join('\n')}\n\nSubnet Mask(s):\n${newLead.attrs.subnets.join('\n')}\n\nGateway(s):\n${newLead.attrs.gateways.join('\n')}\n\nIf you have any questions or issues please reach out to us at support@gocbit.com and we will be happy to help you!`
                })
                */
            }
            banners.addBanner('info', 'Successfully saved changes!');
            setLead(newLead);
            setSaveBtnIcon('fas fa-check');
            setSaveBtnLabel("Saved!");
        })
        .catch(err => {
            console.log(err);
            banners.addBanner('danger', 'Failed to save changes', 'Error');
            setSaveBtnDisabled(false);
            setSaveBtnIcon("fas fa-floppy-disk");
            setSaveBtnLabel("Save SO");
        })
    }

    const confirmCancel = (event) => {
        event.preventDefault();
        const choices = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;No, go back</span>,
                btnColor: "secondary",
                func: (event) => {
                    event.preventDefault();
                    modaling.backtrack();
                }
            },
            {
                btnInner: <span><i className="fas fa-times"/>&nbsp;Yes, cancel this lead</span>,
                btnColor: "danger",
                func: (event) => {
                    event.preventDefault();
                    const controller = new AbortController();
                    const signal = controller.signal;
                    const dismiss = (event) => {
                        event.preventDefault();
                        controller.abort();
                        modaling.setModal(null);
                    }
                    const loadingModal = <Modal choices={[]} dismiss={dismiss}>
                        <BannerLog>
                            <LoadingSpinner size={75}/>
                        </BannerLog>
                    </Modal>
                    modaling.setModal(loadingModal);
                    const body = {};
                    for(const key in lead) {
                        body[key] = lead[key];
                    }
                    body.status = "Cancelled";
                    chimera.callAPI(signal, `/api/leads/type/fiber/${lead._id}`, 'PUT', body)
                    .then(newLead => {
                        setLead(newLead);
                        modaling.setModal(null);
                        if(props.setLeads) props.setLeads(null);
                    })
                    .catch(err => {
                        console.error(err);
                        banners.addBanner('danger', 'Failed to cancel Service Order', 'Error');
                    })
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Are you sure you want to cancel this lead?</h3>
        </Modal>
        modaling.setModal(modal);
    }

    const confirmReopen = (event) => {
        event.preventDefault();
        const choices = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;No, go back</span>,
                btnColor: "secondary",
                func: (event) => {
                    event.preventDefault();
                    modaling.backtrack();
                }
            },
            {
                btnInner: <span><i className="fas fa-plus"/>&nbsp;Yes, reopen this lead</span>,
                btnColor: "danger",
                func: (event) => {
                    event.preventDefault();
                    const controller = new AbortController();
                    const signal = controller.signal;
                    const dismiss = (event) => {
                        event.preventDefault();
                        controller.abort();
                        modaling.setModal(null);
                    }
                    const loadingModal = <Modal choices={[]} dismiss={dismiss}>
                        <BannerLog>
                            <LoadingSpinner size={75}/>
                        </BannerLog>
                    </Modal>
                    modaling.setModal(loadingModal);
                    const body = {};
                    for(const key in lead) {
                        body[key] = lead[key];
                    }
                    body.status = lead.currentStep >= 5 ? "In-Progress" : "New";
                    chimera.callAPI(signal, `/api/leads/type/fiber/${lead._id}`, 'PUT', body)
                    .then(newLead => {
                        setLead(newLead);
                        modaling.setModal(null);
                        if(props.setLeads) props.setLeads(null);
                    })
                    .catch(err => {
                        console.error(err);
                        banners.addBanner('danger', 'Failed to reopen Lead', 'Error');
                    })
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Are you sure you want to reopen this lead?</h3>
        </Modal>
        modaling.setModal(modal);
    }

    const addTechPoc = (event) => {
        event.preventDefault();
        let techPocs = JSON.parse(JSON.stringify(lead.attrs.techPocs));
        techPocs.push({
            firstName: "",
            lastName: "",
            email: "",
            phone: ""
        });
        handleChange({
            preventDefault: () => {},
            target: {
                type: "array",
                name: 'attrs.techPocs',
                value: techPocs
            }
        });
    }

    const deleteTechPoc = (index) => {
        let newPocs = [];
        const oldPocs = JSON.parse(JSON.stringify(lead.attrs.techPocs));
        for(let i = 0; i < oldPocs.length; i++) {
            if(i !== index) {
                newPocs.push(oldPocs[i]);
            }
        }
        handleChange({
            preventDefault: () => {},
            target: {
                type: "array",
                name: "attrs.techPocs",
                value: newPocs
            }
        })
    }

    const stepCompletedCallback = (stepIndex) => {
        const stepNum = stepIndex + 1;
        handleChange({
            preventDefault: () => {},
            target: {
                type: "number",
                name: "currentStep",
                value: stepNum
            }
        })
    }

    const statusColor = (status) => {
        switch(status) {
            case "New":
                return "info";
            case "In-Progress":
                return "warning";
            case "Provisioned":
                return "primary";
            case "Completed":
                return "success";
            case "Cancelled":
                return "danger";
        }
    }

    const deleteString = (basePath, index) => {
        let newStrings = [];
        const strings = chimera.getAttr(lead, basePath);
        for(let i = 0; i < strings.length; i++) {
            if(i !== index)
                newStrings.push(strings[i]);
        }

        handleChange({
            target: {
                type: "Array",
                name: basePath,
                value: newStrings
            },
            preventDefault: () => {}
        });
    }

    const addString = (basePath) => {
        let newStrings = [];
        const strings = chimera.getAttr(lead, basePath);
        for(const str of strings) {
            newStrings.push(str);
        }
        newStrings.push("");

        handleChange({
            target: {
                type: "Array",
                name: basePath,
                value: newStrings
            },
            preventDefault: () => {}
        });
    }

    const emailIsValid = (email) => {
        return email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g) ? true : false
    }

    return (
        <>
        {lead === null ?
        <LoadingSpinner size={75}/>
        :
        <TimelineForm timeline={{currentStep: lead.currentStep}} stepCompletedCallback={stepCompletedCallback}>
            <TimelineForm.Main>
                <TimelineForm.Section label="Step 1" canContinue={lead.steps.collectedCustomerInfo}>
                    <TimelineForm.Field
                        type="text"
                        name="attrs.customerName"
                        label="Customer Name"
                        value={lead.attrs.customerName}
                        description="The customer's business name."
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        classes="my-0"
                        disabled={lead.status === "Cancelled"}
                        required/>
                    <TimelineForm.Field
                        type="component"
                        name="attrs.poc"
                        label="Point of Contact"
                        description="Point of Contact information for the customer."
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}
                        required>
                            <PocDisplay poc={lead.attrs.poc} basePath="attrs.poc" baseValue={lead} onChange={handleChange} isEditing onBlur={trimOnBlur}/>
                    </TimelineForm.Field>
                    <TimelineForm.Field
                        type="component"
                        name="attrs.customerAddress"
                        label="Service Address"
                        description="The service address for the customer."
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}
                        required>
                            <AddressDisplay addr={lead.attrs.customerAddress} basePath="attrs.customerAddress" baseValue={lead} onChange={handleChange} isEditing onBlur={trimOnBlur}/>
                    </TimelineForm.Field>
                    <TimelineForm.Field
                        type="select"
                        options={[
                            {id: "100 Mbps", value: "100 Mbps"},
                            {id: "200 Mbps", value: "200 Mbps"},
                            {id: "250 Mbps", value: "250 Mbps"},
                            {id: "500 Mbps", value: "500 Mbps"},
                            {id: "1 Gbps", value: "1 Gbps"},
                            {id: "2 Gbps", value: "2 Gbps"},
                            {id: "30 Gbps", value: "30 Gbps"}
                        ]}
                        name="attrs.service"
                        label="Service"
                        value={lead.attrs.service}
                        description="The service the customer is requesting."
                        handleChange={handleChange}
                        classes="mt-4"
                        excludeNoneSelected
                        disabled={lead.status === "Cancelled"}
                        required
                        />
                    <TimelineForm.Field
                        type="select"
                        options={[
                            {id: "BPUD / NoaNet", value: "BPUD / NoaNet"},
                            {id: "FPUD", value: "FPUD"},
                            {id: "COR", value: "COR"},
                            {id: "CBIT", value: "CBIT"}
                        ]}
                        name="attrs.provider"
                        label="Provider"
                        value={lead.attrs.provider}
                        description="The fiber provider."
                        handleChange={handleChange}
                        classes="mt-4"
                        excludeNoneSelected
                        disabled={lead.status === "Cancelled"}
                        required
                        />
                </TimelineForm.Section>
                <TimelineForm.Section label="Step 2" canContinue={lead.steps.contactedWholesale}>
                    <TimelineForm.Field
                        type="checkbox"
                        name="steps.contactedWholesale"
                        label="Contact Wholesale for Quote"
                        value={lead.steps.contactedWholesale}
                        description="Please indicate when this step is completed."
                        handleChange={handleChange}
                        checkLabel="Done"
                        classes="my-0"
                        disabled={lead.status === "Cancelled"}
                        required/>
                </TimelineForm.Section>
                <TimelineForm.Section label="Step 3" canContinue={lead.steps.receivedQuote}>
                    <TimelineForm.Field
                        type="checkbox"
                        name="steps.receivedQuote"
                        label="Relay Quote to Customer"
                        value={lead.steps.receivedQuote}
                        description="Please indicate when this step is completed."
                        handleChange={handleChange}
                        checkLabel="Done"
                        classes="my-0"
                        disabled={lead.status === "Cancelled"}
                        required/>
                </TimelineForm.Section>
                <TimelineForm.Section label="Step 4" canContinue={lead.steps.receivedWholesaleSO}>
                    <TimelineForm.Field
                        type="checkbox"
                        name="steps.receivedWholesaleSO"
                        label="Received SO from Wholesale"
                        value={lead.steps.receivedWholesaleSO}
                        description="Please indicate when this step is completed."
                        handleChange={handleChange}
                        checkLabel="Done"
                        classes="my-0"
                        disabled={lead.status === "Cancelled"}
                        required/>
                </TimelineForm.Section>
                <TimelineForm.Section label="Step 5" canContinue={lead.steps.SOtoCustomer}>
                    <TimelineForm.Field
                        type="checkbox"
                        name="steps.SOtoCustomer"
                        label="Send CBIT SO to Customer"
                        value={lead.steps.SOtoCustomer}
                        description="Please indicate when this step is completed."
                        handleChange={handleChange}
                        checkLabel="Done"
                        classes="my-0"
                        disabled={lead.status === "Cancelled"}
                        required/>
                </TimelineForm.Section>
                <TimelineForm.Section label="Step 6" canContinue={lead.steps.customerSignedSO && lead.steps.validBillingAddress && emailIsValid(lead.attrs.billingEmail) && lead.attrs.mrc !== null && lead.attrs.nrc !== null && lead.attrs.mrc !== "" && lead.attrs.nrc !== ""}>
                    <TimelineForm.Field
                        type="checkbox"
                        name="steps.customerSignedSO"
                        label="Receive Signed SO from Customer"
                        value={lead.steps.customerSignedSO}
                        description="Please indicate when this step is completed."
                        handleChange={handleChange}
                        checkLabel="Done"
                        classes="my-0"
                        disabled={lead.status === "Cancelled"}
                        required/>
                    <TimelineForm.Field
                        type="component"
                        name="attrs.techPocs"
                        label="(Optional) Technical POCs"
                        description="Additional technical points of contact."
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}>
                            <PocList pocs={lead.attrs.techPocs} basePath="attrs.techPocs" baseValue={lead} handleChange={handleChange} handleDelete={deleteTechPoc} addPoc={addTechPoc} onBlur={trimOnBlur}/>
                    </TimelineForm.Field>
                    <TimelineForm.Field
                        type="component"
                        name="attrs.billingAddress"
                        label="Billing Address"
                        description="The billing address for the customer."
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}
                        required>
                            <AddressDisplay addr={lead.attrs.billingAddress} basePath="attrs.billingAddress" baseValue={lead} onChange={handleChange} isEditing onBlur={trimOnBlur} sameAsLabel="Same As Customer Address" sameAsPath="attrs.billingSameAs" sameAs={lead.attrs.billingSameAs}/>
                    </TimelineForm.Field>
                    <TimelineForm.Field
                        type="text"
                        name="attrs.billingEmail"
                        label="Billing Email"
                        value={lead.attrs.billingEmail}
                        description="If not given, the email provided in Step 1 will be used."
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        classes="mt-4"
                        sameAsLabel="Same As POC Email"
                        sameAsPath="attrs.emailSameAs"
                        sameAs={lead.attrs.emailSameAs}
                        disabled={lead.status === "Cancelled"}
                        required/>
                    <TimelineForm.Field
                        type="number"
                        name="attrs.mrc"
                        label="Monthly Recurring Cost (MRC)"
                        value={lead.attrs.mrc}
                        description="The monthly recurring cost for the service."
                        handleChange={handleChange}
                        min={0.0}
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}
                        required/>
                    <TimelineForm.Field
                        type="number"
                        name="attrs.nrc"
                        label="Non-Recurring Cost (NRC)"
                        value={lead.attrs.nrc}
                        description="The one-time cost that covers setup, such as construction."
                        handleChange={handleChange}
                        min={0.0}
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}
                        required/>
                </TimelineForm.Section>
                <TimelineForm.Section label="Step 7" canContinue={lead.steps.sentWholesaleSO}>
                    <TimelineForm.Field
                        type="checkbox"
                        name="steps.sentWholesaleSO"
                        label="Sign and Send SO to Wholesale"
                        value={lead.steps.sentWholesaleSO}
                        description="Please indicate when this step is completed."
                        handleChange={handleChange}
                        checkLabel="Done"
                        classes="my-0"
                        disabled={lead.status === "Cancelled"}
                        required/>
                    <TimelineForm.Field
                        type="date"
                        name="attrs.focDate"
                        label="(Optional) FOC Date"
                        value={lead.attrs.focDate}
                        description="The FOC date."
                        handleChange={handleChange}
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}/>
                </TimelineForm.Section>
                <TimelineForm.Section label="Step 8" canContinue={lead.steps.receivedSOA && lead.steps.validBillStartDate && lead.steps.hasValidTechInfo}>
                    <TimelineForm.Field
                        type="checkbox"
                        name="steps.receivedSOA"
                        label="Received SOA from Wholesale"
                        value={lead.steps.receivedSOA}
                        description="Please indicate when this step is completed."
                        handleChange={handleChange}
                        checkLabel="Sent"
                        classes="my-0"
                        disabled={lead.status === "Cancelled"}
                        required/>
                    <TimelineForm.Field
                        type="date"
                        name="attrs.billStartDate"
                        label="Bill Start Date"
                        value={lead.attrs.billStartDate}
                        description="When CBIT will start being charged (and when the customer will need to start being charged)."
                        handleChange={handleChange}
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}
                        required/>
                    <TimelineForm.Field
                        type="component"
                        name="attrs.cids"
                        label="Circuit IDs"
                        description="The CIDs for the customer, provided by the SOA from Wholesale."
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}>
                            <StrInterface strings={lead.attrs.cids} basePath="attrs.cids" placeholder="CID" addString={addString} deleteString={deleteString} handleChange={handleChange} onBlur={trimOnBlur}/>
                    </TimelineForm.Field>
                    <TimelineForm.Field
                        type="component"
                        name="attrs.cpes"
                        label="CPEs"
                        description="The CPEs for the customer, provided by the SOA from Wholesale."
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}>
                            <StrInterface strings={lead.attrs.cpes} basePath="attrs.cpes" placeholder="CPE" addString={addString} deleteString={deleteString} handleChange={handleChange} onBlur={trimOnBlur}/>
                    </TimelineForm.Field>
                    <TimelineForm.Field
                        type="component"
                        name="attrs.ips"
                        label="IP Addresses"
                        description="The IP addresses for the customer, provided by the SOA from Wholesale."
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}>
                            <StrInterface strings={lead.attrs.ips} basePath="attrs.ips" placeholder="IP Address" addString={addString} deleteString={deleteString} handleChange={handleChange} onBlur={trimOnBlur}/>
                    </TimelineForm.Field>
                    <TimelineForm.Field
                        type="component"
                        name="attrs.subnets"
                        label="Subnet Masks"
                        description="The subnet masks for the customer, provided by the SOA from Wholesale."
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}>
                            <StrInterface strings={lead.attrs.subnets} basePath="attrs.subnets" placeholder="Subnet Mask" addString={addString} deleteString={deleteString} handleChange={handleChange} onBlur={trimOnBlur}/>
                    </TimelineForm.Field>
                    <TimelineForm.Field
                        type="component"
                        name="attrs.gateways"
                        label="Gateways"
                        description="The gateways for the customer, provided by the SOA from Wholesale."
                        classes="mt-4"
                        disabled={lead.status === "Cancelled"}>
                            <StrInterface strings={lead.attrs.gateways} basePath="attrs.gateways" placeholder="Gateway" addString={addString} deleteString={deleteString} handleChange={handleChange} onBlur={trimOnBlur}/>
                    </TimelineForm.Field>
                </TimelineForm.Section>
                <TimelineForm.Section label="Step 9" canContinue={lead.steps.firstInvoiceSent && lead.steps.recurringInvoiceCreated} hidden={lead.status !== "Provisioned" && lead.status !== "Completed"}>
                    <TimelineForm.Field
                        type="checkbox"
                        name="steps.firstInvoiceSent"
                        label="Create and Send First Invoice"
                        value={lead.steps.firstInvoiceSent}
                        description="Please indicate when this step is completed."
                        handleChange={handleChange}
                        checkLabel="Done"
                        classes="my-0"
                        disabled={lead.status === "Cancelled"}
                        required/>
                    <TimelineForm.Field
                        type="checkbox"
                        name="steps.recurringInvoiceCreated"
                        label="Create Recurring Invoice"
                        value={lead.steps.recurringInvoiceCreated}
                        description="Please indicate when this step is completed."
                        handleChange={handleChange}
                        checkLabel="Done"
                        classes="my-0"
                        disabled={lead.status === "Cancelled"}
                        required/>
                </TimelineForm.Section>
            </TimelineForm.Main>
            <TimelineForm.Sidebar>
                <div className="row">
                    <div className="col d-flex justify-content-center mx-2">
                        <div className={`alert alert-${statusColor(lead.status)} p-1 w-100`}>
                            {lead.status}
                        </div>
                    </div>
                </div>
                <div className="text-start">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="attrs.savedSignedRetailSO" checked={lead.attrs.savedSignedRetailSO} onChange={handleChange} id="retailSOCheck" disabled={lead.status === "Cancelled"}/>
                        <label className="form-check-label" for="retailSOCheck">
                            Saved Signed Retail SO
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="attrs.savedSignedWholesaleSO" checked={lead.attrs.savedSignedWholesaleSO} onChange={handleChange} id="wholesaleSOCheck" disabled={lead.status === "Cancelled"}/>
                        <label className="form-check-label" for="wholesaleSOCheck">
                            Saved Signed Wholesale SO
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="attrs.savedSOA" checked={lead.attrs.savedSOA} onChange={handleChange} id="SOACheck" disabled={lead.status === "Cancelled"}/>
                        <label className="form-check-label" for="SOACheck">
                            Saved Signed SOA
                        </label>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <button className="btn btn-primary w-75" onClick={handleSave} disabled={saveBtnDisabled || lead.status === "Cancelled"}>
                            <i className={saveBtnIcon}/>
                            &nbsp;{saveBtnLabel}
                        </button>
                    </div>
                </div>
                {lead.status !== "Provisioned" && lead.status !== "Completed" && lead.status !== "Cancelled" ? 
                <div className="mt-2">
                    <TimelineForm.Submit nSteps={8 /** Only 8 steps are required to create the customer */} label="Create Customer" icon="fas fa-plus" handleSubmit={handleSubmit} canSubmit={lead.attrs.savedSignedRetailSO && lead.attrs.savedSignedWholesaleSO && lead.attrs.savedSOA && lead.status !== "Completed" && lead.status !== "Cancelled"}/>
                </div>
                :null}
                <div className="row mt-2">
                    <div className="col section-outline mx-2">
                        <NotesSection header="Notes" objectName="service order" refId={lead._id} notes={notes} setNotes={setNotes} noFilter/>
                    </div>
                </div>
                {lead.status !== "Cancelled" && lead.status !== "Completed" && lead.status !== "Provisioned" ? 
                <div className="row mt-2">
                    <div className="col">
                        <button className="btn btn-danger w-75" onClick={confirmCancel}>
                            <i className="fas fa-times"/>
                            &nbsp;Cancel SO
                        </button>
                    </div>
                </div>
                :null}
                {lead.status === "Cancelled" ?
                <div className="row mt-2">
                    <div className="col">
                        <button className="btn btn-danger w-75" onClick={confirmReopen}>
                            <i className="fas fa-plus"/>
                            &nbsp;Reopen Lead
                        </button>
                    </div>
                </div>
                :null}
            </TimelineForm.Sidebar>
        </TimelineForm>
        }
        </>
    )
}

export default FiberLeadForm;