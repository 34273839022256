import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';

import chimera from '../../chimera';
import LoadingSpinner from '../LoadingSpinner';
import BannerContext, {BannerLog} from '../BannerLogContext';
import ReportTable from '../ReportTable';

const VoIPDiscrepancyBody = props => {
    const [phase, setPhase] = useState('start');
    const [report, setReport] = useState(null);
    const [spinnerLabel, setSpinnerLabel] = useState("");
    const [saveBtnLabel, setSaveBtnLabel] = useState("Save to Chimera");
    const [saveBtnIcon, setSaveBtnIcon] = useState("fas fa-floppy-disk");
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [controller] = useState(new AbortController());
    const [signal] = useState(controller.signal);
    const banners = useContext(BannerContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        if(phase.toLowerCase() === 'start') {
            setReport(null);
            setSaveBtnDisabled(false);
            setSaveBtnLabel('Save to Chimera');
            setSaveBtnIcon('fas fa-floppy-disk');
        }
    }, [phase]);

    const chimeraCustomerIsVoipCustomer = (chimeraCustomer) => {
        for(const location of chimeraCustomer.locations) {
            if(location.serviceTypes.voip === true) return true;
        }
        return false;
    }

     // {chimeraCustomer, unityDomain, qbCustomer, billedInQb}
    const pairCustomers = (chimeraCustomers, unityDomains, qbCustomers) => {
        let customerPairs = [];

        // First, add all VoIP customers in Chimera
        for(const customer of chimeraCustomers) {
            customerPairs.push({
                chimeraCustomer: customer,
                unityDomain: null,
                qbCustomer: null,
                billedInQb: false
            })
        }

        // Go through Unity Domains, looking for matches first by unity integration and then by customer name matching
        for(const domain of unityDomains) {
            let hasChimeraCustomer = false;
            for(let i = 0; i < customerPairs.length; i++) {
                if(customerPairs[i].chimeraCustomer && (customerPairs[i].chimeraCustomer.integrationIds.unity === domain.domain || customerPairs[i].chimeraCustomer.displayName === domain.description || customerPairs[i].chimeraCustomer.businessName === domain.description)) {
                    customerPairs[i].unityDomain = domain;
                    hasChimeraCustomer = true;
                    break;
                }
            }
            if(!hasChimeraCustomer) {
                customerPairs.push({
                    chimeraCustomer: null,
                    unityDomain: domain,
                    qbCustomer: null,
                    billedInQb: false
                })
            }
        }

        // Match QB customers to existing customer pairs either by integration ID on the Chimera customer, by the Chimera customer's name, or by the Unity domain's description
        for(const qbCustomer of qbCustomers) {
            let hasPair = false;
            for(let i = 0; i < customerPairs.length; i++) {
                const matchesChimera = customerPairs[i].chimeraCustomer && (customerPairs[i].chimeraCustomer.integrationIds.quickbooks === qbCustomer.Id || customerPairs[i].chimeraCustomer.displayName === qbCustomer.DisplayName || customerPairs[i].chimeraCustomer.businessName === qbCustomer.CompanyName);
                const matchesUnity = customerPairs[i].unityDomain && (customerPairs[i].unityDomain.description === qbCustomer.DisplayName || customerPairs[i].unityDomain.description === qbCustomer.CompanyName);
                if(matchesChimera || matchesUnity) {
                    customerPairs[i].qbCustomer = qbCustomer;
                    hasPair = true;
                    break;
                }
            }
            if(!hasPair) {
                customerPairs.push({
                    chimeraCustomer: null,
                    unityDomain: null,
                    qbCustomer: qbCustomer,
                    billedInQb: false
                })
            }
        }

        // Remove cases where the customer is non-voip in Chimera and has no other pairs
        return customerPairs.filter((pair) => (pair.chimeraCustomer && chimeraCustomerIsVoipCustomer(pair.chimeraCustomer)) || pair.unityDomain || pair.qbCustomer);
    }

    const IsQbCustomerBilledForVoip = (recurringTransactions, voipItemNames) => {
        let isBilled = false;
        for(const transaction of recurringTransactions) {
            for(const line of transaction.doc.Line) {
                if(line.DetailType === "SalesItemLineDetail") {
                    for(const voipItemName of voipItemNames) {
                        if(line.SalesItemLineDetail.ItemRef.name.includes(voipItemName)) {
                            isBilled = true;
                            break;
                        }
                    }
                }
            }
        }
        return isBilled;
    }

    const run = async(event) => {
        event.preventDefault();
        try {
            setPhase('loading');
            let chimeraCustomers;
            let unityDomains;
            let qbCustomers;
            let tspairs;
            let recurringTransactionsBatch;
    
            try {
                setSpinnerLabel("Reading Customers from Chimera...");
                chimeraCustomers = await chimera.callAPI(signal, '/api/customers');
            }
            catch(err) {
                if(err.name !== "AbortError") {
                    console.error(err);
                    banners.addBanner('danger', `An unhandled exception occurred while reading Chimera Customers. The report cannot continue.`, 'Error');
                }
            }
    
            try {
                setSpinnerLabel("Reading Domains from Unity...");
                unityDomains = await chimera.callAPI(signal, '/api/unity/domains');
            }
            catch(err) {
                if(err.name !== "AbortError") {
                    console.error(err);
                    banners.addBanner('danger', `An unhandled exception occurred while reading Unity Domains. The report cannot continue.`, 'Error');
                }
            }
    
            try {
                setSpinnerLabel("Reading Customers from QuickBooks...");
                qbCustomers = await chimera.callQuickBooksAPI(signal, '/api/qb/customers');
            }
            catch(err) {
                if(err.name !== "AbortError") {
                    console.error(err);
                    banners.addBanner('danger', `An unhandled exception occurred while reading QuickBooks Customers. The report cannot continue.`, 'Error');
                }
            }
    
            try {
                setSpinnerLabel("Reading T/S Pairs from Chimera...");
                tspairs = await chimera.callAPI(signal, '/api/tspairs');
            }
            catch(err) {
                if(err.name !== "AbortError") {
                    console.error(err);
                    banners.addBanner('danger', `An unhandled exception occurred while reading T/S Pairs. The report cannot continue.`, 'Error');
                }
            }

            const customerPairs = pairCustomers(chimeraCustomers, unityDomains, qbCustomers);

            try {
                setSpinnerLabel("Reading Recurring Transactions from QuickBooks...");
                recurringTransactionsBatch = await chimera.getRecurringTransactionsBatch(signal, customerPairs.filter(pair => pair.qbCustomer).map(pair => pair.qbCustomer.Id), 'BMS');
            }
            catch(err) {
                if(err.name !== "AbortError") {
                    console.error(err);
                    banners.addBanner('danger', `An unhandled exception occurred while reading Recurring Transactions. The report cannot continue.`, 'Error');
                }
            }
    
            const cols = [
                "Chimera Name",
                "Unity Domain",
                "Unity Description",
                "QB Name",
                "Set as VoIP in Chimera?",
                "Has Unity Integration in Chimera?",
                "Has QB Integration in Chimera?",
                "VoIP Billed in QB?",
            ]
            let rows = [];
    
            setSpinnerLabel("Compiling report...");
            for(const pair of customerPairs) {
                // If QB customer, check if billed for VoIP in QB
                let billedInQb = false;
                if(pair.qbCustomer) {
                    try {
                        billedInQb = IsQbCustomerBilledForVoip(recurringTransactionsBatch[pair.qbCustomer.Id], tspairs.map(tspair => tspair.item));
                    }
                    catch(err) {
                        if(err.name !== "AbortError") {
                            console.error(err);
                            banners.addBanner('danger', `An unhandled exception occurred while checking recurring transactions for QB customer ${pair.qbCustomer.DisplayName}. The report cannot continue.`, 'Error');
                        }
                    }
                }

                // skip pairs if they are in QB only and not billed for voip
                if(!billedInQb && pair.qbCustomer && !pair.chimeraCustomer && !pair.unityDomain) {
                    continue;
                }

                // skip pairs if they are not voip in chimera, have no unity pair, and are not billed for voip in QB
                if(pair.chimeraCustomer && !chimeraCustomerIsVoipCustomer(pair.chimeraCustomer) && !pair.unityDomain && !billedInQb) {
                    continue;
                }
    
                rows.push({
                    "Chimera Name": pair.chimeraCustomer ? pair.chimeraCustomer.displayName : '',
                    "QB Name": pair.qbCustomer ? pair.qbCustomer.DisplayName : '',
                    "Unity Domain": pair.unityDomain ? pair.unityDomain.domain : '',
                    "Unity Description": pair.unityDomain ? pair.unityDomain.description: '',
                    "Set as VoIP in Chimera?": pair.chimeraCustomer ? chimeraCustomerIsVoipCustomer(pair.chimeraCustomer) : false,
                    "VoIP Billed in QB?": billedInQb,
                    "Has Unity Integration in Chimera?": pair.chimeraCustomer ? (pair.chimeraCustomer.integrationIds.unity ? true : false) : false,
                    "Has QB Integration in Chimera?": pair.chimeraCustomer ? (pair.chimeraCustomer.integrationIds.quickbooks ? true : false) : false
                });
            }
            setReport({type: "VoIPDiscrepancy", cols: cols, rows: rows});
            setPhase('results');
        }
        catch(err) {
            if(err.name !== "AbortError") {
                console.error(err);
                banners.addBanner('danger', `An unhandled exception occurred. The report cannot continue.`, 'Error');
            }
        }
    }

    const saveReport = (event) => {
        event.preventDefault();
        setSaveBtnDisabled(true);
        setSaveBtnIcon("fas fa-spinner");
        setSaveBtnLabel("Saving...");
        chimera.callAPI(signal, '/api/reporttables', 'POST', report)
        .then(_ => {
            setSaveBtnIcon("fas fa-check");
            setSaveBtnLabel("Saved!");
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                console.error(err);
                banners.addBanner('danger', 'An error occurred and the report could not be saved', 'Error');
            }
            setSaveBtnDisabled(false);
            setSaveBtnIcon("fas fa-floppy-disk");
            setSaveBtnLabel("Save to Chimera");
        })
    }

    const phaseStart = () => {
        return <button className="btn btn-primary" onClick={run}>
            <i className="fas fa-play"/>&nbsp;Run Report
        </button>;
    }

    const phaseLoading = () => {
        return <LoadingSpinner size={75} label={spinnerLabel}/>;
    }

    const phaseResults = () => {
        return <>
            <button className="btn btn-primary mb-2" onClick={saveReport} disabled={saveBtnDisabled}>
                <i className={saveBtnIcon}/>
                &nbsp;{saveBtnLabel}
            </button>
            <ReportTable report={report}/>
        </>;
    }

    const presentPhase = (phase) => {
        switch(phase.toLowerCase()) {
            case 'start':
                return phaseStart();
            case 'loading':
                return phaseLoading();
            case 'results':
                return phaseResults();
            default:
                return null;
        }
    }

    return (
        <div>
            {presentPhase(phase)}
        </div>
    )
}

const VoIPDiscrepancy = props => {
    return (
        <BannerLog>
            <VoIPDiscrepancyBody/>
        </BannerLog>
    )
}

export default VoIPDiscrepancy;