import React, {useState, useContext} from 'react';

import BannerContext, { BannerLog } from "../BannerLogContext";
import UserContext from "../../UserContext";

import UsersPanel from './UsersPanel';
import IPPanel from './IPPanel';
import TSPairsPanel from './TSPairsPanel';

const AdminBody = props => {
    const [activePanel, setActivePanel] = useState("Users");
    const userContext = useContext(UserContext);
    const banners = useContext(BannerContext);
    
    const SidebarButton = ({panelName}) => {
        return (
            <button type="button" className={`list-group-item list-group-item-action${activePanel === panelName ? ' active' : ''}`} onClick={(e) => {e.preventDefault(); setActivePanel(panelName)}}>
                {panelName}
            </button>
        )
    }

    const panelBody = () => {
        switch(activePanel) {
            case 'Users':
                return <UsersPanel bannerContext={banners}/>;
            case 'IP Management':
                return <IPPanel bannerContext={banners}/>;
            case 'T/S Pairs':
                return <TSPairsPanel />;
            default:
                return <p className="text-muted">(Coming Soon)</p>;
        }
    }

    return(
        <>
        {userContext.permissions && !userContext.permissions.admin.read ? 
        <>
        <h1>Forbidden</h1>
        <p>You must be an Admin user to access this page.</p>
        </>
        :
        <>
        <h1>Admin</h1>
        <div className="row">
            <div className="col-2">
                <div className="list-group">
                    <SidebarButton panelName='Users'/>
                    <SidebarButton panelName='Groups'/>
                    <SidebarButton panelName='IP Management'/>
                    <SidebarButton panelName='T/S Pairs'/>
                </div>
            </div>
            <div className="col-10 border-start">
                <h2>{activePanel}</h2>
                {panelBody()}
            </div>
        </div>
        </>
        }
        </>
    )
}

const Admin = props => {
    return (
        <div className="container pb-5">
            <BannerLog>
                <AdminBody {...props} />
            </BannerLog>
        </div>
    )
}

export default Admin;