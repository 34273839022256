import React, {useState, useEffect} from 'react';
import FormField from './FormField';

const FileUpload = ({callback, label, description}) => {
    const [file, setFile] = useState(null);
    const [contents, setContents] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(file) {
            const reader = new FileReader();
            reader.onload = e => {
                const dataURL = reader.result;
                const base64 = dataURL.slice(dataURL.indexOf(',')+1);
                setContents(base64);
            }
            reader.readAsDataURL(file);
        }
    }, [file]);

    const handleChange = (event) => {
        if(event.target.name === "file") {
            if(event.target.files[0].size > 15000000) {
                alert('ERROR: Sorry, but this file is larger than 15 MB and cannot be uploaded.');
                setFile(null);
            }
            else {
                setFile(event.target.files[0]);
            }
        }
    }

    return(
        <div>
            <FormField
                name="file"
                handleChange={handleChange}
                label={label ? label : "Upload File"}
                type="file"
                required
                description={description ? description : "The file you want to upload. Must be < 15 MB in size."}
            />
            <button className="btn btn-primary" onClick={(e) => {e.preventDefault(); setLoading(true); callback(file, contents);}} disabled={!file || (file && !contents) || loading}>
                <i className="fas fa-file"/>&nbsp;{loading ? 'Saving...' : 'Upload File'}
            </button>
        </div>
    )
}

export default FileUpload;