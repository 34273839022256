import React, {useState} from "react";
import chimera from "../chimera";
import Tooltip from "./Tooltip";

const TSPairInterface = props => {
    let initialPairs = {};
    for(let i = 0; i < props.tspairs.length; i++) {
        initialPairs[i.toString()] = props.tspairs[i];
    }
    const [saveBtnIcon, setSaveBtnIcon] = useState("fas fa-bookmark");
    const [saveBtnLabel, setSaveBtnLabel] = useState("Saved");
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
    const [fieldsDisabled, setFieldsDisabled] = useState(false);
    const [tspairs, setTspairs] = useState(initialPairs);

    const handleChange = (event) => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        let newPairs = JSON.parse(JSON.stringify(tspairs));
        const tokens = name.split('.');
        if(tokens.length >= 2) {
            const index = tokens[0];
            const key = tokens[1];
            let newValue = value;
            if(key === "s" || key === "t") {
                newValue = parseInt(value);
            }
            else if(key === "price" || key === "monthlyCommission") {
                newValue = parseFloat(value);
            }
            newPairs[index][key] = newValue;
        }
        setTspairs(newPairs);
        setSaveBtnDisabled(false);
        setSaveBtnLabel("Save");
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSaveBtnIcon("fas fa-spinner");
        setSaveBtnLabel("Saving...");
        setSaveBtnDisabled(true);
        setFieldsDisabled(true);

        console.log(tspairs);
        const pairs = [];
        for(const key in tspairs) {
            const tspair = tspairs[key];
            if(tspair.item !== null && tspair.t !== null && tspair.s !== null && tspair.price !== null && tspair.monthlyCommission !== null) {
                pairs.push(tspair);
            }
            else {
                deleteRow(key);
            }
        }
        chimera.callAPI(undefined, '/api/tspairs', 'PUT', {tspairs: pairs})
        .then(response => {
            setSaveBtnIcon("fas fa-bookmark");
            setSaveBtnLabel("Saved");
            setFieldsDisabled(false);
        })
        .catch(e => {
            console.error(e);
            alert(`ERROR: The T/S pairs could not be saved.`);
            setSaveBtnIcon("fas fa-bookmark");
            setSaveBtnLabel("Saved");
            setFieldsDisabled(false);
            setSaveBtnDisabled(false);
        })
    }

    const addRow = (event) => {
        event.preventDefault();
        let newPairs = {};
        let nextKey = 0;
        for(const key in tspairs) {
            newPairs[key] = tspairs[key];
            const n = parseInt(key);
            if(n > nextKey)
                nextKey = n;
        }
        nextKey++;
        newPairs[nextKey.toString()] = {
            item: null,
            s: null,
            t: null,
            price: null,
            monthlyCommission: null
        };
        setTspairs(newPairs);
        setSaveBtnDisabled(false);
        setSaveBtnLabel("Save");
    }

    const deleteRow = (index) => {
        for(const key in tspairs) {
            if(index === key) {
                const newPairs = tspairs;
                delete newPairs[key];
                setTspairs(newPairs);
                setSaveBtnDisabled(false);
                setSaveBtnLabel("Save");
                setSaveBtnDisabled(true);
                break;
            }
        }
    }

    const fields = [];
    let n = 0;
    for(const key in tspairs) {
        fields.push(
            <div className="row mb-2" key={n}>
                <div className="col">
                    <input id={`${key}.item`} name={`${key}.item`}
                    className="form-control centered" type="text"
                    value={tspairs[key].item} 
                    onChange={handleChange}
                    placeholder="Item Name"
                    disabled={fieldsDisabled}/>
                </div>
                <div className="col">
                    <input id={`${key}.t`} name={`${key}.t`}
                    className="form-control centered" type="number"
                    value={tspairs[key].t} 
                    onChange={handleChange}
                    placeholder="Transaction Type"
                    disabled={fieldsDisabled}/>
                </div>
                <div className="col">
                    <input id={`${key}.s`} name={`${key}.s`}
                    className="form-control centered" type="number"
                    value={tspairs[key].s} 
                    onChange={handleChange}
                    placeholder="Service Type"
                    disabled={fieldsDisabled}/>
                </div>
                <div className="col">
                    <input id={`${key}.price`} name={`${key}.price`}
                    className="form-control centered" type="number"
                    value={tspairs[key].price} 
                    onChange={handleChange}
                    placeholder="Price"
                    min={0}
                    step={0.01}
                    disabled={fieldsDisabled}/>
                </div>
                <div className="col">
                    <input id={`${key}.monthlyCommission`} name={`${key}.monthlyCommission`}
                    className="form-control centered" type="number"
                    value={tspairs[key].monthlyCommission} 
                    onChange={handleChange}
                    placeholder="Monthly Commission"
                    min={0}
                    step={0.01}
                    disabled={fieldsDisabled}/>
                </div>
                <div className="col-1">
                    <button className="btn btn-danger" onClick={(event) => {event.preventDefault(); deleteRow(key)}} disabled={fieldsDisabled}>
                        <i className="fas fa-minus"/>
                    </button>
                </div>
            </div>
        );
        n++;
    }
    return(
        <div>
            <form id="tsPairInterface" onSubmit={handleSubmit} noValidate>
                {props.goBack ? 
                <div className="row mb-2">
                    <div className="col text-start">
                        <button className="btn btn-secondary" onClick={(event) => {event.preventDefault(); props.goBack()}}>
                            <i className="fas fa-arrow-left"/>
                            &nbsp;Back
                        </button>
                    </div>
                </div>
                :null}
                <button className="btn btn-primary" type="submit" disabled={saveBtnDisabled}>
                    <i className={saveBtnIcon}/>
                    &nbsp;{saveBtnLabel}
                </button>
                <div className="row">
                    <div className="col text-start">
                        <h4>Item</h4>
                    </div>
                    <div className="col text-start">
                        <h4>Transaction Type</h4>
                    </div>
                    <div className="col text-start">
                        <h4>Service Type</h4>
                    </div>
                    <div className="col text-start">
                        <h4>Price</h4>
                    </div>
                    <div className="col text-start">
                        <h4>
                            <span>Monthly Commission</span>&nbsp;
                            <Tooltip text="The amount in dollars that a VoIP Agent will be paid each month per unit sold">
                                <i className="fas fa-circle-question"/>
                            </Tooltip>
                        </h4>
                    </div>
                    <div className="col-1"/>
                </div>
                {fields}
                <div className="row text-start">
                    <div className="col-1">
                        <button className="btn btn-success" onClick={addRow}>
                            <i className="fas fa-plus"/>
                        </button>
                    </div>
                </div>
                <button className="btn btn-primary" type="submit" disabled={saveBtnDisabled}>
                    <i className={saveBtnIcon}/>
                    &nbsp;{saveBtnLabel}
                </button>
            </form>
        </div>
    )
}

export default TSPairInterface;