import React, {useState, useEffect, useContext} from "react";

import ModalContext from './ModalContext';
import Modal from './Modal';
import FormField from "./FormField";
import chimera from "../chimera";
import BannerContext, { BannerLog } from './BannerLogContext';
import LoadingSpinner from "./LoadingSpinner";
import UserContext from "../UserContext";
import Pagination from "./Pagination";

const RequestForm = props => {
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [impact, setImpact] = useState(5.5);
    const [submitBtnLabel, setSubmitBtnLabel] = useState("Submit");
    const [submitBtnIcon, setSubmitBtnIcon] = useState("fas fa-arrow-right");
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
    const banners = useContext(BannerContext);
    const userContext = useContext(UserContext);

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitBtnLabel("Submitting...");
        setSubmitBtnIcon('fas fa-spinner');
        setSubmitBtnDisabled(true);
        chimera.callAPI(undefined, '/api/requests', 'POST', {
            subject: subject,
            description: description,
            impact: impact,
            type: props.type,
        })
        .then(submittedRequest => {
            chimera.callAPI(undefined, '/api/sendmail', 'POST', {
                from: "DEFAULT",
                email: "developer@gocbit.com",
                subject: `New ${props.type} Request in Chimera`,
                text: `\"${subject}\" submitted by ${userContext.user.first} ${userContext.user.last}:\n\n${description}`
            })
            .catch(err => {
                console.error(err);
            })
            if(props.submitCallback) props.submitCallback(submittedRequest);
        })
        .catch(err => {
            console.error(err);
            banners.addBanner('danger', `Failed to save ${props.type} Request`, 'Error');
            setSubmitBtnLabel("Submit");
            setSubmitBtnIcon('fas fa-arrow-right');
            setSubmitBtnDisabled(false);
        })
    }

    const handleChange = (event) => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        if(name === "subject") {
            setSubject(value);
        }
        else if(name === "description") {
            setDescription(value);
        }
        else if(name === "impact") {
            setImpact(value);
        }
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    return (
        <form id="requestForm" onSubmit={handleSubmit} noValidate>
            <FormField
                type="text"
                name="subject"
                label="Subject"
                value={subject}
                description="A short, descriptive title for this request."
                handleChange={handleChange}
                placeholder={props.type === "Help" ? "Example: Error when saving customer edits" : "Example: What if Chimera could brew coffee?"}
                onBlur={trimOnBlur}
                fullWidth
                required
            />
            <FormField
                type="textarea"
                name="description"
                label="Description"
                value={description}
                description="Please describe your request. More detail is (usually) better!"
                handleChange={handleChange}
                placeholder={props.type === "Help" ? "Example: Precise steps to replicate the issue, the full error message, etc." : "Example: Seriously, I think it would be sick if Chimera could brew us some coffee."}
                onBlur={trimOnBlur}
                fullWidth
                required
            />
            <FormField
                type="range"
                name="impact"
                label="Impact"
                value={impact}
                description={props.type === "Help" ? "On a scale from 1-10, how much impact is this issue having on your work? (More impact = higher priority)" : "On a scale from 1-10, how much impact would fulfilling this request have for our success? (More impact = higher priority)"}
                handleChange={handleChange}
                onBlur={trimOnBlur}
                required
                min={1}
                max={10}
                step={0.1}
                fullWidth
            />
            <button className="btn btn-primary" type="submit" disabled={submitBtnDisabled}>
                <i className={submitBtnIcon}/>&nbsp;{submitBtnLabel}
            </button>
        </form>
    )
}

function statusColor(status){
    switch(status) {
        case "Open":
            return "info";
        case "In-Progress":
            return "warning";
        case "Closed":
            return "secondary";
    }
}

const RequestDetails = props => {
    const [developerResponse, setDeveloperResponse] = useState("");
    const [estimatedEffort, setEstimatedEffort] = useState(1.0);
    const [selectedStatus, setSelectedStatus] = useState(props.request.status);
    const [sendBtnIcon, setSendBtnIcon] = useState("fas fa-envelope");
    const [sendBtnLabel, setSendBtnLabel] = useState("Send Response");
    const [sendBtnDisabled, setSendBtnDisabled] = useState(false);
    const userContext = useContext(UserContext);
    const banners = useContext(BannerContext);

    const handleChange = (event) => {
        event.preventDefault();
        if(event.target.name === "developerResponse") {
            setDeveloperResponse(event.target.value);
        }
        else if(event.target.name === "effort") {
            setEstimatedEffort(event.target.value);
        }
        else if(event.target.name === "status") {
            setSelectedStatus(event.target.value);
            chimera.callAPI(undefined, `/api/requests/${props.request._id}`, 'PUT', {status: event.target.value})
            .catch(err => {
                console.error(err);
                alert("Failed to save status change");
            })
        }
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSendBtnIcon('fas fa-spinner');
        setSendBtnLabel('Submitting...');
        setSendBtnDisabled(true);
        const now = new Date();
        chimera.callAPI(undefined, `/api/requests/${props.request._id}`, 'PUT', {
            developerResponse: {
                text: developerResponse,
                author: {
                    first: userContext.user.first,
                    last: userContext.user.last,
                    email: userContext.user.email
                },
                timestamp: now
            },
            effort: estimatedEffort
        })
        .then(updatedRequest => {
            chimera.callAPI(undefined, '/api/sendmail', 'POST', {
                from: "DEFAULT",
                email: props.request.author.email,
                subject: `${props.request.type} Request Response`,
                text: `Your Request Subject: ${props.request.subject}\n\nDeveloper Response:\n${developerResponse}\n\nView this Request and its response in Chimera at https://bms.gocbit.com/help`
            })
            .catch(err => {
                console.error(err);
            })
            if(props.submitCallback) {
                props.submitCallback(updatedRequest);
            }
        })
        .catch(err => {
            console.error(err);
            banners.addBanner('danger', 'Failed to submit developer response', 'Error');
        })
    }

    return (
        <>
        <div className="text-start">
            <div className="row">
                <div className="col d-flex flex-row">
                    <h3><b>{props.request.type} Request:</b> {props.request.subject}<span className={`ms-2 badge bg-${statusColor(selectedStatus)}`}>{selectedStatus}</span></h3>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <span className="text-muted">Submitted by {props.request.author.first} {props.request.author.last} at {(new Date(props.request.createdAt)).toLocaleString()}</span>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <p>
                        {props.request.description}
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {props.request.developerResponse ?
                        <>
                        <h3>Developer Response</h3>
                        <span className="text-muted">Submitted by {props.request.developerResponse.author.first} {props.request.developerResponse.author.last} at {(new Date(props.request.developerResponse.timestamp)).toLocaleString()}</span>
                        <p>{props.request.developerResponse.text}</p>
                        </>
                    :
                        <b><i>Awaiting a response from the developer.</i></b>
                    }
                </div>
            </div>
        </div>
        {userContext.user.groups.includes("Developer") ? 
            <div className="row mb-1">
                <div className="col">
                    <select className="form-select float-start" name="status" onChange={handleChange}>
                        <option value="Open" selected={selectedStatus === "Open"}>Open</option>
                        <option value="In-Progress" selected={selectedStatus === "In-Progress"}>In-Progress</option>
                        <option value="Closed" selected={selectedStatus === "Closed"}>Closed</option>
                    </select>
                </div>
            </div>
        :null}
        {userContext.user.groups.includes("Developer") && !props.request.developerResponse ? 
            <>
            <div className="row mb-1">
                <div className="col">
                    <textarea className="form-control" placeholder="Write a response..." name="developerResponse" value={developerResponse} onChange={handleChange} onBlur={trimOnBlur} disabled={sendBtnDisabled}/>
                </div>
            </div>
            <div className="row">
                <div className="col d-flex flex-column align-items-start">
                    <label className="text-start" htmlFor="estimatedEffort">
                        Estimated Effort:
                        <input id="estimatedEffort" className="form-control w-fit" type="number" name="effort" value={estimatedEffort} onChange={handleChange} min={1} max={10} step={0.1}/>
                    </label><br/>
                    <span><b>Calculated Suggested Priority:</b>&nbsp;{(props.request.impact / estimatedEffort).toFixed(1)}</span>
                </div>
            </div>
            <div className="row mb-1">
                <div className="col">
                    <button className="btn btn-primary" onClick={handleSubmit} disabled={sendBtnDisabled || !developerResponse}>
                        <i className={sendBtnIcon}/>&nbsp;{sendBtnLabel}
                    </button>
                </div>
            </div>
            </>
        : null}
        </>
    )
}

const RequestsBody = props => {
    const [requests, setRequests] = useState(null);
    const [sortBy, setSortBy] = useState("date");
    const [page, setPage] = useState(1); // 1-indexed
    const [nPages, setNPages] = useState(1);
    const modaling = useContext(ModalContext);
    const banners = useContext(BannerContext);

    const perPage = 10;

    useEffect(() => {
        if(page > nPages) {
            setPage(nPages === 0 ? 1 : nPages); // `page` should never be 0
        }
    }, [nPages]);

    useEffect(() => {
        if(requests === null) {
            chimera.callAPI(undefined, '/api/requests')
            .then(newRequests => {
                setRequests(newRequests);
                setNPages(Math.ceil(newRequests.length / perPage));
            })
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to read requests from database', 'Error');
            })
        }
        else {
            console.log(requests);
        }
    }, [requests]);

    const handleChange = (event) => {
        if(event.target.name === "sortBy") {
            setSortBy(event.target.value);
        }
    }

    const openFormInModal = (requestType) => {
        const choices = [
            {
                btnColor: 'secondary',
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                func: (e) => {
                    e.preventDefault();
                    modaling.backtrack();
                }
            }
        ];
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <div className="container">
                <BannerLog>
                    <RequestForm type={requestType} submitCallback={() => {modaling.setModal(null); setRequests(null); banners.addBanner('info', `Your ${requestType} Request was successfully submitted.`, 'Success')}}/>
                </BannerLog>
            </div>
        </Modal>
        modaling.setModal(modal);
    }

    const openRequestDetailsInModal = (request) => {
        const choices = [
            {
                btnColor: 'secondary',
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                func: (e) => {
                    e.preventDefault();
                    modaling.backtrack();
                }
            }
        ];
        const submitCallback = (updatedRequest) => {
            modaling.setModal(null);
            setRequests(null);
            openRequestDetailsInModal(updatedRequest);
        }
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <div className="container">
                <BannerLog>
                    <RequestDetails request={request} submitCallback={submitCallback}/>
                </BannerLog>
            </div>
        </Modal>
        modaling.setModal(modal);
    }

    const calculatedPriority = (request) => {
        return (request.impact / request.effort).toFixed(1);
    }

    const getSortFunc = () => {
        switch(sortBy) {
            case "priority":
                return (a, b) => calculatedPriority(a) < calculatedPriority(b) ? 1 : -1;
            default:
                return (a, b) => a.createdAt < b.createdAt ? 1 : -1;
        }
    }

    return (
        <>
            <div className="section-outline">
                <div className="row">
                    <div className="col">
                        <h3>I have a...</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h4>... great idea!</h4>
                        <button className="btn btn-primary" onClick={(event) => {event.preventDefault(); openFormInModal('Feature')}}>
                            <i className="fas fa-lightbulb"/>&nbsp;Open New Feature Request
                        </button>
                    </div>
                    <div className="col">
                        <h4>... question or problem</h4>
                        <button className="btn btn-primary" onClick={(event) => {event.preventDefault(); openFormInModal('Help')}}>
                            <i className="fas fa-circle-question"/>&nbsp;Open New Help Request
                        </button>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    {requests === null ? 
                        <LoadingSpinner size={50}/>
                    :   
                        <>
                        {requests.length > 0 ? 
                            <>
                            <div className="d-flex flex-row mb-3">
                                <span className="mt-1 me-1">Sort By:&nbsp;</span>
                                <select className="form-select w-fit" name="sortBy" onChange={handleChange}>
                                    <option value="date">Newest to Oldest</option>
                                    <option value="priority">Highest to Lowest Priority</option>
                                </select>
                            </div>
                            <Pagination page={page} setPage={setPage} nPages={nPages}/>
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Type</th>
                                        <th>Priority</th>
                                        <th>Subject</th>
                                        <th>Author</th>
                                        <th>Submit Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {requests.sort(getSortFunc()).slice((page * perPage) - perPage, (page * perPage)).map(request => <tr>
                                        <td className={`cursor-pointer table-${statusColor(request.status)}`} onClick={(event) => {event.preventDefault(); openRequestDetailsInModal(request)}}>{request.status}</td>
                                        <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); openRequestDetailsInModal(request)}}>{request.type}</td>
                                        <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); openRequestDetailsInModal(request)}}>{calculatedPriority(request)}</td>
                                        <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); openRequestDetailsInModal(request)}}>{request.developerResponse ? <span className="badge bg-secondary me-1">Dev Response</span> : null}{request.subject}</td>
                                        <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); openRequestDetailsInModal(request)}}>{request.author.first} {request.author.last}</td>
                                        <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); openRequestDetailsInModal(request)}}>{(new Date(request.createdAt)).toLocaleDateString()}</td>
                                    </tr>)}
                                </tbody>
                            </table>
                            <Pagination page={page} setPage={setPage} nPages={nPages}/>
                            </>
                        :
                            <p className="text-muted">Nothing to see here yet. Submit the first request!</p>
                        }
                        </>
                    }
                </div>
            </div>
        </>
    );
}

const Requests = props => {
    return (
        <div className="container pb-5">
            <div className="row">
                <div className="col">
                    <h1>Requests</h1>
                </div>
            </div>
            <BannerLog>
                <RequestsBody/>
            </BannerLog>
        </div>
    )
}

export default Requests;