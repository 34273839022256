import React, {useContext} from 'react';
import { BannerLog } from '../BannerLogContext';
import UserContext from '../../UserContext';

const ToolPage = (props) => {
    const context = useContext(UserContext);
    let subComponents = React.Children.map(props.children, (child) => {
            if(child.type === Header ||
               child.type === How ||
               child.type === Body) {
                return child;
            }
            else {
                return null;
            }
        }
    );

    return (
        <div className="pb-5">
            {context.user && (context.tools.includes(props.toolId) || context.tools.includes("ALL")) ?
                <div className="container">
                    <div className="row align-items-center my-5">
                        {subComponents.map((component) => component)}
                    </div>
                </div>
            :
                <div className="container">
                    <h2>Access Denied</h2>
                    <p>You do not have access to this tool.</p>
                    <p>
                        If you believe this to be an error, please
                        contact the site administrator.
                    </p>
                </div>
            }
        </div>
    );
}

const Header = (props) =>
    <>
        {props.image ? <div className="col-lg-7">
            <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={props.image}
                alt={props.alt}/>
        </div> : null}
        <div className={props.image ? "col-lg-5" : 'col-12'}>
            <h1 className="font-weight-light">{props.toolName}</h1>
            <p>
                {props.children}
            </p>
        </div>
    </>;
ToolPage.Header = Header;

const How = (props) => 
    <div className="col-lg-12">
        <div id="accordion" className="tool-how">
            <div className="card">
                <a data-bs-toggle="collapse" href="#howDoesThisWork">
                    <div className="card-header">
                        <span className="btn">How does this work?</span>
                    </div>
                </a>
                <div id="howDoesThisWork" className="collapse" data-bs-parent="#accordion">
                    <div className="card-body text-start">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    </div>;
ToolPage.How = How;

const Body = (props) => 
    <BannerLog>
        {props.children}
    </BannerLog>;
ToolPage.Body = Body;

export default ToolPage;