import React, {useState, useEffect, useContext} from 'react';

import LoadingSpinner from '../LoadingSpinner';
import chimera from '../../chimera';
import BannerContext from "../BannerLogContext";

const UnityFaxList = props => {
    const [pangeaNumbers, setPangeaNumbers] = useState(null);
    const [phaxioNumbers, setPhaxioNumbers] = useState(null);
    const [loading, setLoading] = useState(true);
    const banners = useContext(BannerContext);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if(pangeaNumbers === null) {
            chimera.callAPI(signal, '/api/unity/dialplan/DID Table/fax')
            .then(numbers => setPangeaNumbers(numbers.filter(number => number.domain === props.domain)))
            .catch(e => {
                if(e.name !== "AbortError") {
                    console.error(e);
                    banners.addBanner('danger', 'Cannot read Pangea fax numbers', 'Error');
                }
            })
        }
        if(phaxioNumbers === null) {
            chimera.callAPI(signal, '/api/phaxio/numbers')
            .then(numbers => setPhaxioNumbers(numbers.filter(number => number.domain === props.domain)))
            .catch(e => {
                if(e.name !== "AbortError") {
                    console.error(e);
                    banners.addBanner('danger', 'Cannot read Phaxio fax numbers', 'Error');
                }
            })
        }
        if(pangeaNumbers !== null && phaxioNumbers !== null) {
            setLoading(false);
        }

        return () => {
            controller.abort();
        }
    }, [pangeaNumbers, phaxioNumbers]);

    return (
        <>
        {loading ?
            <LoadingSpinner size={50}/>
        :
            <div className="text-start">
                <u>Pangea:</u>
                <ol>
                    <>
                    {pangeaNumbers.length > 0 ? 
                        <>{pangeaNumbers.map((num, i) => <li key={i}>{chimera.phoneNumberStr(num.number)}{num.enabled !== "yes" ? ' (DISABLED)' : ''}</li>)}</>
                    :
                        <i className="text-muted">There are no Pangea numbers for this customer.</i>
                    }
                    </>
                </ol>
                <u>Phaxio:</u>
                <ol>
                    <>
                    {phaxioNumbers.length > 0 ? 
                        <>{phaxioNumbers.map((num, i) => <li key={i}>{chimera.phoneNumberStr(num.number)}</li>)}</>    
                    :
                        <i className="text-muted">There are no Phaxio numbers for this customer.</i>
                    }
                    </>
                </ol>
            </div>
        }
        </>
    )
}

export default UnityFaxList