import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';

import chimera from '../../chimera';
import LoadingSpinner from '../LoadingSpinner';
import ReportTable from '../ReportTable';
import BannerContext, {BannerLog} from '../BannerLogContext';

const UnityOldDevicesBody = props => {
    const [report, setReport] = useState(null);
    const [loading, setLoading] = useState(true);
    const [label, setLabel] = useState("");
    const [saveBtnLabel, setSaveBtnLabel] = useState("Save to Chimera");
    const [saveBtnIcon, setSaveBtnIcon] = useState("fas fa-floppy-disk");
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [controller, setController] = useState(new AbortController());
    const [signal, setSignal] = useState(controller.signal);
    const banners = useContext(BannerContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        setLabel("Reading Domains...");
        chimera.callAPI(signal, '/api/unity/domains')
        .then(async domains => {
            const cols = ["Domain", "Subscriber", "User Agent", "Last Seen"];
            let rows = [];
            const threshold = new Date();
            threshold.setDate(threshold.getDate() - 60); // 60 days ago
            const thresholdStr = threshold.toISOString().substring(0,10);
            for(const domain of domains) {
                setLabel(`Reading Devices for ${domain.domain}...`);
                try {
                    const devices = await chimera.callAPI(signal, `/api/unity/domains/${domain.domain}/devices`);
                    for(const device of devices) {
                        if(device.user_agent) {
                            const lastSeenDate = new Date(device.registration_time);
                            if(lastSeenDate.toString() !== "Invalid Date") {
                                const lastSeenDateStr = lastSeenDate.toISOString().substring(0,10);
                                if(thresholdStr >= lastSeenDateStr) {
                                    rows.push({
                                        "Domain": domain.domain,
                                        "Subscriber": device.subscriber_name,
                                        "User Agent": device.user_agent,
                                        "Last Seen": lastSeenDate.toLocaleString()
                                    })
                                }
                            }
                            else {
                                console.log(device);
                            }
                        }
                    }
                }
                catch(err) {
                    if(err.name !== "AbortError") {
                        console.error(err);
                    }
                }
            }
            setReport({type: "UnityOldDevices", cols: cols, rows: rows});
            setLoading(false);
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                if(err.details) {
                    console.error(err.details);
                }
                else {
                    console.error(err);
                }
                banners.addBanner('danger', 'Could not read Unity domains. The job will not continue.', 'Error');
            }
        })
    }, []);

    const saveReport = (event) => {
        event.preventDefault();
        setSaveBtnDisabled(true);
        setSaveBtnIcon("fas fa-spinner");
        setSaveBtnLabel("Saving...");
        chimera.callAPI(signal, '/api/reporttables', 'POST', report)
        .then(_ => {
            setSaveBtnIcon("fas fa-check");
            setSaveBtnLabel("Saved!");
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                console.error(err);
                banners.addBanner('danger', 'An error occurred and the report could not be saved', 'Error');
                setSaveBtnDisabled(false);
                setSaveBtnIcon("fas fa-floppy-disk");
                setSaveBtnLabel("Save to Chimera");
            }
        })
    }

    return (
        <>
        {loading ?
            <LoadingSpinner size={75} label={label}/>
        :
            <>
            <button className="btn btn-primary mb-2" onClick={saveReport} disabled={saveBtnDisabled}>
                <i className={saveBtnIcon}/>
                &nbsp;{saveBtnLabel}
            </button>
            <ReportTable report={report}/>
            </>
        }
        </>
    )
}

const UnityOldDevices = props => {
    return (
        <BannerLog>
            <UnityOldDevicesBody/>
        </BannerLog>
    )
}

export default UnityOldDevices;