import React, {useState, useEffect} from 'react';

import LoadingSpinner from '../LoadingSpinner';
import chimera from '../../chimera';

const UnsentInvoices = props => {
    const [invoices, setInvoices] = useState(null);
    const [showError, setShowError] = useState(false);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if(invoices === null) {
            chimera.callQuickBooksAPI(signal, '/api/qb/invoice/status/0')
            .then(allInvoices => {
                let newInvoices = [];
                for(const invoice of allInvoices) {
                    if(invoice.CustomerRef.value === props.id) {
                        newInvoices.push(invoice);
                    }
                }
                setInvoices(newInvoices);
            })
            .catch(e => {
                if(e.name !== "AbortError") {
                    console.error(e);
                    setShowError(true);
                }
            })
        }
        return () => {
            controller.abort();
        }
    }, [invoices]);

    if(showError) {
        return (
            <p className="text-danger text-start">An error occurred and the list could not be determined.</p>
        )
    }
    else {
        return (
            <>
            {invoices !== null ? 
                <>
                {invoices.length > 0 ?
                    <>
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Invoice Number</th>
                                <th>Link</th>
                            </tr>
                        </thead>
                        <tbody>
                            <>
                            {invoices.slice(0, showMore ? invoices.length : 3).map((invoice, i) => <tr key={i}>
                                <td>{invoice.TxnDate}</td>
                                <td>{invoice.DocNumber}</td>
                                <td><a href={`https://app.qbo.intuit.com/app/invoice?txnId=${invoice.Id}`} target="_blank" rel="noopener noreferrer"><i className="fas fa-up-right-from-square"/></a></td>
                            </tr>)}
                            </>
                        </tbody>
                    </table>
                    {invoices.length > 3 ?
                        <>
                        {showMore ? 
                            <button className="btn btn-secondary btn-sm" onClick={(event) => {event.preventDefault(); setShowMore(false)}}>
                                <i className="fas fa-angle-up"/>&nbsp;
                                Show Less
                            </button>
                        :
                            <button className="btn btn-secondary btn-sm" onClick={(event) => {event.preventDefault(); setShowMore(true)}}>
                                <i className="fas fa-angle-down"/>&nbsp;
                                Show More
                            </button>
                        }
                        </>
                    :null}
                    </>
                :
                    <p className="text-success text-start">All invoices for this customer have been sent!</p>
                }
                </>
            :
                <LoadingSpinner size={50}/>
            }
            </>
        )
    }
}

export default UnsentInvoices;