import React, {useState} from "react";

import FormField from "../FormField";
import Modal, {choiceCancel} from '../Modal';
import chimera from "../../chimera";

function defaultCheckInDate() {
    let sevenDaysLater = new Date();
    sevenDaysLater.setDate(sevenDaysLater.getDate() + 14);
    return sevenDaysLater.toISOString().substring(0,10);
}

const DelayModal = props => {
    const [delayUntilDate, setDelayUntilDate] = useState(defaultCheckInDate());
    const [isSaving, setIsSaving] = useState(false);

    const handleChange = (event) => {
        if(event.target.name === "delayUntilDate") {
            setDelayUntilDate(event.target.value);
        }
    }

    const choices = [
        choiceCancel(props),
        {
            btnColor: 'primary',
            btnInner: <span><i className={isSaving ? "fas fa-spinner" : "fas fa-floppy-disk"}/>&nbsp;{isSaving ? "Saving..." : "Save"}</span>,
            func: async (e) => {
                e.preventDefault();
                setIsSaving(true);
                chimera.callAPI(undefined, '/api/internetOrders/delayUntilDates', 'POST', {delayUntilDate: delayUntilDate, orderIds: props.orders.map((order) => order._id)})
                .then(_ => {
                    props.bannerContext.addBanner('info', 'The selected orders were successfully delayed.');
                })
                .catch(err => {
                    console.error(err);
                    props.bannerContext.addBanner('danger', 'Something went wrong and the Orders could not be delayed.', 'Error');
                })
                .finally(() => {
                    if(props.callback) {
                        props.callback();
                    }
                })
            }
        }
    ]

    return (
        <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Delay</h3>
            <p>Delay the ({props.orders.length}) selected orders until the selected date:</p>
            <FormField
                type="date"
                name="delayUntilDate"
                label="Delay Until Date"
                value={delayUntilDate}
                handleChange={handleChange}
                description="The selected orders will have their status set to Delayed for until the specified date."
                disabled={isSaving}
            />
        </Modal>
    );
}

export default DelayModal;