import React from 'react';

/**
 * TODO: Outline props
 * */
const FormFieldMicro = props => {
    /** 
    let input;
    if(props.type.component) 
    return(
        <>
        <label htmlFor={props.name} className="form-label">
            {props.required ? <span className="red">*</span> : null} {props.label.trim()}:
            {input}
        </label>
        </>
    )
    */
    const descr = `${props.name}Descr`;
    let options = [];
    const disabled = props.disabled || props.sameAs;
    if(props.type === "select") {
        if(!props.excludeNoneSelected)
            options.push(<option value="NOT SET" key={0}>-- None Selected --</option>);
        for(let i = 0; i < props.options.length; i++) {//const opt of props.options) {
            const opt = props.options[i];
            options.push(<option value={opt.id} key={i+1}>{opt.value}</option>);
        }
    }
    let input;
    if(props.type === "select") {
        input = 
            <>
            <br/>
            <select className="form-select centered" name={props.name} onChange={props.handleChange} required={props.required} disabled={disabled} value={props.value}>
                {options}
            </select>
            </>;
    }
    else if(props.type === "textarea") {
        input = 
            <>
            <br/>
            <textarea className="form-control centered" name={props.name} onChange={props.handleChange} disabled={disabled} required={props.required} style={{resize: props.resize ? props.resize : "both"}} placeholder={props.placeholder ? props.placeholder : ""} maxLength={props.maxLength ? props.maxLength : 524288} value={props.value}/>
            </>;
    }
    else if(props.type === "file") {
        input =
        <input id={props.name} name={props.name}  
            className="form-control centered" type={props.type} 
            aria-describedby={descr} 
            onChange={props.handleChange}
            onBlur={typeof(props.onBlur) === "function" ? props.onBlur : () => {}}
            required={props.required}
            disabled={disabled}/>;
    }
    else if(props.type === "number") {
        input =
        <input id={props.name} name={props.name}
            className="form-control centered" type={props.type}
            aria-describedby={descr}
            value={props.value} 
            onChange={props.handleChange}
            onBlur={typeof(props.onBlur) === "function" ? props.onBlur : () => {}}
            required={props.required}
            min={props.min !== undefined ? props.min : null}
            max={props.max !== undefined ? props.max : null}
            step={props.step !== undefined ? props.step : null}
            disabled={disabled}
            size={props.size}
            />;
    }
    else if(props.type === "checkbox") {
        input = 
        <div className="form-check">
            <input id={props.name} name={props.name}
                className="form-check-input float-none" type={props.type}
                aria-describedby={descr}
                checked={props.value} 
                onChange={props.handleChange}
                onBlur={typeof(props.onBlur) === "function" ? props.onBlur : () => {}}
                required={props.required}
                disabled={disabled}/>&nbsp;
            <label className="form-check-label" htmlFor={props.name}>
                {props.checkLabel !== undefined ? props.checkLabel : `${props.value}`.charAt(0).toUpperCase() + `${props.value}`.slice(1)}
            </label>
        </div>
    }
    else if(props.type === "range") {
        input = <>
            <input type="range" class="form-range" value={props.value} min={props.min} max={props.max} step={props.step} id={props.name} name={props.name} onChange={props.handleChange}/><br/>
            <span>{props.value}</span>
        </>
    }
    else if(props.type === "component") {
        let children = React.Children.map(props.children, (child) => child);
        input = children[0];
        //input.props.disabled = disabled;
    }
    else {
        input =
            <input id={props.name} name={props.name}  
                className="form-control centered" type={props.type} 
                aria-describedby={descr}
                value={props.value} 
                onChange={props.handleChange}
                onBlur={typeof(props.onBlur) === "function" ? props.onBlur : () => {}}
                required={props.required}
                maxLength={props.maxLength ? props.maxLength : 524288}
                placeholder={props.placeholder ? props.placeholder : ""}
                size={props.size}
                pattern={props.pattern}
                disabled={disabled}/>;
    }
    let classes = ['form-label', 'text-start']
    if(props.fullWidth) classes.push('w-100');
    else if(props.fit) classes.push('w-fit');
    if(props.classes) {
        for(const c of props.classes) {
            classes.push(c);
        }
    }
    return(
        <>
        <label htmlFor={props.name} className={classes.join(' ')}>
            {props.required ? <span className="red">*</span> : null} {props.label.trim()}:
            {props.sameAsLabel ? 
            <div className="form-check d-flex justify-content-center">
                <input className="form-check-input" type="checkbox" name={props.sameAsPath} id={props.sameAsPath} checked={props.sameAs} onChange={props.handleChange} disabled={props.disabled}/>
                &nbsp;
                <label className="form-check-label" htmlFor={props.sameAsPath}>
                    {props.sameAsLabel}
                </label>
            </div>
            :null}
            &nbsp;&nbsp;
            {input}
            {props.type !== "component" ? <>{props.children}</> : null}
        </label>
        </>
    );
}

export default FormFieldMicro;