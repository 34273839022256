import React, {useState, useContext} from 'react';
import chimera from '../chimera';
import BannerContext, {BannerLog} from './BannerLogContext';
import Modal, {choiceCancel} from './Modal';
import LoadingSpinner from './LoadingSpinner';

const EmailModalBody = props => {
    const [sending, setSending] = useState(false);
    const banners = useContext(BannerContext);

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }
    
    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        const name = event.target.name;
        const value = event.target.type !== "checkbox" ? event.target.value : event.target.checked;
        if(name === "to") {
            props.setTo(value);
        }
        else if(name === "cc") {
            props.setCc(value);
        }
        else if(name === "bcc") {
            props.setBcc(value);
        }
        else if(name === "subject") {
            props.setSubject(value);
        }
        else if(name === "text") {
            props.setText(value);
        }
    }

    const emailIsValid = (email) => {
        return email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g);
    }

    const emailsAsArray = (str) => {
        if(!str) return undefined;
        return str.split(/[\s,]+/);
    }

    const handleSend = (event) => {
        event.preventDefault();
        // Verify
        const toArray = emailsAsArray(props.to);
        const ccArray = emailsAsArray(props.cc);
        const bccArray = emailsAsArray(props.bcc);

        if(toArray) {
            for(const email of toArray) {
                if(!emailIsValid(email)) {
                    banners.addBanner('danger', `"${email}" is not a valid email address for field 'To'`, 'Invalid Email');
                    return;
                }
            }
        }

        if(ccArray) {
            for(const email of ccArray) {
                if(!emailIsValid(email)) {
                    banners.addBanner('danger', `"${email}" is not a valid email address for field 'CC'`, 'Invalid Email');
                    return;
                }
            }
        }

        if(bccArray) {
            for(const email of bccArray) {
                if(!emailIsValid(email)) {
                    banners.addBanner('danger', `"${email}" is not a valid email address for field 'BCC'`, 'Invalid Email');
                    return;
                }
            }
        }

        // Send
        setSending(true);
        chimera.callAPI(undefined, '/api/sendmail2', 'POST', {
            from: 'FIBER',
            mail: {
                to: toArray,
                cc: ccArray,
                bcc: bccArray,
                subject: props.subject,
                text: props.text
            }
        })
        .then(_ => {
            props.setSent(true);
            setSending(false);
            if(props.callback) props.callback();
        })
        .catch(err => {
            console.error(err);
            setSending(false);
            if(err.details.code === 'EAUTH') {
                banners.addBanner('danger', `The email could not be sent due to an invalid login: ${err.details.response}`, 'Email Authentication Error');
            }
            else {
                banners.addBanner('danger', 'Failed to send the email', 'Error');
            }
        })
    }

    return(
        <div className="mb-2">
            {sending ? <LoadingSpinner size={75}/> : 
            <>
            {props.sent ? 
                <div className="row mb-2">
                    <h3>Email Sent Successfully!</h3>
                    <h5 className="text-success"><i className="fas fa-check"/></h5>
                </div>
            :
            <>
            <div className="row mb-2">
                <h3>Send Email</h3>
            </div>
            <div className="row mb-2">
                <div className="col-1 text-start">
                    <b>To:</b>
                </div>
                <div className="col-11">
                    <input className="form-control" type="text" name="to" value={props.to} onChange={handleChange} onBlur={trimOnBlur}/>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-1 text-start">
                    <b>CC:</b>
                </div>
                <div className="col-11">
                    <input className="form-control" type="text" name="cc" value={props.cc} onChange={handleChange} onBlur={trimOnBlur}/>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-1 text-start">
                    <b>BCC:</b>
                </div>
                <div className="col-11">
                    <input className="form-control" type="text" name="bcc" value={props.bcc} onChange={handleChange} onBlur={trimOnBlur}/>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-1 text-start">
                    <b>Subject:</b>
                </div>
                <div className="col-11">
                    <input className="form-control" type="text" name="subject" value={props.subject} onChange={handleChange} onBlur={trimOnBlur}/>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-1 text-start">
                    <b>Text:</b>
                </div>
                <div className="col-11">
                    <textarea className="form-control" rows={10} name="text" value={props.text} onChange={handleChange} onBlur={trimOnBlur}/>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <button className="btn btn-primary w-fit" onClick={handleSend}>
                    <i className="fas fa-envelope"/>&nbsp;Send Email
                </button>
            </div>
            </>
            }
            </>
            }
        </div>
    )
}

const EmailModal = props => {
    const [to, setTo] = useState(props.to ? props.to : "");
    const [cc, setCc] = useState(props.cc ? props.cc : "");
    const [bcc, setBcc] = useState(props.bcc ? props.bcc : "");
    const [subject, setSubject] = useState(props.subject ? props.subject : "");
    const [text, setText] = useState(props.text ? props.text : "");
    const [sent, setSent] = useState(false);

    const choices = [
        choiceCancel(props, !sent, <>
            <h3>This message has not been sent</h3>
            <p>Are you sure you want to close this form? Your changes will not be saved.</p>
        </>, "Close")
    ]

    return (
        <Modal choices={choices} dismiss={choices[0].func}>
            <BannerLog>
                <EmailModalBody {...props}
                    to={to} setTo={setTo}
                    cc={cc} setCc={setCc}
                    bcc={bcc} setBcc={setBcc}
                    subject={subject} setSubject={setSubject}
                    text={text} setText={setText}
                    sent={sent} setSent={setSent}
                />
            </BannerLog>
        </Modal>
    )
}

export default EmailModal;