import React from 'react';

/**
 * 
 * @param {*} props 
 * @returns 
 */
const Tooltip = props => {
    return(
        <div className="tooltip-container border-0">
            <span className={props.pos === "bottom" ? "tooltip-text-bottom" : "tooltip-text"}>
                {props.text}
            </span>
            {props.children}
        </div>
    )
}

export default Tooltip;