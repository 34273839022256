import React from 'react';

const Bubble = props => {
    return (
        <span className={`${props.inline ? "" : "position-absolute top-0 start-100 translate-middle"} badge rounded-pill bg-${props.bg ? props.bg : 'danger'}`}>
            {props.children}
        </span>
    )
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
const AlertBubble = props => {
    return(
        props.reason ?
        <div className="tooltip-container border-0">
            <span className="tooltip-text">
                {props.reason}
            </span>
            <Bubble {...props}/>
        </div>
        : <Bubble {...props}/>
    )
}

export default AlertBubble;