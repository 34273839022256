import React, {useState, useEffect, useContext} from 'react';
import BannerContext, { BannerLog } from '../BannerLogContext';
import ModalContext from '../ModalContext';
import chimera from '../../chimera';
import VendorsTable from './VendorsTable';
import VendorFormModal from './VendorFormModal';
import { useParams, useNavigate } from 'react-router-dom';
import AlertsContext from '../AlertsContext';

const VendorsBody = props => {
    const [vendors, setVendors] = useState(null);
    const [openVendorId, setOpenVendorId] = useState(null);
    const modaling = useContext(ModalContext);
    const banners = useContext(BannerContext);
    const alerts = useContext(AlertsContext);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if(id) {
            setOpenVendorId(id);
        }
    }, [id]);

    useEffect(() => {
        if(openVendorId) {
            modaling.setModal(<VendorFormModal vendorId={openVendorId} modalContext={modaling} banners={banners} onClose={onModalClose}/>);
        }
    }, [openVendorId]);

    useEffect(() => {
        if(vendors === null) {
            chimera.callAPI(undefined, '/api/vendors')
            .then(newVendors => setVendors(newVendors))
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to read Vendors; they cannot be displayed.', 'Error');
            })
        }
    }, [vendors]);

    const onModalClose = () => {
        setOpenVendorId(null);
        setVendors(null);
        modaling.setModal(null);
        alerts.reload();
        navigate('/vendors');
    }

    return (<div>
        <div className="row">
            <div className="col">
                <VendorsTable vendors={vendors} setVendors={setVendors} clickedVendor={(vendor) => navigate(`/vendors/${vendor._id}`)}/>
            </div>
        </div>
    </div>)
}

const Vendors = props => {
    return (
        <div className="container pb-5">
            <h1>
                Vendors
            </h1>
            <BannerLog>
                <VendorsBody/>
            </BannerLog>
        </div>
    )
}

export default Vendors;