import React, {useState, useLayoutEffect, useContext} from "react";
import chimera from "../../chimera";
import techbillimg from "../../images/techbill.png";
import BannerLogContext from "../BannerLogContext";
import FormField from "../FormField";
import ToolPage from './ToolPage';

const TechnicianBillabilityBody = props => {
    const dateInLastMonth = new Date();
    dateInLastMonth.setMonth(dateInLastMonth.getMonth() - 1);
    const daysInLastMonth = chimera.extrapolateDaysInMonth(dateInLastMonth.getFullYear(), dateInLastMonth.getMonth());
    const [startDate, setStartDate] = useState(daysInLastMonth[0]);
    const [endDate, setEndDate] = useState(daysInLastMonth[daysInLastMonth.length-1]);
    const [submitBtnLabel, setSubmitBtnLabel] = useState("Submit");
    const [submitBtnIcon, setSubmitBtnIcon] = useState("fas fa-arrow-right");
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
    const [downloadBtnLabel, setDownloadBtnLabel] = useState("Download");
    const [downloadBtnIcon, setDownloadBtnIcon] = useState("fas fa-download");
    const [downloadBtnDisabled, setDownloadBtnDisabled] = useState(false);
    const [blob, setBlob] = useState(null);
    const [controller, setController] = useState(new AbortController());
    const [signal, setSignal] = useState(controller.signal);
    const banners = useContext(BannerLogContext);
    const percentFormat = "0.00%; 0.00%; -";
    const dollarFormat = "$#,##0.00; ($#,##0.00); -";
    const percentStyle = {
        numberFormat: percentFormat
    }
    const dollarStyle = {
        numberFormat: dollarFormat
    }

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    const getTicketTimers = () => {
        return new Promise((resolve, reject) => {
            // Expand range by 1 day on both sides since it is a lt and not lte operation, to include the startDate/endDate itself.
            const dayBeforeStartDate = chimera.dateFromISOFragment(startDate);
            dayBeforeStartDate.setDate(dayBeforeStartDate.getDate() - 1);
            const dayBeforeStartDateStr = chimera.dateToISOFragment(dayBeforeStartDate);

            const dayAfterEndDate = chimera.dateFromISOFragment(endDate);
            dayAfterEndDate.setDate(dayAfterEndDate.getDate()+1);
            const dayAfterEndDateStr = chimera.dateToISOFragment(dayAfterEndDate);

            chimera.callSyncroAPI(signal, 'GET', `/ticket_timers/range/${dayBeforeStartDateStr}/${dayAfterEndDateStr}`, null, () => {setSubmitBtnLabel("Waiting out Syncro API limits for 1 minute...")}, () => {setSubmitBtnLabel("Loading...")})
            .then(ticketTimers => {
                resolve(ticketTimers.filter(ticketTimer => {
                    // Ensure the time logged is actually within the range, since we queried the CREATION date but not the START/END TIMES
                    const startTimeStr = ticketTimer.start_time.substring(0,10);
                    const endTimeStr = ticketTimer.end_time.substring(0,10);
                    return startTimeStr >= startDate && endTimeStr <= endDate
                }));
            })
            .catch(err => {
                reject(err);
            })
        })
    }

    // Returns an object where Ticket IDs are the keys and arrays of the associated ticket timers are the values
    const organizeTicketTimersByTicketID = (ticketTimers) => {
        let organizedTicketTimers = {};
        for(const ticketTimer of ticketTimers) {
            if(organizedTicketTimers[ticketTimer.ticket_id] === undefined) {
                organizedTicketTimers[ticketTimer.ticket_id] = [ticketTimer];
            }
            else {
                organizedTicketTimers[ticketTimer.ticket_id].push(ticketTimer);
            }
        }
        return organizedTicketTimers;
    }

    const organizeTicketTimersBySyncroUser = (ticketTimers) => {
        let organizedTicketTimers = {};
        for(const ticketTimer of ticketTimers) {
            if(organizedTicketTimers[ticketTimer.user_id] === undefined) {
                organizedTicketTimers[ticketTimer.user_id] = [ticketTimer];
            }
            else {
                organizedTicketTimers[ticketTimer.user_id].push(ticketTimer);
            }
        }
        return organizedTicketTimers;
    }

    const getTicketNumber = (ticketId) => {
        return new Promise((resolve, reject) => {
            chimera.callSyncroAPI(signal, 'GET', `/tickets/${ticketId}`, null, () => {setSubmitBtnLabel("Waiting out Syncro API limits for 1 minute...")}, () => {setSubmitBtnLabel("Loading...")})
            .then(ticket => {
                resolve(ticket.number)
            })
            .catch(err => {
                reject(err);
            })
        })
    }

    const getSyncroUsers = () => {
        return new Promise((resolve, reject) => {
            chimera.callSyncroAPI(signal, 'GET', '/users', null, () => {setSubmitBtnLabel("Waiting out Syncro API limits for 1 minute...")}, () => {setSubmitBtnLabel("Loading...")})
            .then(users => {
                console.log(`users`);
                console.log(users);
                resolve(users.map(user => {
                    return {
                        id: user[0],
                        name: user[1]
                    }
                }))
            })
            .catch(err => {
                reject(err);
            })
        })
    }

    const getSyncroUserNameByID = (syncroUsers, id) => {
        for(const user of syncroUsers) {
            if(user.id.toString() === id.toString()) return user.name;
        }
        return "";
    }

    const getProductHours = (laborLogs, productName) => {
        let productId;
        if(productName === "ITSA") productId = 15552228;
        else if(productName === "NC-Labor") productId = 11386364;
        if(!productId) return 0;
        let total = 0;
        for(const laborLog of laborLogs) {
            if(laborLog.product_id === productId) {
                // Determine the number of hours
                const startTime = new Date(laborLog.start_time);
                const endTime = new Date(laborLog.end_time);
                const diff = endTime.getTime() - startTime.getTime();
                total += diff / 3600 / 1000; // milliseconds converted into hours
            }
        }
        return total;
    }

    const creditHoursApplied = (lineItemDescription) => {
        const phraseRegex = /Applied \d+(\.\d+)? Credited Labor Hours/g
        const qtyRegex = /\d+(\.\d+)?/g
        const matches = lineItemDescription.match(phraseRegex);
        if(matches) {
            const phrase = matches[0];
            const qty = parseFloat(phrase.match(qtyRegex)[0]);
            return qty;
        }
        else {
            return 0;
        }
    }

    const createDetailedDataSet = (data, syncroUsers) => {
        const cols = [
            {
                string: "Ticket #",
                key: "ticketNumber"
            },
            {
                string: "Ticket ID",
                key: "ticketId"
            },
            {
                string: "Technician",
                key: "technician"
            },
            {
                string: "ITSA Hours",
                key: "itsaHours"
            },
            {
                string: "NC-Labor Hours",
                key: "nclaborHours"
            },
            {
                string: "Total ITSA",
                key: "itsaTotal"
            },
            {
                string: "Total NC-Labor",
                key: "nclaborTotal"
            },
            {
                string: "ITSA Ratio",
                key: "itsaRatio"
            },
            {
                string: "NC-Labor Ratio",
                key: "nclaborRatio"
            },
            {
                string: "Total Amount",
                key: "amountTotal"
            },
            {
                string: "Amount Contributed (Before Credit Hours Applied)",
                key: "amountContributed"
            },
            {
                string: "Amount Contributed (After Credit Hours Applied)",
                key: "realAmountContributed"
            },
            {
                string: "Included in QB Invoices",
                key: "includedInvoices"
            }
        ]
        let rows = [];
        const highlightFill = {
            type: 'pattern',
            patternType: 'solid',
            fgColor: '#FFEB9C'
        }
        const highlightFont = {
            color: "#9C5700"
        }
        const highlightPercentStyle = {
            numberFormat: percentFormat,
            fill: highlightFill,
            font: highlightFont
        }
        const highlightDollarStyle = {
            numberFormat: dollarFormat,
            fill: highlightFill,
            font: highlightFont
        }
        const highlightStyle = {
            fill: highlightFill,
            font: highlightFont
        }
        for(const ticket of data) {
            const organizedTicketTimers = organizeTicketTimersBySyncroUser(ticket.laborLogs);
            const itsaTotal = getProductHours(ticket.laborLogs, "ITSA");
            const nclaborTotal = getProductHours(ticket.laborLogs, "NC-Labor");
            const highlight = itsaTotal > 0 && ticket.amount === 0;
            for(const userId in organizedTicketTimers) {
                const userName = getSyncroUserNameByID(syncroUsers, userId);
                const itsaHours = getProductHours(organizedTicketTimers[userId], "ITSA");
                const nclaborHours = getProductHours(organizedTicketTimers[userId], "NC-Labor");
                rows.push({
                    ticketNumber: {string: ticket.ticketNumber.toString(), style: highlight ? highlightStyle : {}},
                    ticketId: {string: ticket.ticketId.toString(), style: highlight ? highlightStyle : {}},
                    technician: {string: userName, style: highlight ? highlightStyle : {}},
                    itsaHours: {number: itsaHours, style: highlight ? highlightStyle : {}},
                    nclaborHours: {number: nclaborHours, style: highlight ? highlightStyle : {}},
                    itsaTotal: {number: itsaTotal, style: highlight ? highlightStyle : {}},
                    nclaborTotal: {number: nclaborTotal, style: highlight ? highlightStyle : {}},
                    itsaRatio: {number: itsaTotal > 0 ? itsaHours / itsaTotal : 0, style: highlight ? highlightPercentStyle : percentStyle},
                    nclaborRatio: {number: nclaborTotal > 0 ? nclaborHours / nclaborTotal : 0, style: highlight ? highlightPercentStyle : percentStyle},
                    amountTotal: {number: itsaTotal > 0 ? ticket.amount : 0, style: highlight ? highlightDollarStyle : dollarStyle}, // if ITSA time is 0, amount is 0. the amount comes from labor outside the date range of this report.
                    amountContributed: {number: itsaTotal > 0 ? ticket.amount * (itsaHours / itsaTotal) : 0, style: highlight ? highlightDollarStyle : dollarStyle},
                    realAmountContributed: {number: itsaTotal > 0 ? ticket.realAmount * (itsaHours / itsaTotal) : 0, style: highlight ? highlightDollarStyle : dollarStyle},
                    includedInvoices: {string: ticket.includedInInvoices.map(invoice => `Invoice ${invoice.docNumber} (ID: ${invoice.id})`).join('; '), style: highlight ? highlightStyle : {}}
                })
            }
        }
        return {
            worksheet: "Details",
            headers: cols,
            data: rows
        };
    }

    const createSummaryDataSet = (detailedDataSet, tickets) => {
        const cols = [
            {
                string: "Technician",
                key: "technician"
            },
            {
                string: "ITSA Hours",
                key: "itsaHours"
            },
            {
                string: "NC-Labor Hours",
                key: "nclaborHours"
            },
            {
                string: "Percentage of Total ITSA",
                key: "itsaPercent"
            },
            {
                string: "Percentage of Total NC-Labor",
                key: "nclaborPercent"
            },
            {
                string: "Amount Contributed (Before Credit Hours Applied)",
                key: "amount"
            },
            {
                string: "Amount Contributed (After Credit Hours Applied)",
                key: "realAmount"
            }
        ]
        let rows = [];
        let techRows = {};
        let itsaTotalHours = 0;
        let nclaborTotalHours = 0;
        for(const ticket of tickets) {
            itsaTotalHours += getProductHours(ticket.laborLogs, "ITSA");
            nclaborTotalHours += getProductHours(ticket.laborLogs, "NC-Labor");
        }
        for(const data of detailedDataSet.data) {
            const techName = data.technician.string;
            if(techRows[techName] === undefined) {
                techRows[techName] = {
                    itsaHours: 0,
                    nclaborHours: 0,
                    amount: 0,
                    realAmount: 0
                };
            }
            techRows[techName].itsaHours += data.itsaHours.number;
            techRows[techName].nclaborHours += data.nclaborHours.number;
            techRows[techName].amount += data.amountContributed.number;
            techRows[techName].realAmount += data.realAmountContributed.number;
        }
        for(const techName in techRows) {
            const itsaPercent = itsaTotalHours > 0 ? techRows[techName].itsaHours / itsaTotalHours : 0;
            const nclaborPercent = nclaborTotalHours > 0 ? techRows[techName].nclaborHours / nclaborTotalHours : 0;

            rows.push({
                technician: {string: techName},
                itsaHours: {number: techRows[techName].itsaHours},
                nclaborHours: {number: techRows[techName].nclaborHours},
                itsaPercent: {number: itsaPercent, style: percentStyle},
                nclaborPercent: {number: nclaborPercent, style: percentStyle},
                amount: {number: techRows[techName].amount, style: dollarStyle},
                realAmount: {number: techRows[techName].realAmount, style: dollarStyle},
            })
        }
        return {
            worksheet: "Summary",
            headers: cols,
            data: rows
        };
    }

    const handleSubmit = async(event) => {
        event.preventDefault();
        setSubmitBtnIcon("fas fa-spinner");
        setSubmitBtnLabel("Loading...");
        setSubmitBtnDisabled(true);

        let data = [];
        let ticketTimers, invoices, syncroUsers;

        // Pull Labor Logs in date range
        // Group Labor Logs by Ticket ID
        // Look up Ticket Numbers for each Ticket
        try {
            ticketTimers = await getTicketTimers();
            console.log(ticketTimers);
        }
        catch(err) {
            console.error(err);
            banners.addBanner('danger', 'Failed to read Labor Logs; the job cannot continue', 'Error');
            setSubmitBtnIcon("fas fa-arrow-right");
            setSubmitBtnLabel("Submit");
            setSubmitBtnDisabled(false);
            return;
        }

        const organizedTicketTimers = organizeTicketTimersByTicketID(ticketTimers);
        console.log(organizedTicketTimers);

        for(const ticketId in organizedTicketTimers) {
            try {
                data.push(
                    {
                        ticketId: ticketId,
                        ticketNumber: await getTicketNumber(ticketId),
                        laborLogs: organizedTicketTimers[ticketId],
                        amount: 0,
                        realAmount: 0,
                        includedInInvoices: []
                    }
                )
            }
            catch(err) {
                console.error(err);
                banners.addBanner('warning', `Failed to read Ticket Number for Ticket ID ${ticketId}; it will not be included in the report`, 'Warning');
            }
        }

        // Pull QB invoices
        try {
            // We project the date range because the QB invoices are created after the ticket range in question
            let monthAfterEndDate = chimera.dateFromISOFragment(endDate);
            monthAfterEndDate.setMonth(monthAfterEndDate.getMonth() + 1);
            let monthAfterEndDateStr = monthAfterEndDate.toISOString().substring(0,10);
            console.log(`querying invoices between ${startDate} - ${monthAfterEndDateStr}`);
            invoices = await chimera.callQuickBooksAPI(signal, `/api/qb/invoice/syncro/range/${startDate}/${monthAfterEndDateStr}`);
        }
        catch(err) {
            console.error(err);
            banners.addBanner('danger', 'Failed to read QuickBooks Invoices; the job cannot continue', 'Error');
            setSubmitBtnIcon("fas fa-arrow-right");
            setSubmitBtnLabel("Submit");
            setSubmitBtnDisabled(false);
            return;
        }

        console.log(invoices);

        // Pore through invoices to get Amounts for each Ticket
        for(const ticket of data) {
            for(const invoice of invoices) {
                for(const line of invoice.Line) {
                    if(line.DetailType === "SalesItemLineDetail") {
                        if(line.Description === undefined) {
                            continue; // Assuming a Description is always defined for ITSA line items (an automated process), this is safe.
                        }
                        else if(line.Description.startsWith("Ticket#")) {
                            const ticketNumber = line.Description.split(",")[0].split("#")[1];
                            if(ticketNumber === ticket.ticketNumber.toString()) {
                                // (Credit Hours + QB Qty) * QB Rate = Amount
                                let qty = line.SalesItemLineDetail.Qty;
                                let rate = line.SalesItemLineDetail.UnitPrice;
                                ticket.amount += (creditHoursApplied(line.Description) + (qty ? qty : 0)) * (rate ? rate : 0); // must check if these are defined, because they aren't always
                                ticket.realAmount += line.Amount;
                                ticket.includedInInvoices.push({id: invoice.Id, docNumber: invoice.DocNumber});
                            }
                        }
                    }
                }
            }
        }

        // Pull Syncro users
        try {
            syncroUsers = await getSyncroUsers();
        }
        catch(err) {
            console.error(err);
            banners.addBanner('danger', 'Failed to read Syncro users; the job cannot continue', 'Error');
            setSubmitBtnIcon("fas fa-arrow-right");
            setSubmitBtnLabel("Submit");
            setSubmitBtnDisabled(false);
            return;
        }

        // Use data collections to create excel4node datasets
        const detailedDataSet = createDetailedDataSet(data, syncroUsers);
        const summaryDataSet = createSummaryDataSet(detailedDataSet, data);

        // Create XLSX blob
        try {
            const xlsxBlob = await chimera.callAPI(signal, '/api/xlsx', 'POST', {
                dataSets: [summaryDataSet, detailedDataSet]
            }, 'blob');
            setBlob(xlsxBlob);
        }
        catch(err) {
            console.error(err);
            banners.addBanner('danger', 'Failed to generate Excel document; the job cannot continue', 'Error');
            setSubmitBtnIcon("fas fa-arrow-right");
            setSubmitBtnLabel("Submit");
            setSubmitBtnDisabled(false);
            return;
        }

        console.log(data);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if(name === "startDate")
            setStartDate(value > endDate ? endDate : value);
        else if(name === "endDate")
            setEndDate(value < startDate ? startDate : value);
    }

    const handleDownload = (event) => {
        event.preventDefault();
        setDownloadBtnIcon("fas fa-spinner");
        setDownloadBtnDisabled(true);
        setDownloadBtnLabel("Downloading...");
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Technician Billability Report ${startDate}_${endDate}.xlsx`;
        link.click();
        setDownloadBtnIcon("fas fa-download");
        setDownloadBtnDisabled(false);
        setDownloadBtnLabel("Download");
    }

    return (
        <div className="col-lg-12">
            {blob !== null ? 
            <div>
                {/** Show Download */}
                <button className="btn btn-success" onClick={handleDownload} disabled={downloadBtnDisabled}>
                    <i className={downloadBtnIcon}/>&nbsp;{downloadBtnLabel}
                </button>
            </div>
            :
            <form id="techbillform" onSubmit={handleSubmit} noValidate>
                <FormField
                    type="date"
                    name="startDate"
                    label="Start Date"
                    value={startDate}
                    description="Process Labor Logs recorded on or after this date."
                    handleChange={handleChange}
                    disabled={submitBtnDisabled}
                    required
                />
                <FormField
                    type="date"
                    name="endDate"
                    label="End Date"
                    value={endDate}
                    description="Process Labor Logs recorded on or before this date."
                    handleChange={handleChange}
                    disabled={submitBtnDisabled}
                    required
                />
                <button type="submit" className="btn btn-primary" disabled={submitBtnDisabled}>
                    <i className={submitBtnIcon}></i>
                    &nbsp;{submitBtnLabel}
                </button>
            </form>
            }
        </div>
    );
}

const TechnicianBillability = props => {
    const toolName = "Technician Billability";
    const toolId = "techbill";
    return(
        <ToolPage toolId={toolId} toolName={toolName}>
            <ToolPage.Header image={techbillimg} alt="placeholder image" toolName={toolName}>
                Generate an Excel spreadsheet showing the workload spread and billability of every CBIT tech registered on Syncro.
            </ToolPage.Header>
            <ToolPage.How>
                <h3>About the Report</h3>
                <p>
                    This tool generates an Excel document containing 2 spreadsheets: a "Summary" tab and a "Details" tab.
                </p>
                <p>
                    The Summary spreadsheet contains a row for every technician registered in Syncro, showing their:
                </p>
                <ul>
                    <li>
                        Total number of ITSA hours logged during the specified timeframe
                    </li>
                    <li>
                        Total number of NC-Labor hours logged during the specified timeframe
                    </li>
                    <li>
                        Percentage of the total ITSA hours across all technicians that was contributed by them
                    </li>
                    <li>
                        Percentage of the total NC-Labor hours across all technicians that was contributed by them
                    </li>
                    <li>
                        Total Amount Contributed before applying Labor Credit Hours
                    </li>
                </ul>
                <p>
                    The Details spreadsheet shows a full breakdown per technician per ticket. Only the time logged within the specified timeframe is considered;
                    tickets with time logged both outside and inside the timeframe will not be fully represented (that data will appear when the report is run for a timeframe that includes it).
                    If multiple technicians worked on the same ticket, there will be multiple rows for the same ticket to show the breakdown per technician. Otherwise, it is largely one ticket per row.
                    The columns are:
                </p>
                <ul>
                    <li>
                        The ticket number
                    </li>
                    <li>
                        The ticket ID (useful for navigating to the ticket quickly via URL. It will be accessible at <i>https://gocbit.syncromsp.com/tickets/{'{{TICKET_ID}}'}</i> )
                    </li>
                    <li>
                        The technician's name
                    </li>
                    <li>
                        The ITSA hours logged by the technician on the ticket during the specified timeframe
                    </li>
                    <li>
                        The NC-Labor hours logged by the technician on the ticket during the specified timeframe
                    </li>
                    <li>
                        The total number of ITSA hours logged on the tickets by all technicians during the specified timeframe
                    </li>
                    <li>
                        The total number of NC-Labor hours logged on the tickets by all technicians during the specified timeframe
                    </li>
                    <li>
                        The percentage of the ITSA hours logged by the technician (ITSA Hours logged by the technician / Total ITSA hours logged for the ticket)
                    </li>
                    <li>
                        The percentage of the NC-Labor hours logged by the technician (NC-Labor Hours logged by the technician / Total NC-Labor hours logged for the ticket)
                    </li>
                    <li>
                        The total dollar amount contributed by all time logged on the ticket within the specified timeframe
                    </li>
                    <li>
                        The total dollar amount contributed by the technician alone
                    </li>
                    <li>
                        The list of QuickBooks invoices (with DocNumber and ID) that the ticket appears in
                    </li>
                </ul>
                <h3>Usage</h3>
                <p>
                    The <b>Start Date</b> and <b>End Date</b> fields below specify a timeframe for the report. They default to the first and last day of the previous month relative to the current date.
                    This timeframe represents the timeframe in which the work that you want to capture was done.
                </p>
                <p>
                    With the timeframe set, <b>click Submit</b> to start running the task. This process can take a few minutes for a month-long range, and even longer for longer timeframes.
                    This is due largely to limitations to the Syncro API (see Methodology for details).
                </p>
                <p>
                    When it's done loading, a Download button will appear. <b>Click the Download button</b> to download the report, whose filename contains the date range specified by Start Date and End Date.
                </p>
                <h3>Methodology</h3>
                <ol>
                    <li>
                        First, this tool pulls "Ticket Timers" (also called Labor Logs on the Syncro frontend) from the Syncro API. The only way to query Labor Logs is by using a date range.
                        The Syncro API returns all Labor Logs within the date range, but <i>exclusively</i> rather than <i>inclusively</i> (think of the difference between "greater than" and "greater than or equal to").
                        This can cause Labor Logs that were filed exactly on, say, the End Date, to be missed, so the tool casts the net a little wider by actually requesting all Labor Logs in a date range broadened on both ends by 1 day.
                    </li>
                    <li>
                        Then, the Labor Logs are organized by Ticket ID, so that all Labor Logs for a given ticket are grouped together. The tool iterates over all the Ticket IDs and uses them to send a Syncro API request per Ticket ID to find the Ticket Number.
                        Unfortunately, there is no way to do a batch query, so there is <i>1 API request per ticket</i> happening here, which generates a lot of requests and slows the tool down, hence why it takes a few minutes and takes even longer the wider the date range.
                        This is futher exacerbated by the rate limit for the Syncro API. When too many requests are made, we have to pause execution and wait for 1 minute before continuing with the API requests. The button text will update to say "Waiting out Syncro API limitations for 1 minute..." when this occurs.
                    </li>
                    <li>
                        Third, the QuickBooks invoices are pulled. These are queried by accessing only the Syncro-generated invoices (those in the 11000-11999 range) that were created within a given timeframe. Because invoices are created at the start of a month to reflect the previous month's work,
                        the timeframe specified by Start Date and End Date is not useful. Instead, the End Date is projected out by a month, and any invoices created between the Start Date and 1 month after the End Date are pulled.
                        The tool then goes through all the invoices, matching them up to the tickets by the Ticket Number contained in their line item descriptions, so that it can gather authoritative information about what was actually billed for that ticket.
                    </li>
                    <li>
                        The list of Syncro users are pulled via the Syncro API. This is used to give a readable name to the technicians, since all the Labor Logs contain by themselves are the user IDs. The IDs are matched
                        to find the names. Querying the users each time the tool is ran instead of cacheing the users is done so that the tool remains dynamic as technicians are added/removed in the future.
                    </li>
                    <li>
                        With all this information, the tool is finally able to compile the report with some math. The Syncro Labor Logs are used to determine the ratio of work done, while the QuickBooks invoices are used to determine the amount that was billed.
                        Applying the ratio from Syncro to the amounts from QuickBooks lets us arrive at an accurate account of how much was contributed by each technician.
                    </li>
                </ol>
                <b>WARNING: The only way to query Labor Logs is by their CREATION DATE, which may vary from the date the work was done. Therefore, there's no way to guarantee accuracy in the case that time is logged too long after it is done, especially if it is logged after the End Date.</b>
            </ToolPage.How>
            <ToolPage.Body>
                <TechnicianBillabilityBody authenticated={props.authenticated}/>
            </ToolPage.Body>
        </ToolPage>
    );
}

export default TechnicianBillability;