import React from 'react';

/** @param options An array of objects containing the following keys: `text`, `icon`, `func` */
const ButtonOptionList = props => {
    return(<>
        {props.options.map((option, index) => 
            <div className="row mb-3 mt-3" key={index}>
                <div className="col-4"/>
                <div className="col-4">
                    <button className="btn btn-primary w-100" onClick={option.func ? option.func : (e) => {e.preventDefault();}} disabled={props.disabled || option.disabled}>
                        {option.icon ? <><i className={option.icon}/>&nbsp;</> : null}{option.text}
                    </button>
                </div>
                <div className="col-4"/>
            </div>
        )}
    </>)
}

export default ButtonOptionList;