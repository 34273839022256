import React, {useState, useLayoutEffect, useContext} from "react";
import syncroinvoiceimg from "../../images/syncroinvoiceimg.png";
import ProgressBar from "../ProgressBar";
import ToolPage from './ToolPage';
import chimera from "../../chimera";
import BannerContext from "../BannerLogContext";

class ConsolidatedLineItemsList {
    constructor() {
        this.list = [];
    }

    get consolidatedList() {
        // Iterate over list to return just the line items,
        // // not the ticket number.
        let lineItems = [];
        for(const obj of this.list) {
            lineItems.push(obj.lineItem);
        }
        return lineItems;
    }

    getTicketNumber = (desc) => {
        if(desc.startsWith("Ticket#")) {
            // Example: "Ticket#21665, regarding issue..."
            return desc.split(",")[0].split("#")[1];
        }
        else {
            return null;
        }
    }

    addToList(item) {
        const ticketNumber = this.getTicketNumber(item.name);
        let ticketNumberAlreadyIncluded = false;
        for(let i = 0; i < this.list.length; i++) {
            // If the ticket number and price ("Rate") and item ("Labor") are the same,
            // the tickets can be consolidated.
            if(this.list[i].ticketNumber === ticketNumber && this.list[i].lineItem.price === item.price && this.list[i].lineItem.item === item.item) {
                ticketNumberAlreadyIncluded = true;
                // Add stuff to existing line item
                this.list[i].lineItem.quantity = 
                    (
                        parseFloat(item.quantity)
                        +
                        parseFloat(this.list[i].lineItem.quantity)
                    ).toString();
            }
        }
        if(!ticketNumberAlreadyIncluded) {
            // Add the new line item
            this.list.push({
                ticketNumber: ticketNumber,
                lineItem: item
            });
        }
    }
}

const SyncroInvoiceBody = props => {
    const now = new Date();
    const firstOfTheMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const [btnLabel, setBtnLabel] = useState("Push Invoices to QuickBooks");
    const [btnIcon, setBtnIcon] = useState("fas fa-arrow-right");
    const [startDate, setStartDate] = useState(firstOfTheMonth.toISOString().substring(0,10));
    const [endDate, setEndDate] = useState(now.toISOString().substring(0,10));
    const [urls, setUrls] = useState(null);
    const [showInput, setShowInput] = useState(true);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [progress, setProgress] = useState(0);
    const [progressBarLabel, setProgressBarLabel] = useState("Working...");
    const [controller] = useState(new AbortController());
    const [signal] = useState(controller.signal);
    const banners = useContext(BannerContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        const startDate_ = new Date(startDate);
        const endDate_ = new Date(endDate);
        const dateRange = (endDate_.getTime() - startDate_.getTime()) / (1000*60*60*24); // difference in days
        if(dateRange < 0) {
            alert("Start Date must be earlier than End Date.");
            return;
        }
        else if(dateRange > 31) {
            alert("Date range must not exceed 31 days.");
            return;
        }
        else {
            setBtnLabel("Pushing...");
            setBtnIcon("fas fa-spinner");
            handleSubmitCallback();
        }
    }

    const incrementProgress = (n, proportion) => {
        const additionalProgress = ((proportion * 100) / n);
        setProgress(prevProgress => (prevProgress + additionalProgress));
    }

    /** Returns a successful `res` object or `null` if the call fails. */
    const callSyncroAPI = async(path, opts, resumeLabel) => {
        let res = null;
        await chimera.callSyncroAPI(signal, opts.method, path, opts.body ? opts.body : null, 
            () => setProgressBarLabel("Waiting out Syncro API limits for 1 minute..."),
            () => setProgressBarLabel(resumeLabel))
        .then(response => {
            res = response;
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                console.error(err);
                alert(`${opts.method} ${path} failed (status: ${err.status}). See console output for more details (CTRL+Shift+J)`);
            }
        });
        return res;
    }

    const getChimeraCustomersForInvoices = async(invoices, label, proportion) => {
        try {
            const chimeraCustomers = await chimera.CommercialCustomer.getAll(signal);
            for(let i = 0; i < invoices.length; i++) {
                let found = false;
                for(const customer of chimeraCustomers) {
                    if(customer.integrationIds.syncro === invoices[i].customer_id.toString()) {
                        invoices[i].chimera = customer;
                        found = true;
                        break;
                    }
                }
                if(!found) {
                    banners.addBanner('warning', `No Chimera customer found for Syncro customer ID "${invoices[i].customer_id}". This customer's invoices will not be processed.`, 'Warning');
                }
                incrementProgress(invoices.length, proportion);
            }
            return true;
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
            }
            return false;
        }
    }

    const getLineItemsForInvoices = async(invoices, label, proportion) => {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        for(let i = 0; i < invoices.length; i++) {
            const res = await callSyncroAPI(`/invoices/${invoices[i].id}/line_items`, {
                method: 'GET',
                headers: headers
            }, label);
            if(!res) return false;

            invoices[i].line_items = res;
            incrementProgress(invoices.length, proportion);
        }
        return true;
    }

    const consolidateLineItemsInInvoices = (invoices, proportion) => {
        for(let i = 0; i < invoices.length; i++) {
            let consolidatedLineItemsList = new ConsolidatedLineItemsList();
            for(const lineItem of invoices[i].line_items) {
                // Remove any note Syncro may have had about credit hours being applied.
                lineItem.name = lineItem.name.split(/ - Applied \d\.\d Prepay Hours/g)[0];
                consolidatedLineItemsList.addToList(lineItem);
            }
            incrementProgress(invoices.length, proportion);
            invoices[i].line_items = consolidatedLineItemsList.consolidatedList;
        }
    }

    const applyLaborCreditHours = async(invoices, allowedItemNames, label, proportion) => {
        for(let i = 0; i < invoices.length; i++) {
            // Apply Labor Credit Hours
            const totalCreditHours = invoices[i].chimera ? invoices[i].chimera.laborCreditHours : 0;
            let remainingCreditHours = totalCreditHours;
            if(totalCreditHours > 0.0) {
                for(let j = 0; j < invoices[i].line_items.length; j++) {
                    // TODO: Lock down application to only items in Labor category.
                    // Will become necessary if whitelist grows to contain non-labor items.
                    if(allowedItemNames.includes(invoices[i].line_items[j].item)) {
                        if(parseFloat(invoices[i].line_items[j].quantity) > remainingCreditHours) {
                            // This item will have to be split.
                            const hoursApplied = remainingCreditHours;
                            const qtyRemaining = parseFloat(invoices[i].line_items[j].quantity) - hoursApplied;
                            invoices[i].line_items[j].quantity = qtyRemaining.toString();
                            console.log(`invoice ${invoices[i].number} item ${j} set to qty ${invoices[i].line_items[j].quantity}`);
                            invoices[i].line_items[j].name += ` - Applied ${hoursApplied} Credited Labor Hours`;
                            remainingCreditHours = 0.0;
                        }
                        else {
                            // Apply as many credit hours as possible.
                            const hoursApplied = parseFloat(invoices[i].line_items[j].quantity);
                            invoices[i].line_items[j].quantity = "0.0";
                            invoices[i].line_items[j].name += ` - Applied ${hoursApplied} Credited Labor Hours`;
                            remainingCreditHours -= hoursApplied;
                        }
                        if(remainingCreditHours <= 0.0) {
                            break;
                        }
                    }
                }
            }
            incrementProgress(invoices.length, proportion);
        }
        return true;
    }

    const getTermsForInvoices = async(invoices, label, proportion) => {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        for(let i = 0; i < invoices.length; i++) {
            let obj = await callSyncroAPI(`/customers/${invoices[i].customer_id}/invoice_term_id`, {
                method: 'GET',
                headers: headers
            }, label);
            if(!obj) return false;
            const invoiceTermId = obj.invoice_term_id;

            const termName = invoiceTermId === 8179 ? "Net 30" : "Net 15";

            try {
                const termObj = await chimera.callQuickBooksAPI(signal, `/api/qb/term/${termName}`);
                invoices[i].term = {Id: termObj.Id, Name: termObj.Name};
            }
            catch(err) {
                console.error(err);
                banners.addBanner('danger', `Request Failed: GET /api/qb/term/${termName}${err.status ? ` (Status: ${err.status})` : ''}`, 'Error');
                return false;
            }

            incrementProgress(invoices.length, proportion);
        }
        return true;
    }

    const createInvoices = async(invoices, allowedItems, proportion) => {
        let newUrls = [];
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        for(let i = 0; i < invoices.length; i++) {
            if(invoices[i].chimera && invoices[i].chimera.integrationIds.quickbooks) {
                console.log(`making invoice ${invoices[i].number}:`);
                setProgressBarLabel(`Creating Invoice ${invoices[i].number} in QuickBooks...`)
                try {
                    const urlObj = await chimera.callQuickBooksAPI(signal, '/api/qb/invoice', 'POST', {
                        number: invoices[i].number,
                        customerId: invoices[i].chimera.integrationIds.quickbooks,
                        customerName: invoices[i].chimera.displayName,
                        lineItems: invoices[i].line_items.map(item => {
                            // Tax-Exempted customers turn ITSA items into YR-Labor items and Break/Fix into YR-Break/Fix Labor
                            if(!invoices[i].chimera.taxExempt) return item;
                            else {
                                if(item.item.trim() === "ITSA") {
                                    item.item = "YR-Labor";
                                }
                                else if(item.item.trim() === "Break/Fix") {
                                    item.item = "YR-Break/Fix Labor";
                                }
                                return item;
                            }
                        }),
                        allowedItems: allowedItems,
                        term: invoices[i].term,
                        standardRate: invoices[i].chimera.standardRate,
                        afterHoursRate: invoices[i].chimera.afterHoursRate
                    });
                    newUrls.push(urlObj);
                }
                catch(err) {
                    console.error(err);
                    if(err.status) {
                        banners.addBanner('danger', `Invoice creation failed (Status: ${err.status})`, invoices[i].chimera.displayName)
                    }
                    else {
                        banners.addBanner('danger', 'Invoice creation failed (unhandled error)', invoices[i].chimera.displayName)
                    }
                    return null;
                }
            }
            else if(invoices[i].chimera && !invoices[i].chimera.integrationIds.quickbooks) {
                banners.addBanner('warning', `${invoices[i].chimera.displayName} has no QuickBooks integration. No invoice(s) will be created for them.`, 'Warning');
            }
            incrementProgress(invoices.length, proportion);
        }
        return newUrls;
    }

    const handleSubmitCallback = async() => {
        try {
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let label = "Fetching invoices from Syncro...";
            setProgress(0);
            setShowInput(false);
            setShowProgressBar(true);
            setProgressBarLabel(label);
            // Fetch the invoices between `startDate` and `endDate`
            const invoices = await callSyncroAPI(`/invoices/range/${startDate}/${endDate}`, {
                method: 'GET',
                headers: headers
            }, label);
            if(invoices === null) return;
    
            // For each invoice, get the corresponding QuickBooks customer ID
            label = "Matching Syncro invoices with QuickBooks customers...";
            setProgress(1);
            setProgressBarLabel(label);
            let success = await getChimeraCustomersForInvoices(invoices, label, 0.15);
            if(!success) return;
    
            // For each invoice, get their line items
            label = "Getting line items for each invoice from Syncro...";
            setProgressBarLabel(label);
            success = await getLineItemsForInvoices(invoices, label, 0.20);
            if(!success) return;
    
            // Consolidate line items
            label = "Consolidating line items...";
            setProgressBarLabel(label);
            consolidateLineItemsInInvoices(invoices, 0.05);
    
            // Get Labor Credit Hours per customer from Syncro
            const allowedItemNames = [
                "ITSA", "Break/Fix", "YR-Labor", "YR-Break/Fix Labor"
            ];
            label = "Applying labor credit hours...";
            setProgressBarLabel(label);
            success = await applyLaborCreditHours(invoices, allowedItemNames, label, 0.15);
            if(!success) return;
    
            // Get the IDs of the list of allowed items (right now, just "ITSA" and "Break/Fix")
            // TODO: Allow user to specify the items they want to include,
            // and have defaults from the hardcoded whitelist
            setProgressBarLabel("Getting item IDs from QuickBooks...");
            let allowedItems = [];
            try {
                allowedItems = await chimera.callQuickBooksAPI(signal, '/api/qb/items', 'POST', {whitelist: allowedItemNames});
            }
            catch(err) {
                console.error(err);
                if(err.status) {
                    banners.addBanner('danger', `Request failed: POST /api/qb/items${err.status ? ` (Status: ${err.status})` : ''}`, 'Error');
                }
                else {
                    banners.addBanner('danger', 'An unhandled error occurred while trying to fetch the item IDs from QuickBooks', 'Error');
                }
            }

            // Get the invoice terms for each invoice
            label = "Setting invoice terms...";
            setProgressBarLabel(label);
            success = await getTermsForInvoices(invoices, label, 0.16);
            if(!success) return;
    
            // For each invoice, make the request to create it within QuickBooks
            setProgressBarLabel("Creating invoices in QuickBooks...");
            const newUrls = await createInvoices(invoices, allowedItems, 0.27);
            if(newUrls === null) return;

            setUrls(newUrls);
            setProgress(100);
            setProgressBarLabel("Done!");
        }
        catch(error) {
            console.error(error);
            alert(`Job terminated - An unhandled error has occurred. \n\nPlease refresh and try again.`);
            return;
        }
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if (name === "startDate") {
            setStartDate(value);
        }
        else if (name === "endDate") {
            setEndDate(value);
        }
    }

    let urlList = [];
    if(urls) {
        for(const url of urls) {
            if(url.url) {
                urlList.push(
                    <li>
                        <a href={url.url} target="_blank" rel="noopener noreferrer">
                            Invoice {url.number} ({url.name}) {url.note ? (url.note) : null}
                        </a>
                    </li>
                )
            }
            else if(url.note) {
                urlList.push(
                    <li>
                        Invoice {url.number} ({url.name}) was not created ({url.note})
                    </li>
                )
            }
            else {
                urlList.push(
                    <li>
                        Invoice {url.number} ({url.name}) was not created
                    </li>
                )
            }
        }
    }
    return (
        <div className="col-lg-12">
            {showProgressBar ? 
                <ProgressBar bgColor="#99ccff" progress={progress.toString()} height={30} label={progressBarLabel}/>
            : null}
            {showInput ?
            <>
                <form id="invoiceDatesForm" onSubmit={handleSubmit} noValidate>
                    <div className="mb-3 centered">
                        <label htmlFor="startDate" className="form-label">Start Date:
                            <input id="startDate" name="startDate"  
                            className="form-control centered" type="date" 
                            aria-describedby="startDateDescr" value={startDate} 
                            onChange={handleChange} required/>
                        </label>
                        <div id="startDateDescr" className="form-text">
                        The tool will transfer invoices created on or after this date.
                        </div>
                        <label htmlFor="endDate" className="form-label">End Date:
                            <input id="endDate" name="endDate"  
                            className="form-control centered" type="date" 
                            aria-describedby="endDateDescr" value={endDate} 
                            onChange={handleChange} required/>
                        </label>
                        <div id="endDateDescr" className="form-text">
                        The tool will transfer invoices created on or before this date.
                        </div>
                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                            <i className={btnIcon}></i>
                            <span>&nbsp;{btnLabel}</span>
                        </button>
                    </div>
                </form>
            </>
            : null }
            {urls != null ?
                <div className="text-start">
                    <ol>
                        {urlList}
                    </ol>
                </div>
            : null }
        </div>
    );
}

const SyncroInvoice = props => {
    const toolName = "Syncro Invoice";
    const toolId = "syncroinvoice";
    return (
        <ToolPage toolId={toolId} toolName={toolName}>
            <ToolPage.Header image={syncroinvoiceimg} alt="Arrow from Syncro to QuickBooks" toolName={toolName}>
                This tool performs a one-time, one-way migration of invoices from
                Syncro to QuickBooks. Along the way, it automatically applies
                credit hours and consolidates line items corresponding to the same
                ticket number. The resulting invoices are linked on this page
                at the end of the process for convenience.
            </ToolPage.Header>
            <ToolPage.How>
                <h3>
                    Preparation
                </h3>
                <p>
                    This tool reads invoices from Syncro, so please be sure that the targeted invoices
                    have been appropriately prepared. This can be accomplished at least in part by
                    Syncro's automation, but it is also important to ensure that each invoice is populated
                    with line items (i.e., a ticket must be added to the invoice for it to count).
                </p>
                <p>
                    This tool will read the <b>Labor Credit Hours</b> custom field for the customer within
                    Syncro and distribute them accordingly. For the smoothest experience, please ensure
                    that credit hours exist here and nowhere else in Syncro, so that Syncro does not try
                    to apply them and that this tool may have full control in that respect. Note that an
                    empty "Labor Credit Hours" field is treated as a value of 0.
                </p>
                <h3>
                    Usage
                </h3>
                <ol>
                    <li>
                        <b>Enter the "Start Date".</b> This is the lower bound on the range of invoices to process.
                    </li>
                    <li>
                        <b>Enter the "End Date".</b> This is the upper bound on the range of invoices to process.
                    </li>
                    <li>
                        <b>Click "Push Invoices to QuickBooks".</b> This starts the job, which will grab all invoices
                        created on or between the given dates and perform several API calls to gather the
                        necessary information for the migration to QuickBooks.
                    </li>
                    <li>
                        <b>Wait patiently.</b> The tool can take a while to run. Watch as the progress bar updates
                        over time or grab a beer if it's really chugging along. Be sure to check back to look
                        for errors.
                        <br/>
                        <b><u>NOTE:</u></b> If the app encounters an unexpected error, it may freeze. If the progress
                        bar gets stuck for a suspiciously long time, please ask for assistance in diagnosing these
                        stealthy errors.
                    </li>
                    <li>
                        <b>View the list of links.</b> Each link will open a new tab that takes you to the invoice within
                        QuickBooks, where you are able to make any additional changes as you see fit.
                        <br/>
                        <b><u>NOTE:</u></b> Sometimes an invoice won't come with a link. This is usually because the invoice
                        lacked line items. Note that ("no line items") means that the invoice never had any line items in
                        Syncro to begin with, while ("no valid line items") means that the invoice didn't have any line items
                        remaining after filtering out the undesired item types. In addition, you may see an item with a link
                        but with a note that says "already exists", which means that an invoice with the same number already
                        existed in QuickBooks. The link in that case will take you to the preexisting invoice.
                    </li>
                </ol>
                <p>
                    <b><u>NOTE:</u></b> The date range may not exceed 31 days. If you wish to migrate invoices created within a date
                    range greater than 31 days, then you will have to run this tool multiple times.
                </p>
                <h3>
                    Behind the Scenes
                </h3>
                <p>
                    The label under the progress bar will update with some information on where the tool is at
                    in the process, but here is a brief (and somewhat technical) breakdown:
                </p>
                <ol>
                    <li>
                        First, the tool uses the Syncro API to grab all invoices created between the Start Date
                        and End Date (inclusively).
                    </li>
                    <li>
                        The version of the invoices that the tool gets back from the first step is not the whole
                        story. The internal IDs of each invoice, given by the API response, is used to ask for
                        more detailed information, from which the tool can gather the line items for each invoice.
                    </li>
                    <li>
                        With all the line items gathered, they are consolidated where the ticket number, item type
                        (i.e., "Labor") and Rate all match. The resulting line item has the total "QTY" and "AMOUNT"
                        as you can see in the final QuickBooks invoice.
                    </li>
                    <li>
                        After consolidating the line items, the tool then requests the Labor Credit Hours for the
                        invoices' customer and distributes discounts throughout, marking the items that have been
                        discounted along the way.
                    </li>
                    <li>
                        In order to submit a list of line items to the QuickBooks API as part of creating an invoice,
                        it's not sufficient to just have the name of the item type. So, we have to make a call to the
                        QuickBooks API to get the ID of each kind of item we want to use.
                    </li>
                    <li>
                        The last piece we need before we can request for a new invoice to be created within QuickBooks
                        is to get the internal ID corresponding to the customer. So, we make another set of QuickBooks
                        API calls to get the ID for each invoice's associated customer.
                    </li>
                    <li>
                        Finally, we have everything we need to start creating invoices in QuickBooks one by one. At
                        this point, you can see in the progress bar which invoice is currently being created.
                    </li>
                    <li>
                        Once all invoices have been created, the tool displays the links and the job is done.
                    </li>
                </ol>
                <p>
                    It's worth noting that the Syncro API response bodies come in pages, limited to a certain amount of
                    objects per page. Because of this, calls such as those made to get the invoices and then the line items
                    for each invoice can spawn multiple calls as all necessary pages are requested. Because of this, the
                    tool could potentially run into a case where it exceeds the API limit of 180 requests per minute. In
                    that case, the progress bar will inform you that it is waiting for 1 minute before continuing again.
                </p>
            </ToolPage.How>
            <ToolPage.Body>
                <SyncroInvoiceBody authenticated={props.authenticated}/>
            </ToolPage.Body>
        </ToolPage>
    );
}

export default SyncroInvoice;